import React, { Component } from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import Select from '../../Generics/Select';
import SearchBar from '../../Generics/SearchBar';
import Graph from '../../Generics/Graph';
import CustomInput from '../../Generics/Input';

class Analytics extends Component {

    constructor(props){
        super(props);
        this.filter = this.filter.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.increaseRecordLimit = this.increaseRecordLimit.bind(this);
        this.state = {
            detail_record_limit: 9,
            filters:{
                event_type: 'ALL',
                date_period: 'day',
            }
        };
        this.fetchAnalyticsData();
      }

    filter(searchText) {
        this.setState({searchText: searchText});
    }

    fetchAnalyticsData(){
        const { date_period, dateFrom, dateTo } = this.state.filters;
        const map = { day: 1, week:7, month: 30 };
        let after_date, before_date;

        if(date_period == 'custom'){
            let date_from = new Date(dateFrom);
            let date_to = new Date(dateTo);
            after_date = date_from.getTime();
            before_date = date_to.getTime();
            if(!after_date && !before_date){
                return;
            }
        } else {
            let result = new Date();
            result.setDate(result.getDate() - map[date_period]);
            after_date = result.getTime();
        }

        axios.get('/api/analytics', {
            params:{
                filters:{after_date, before_date}
            }
        })
            .then(
                (res) => {
                    let records = [];
                    for (const id in res.data) {
                        const audit = res.data[id];
                        if(
                            !audit.event.includes("RECORD") 
                            && !audit.event.includes("DEAL") 
                            && !audit.event.includes("RECORD") 
                            && !audit.event.includes("VENDOR") 
                            && audit.user_id != 'davidcurbina@gmail.com'
                        ){
                            audit.date_obj = new Date(audit.created_on);
                            audit.name = new Date(audit.created_on).toLocaleDateString();
                            records.push(audit)
                        }
                    }
                    this.state.records = records;
                    this.setState(this.state);
                })
            .catch((e) => console.log(e));
    }

    increaseRecordLimit(){
        let {detail_record_limit} = this.state;
        detail_record_limit += 10;
        this.setState({detail_record_limit});
    }

    handleDateChange(e){
        const name = e.target.name;
        let value = e.target.value;

        this.state.filters[name] = value;
        this.setState(this.state, ()=>{
            if(this.state.filters.dateFrom && this.state.filters.dateTo){
                this.fetchAnalyticsData();
            }
        });
    }

    handleDropdown(e){
        const name = e.target.name;
        let value = e.target.value;

        this.state.filters[name] = value;
        this.setState(this.state, ()=>{
            if(name == 'date_period'){
                this.setState({detail_record_limit: 9});
                this.fetchAnalyticsData();
            }
        });
    }

    renderDetailsTable() {
        const { records, filters, searchText, detail_record_limit } = this.state;
        const {date_period, event_type} = filters;
        let returnElement = [];
        let recordsElements = [];
        let i = 0;
        let d = 0;

        if(!records){
            return;
        }

        records.forEach((record)=>{
            const date = new Date(record.created_on);
            const text = searchText ? searchText.toLowerCase() : '';
            if((record.event == event_type || event_type == "ALL") && (
                text === '' ||
                record.user_id.toLowerCase().includes(text) ||
                record.event.toLowerCase().includes(text)
                || (
                    record.event_details && record.event_details.name && record.event_details.name.toLowerCase().includes(text)
                )
            )){
                if(i > detail_record_limit){
                    i++;
                    return;
                }
                let details = 'N/A'
                if(["DOWNLOAD", "VIEW_FILE"].includes(record.event) && record.event_details){
                    details = [];
                    const {type, name} = record.event_details;
                    if(type){
                        details.push(`Type: ${type}`);
                    }
                    if(name){
                        details.push(`Name: ${name}`);
                    }
                    details = details.join(', ');
                }
                recordsElements.push(
                    <tr>
                        <td className="center">{record.user_id}</td>
                        <td className="center">{record.event}</td>
                        <td className="center">{date.toLocaleDateString()}</td>
                        <td className="center mobile-hide">{details}</td>
                    </tr>
                );
                i++;
                d++;
            }
        });
        returnElement.push(
            <table>
                <thead>
                <tr>
                    <th className="center">Username</th>
                    <th className="center">Event</th>
                    <th className="center">Date Created</th>
                    <th className="center mobile-hide">Details</th>
                </tr>
                </thead>
                <tbody>
                {recordsElements}
                </tbody>
            </table>
        )

        if(i > d){
            // Show more option
            returnElement.push(
                <span
                    className='col s12 center'
                    style={{color: '#0089ec', cursor: 'pointer'}}
                    onClick={this.increaseRecordLimit}
                >Show More</span>,
            );
        }

        // Table Displaing count
        returnElement.push(
            <h5 className='right'>Showing: {d} of {i}</h5>
        );
        return returnElement;
    }

    renderSummaryGraph(){
        const { records = [], filters } = this.state;
        const { event_type } = filters;
        let data = [];

        // Create XY Data for graph
        records.forEach((record)=>{
            if(event_type != 'ALL' && record.event != event_type){
                return;
            }
            let index = data.findIndex(item=>new Date(item.x).toLocaleDateString("en-US") == new Date(record.name).toLocaleDateString("en-US"));
            if(index === -1){
                const date = new Date(record.name);
                data.push({x: date.getTime(), y:1, date: record.date_obj});
            } else {
                data[index].y += 1;
            }
        });
        if(data.length == 1){
            // Create fake data for previous day with count 0
            const prevDay = new Date(data[0].date)
            prevDay.setDate(prevDay.getDate() - 1);
            data.push({x: prevDay.getTime(), y:0, date: prevDay});
        }
        data.sort((a, b) => a.date - b.date);

        return (
            <div className = "row">
                <Graph
                    data ={{
                        xtitle:'Date',
                        ytitle:'Number of Events',
                        data,
                    }}
                    buildY = {true}
                    type = "line"
                />
            </div>
        )
    }

    renderSummaryText(){
        const {filters, records} = this.state;
        const {event_type, date_period} = filters;
        const elements = [];
        let summary = {
            DOWNLOAD: {
                count: 0,
                name: 'Downloads'
            },
            VIEW_FILE: {
                count: 0,
                name: 'File Views'
            },
            LOGIN: {
                count: 0,
                name: 'Logins'
            },
        };

        if(!records){
            return;
        }

        // Build Summary for all types
        records.forEach((rec)=>{
            summary[rec.event].count += 1;
        });

        // Build Summary Sections
        for(var prop in summary){
            const item = summary[prop];
            if(prop == event_type || event_type == "ALL"){
                elements.push(
                    <div className="col l4 s12 center" style={{marginTop: '1rem'}}>
                        <h5>{item.name}</h5>
                        <h5>{item.count}</h5>
                    </div>
                );
            }
        }

        return (
            <div className="row">
                <div className="col s12" style={{padding: '2.5rem'}}>
                    {elements}
                </div>
            </div>
        )
    }

    renderDetailsData(){
        return(
            <div className = "col s12">
                <h4>Details</h4>
                <p className ="col l8">You can get detailed information on user activty using in this section.</p>
                <SearchBar
                    onInput = {this.filter}
                    className="col s12 l4 offset-s1 s12"
                    placeholder="Enter username, event type"
                />
                {this.renderDetailsTable()}
            </div>
        );
    }

    renderSummaryData(){
        const {event_type, date_period, dateFrom, dateTo} = this.state.filters;
        const elements = [];
        elements.push(this.renderSummaryText());
        if(date_period !== 'day'){
            elements.push(this.renderSummaryGraph());
        }
        return (
            <div className = "col s12">
                <h4>Summary</h4>
                    <div className="col l3 s6">
                        <Select
                            key="date_period"
                            disableDefault = {true}
                            placeholder="Date Period"
                            defaultValue="Filter By"
                            name="date_period"
                            value={date_period}
                            errors={[]}
                            options={[
                                {name:"Past Day", value:"day"},
                                {name:"Past Week", value:"week"},
                                {name:"Past Month", value:"month"},
                                {name:"Custom", value:"custom"},
                            ]}
                            onChange={(e)=>this.handleDropdown(e)}
                        />
                    </div>
                    {
                        date_period == 'custom'
                        && <div className = 'col s12'>
                            <CustomInput
                                key={'from'}
                                className={'col l4 s12'}
                                type = {'date'}
                                name={'dateFrom'}
                                disabled={false}
                                placeholder={"From"}
                                errors={[]}
                                value={dateFrom}
                                handleChange={this.handleDateChange}
                            />
                            <CustomInput
                                key={'to'}
                                className={'col l4 s12'}
                                type = {'date'}
                                name={'dateTo'}
                                disabled={false}
                                placeholder={"To"}
                                errors={[]}
                                value={dateTo}
                                handleChange={this.handleDateChange}
                            />
                        </div>
                    }
                    <div className="col l3 s6">
                        <Select
                            key="event_type"
                            disableDefault = {true}
                            placeholder="Event Type"
                            defaultValue="Filter By"
                            name="event_type"
                            value={event_type}
                            errors={[]}
                            options={[
                                {name:"All", value:"ALL"},
                                {name:"Downloads", value:"DOWNLOAD"},
                                {name:"Logins", value:"LOGIN"},
                                {name:"File Views", value:"VIEW_FILE"},
                            ]}
                            onChange={(e)=>this.handleDropdown(e)}
                        />
                    </div>
                {elements}
            </div>
        );
    }

    render(){
        return(
            <div className="row" style={{marginTop: '7%'}}>
				<div className = 'col s12' style={{ paddingLeft: '8%', paddingBottom: '20px'  }}>
					<button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
				</div>
                <div className = 'container'>
                    <h2>Analytics</h2>
                    <div className="row">
                        {this.renderSummaryData()}
                        <div className = "col l12">
                            <hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
                        </div>
                        {this.renderDetailsData()}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(Analytics);