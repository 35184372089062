const datasetTypes = [
    {name: 'Real Sector', value:'real_sector'},
    {name: 'Fiscal', value:'fiscal'},
    {name: 'Financial', value:'financial'},
    {name: 'Sociodemographic Data', value:'sociodemographic'},
    {name: 'International Trade', value:'international_trade'},
    {name: 'Environment', value:'environment'},
    {name: 'Energy', value:'energy'},
    {name: 'Agriculture', value:'agriculture'},
    {name: 'Commodity', value:'commodity'}
];

const dataTypeIndustryMap = {
    real_sector: ['national_accounts', 'consumer_prices', 'labour_market','production', 'commodities','services'],
    fiscal: ['government_revenue', 'government_expenditure', 'government_debt'],
    financial: ['central_bank_data', 'financial_institutions_data', 'securities', 'private_institutions', 'public_institutions', 'real_estate', 'alternative_investments', 'interest_rates', 'exchange_rates'],
    sociodemographic: ['population', 'healthcare', 'education', 'poverty', 'crime'],
    international_trade: ['exports', 'imports', 'foregin_direct_investment', 'balance_of_payments'],
    environment: ['emissions_of_greenhouse_gas', 'waste', 'biodiversity', 'water'],
    energy: ['generation', 'production', 'consumption', 'prices'],
    agriculture: ['production', 'area', 'farmers', 'prices', 'type'],
    commodity: ['commodity_price_indices', 'energy', 'beverages', 'cereals', 'meat', 'seafood', 'sugar', 'vegetable_oils_and_protein_meal', 'agricultural_raw_materials', 'metals', 'fertilizers', 'livestock', 'dairy']
}
const industries = [
    {name: 'National Accounts', value:'national_accounts'},
    {name: 'Consumer Prices', value:'consumer_prices'},
    {name: 'Labour Market', value:'labour_market'},
    {name: 'Production', value:'production'},
    {name: 'Commodities', value:'commodities'},
    {name: 'Services', value: 'services'},
    {name: 'Government Revenue', value:'government_revenue'},
    {name: 'Government Expenditure', value:'government_expenditure'},
    {name: 'Government Debt', value:'government_debt'},
    {name: 'Central Bank Data', value:'central_bank_data'},
    {name: 'Financial Institutions Data', value:'financial_institutions_data'},
    {name: 'Securities', value:'securities'},
    {name: 'Private Institutions', value:'private_institutions'},
    {name: 'Public Institutions', value:'public_institutions'},
    {name: 'Real Estate', value:'real_estate'},
    {name: 'Alternative Investments', value:'alternative_investments'},
    {name: 'Interest Rates', value:'interest_rates'},
    {name: 'Exchange Rates', value:'exchange_rates'},
    {name: 'Population', value:'population'},
    {name: 'Healthcare', value:'healthcare'},
    {name: 'Education', value:'education'},
    {name: 'Poverty', value:'poverty'},
    {name: 'Crime', value:'crime'},
    {name: 'Exports', value:'exports'},
    {name: 'Imports', value:'imports'},
    {name: 'Foregin Direct Investment', value:'foregin_direct_investment'},
    {name: 'Balance of Payments', value:'balance_of_payments'},
    {name: 'Emissions of greenhouse gas', value:'emissions_of_greenhouse_gas'},
    {name: 'Waste', value:'waste'},
    {name: 'Biodiversity', value:'biodiversity'},
    {name: 'Water', value:'water'},
    {name: 'Generation', value:'generation'},
    {name: 'Consumption', value:'consumption'},
    {name: 'Area', value:'area'},
    {name: 'Farmers', value:'farmers'},
    {name: 'Prices', value:'prices'},
    {name: 'Type', value:'type'},
    {name: 'Commodity Price Indices', value:'commodity_price_indices'},
    {name: 'Energy', value:'energy'},
    {name: 'Beverages', value:'beverages'},
    {name: 'Cereals', value:'cereals'},
    {name: 'Fruits', value:'fruits'},
    {name: 'Meat', value:'meat'},
    {name: 'Seafood', value:'seafood'},
    {name: 'Sugar', value:'sugar'},
    {name: 'Vegetable Oils and Protein Meal', value:'vegetable_oils_and_protein_meal'},
    {name: 'Agricultural Raw Materials', value:'agricultural_raw_materials'},
    {name: 'Metals', value:'metals'},
    {name: 'Fertilizers', value:'fertilizers'},
    {name: 'Livestock', value:'livestock'},
    {name: 'Dairy', value:'dairy'},
];


const getIndustries = (dataType) =>{
    const industriesList = dataTypeIndustryMap[dataType];
    if(!industriesList){
        return [];
    }

    return industriesList.map((industry)=>{
        const cat = industries.find(x => x.value == industry);
        if(!cat){
            return {value: industry, name: industry};
        }
        return cat;
    });
};

export { datasetTypes, getIndustries };