import React, { useState } from 'react'
import Graph from './Graph'
import TabSelector from './TabSelector'

export default function MultiTypeGraph({dataGroup, chartTypes = ['line ','bar ', 'pie ', 'area '],...args}) {
	const [chartType, setChartType] = useState('bar');

	const graphElement =  dataGroup ? <Graph
			dataGroup ={dataGroup}
			type = {chartType.trim()}
			{...args}
		/> : 
		<Graph
			type = {chartType.trim()}
			{...args}
		/>

	return (
		<div>
			 <TabSelector
                    options={chartTypes}
                    value={chartType}
                    className = "left col s4 no-print"
                    placeholder=""
                    onChange={(val)=>setChartType(val)}
                />
                <div style={{paddingTop: '1rem'}}>
                   {graphElement}
                </div>
		</div>
	)
}
