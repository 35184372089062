import React, { Component } from 'react';
import {connect} from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';
import Checkbox from '../../../Generics/Checkbox';
import Radiobutton from '../../../Generics/Radiobutton';
import Select from '../../../Generics/Select';
import Graph from '../../../Generics/Graph';
import Chip from '../../../Generics/Chip';
import TabSelector from '../../../Generics/TabSelector';
import axios from 'axios';
import DataView from '../Manage/Group/DataView';
import { datasetTypes, getIndustries } from '../../../Dictionaries/datasetTypes';
import _ from 'lodash';
import { hasPermission } from '../../../../utils/PermissionUtil';
import { Button, Modal, Icon } from "react-materialize";

class SearchStatisticalDatebase extends Component {

    constructor(props){
        super(props);

        this.onRadioChange = this.onRadioChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.chooseDataset = this.chooseDataset.bind(this);
        this.chooseGroup = this.chooseGroup.bind(this);
        this.onTabSelectorChange = this.onTabSelectorChange.bind(this);
        this.loadData = this.loadData.bind(this);

        this.state = {
            viewType: 'table ',
            filterType: 'single ',
            loading: false,
            filterOptions: {
                datasets: undefined,
                groups: undefined,
                locations: undefined,
                dates:undefined,
            },
            filterValues:{
                datasets: '',
                groups: '',
                locations: [],
                dates: [],
                datasetsCompared: []
            }
        };

        // this.state.filterValues = {"datasets":"Tourist Arrivals","groups":"By Year","locations":["Belize"],"dates":["1995","1996","1997","1998","1999","2000","2001","2002","2003","2004","2005","2006","2007","2008","2009","2010","2011","2012","2013","2014","2015","2016","2017","2018"],"datasetsCompared":[],"datatype":"all","filterType":"single","location_type":"country"};
        //{"datasets":"GDP","groups":"PPP International Dollars (current prices)","locations":["Afghanistan","Albania"],"dates":["1980","1981","1982","1983"],"datasetsCompared":[],"datatype":"all","filterType":"single","location_type":"country"};
    }

    loadData(){
        const { filterValues } = this.state;
        if(!filterValues.dates || filterValues.dates.length == 0){
            alert("Missing Dates. Please select date values.");
            return;
        }
        this.setState({loading: true},()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.post(`/api/searchTool/query`, {
                data:{
                    filterValues
                }
            }).then(
                (res) => {
                    const {result, results, error} = res.data;
                    if(result == "Success"){
                        this.setState({data: results, viewType: 'table '});
                    } else {
                        alert(error);
                    }
                }
            ).catch((e)=>this.setState({error: true}))
            .finally(()=>{
                this.setState({loading: false, selectedRows: []});
            });
        });
    }

    filterIsComplete(){
        const { industry, datatype, location_type } = this.state.filterValues;
        if((!industry || industry == '') && datatype !== 'all'){
           return false;
        }
        return true;
    }

    postOptionLoadMessage(queriedField, options){
        const { filterType, filterValues, filterOptions } = this.state;
        const { datasetsCompared } = filterValues;
        const datasetsMatched = [];

        // Notify user of empty matches
        if(options[queriedField] && options[queriedField].length == 0){
            alert('Could not find any matching data for that query.');
            return;
        }

        if(filterType == 'compare ' && queriedField == 'groups' && options[queriedField]){
            Object.keys(options[queriedField]).forEach((key)=>{
                const groupOptions = options[queriedField][key] || [];
                groupOptions.forEach(({dataset})=>{
                    if(!datasetsMatched.includes(dataset)){
                        datasetsMatched.push(dataset);
                    }
                });
            });

            const missingValues = datasetsCompared.filter(dsC=>{
                return !datasetsMatched.find(x=>x==dsC);
            });

            // Notify user of datasets that do not have matches
            if(missingValues.length > 0){
                alert(`Could not find matching groups for datasets: ${missingValues.join(",")}`);
            }
        }
    }

    loadOptionData(queriedField){
        const {filterOptions, filterValues, filterType} = this.state;

        if(!this.filterIsComplete()){
            return;
        }
        filterValues.filterType = filterType.trim();
        this.setState({loading: true},()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.post(`/api/searchTool/options`, {
                data:{
                    filterOptions,
                    filterValues
                }
            }).then(
                (res) => {
                    const {result, options, error} = res.data;
                    if(result == "Success"){
                        this.setState({filterOptions: options}, ()=>{
                            this.postOptionLoadMessage(queriedField, options);
                        });
                    } else {
                        alert(error);
                        //this.setState({error: true});
                    }
                }
            ).catch((e)=>this.setState({error: true}))
            .finally(()=>{
                this.setState({loading: false});
            });
        });
    }

    datatsetToGraphData(rows, dataRows = [], key = ""){
        const {selectedRows, data} = this.state;
        const { header} = data;
        const { parent: headerParent } = header;
        rows.forEach((row)=>{
            if(!row){
                return;
            }
            if(row.children && row.children.length > 0){
                dataRows = this.datatsetToGraphData(row.children, dataRows, row.key + '-');
                if(selectedRows && !selectedRows.includes(row.key)){
                    return;
                }
                dataRows.groups = dataRows.groups || [];
                dataRows.groups.push({name: key + row.key, data: []});
                row.parent.forEach((field, i)=>{
                    let header = headerParent[i];
                    const pieces = header.split('/').reverse();
                    let date = new Date(pieces);
                    date = date.getTime();
                    if(field){
                        dataRows.groups[dataRows.groups.length-1].data.push({x: date, y: field, value: header});
                    }
                });
            } else {
                if(selectedRows && !selectedRows.includes(row.key)){
                    return;
                }
                dataRows.groups = dataRows.groups || [];
                dataRows.groups.push({name: key + row.key, data: []});
                row.parent.forEach((field, i)=>{
                    let header = headerParent[i];
                    const pieces = header.split('/').reverse();
                    let date = new Date(pieces);
                    date = date.getTime();
                    if(field){
                        dataRows.groups[dataRows.groups.length-1].data.push({x: date, y: field, value: header});
                    }
                });
            }
        });

        return dataRows;
        // if()
        // data.push({x: record.name, y:1, date: record.date_obj});
    }

    onTabSelectorChange(prop, value){
        let { filterValues, filterOptions, groupKey } = this.state;

        if(prop == 'filterType'){
            filterValues.datasets = '';
            filterValues.location_type = '';
            filterValues.locations = [];
            filterValues.groups = '';
            filterValues.locations = [];
            filterValues.dates = [];
            filterValues.groupsCompared = [];
            filterValues.datasetsCompared = [];

            filterOptions.locations = [];
            filterOptions.groups = [];
            filterOptions.datasetsCompared = [];
            filterOptions.dates = [];

            groupKey = undefined;
        }
        this.setState({[prop]: value, filterOptions, filterValues, groupKey});
    }

    onRadioChange(type, name, value, key){
        let { filterValues, filterOptions, filterType, groupKey } = this.state;
        filterValues[type] = value;
        if(type == "datasets"){
            filterValues.location_type = '';
            filterValues.locations = [];
            filterValues.locations = [];

            filterOptions.locations = [];
            filterOptions.dates = [];

            if(filterType =='single '){
                filterValues.groups = '';
                filterOptions.groups = [];
            }
        } else if (type == "groups"){
            filterValues.dates = [];
            filterValues.locations = [];
            filterValues.location_type = '';

            filterOptions.dates = [];
            filterOptions.locations = [];
            if(filterType == 'compare '){
                if(groupKey && groupKey != key){
                    if(window.confirm('This is another group type. Selecting a value from this group will remove previously selected groups. Do you wish to continue?')){
                        filterValues.groupsCompared = [];
                    } else {
                        return;
                    }
                }
                groupKey = key;
            }
        }
        this.setState({filterValues, filterOptions, groupKey}, ()=>{
            if(
                (filterType == 'compare ' && type != 'datasets' && type != 'groups')
                || filterType == 'single '
            ){
                this.loadOptionData(name);
            }
        });
    }

    onCheckboxChange(e){
        const { filterValues } = this.state;
        const name = e.target.name;
        const value = e.target.value;

        const i = filterValues[value].findIndex((x)=>x == name);
        if(i > -1){
            filterValues[value].splice(i, 1);
        } else {
            filterValues[value].push(name);
        }

        // Clear Selected dated
        if(value == 'locations' && filterValues[value].length === 0){
            filterValues.dates = [];
        }
        if(value == 'locations' && filterValues[value].length > 15){
            return alert('You cannot select more than 15 locations at a time.')
        }

        this.setState({filterValues}, ()=>{
            // dont reload page for date changes
            if(value != 'dates'){
                this.loadOptionData(name);
            }
        });
    }

    onSelectChange(e){
        const name = e.target.name;
        const value = e.target.value;
        let { filterValues, filterOptions, filterType } = this.state;
        let loadType;
        filterValues[name] = value;

        if(['industry', 'datatype'].includes(name)){
            loadType = 'datasets';
            if(name === 'datatype'){
                filterValues.industry = undefined;
            }

            // Reset Filter
            filterOptions = Object.assign(filterOptions, {
                datasets: [],
                locations: [],
                dates:[],
            });

            filterValues = Object.assign(filterValues, {
                datasets: '',
                locations: [],
                dates: []
            });

            if(filterType == 'single '){
                filterOptions.groups = [];
                filterValues.groups= '';
            }
        }
        if(name == 'location_type'){
            loadType = 'locations';
            filterOptions = Object.assign(filterOptions, {
                locations: [],
                dates:[],
            });
            filterValues = Object.assign(filterValues, {
                locations: [],
                dates: []
            });
        }
        this.setState({filterValues, filterOptions}, ()=>{
            if(name !== 'dates'){
                this.loadOptionData(loadType);
            }
        });
    }

    chooseGroup(){
        const { filterValues, filterOptions } = this.state;
        const { groups, groupsCompared = [] } = filterValues;
        const index = groupsCompared.findIndex(x=>x == groups);

        if(index > -1){
            return alert(`Alert: ${groups} has already been selected.`);
        }

        groupsCompared.push(groups);
        filterValues.groups = undefined;
        filterValues.locations = undefined;
        filterValues.groupsCompared = groupsCompared;

        this.setState({filterValues, filterOptions});
    }

    removeGroupChoice(name){
        let { filterValues, filterOptions, groupKey } = this.state;
        const { groupsCompared = [] } = filterValues;

        const index = groupsCompared.findIndex(x=>x == name);

        if(index > -1){
            filterValues.groupsCompared.splice(index, 1);
        }
        filterOptions.locations = undefined;

        if(groupsCompared.length == 0){
            groupKey = undefined;
        }
        this.setState({ filterValues, filterOptions, groupKey });
    }

    chooseDataset(){
        const { filterValues, filterOptions } = this.state;
        const { datasets, datasetsCompared = [] } = filterValues;
        const index = datasetsCompared.findIndex(x=>x == datasets);

        if(index > -1){
            return alert(`Alert: ${datasets} has already been selected.`);
        }

        datasetsCompared.push(datasets);
        filterValues.datasets = undefined;
        filterValues.groups = undefined;
        filterValues.datasetsCompared = datasetsCompared;
        filterOptions.groups = undefined;

        this.setState({filterValues, filterOptions},()=>{
            this.loadOptionData('groups');
        });
    }

    removeDatasetChoice(name){
        const { filterValues, filterOptions } = this.state;
        const { datasetsCompared = [] } = filterValues;

        const index = datasetsCompared.findIndex(x=>x == name);
        if(index > -1){
            filterValues.datasetsCompared.splice(index, 1);
        }
        filterOptions.groups = undefined;
        filterValues.groupsCompared = [];
        filterValues.locations = [];
        filterValues.dates = [];

        this.setState({ filterValues, filterOptions }, ()=>{
            //if(datasetsCompared.length > 1){
                this.loadOptionData('groups');
            //}
        });
    }

    renderDateOptions(section){
        const { name, title, multiple, className } = section;
        const { allSelected=[], filterOptions, filterValues } = this.state;
        const { xDataType, dates: options = [] } = filterOptions;
        let values = filterValues.dates;

        const years = [], months = [];
        let optionElements = [];

        if(options.length > 1){
            optionElements.push(<Checkbox
                className={"offset-s8 col s4"}
                name={`select-all-${name}`}
                placeholder={"Select All"}
                value={allSelected.includes(name)}
                group={name}
                onChange={()=>{
                    if(allSelected.includes(name)){
                        allSelected.splice(allSelected.indexOf(name), 1);
                        filterValues[name] = [];
                    } else {
                        options.forEach(({name: val})=>{
                            filterValues[name].push(val);
                        });

                        allSelected.push(name);
                    }

                    this.setState({filterValues, allSelected});
                }}
            />);
        }

        options.forEach((option)=>{
            values = values || [];
            const value = values.includes(option.name);
            let placeholder = option.name;

            if(!placeholder){
                return;
            }

            switch(xDataType){
                case 'month':
                    const year = placeholder.split('/')[1];
                    placeholder = placeholder.split('/')[0];
                    if(!years.includes(year)){
                        years.push(year);
                        optionElements.push(<div className="col s12" style={{paddingTop:'1rem'}}>{year}</div>);
                    }
                    break;
                case 'date':
                    break;
            }

            optionElements.push(<Checkbox
                className={className || "col s3"}
                name={option.name}
                placeholder={placeholder}
                value={value}
                group={name}
                inputStyle={{}}
                onChange={this.onCheckboxChange}
            />);
        });

        return optionElements;
    }

    renderGroupCompare(section){
        const { name, title, options = {} } = section;
        const { filterValues, loading } = this.state;
        const { groupsCompared = [], groups: groupVal } = filterValues;

        const optionElements = [];

        if(Object.keys(options).length == 0){
            return;
        }

        if(groupsCompared.length > 0){
            const chips = groupsCompared.map((grpName)=>{
                const grpPlaceholder = grpName.replace('|', ' - ');
                return <Chip name={grpName} placeholder={grpPlaceholder} onRemove={()=>{this.removeGroupChoice(grpName)}} />;
            });

            optionElements.push(<div className="col s12">{chips}</div>);
        }


        if(groupVal) {
            optionElements.push(
                <button
                    style={{margin:'10px'}}
                    className="ppf-primary-button right"
                    disabled={loading}
                    onClick={this.chooseGroup}>
                        Choose Group
                </button>
            );
        };

        Object.keys(options).forEach((key)=>{
            let groupOptions = JSON.parse(JSON.stringify(options[key]));
            const grpOptions = groupOptions.map(option=>{
                option.value = `${option.dataset}|${option.name}`;
                option.name = `${option.dataset} - ${option.name}`;
                return option;
            });

            optionElements.push(<div className="col s12" style={{paddingTop:'1rem'}}>{key.toUpperCase()}</div>);

            optionElements.push(<Radiobutton
                className={"col s12"}
                inputClass={"col s12"}
                group={'groups'}
                value={groupVal}
                options={grpOptions}
                onChange={(group, name, value)=>this.onRadioChange(group, name, value, key)}
            />);
            /*groupOptions.forEach(option=>{
                const value = groupsCompared.includes(option.name);

            });*/
        });

        return optionElements;
    }

    renderFilterSection(section){
        const { allSelected=[], filterOptions, filterValues, filterType } = this.state;
        let optionElements = [];
        let { name, title, multiple, className, preRender, customRender, compareRender, options = []} = section;
        let values = filterValues[name];

        if(preRender){
            optionElements = optionElements.concat(preRender(filterValues));
        }
        if(filterType == 'compare ' && compareRender){
            optionElements = optionElements.concat(compareRender(section));
        } else if(customRender){
            optionElements = optionElements.concat(customRender(section));
        } else {
            if(multiple){
                if(options.length > 1){
                    // Render Select All
                    optionElements.push(<Checkbox
                        className={"offset-s8 col s4"}
                        name={`select-all-${name}`}
                        placeholder={"Select All"}
                        value={allSelected.includes(name)}
                        group={name}
                        onChange={()=>{
                            if(name == 'locations' && options.length > 15){
                                return alert('You cannot select more than 15 locations at a time.')
                            }

                            if(allSelected.includes(name)){
                                allSelected.splice(allSelected.indexOf(name), 1);
                                filterValues[name] = [];
                            } else {
                                options.forEach(({name: val})=>{
                                    filterValues[name].push(val);
                                });

                                allSelected.push(name);
                            }

                            this.setState({filterValues, allSelected}, ()=>{
                                // Do not request options for date changes
                                if(name != 'dates'){
                                    this.loadOptionData(name);
                                }
                            });
                        }}
                    />);
                }

                options.forEach((option)=>{
                    values = values || [];
                    const value = values.includes(option.name);
                    optionElements.push(<Checkbox
                        className={className || "col s3"}
                        name={option.name}
                        placeholder={option.name}
                        value={value}
                        group={name}
                        onChange={this.onCheckboxChange}
                    />);
                });
            } else {
                optionElements.push(<Radiobutton
                    className={"col s12"}
                    inputClass={className || "col s3"}
                    group={name}
                    value={values}
                    options={options}
                    onChange={(group, name, value)=>this.onRadioChange(group, name, value)}
                />);
            }
        }
        return(
            <div className="col s12" style={{overflow: 'auto'}}>
                <h5>{title}</h5>
                {optionElements}
            </div>
        );
    }

    renderSideBar(){
        const sectionsElements = [];
        const { error, loading, filterOptions, filterType, filterValues } = this.state;
        const { datasets, locations, groups, dates} = filterOptions;
        const { datasets: datasetVal, dates: datesFilter = [], groupsCompared = []} = filterValues;

        const filters = [
            {
                name: 'datasets',
                title: 'Datasets',
                options: datasets,
                multiple: false,
                className: 'col s6',
                condition: () => true,
                preRender: (values)=>{
                    const optionElements = [];
                    const { datasetsCompared = [] } = this.state.filterValues;
                    const dataTypes = JSON.parse(JSON.stringify(datasetTypes));
                    dataTypes.unshift({name: 'All', value: 'all'});
                    const industries = getIndustries(values.datatype);
                    industries.unshift({name: 'All', value: 'all'});
                    const chips = datasetsCompared.map((dsName)=>{
                        return <Chip name={dsName} placeholder={dsName} onRemove={()=>{this.removeDatasetChoice(dsName)}} />;
                    });

                    if(filterType == 'compare ' && datasetVal) {
                        optionElements.push(
                            <button
                                style={{margin:'10px'}}
                                className="ppf-primary-button right"
                                disabled={loading}
                                onClick={this.chooseDataset}>
                                    Choose Dataset
                            </button>
                        );
                    };

                    // Add Selected Dataset Chips
                    if(chips.length > 0){
                        optionElements.push(
                            <div className = 'col s12'>
                                {chips}
                            </div>
                        );
                    }

                    // Add Basic Dataset filter options
                    optionElements.push(
                        <div className = 'col s12'>
                            <Select
                                key= 'dataset-type'
                                className= 'col l6'
                                name= 'datatype'
                                placeholder= 'Data Type'
                                value={values.datatype}
                                options={dataTypes}
                                onChange={(e)=>this.onSelectChange(e)}
                            />
                            {values.datatype != 'all' && <Select
                                key= 'dataset-industry'
                                className= 'col l6'
                                name= 'industry'
                                placeholder= 'Industry'
                                value={values.industry || ''}
                                options={industries}
                                onChange={(e)=>this.onSelectChange(e)}
                            />}
                        </div>
                    );
                    return optionElements;
                }
            },
            {
                name: 'groups',
                title: 'Groups',
                options: groups,
                multiple: false,
                className: 'col s6',
                compareRender: (section)=> this.renderGroupCompare(section),
                condition: () => true},
            {
                name: 'locations',
                title: 'Locations',
                options: locations,
                multiple: true,
                className: 'col s4 m6',
                preRender: (values)=>{
                    if(
                        (values.groups && values.groups != '' && filterType == 'single ')
                        || (values.groupsCompared && values.groupsCompared.length > 0 && filterType == 'compare ')
                    ){
                        return (
                            [<div className = 'col s12'>
                                <Select
                                    key= 'location_type'
                                    className= 'col l6'
                                    name= 'location_type'
                                    placeholder= 'Location Type'
                                    value={values.location_type}
                                    options={[
                                        {name:'Global', value: 'global'},
                                        {name:'By Region', value: 'region'},
                                        {name:'By Country', value: 'country'}
                                    ]}
                                    onChange={(e)=>this.onSelectChange(e)}
                                />
                            </div>]
                        );
                    }
                },
                condition: () => true
            },
            {
                name: 'dates',
                title: 'Dates',
                options: dates,
                multiple: true,
                className: 'col s6 m4 l3',
                customRender: (section)=> this.renderDateOptions(section),
                condition: () => true
            },
        ];

        if(!datasets && !loading && !error){
            this.loadOptionData('datasets');
         } else if(error){
            return(<div className="col s12 l8 center">Error Loading Filters...</div>)
        }

        filters.forEach((filter)=>{
            if(filter.name != 'dates' && filter.options && Array.isArray(filter.options)){
                filter.options = filter.options.sort((a, b) => {
                    const nameA = a.name.toUpperCase();
                    const nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }

                      return 0;
                });
            }

            if(!filter.condition || filter.condition()){
                sectionsElements.push(this.renderFilterSection(filter));
                sectionsElements.push( <div className="col s12">
                    <hr className="ppf-hr"/>
                </div>);
            }
        })

        return(
            <div>
                <div className= "col s12">
                    <TabSelector
                        options={['single ','compare ']}
                        value={filterType}
                        className = "left col s10 offset-s1"
                        placeholder="Search Mode:"
                        onChange={(val)=>this.onTabSelectorChange('filterType', val)}
                    />
                </div>
                {datesFilter.length > 0 && <button className="ppf-primary-button right" disabled={loading} onClick={this.loadData}>Update Data</button>}
                {sectionsElements}
            </div>
        );
    }

    renderDataGraph(){
        const { loading, data: datasetData, chartType = "line ", filterValues } = this.state;

        if(!datasetData){
            return;
        }
        const data = this.datatsetToGraphData(datasetData.rows);

        if(!data.groups){
            return;
        }
        // Add Group Name Attribute
        data.groups = data.groups.map(x=>{
            const i = filterValues.locations && filterValues.locations.length > 1 ? 1 : 0;
            const pieces = x.name.split('-');
            const baseName = pieces[i];
            x.groupName = baseName;
            return x;
        })

        data.ytitle = datasetData.yDataType;
        if(['currency', 'measurement'].includes(datasetData.yDataType)){
            data.ytitle += `(${datasetData.currency.toUpperCase() || datasetData.measurement})`;
        }

        if(!data.groups || data.groups.length == 0){
            return (
                <div className = "center" style={{marginTop: '5%'}}>
                    <h5>No Data Selected</h5>
                    <p>Please select data from the table view.</p>
                </div>
            )
        }

        return (
            <div className = "row">
               
            </div>
        )
    }

    rendersDataPreview(){
        const { loading, data, viewType, selectedRows = [], collapsedRows } = this.state;
        let graphElement;

        if(!data){
            return(
                <div className = "center" style={{marginTop: '5%'}}>
                    <h5>No Data</h5>
                    <p>Please select all filters and click "Update Data"</p>
                </div>
            );
        }

        graphElement = <DataView
            loading={false}
            group = {data}
            viewOnly={true}
            selectable={true}
            onSave={()=>{}}
            selectedRows={selectedRows}
            collapsedRows={collapsedRows}
            onRowSelected={(selectedRows)=>{
                this.setState({selectedRows});
            }}
            onCollapseRow={(collapsedRows)=>{
                this.setState({collapsedRows});
            }}
        />

        if(viewType.trim() == 'chart'){
            graphElement = this.renderDataGraph();
        }

        return(
            <div style={{}}>
                <TabSelector
                    options={['chart ','table ']}
                    value={viewType}
                    className = "right col s4 no-print"
                    placeholder=""
                    onChange={(val)=>this.onTabSelectorChange('viewType', val)}
                />
                <div className = "col s12" style={{padding: '0px', marginTop: '15px'}}>
                    {graphElement}
                </div>
            </div>
        );
    }

    renderDataSearchTool(){
        const { viewType } = this.state;
        return (
            <div className="col s12"
             style={{
                 display: 'flex',
                 flexFlow: 'wrap',
                 borderTop: 'solid rgb(232, 232, 232) 1px'}}
                 >
                <div className="col s12 m4 l3 no-print">
                    {this.renderSideBar()}
                </div>
                <div className="col s12 m8 l9 data-preview" style={{alignItems: 'stretch', borderLeft: 'solid #797777'}}>
                    {this.rendersDataPreview()}
                </div>
            </div>
        )
    }

    render(){
        return(
            <div className ='row' style={{marginTop: '7%'}}>
                <div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
                    <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
                </div>
                <div className="container no-print">
                    <div id='plot-header-label' className="col l12">
                        <h2 className="left">Statistical Database</h2>
                        {   (
                                hasPermission(this.props.auth.user, 'database', 'schemas')
                                || hasPermission(this.props.auth.user, 'database', 'datasets')
                            )
                            && <Link to="/database/manage">
                                <button className="ppf-primary-button right">Manage Data</button>
                            </Link>
                        }
                    </div>
                </div>
                <div className="row"  style={{ paddingLeft: '5%' }}>
                    {this.renderDataSearchTool()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(SearchStatisticalDatebase);