import React, { Component } from 'react';

class PublicationItem extends Component{
  render(){
    return(
      <a href={this.props.publication.fileURL}>
        <div className={this.props.class} style={this.props.style}>
          <h3 className="white-text">{this.props.publication.title}</h3>
          <span className="white-text">{this.props.publication.description}
          </span>
        </div>
      </a>
    );
  }
}

export default PublicationItem;