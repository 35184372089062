import React, {Component} from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import * as _ from 'lodash';
import SideNav from './SideNav';
import { Switch } from 'react-materialize';
import PPFView from '../../Generics/PPFView';
import { withTemplate } from '../../Generics/withTemplate';
import generalFields from './Sections/General/Templates/generalFields';
import indFields from './Sections/Individual/Templates/individualInformation';
import entityFields from './Sections/Entity/Templates/entityInformation';
import questionFields from './Sections/Individual/Templates/questions';
import EntitiesView from './Sections/Entity/Entities';
import ContactsView from './Sections/General/Contacts';
import DocumentsView from './Sections/General/Documents';
import { hasPermission } from '../../../utils/PermissionUtil';
import { addFormLayer } from '../../../actions';
import EntityDocuments from './Sections/Entity/Templates/documentsTemplate';
import IndividualDocuments from './Sections/Individual/Templates/documentsTemplate';
import ReportsDocuments from './Sections/General/Templates/reportsTemplate';
import DealsDocuments from './Sections/General/Templates/dealsTemplate';
import Admin from '../Admin/Admin';
import { RiskRating } from './Sections/General/RiskRating/RiskRating';
import { ClientSanctionsView } from './Sections/General/ClientSanctionsView';

const style = {
    content:{
        background: '#F4F5F8',
        margin: "0px",
        minHeight: "100vh",
    },
    container:{
        margin:"85px 0px 0px 0px"
    },
    wrapper:{
        background: "white",
        marginBottom: "15px"
    },
    padded:{
        padding: "40px",
    },
}

class ClientView extends Component{
    constructor(props){
        super(props);

        this.onBack = this.onBack.bind(this);
        this.onDeleteRecord = this.onDeleteRecord.bind(this);
        this.onNew = this.onNew.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.savePartial = this.savePartial.bind(this);
        this.onChangeSectionChange = this.onChangeSectionChange.bind(this);

        this.state = {
            selected: 'general',
            isNew: true
        };
    }

    componentDidMount(){
        let key = null;

        if(this.props.match && this.props.match.params && this.props.match.params.clientId ){
			const section = this.props.location?.state?.section || 'general';
            key = this.props.match.params.clientId;
			
            this.getRecordData(key, true, (data)=>{
                data.key = key;
                this.setState({key, clientData: data, selected: section, isNew: false});
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(
            this.props.location
            && (prevProps.location.state != this.props.location.state)
        ){
            const { formLayer } = this.props;
            const { key } = this.props.location.state || {};
            let { selected, clientData } = this.state;

            if(!key){
                selected = 'general';
            }
            if(key){
                this.getRecordData(key, true, (data)=>{
                    data.key = key;
                    this.setState({key, clientData: data, isNew: false}, ()=>{
                        if (formLayer.length > 0 && formLayer[formLayer.length-1].record && key) {
                            this.loadFormLayer();
                        }
                    });
                });
            } else {
                this.setState({key, selected: 'general', clientData: undefined});
            }
        }
    }

    buildAuth(){
        return { auth: this.props.auth.token, access_token: this.state.access_token};
    }

    buildCustomer({ email = null, address, first_name = null,
        last_name = null, middle_name = null, dob = null,
        gender = null, nationality = null, businessName = null,
        incorporation_type = null, business_purpose  = null, recordClass = null}){

        let customer = {
          type:
            recordClass ? recordClass === "individual"
              ? "INDIVIDUAL"
              : "COMPANY" : null,
          email,
          addresses: address ? [
            {
              property_number: address.number,
              line: address.street,
              city: address.city,
              postal_code: address.postalcode,
              state_or_providence: address.state,
              country: address.country
            }
          ] : null
        };

        if (this.state.clientData.recordClass === "individual") {
          customer = Object.assign(customer, {
            first_name,
            last_name,
            middle_name,
            gender: gender ? gender.toUpperCase() : null,
            dob,
            nationality
          });
        } else {
          customer = Object.assign(customer, {
            company_name: businessName,
            incorporation_country: address.country,
            incorporation_type,
            business_purpose
          });
        }
        return customer;
    }

    loadFormLayer() {
        let self = this;
        const { formLayer } = this.props;
        const { type, record, key } = formLayer.pop();

        addFormLayer(formLayer);

        self.state.selected = 'entities';
        if (key) {
            if (["directors", "management"].includes(type)) {
                self.state.clientData[type].push({ key });
            } else {
                self.state.searchModal = {type, searchRecord:{record, key}};
            }
        }
        self.setState(self.state);
    }

    async savePartial(partialData, template, silent = false, cb = ()=>{}){
        const { inputs } = template;
        let updatedData = {};

        inputs.forEach(({name}) => {
            updatedData[name] = partialData[name];
        });

        this.save(updatedData, (state)=>{
            if(!silent){
                alert('Successfully Saved!');
            }
            cb(state);
        });
    }

    save(updatedData, cb = ()=>{}){
        const { clientData: form = {}, key} = this.state;
        const update = key ? true : false;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;

        let endpoint = update ? '/api/updateRecord' : '/api/createRecord';
        let data = update ? { key, changed: updatedData} : form;

        axios.post(endpoint, { data }).then(
            (res) => {
                this.state.key = res.data.key;
                if(!update){
                    this.props.history.replace(`/client/${this.state.key}`)
                }
                this.state.searchModal = null;
                this.setState(this.state, ()=>{
                    this.onRefresh((state)=>{
                        // Update nested layers
                        let formLayer = this.props.formLayer || [];
                        if(formLayer.length > 0){
                            const layer = formLayer.pop();
                            layer.record = this.state.clientData;
                            layer.key = this.state.key;
                            layer.record.key = layer.key;
                            formLayer.push(layer);
                            addFormLayer(formLayer);
                        }

                        cb(state)
                    });
                });
            })
            .catch(
                ({response}) => {
                    //console.log(response);
            });
    }

    onBack() {
        this.props.history.goBack();
    }

    /**
     *
     * @param {Object} e    includes changes, new state and type of change
     * @param {*} cb    Callback used to send back data to triggering view.
     */
    onChangeSectionChange(e, cb = () => {}){
        let {type, changed: newChanges, newState: data} = e;
        let { changed = {}, key } = this.state;

        changed = Object.assign(changed, newChanges);

        /* Handles record Class Changes */
        if(newChanges && newChanges.recordClass != null && !newChanges.recordType){
            data.recordType = null;
            data = Object.assign(data, this.cleanUpClientInfo(data));
        }

        /* If this is new record then set the current state */
        if(!key){
            this.setState({clientData: data}, ()=>{
                cb(data);
            });
        } else {
            cb(data);
        }
    }

    onDeleteRecord(){
        if(!window.confirm("Do you wish to completely remove this client and all of it's assocaited data?")){
            return;
        }

        const data = { key: this.state.key, form: this.state.clientData};
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post("/api/deleteRecord",{
            data
        }
        ).then(
        (res) => {
            this.setState({error:''});
            this.setState({error:res.data.result}, ()=>{
                alert("Delete Successful!");
                this.props.history.goBack();
            });
        })
        .catch((e)=>console.log(e));
    }

    onRefresh(cb = ()=>{}){
        const { key } = this.state;

        this.getRecordData(key, true, (data)=>{
            data.key = key;
            this.setState({key, clientData: data, isNew: false}, cb(data));
        });
    }

    onNew(type){
        const { form, key } = this.state;
        let formLayer = this.props.formLayer || [];

        formLayer.push(JSON.parse(JSON.stringify({parent: key, record:{}, type})));
        addFormLayer(formLayer);
        this.setState({...this.state, isNew: true}, ()=>{
            this.props.history.push('/client/new');
        });
    }

    onPrint() {
        const { clientData } = this.state;
        let printStructure = []
        this.viewMapping.forEach((view)=>{
            const { component = {} } = view;
            const { template } = component.props || {};
            if(template){
                // has template. add this to print structure
                printStructure = printStructure.concat(template);
            } else {
                // guess we gotta try to figure it out
                if(component.type && typeof(component.type.getTemplate) !== 'undefined' )
                printStructure = printStructure.concat(component.type.getTemplate());
            }
        });

        const data = { record: clientData, structure: printStructure };
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.defaults.headers.common['Accept'] = "application/pdf";
        axios.post("/api/createRecordForm", {
            data,
        }
        ).then(
            (res) => {
                this.displayPrintForm(res.data.fileName);
            })
            .catch((e) => console.log(e));
    }

    cleanUpClientInfo(updatedData){
        const { clientData = {}, form, key, changed  } = this.state;
        const { recordClass } = clientData;

        if(recordClass == 'individual'){
            updatedData.businessName = undefined;
            updatedData.nature = undefined;
            updatedData.incorporation_type = undefined;
            updatedData.business_purpose = undefined;
        } else {
            updatedData.last_name = undefined;
            updatedData.first_name = undefined;
            updatedData.gender = undefined;
            updatedData.nationality = undefined;
            updatedData.dob = undefined;
            updatedData.nameOfBussiness = undefined;
            updatedData.shares = undefined;
            updatedData.occupation = undefined;
            updatedData.identityType = undefined;
            updatedData.identityNumber = undefined;
            updatedData.sourceOfIncome = undefined;
        }

        return updatedData;
    }

    getRecordData(key, details, callback){
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        let data = { key, details};

		if(key != 'new'){
			axios.post('/api/getRecord',{
				data
			}).then(
			(res) => {
				if(res.data.error){
					if(res.data.error === "Access Denied."){
						alert(res.data.error);
						return this.props.history.goBack();
					}
					return alert(res.data.error);
				}
				callback(res.data);
			})
			.catch((e)=>{
				console.log(e);
			});
		}
    }

    renderControls(){
        const { edit_mode = false, selected } = this.state;
        const canEdit = hasPermission(this.props.auth.user, 'clientManagement', selected, 'write');

        return(
            <div className = 'col s12 valign-wrapper no-print'>
                {canEdit && <div className='col l1 offset-l7 hide-on-small-only'>
                    <span className = 'col s12 ppf-control-title'>Edit:</span>
                </div>}
                {canEdit && <div className='col s8 l2'>
                    <Switch
                        id="edit_mode"
                        checked={edit_mode}
                        name="edit_mode"
                        offLabel="Off"
                        onChange={(e)=>{
                            let value = e.target.checked;
                            this.setState({edit_mode: value});
                        }}
                        onLabel="On"
                    />
                </div>}
                <button className="ppf-nav-button col l2 s4" onClick={this.onBack}>Back</button>
            </div>
        )
    }

    renderSection(){
        const { auth, firebase } = this.props;
        const { user } = auth;
        const { selected, clientData = {}, edit_mode = false, key, searchModal } = this.state;
        const { recordClass } = clientData;
        const sectionElement = [];
        const methods = {
            onDeleteRecord: this.onDeleteRecord,
            onRefresh: this.onRefresh,
            onSave: this.savePartial,
            onChange: this.onChangeSectionChange,
            onPrint: this.onPrint,
            onNew: this.onNew
        }
        const superProps = this.props;
        const state = {viewOnly: !edit_mode, data: clientData, searchModal};
        const handlers = { state, methods, superProps };

		const generalTemplate = generalFields(methods, this.props, this.state);
        const entityMap = [
            {id: 'general', component: <PPFView key = "general" {...handlers} template = {generalTemplate} />},
            {id: 'clientInfo', component: <PPFView key = "client" {...handlers} template = {entityFields} />},
            {
                id: 'documents',
                component: <DocumentsView
                    key='edocuments'
                    title='Documents'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                    mapping={EntityDocuments}
                />,
                title: 'Client Documents'
            },
            {
                id: 'reports',
                component: <DocumentsView
                    key='ereport'
                    title = 'Reports'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                    mapping={ReportsDocuments}
                />,
                title: 'Report Documents',
                condition: (data) => {
                    return data.is_client == 'yes';
                }
            },
            {
                id: 'risk',
                component: <RiskRating
                    key='eRisk'
                    title = 'Risk'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                />,
                title: 'Risk Rating'
            },
            {id: 'entities', component: <EntitiesView key = {`${key}_entities`} {...this.props} state={state} superProps={superProps} methods={methods} />},
            {id: 'users', component: <Admin {...this.props} clientId={key} viewOnly={true} />, icon: 'folder_open'},
            {id: 'contacts', component:  <ContactsView state={state} superProps={superProps} methods={methods} />},
            {id: 'sanctions',component: <ClientSanctionsView title = 'AML Screenings' state={state} superProps={superProps} methods={methods} />},
        ];

        const individualMap = [
            {id: 'general', component: <PPFView key = "general" {...handlers} template = {generalTemplate} />},
            {id: 'clientInfo', component: <PPFView key = "client" {...handlers} template = {indFields} />},
            {
                id: 'documents',
                component: <DocumentsView
                    key='idocument'
                    title='Documents'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                    mapping={IndividualDocuments}
                />,
                title: 'Client Documents'
            },
            {
                id: 'reports',
                component: <DocumentsView
                    key='ireport'
                    title = 'Reports'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                    mapping={ReportsDocuments}
                />,
                title: 'Report Documents',
                condition: (data) => {
                    return data.is_client == 'yes';
                }
            },
            {
                id: 'risk',
                component: <RiskRating
                    key='iRisk'
                    title = 'Risk'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                />,
                title: 'Risk Rating'
            },
            {id: 'questions', component: <PPFView key = "questions" {...handlers} template = {questionFields} />},
            {id: 'contacts',component: <ContactsView state={state} superProps={superProps} methods={methods} />},
            {id: 'sanctions',component: <ClientSanctionsView title = 'AML Screenings' state={state} superProps={superProps} methods={methods} />},
        ];

        this.viewMapping = entityMap;

        if(recordClass == 'individual'){
            this.viewMapping = individualMap;
        }

        this.viewMapping.forEach(({id, component, condition = ()=>true}) => {
            if(id == selected){
                const hasAccess = hasPermission(this.props.auth.user, 'clientManagement', id);
                const conditionPassed = condition(clientData);
                if(hasAccess && conditionPassed){
                    sectionElement.push(component);
                } else {
                    alert('Permission denied. Contact you admin.')
                }
            }
        });

        return(
            <div className = 'col s12 ppf-section'>
                {
                    sectionElement.length == 0 ?
                    <div>
                        <h5>No Data Found</h5>
                    </div>
                    : sectionElement
                }
            </div>
        )
    }

    renderClientInfo({peps, sanctions, recordName, key}){
        return <div className ="row print-only">
            <span className="name bold">
                {recordName}
                {peps && " [PEPS]"}
                {sanctions && " [SANCTION]"}
            </span><br/>
            <span className="email">{key}</span>
        </div>;
    }

    render() {
        const { clientData = {}, selected, key, isNew } = this.state;
        if(!isNew && _.isEmpty(clientData)){
            return (
                <div className="row white" style={{height: '400px', marginTop: '20%'}}>
                    <div className = 'valign-wrapper' style={style.container}>
                        <h3 className = 'center col s12'>Loading...</h3>
                    </div>
                </div>
            );
        }
    
        const { first_name = '', last_name = '', businessName = '', email = '', recordClass = 'entity', is_client, profile_image, documents, peps = {}, sanctions={} } = clientData;
        let recordName = businessName;

        if(recordClass == 'individual'){
            recordName = `${first_name} ${last_name}`;
        }
        const generalInfo = {
            key,
            businessName,
            recordName,
            email,
            recordClass,
            isClient: is_client == 'yes',
            profile_image,
            documents,
            peps: peps.confirmed_matches && peps.confirmed_matches.length > 0,
            sanctions: sanctions.confirmed_matches && sanctions.confirmed_matches.length > 0,
        }

        return(
            <div className="row" style={style.content}>
                <div className="col l2 m4 s3 no-print">
                    <SideNav
                        superProps = {this.props}
                        data = { generalInfo }
                        selected = { selected }
                        onChange = { (type) => { this.setState({selected: type, edit_mode: false })} }
                    />
                </div>
                <div className="col l10 m8 s9 center">
                    <div style={style.container}>
                        {this.renderClientInfo(generalInfo)}
                        {this.renderControls()}
                        {this.renderSection()}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
      auth:state.auth,
      formLayer:state.formLayer
    };
}

export default connect(mapStateToProps)(ClientView);