import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import uid from 'uid';
import * as _ from 'lodash';
import firebase from 'firebase';
import FileDownload from 'js-file-download';
import { hasPermission } from '../../../../../utils/PermissionUtil';

import { uploadSingleFile, deleteFile } from '../../../../../utils/FileHandler';
import PPFView from '../../../../Generics/PPFView';
import milestoneFields from './Templates/milestoneFields';
import { successWrapper } from '../../../../../utils/SuccessWrapper';

class MilestonesView extends Component {
    constructor(props){
        super(props);
        
        this.onSave = this.onSave.bind(this);

        this.state = {
            key: this.props.state.data.key,
            milestones: this.props.state.data.milestones || [],
            milestone: {}
        };
    }

    onCloseModal(){
        const { milestones = [] } = this.state;
        const filteredMilestones =  milestones.filter(doc=>{
            return !doc.pending;
        })

        // Clean Up state
        this.setState({modal: false, milestones: filteredMilestones, milestone: {}});
    }

    onChange(e){
        const { milestone } = this.state;
        const { name, value } = e.target;

        milestone[name] = value;

        if(milestone.created_date){
            milestone.modified_date = Date.now();
        } else {
            milestone.created_date = Date.now();
        }
        this.setState({milestone});
    }

    checkIfUserHasPermission(user, permissions){
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        return axios.post('/api/user/hasPermission', {id: user, permissions})
            .then(res=>{
                return successWrapper(res, ({ hasPerm = false })=>{
                    return hasPerm;
                })
            });
    }

    async onSave(milestone, options, changed){
        const promises = [];
        const { milestones, key, original_milestone } = this.state;
        const userHasPerm = await this.checkIfUserHasPermission(milestone.assigned_to, {feature: 'dealsManagement', part: 'milestones'});

        if(!userHasPerm){
            if(!window.confirm('This user does not have priviledges to view this milestone. Continue?')){
                return;
            }
        }

        if(!milestone.key){
            const id = uid(25);
            milestone.key = id;
            milestone.created_date = Date.now();
            milestones.push(milestone);
        } else {
             // Create history record if status changes
             if(changed.status){
                const { milestone_history = [] } = milestone;
                original_milestone.outdated_date = Date.now();
                milestone_history.push(original_milestone);
                milestone.milestone_history = milestone_history;
            }
        }

        if(!key){
            alert('Please save record before adding milestones.');
            return;
        }

        this.props.methods.onSave(this.state, {inputs:[{name: 'milestones'}]}, false, ()=>{
            this.setState({modal: false, milestone: {}});
        });
    }

    onDelete(milestone){
        let { milestones } = this.state;
        const { path, key } = milestone;

        if(!window.confirm('This will permanently delete the file. Do you want to continue?')){
            return;
        }

        milestones = milestones.filter(doc=>{
            if(doc.key == key){
                if(path){
                    deleteFile(firebase, path, () => {
                        alert('Successfully deleted file.');
                    });
                }
                return false;
            }
            return true;
        });
        
        this.props.methods.onSave({milestones}, {inputs:[{name: 'milestones'}]}, true, (state)=>{
            this.setState({milestones: state.milestones || []});
        });
    }

    onView(milestone){
        const data = { id: milestone.path };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/getFileURL", {data})
            .then(
                (res) => {
                    window.open(res.data.downloadURL);
                })
            .catch((e)=>console.log(e));
    }

    onEdit(milestone, modalViewOnly){
        this.setState({
            milestone,
            modal: true,
            modalViewOnly,
            original_milestone: JSON.parse(JSON.stringify(milestone))
        });
    }

    onDownload(milestone) {
        const { path } = milestone;        
        const fileName = path ? path.split(/[\\\/]/).pop() : '';
        const data = { id: path };
        
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/downloadRecordFile",
            {data},
            {responseType: 'arraybuffer'},
        )
            .then(
                (res) => {
                    FileDownload(res.data, fileName);
                })
            .catch((e)=>console.log(e));
    }

	getClients() {
		const { fetching } = this.state;
		if(fetching){
			return;
		}
		this.setState({fetching: true}, ()=>{
			axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
			axios.get('/api/clients')
				.then(
					(res) => {
                        const dealState = this.props.state.data;
                        const clientIds = [];

                        for(const prop in dealState){
                            if(prop.includes('contract_party_')){
                                const cli_id = dealState[prop];
                                clientIds.push(cli_id);
                            }
                        }

						this.state.clients = res.data.clients.filter(client => {
                            if(clientIds.includes(client.value)){
                                return true;
                            }
                            return false;
                        });
                        
						this.setState(this.state);
					})
				.catch((e) => console.log(e))
				.finally(() => this.setState({fetching: false}));
			});
    }

    renderTable(){
        const { viewOnly } = this.props.state;
        const { milestones = [], employees } = this.state;
        const rows = [];

        if(milestones.length == 0){
            return;
        }
        // Sort by Pending
        milestones.sort((a, b) => {
            if ((a.pending || false) > (b.pending || false)) {
                return -1;
            }
            if ((a.pending || false) < (b.pending || false)) {
                return 1;
            }
            return 0;
        });

        milestones.forEach((milestone) => {
            if(!milestone){
                return;
            }
            let { milestone_name, assigned_to, due_date} = milestone;
            const assigedTo = employees.find(x => x.value == assigned_to) || {};
            const dueDate = new Date(due_date).toLocaleDateString();
            
            rows.push(
                <tr key={`${milestone_name}`}>
                    <td>{milestone_name}</td>
                    <td>{assigedTo.name}</td>
                    <td>{dueDate}</td>
                    <td>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(milestone, true)}>View</button>
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'milestones', 'write') &&
                            <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(milestone)}>Edit</button>
                        }
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'milestones', 'delete') &&
                            <button className='ppf-danger-button' onClick={()=>this.onDelete(milestone)}>Delete</button>
                        }
                    </td>
                </tr>
            );
        });

        return(
            <table key={this.props.title.toLowerCase()}>
                <thead>
                <tr>
                    <th className="center">Milestone Name</th>
                    <th className="center mobile-hide">Assigned To</th>
                    <th className="center mobile-hide">Due Date</th>
                    <th className="center mobile-hide">Actions</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderModal (){
		if(!this.state.modal){
		  return(
			<div></div>
		  );
        }

        const { milestone, modalViewOnly = false, employees, clients, } = this.state;
        const contacts = this.props.state.data.contacts ? this.props.state.data.contacts.map(contact => {
            return {name: `${contact.fullName} [${contact.role}]`, value: contact.index};
        }) : [];

        const state = {
            contacts,
            clients,
            employees,
            data: milestone,
            viewOnly: modalViewOnly
        };

        const methods = {
            onRefresh: () => {},
            onSave: (milestone, options, changed)=> this.onSave(milestone, options, changed)
        }

        const inputs = 
            <PPFView
                key ={'milestone-modal'}
                superProps = {this.props.superProps}
                template = {milestoneFields(methods, state)}
                state = {state}
                methods = {methods}
            />;

       
		return(
		  <div id="myModal" className="modal row">
			<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			  <span className="close" onClick={()=>this.onCloseModal()}>&times;</span>
                {inputs}
			</div>
		  </div>
		)
	}

    render(){
        const { viewOnly } = this.props.state;
        const { employees, clients } = this.state;

        if(!employees){
            axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
            axios.get("/api/employees")
                .then(
                    (res) => {
                        this.setState({employees: res.data.employees});
                    })
                .catch((e)=>console.log(e));

            return <div>Loading...</div>;
        }

        if(!clients){
            // Find all partied involved in deal
            this.getClients();
            return <div>Loading...</div>;
        }

        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {this.renderModal()}
                <h5>{this.props.title}</h5>
                {this.renderTable()}
                {
                    !viewOnly 
                    && <button className='ppf-primary-button right' onClick={()=>this.setState({modal: true, modalViewOnly: false})}>Add Milestone</button>
                }
            </div>
        );
    }
}

export default MilestonesView;