import React, {Component} from 'react';

class SearchBar extends Component{

    render(){
        return(
            <div className={this.props.className}>
                <input className="col l10 s10" id="seachInput" type="text" placeholder={this.props.placeholder}
                    onInput={() => this.props.onInput(document.getElementById("seachInput").value)}/>
                <div className = 'col l2 s2'>
                    <img className="responsive-img"style={{ marginTop: '10px'}} src="/static/images/search-icon.png"/>
                </div>
            </div>
        );
    }
}

export default SearchBar;