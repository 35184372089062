const template = [
    {
        permission: [
            {feature: 'clientManagement', part: 'clientInfo'}
        ],
        title:"Personal Information",
        inputs:[
            { name: "last_name", type: "text", placeholder: "Surname", className: "col s6", value: "", required: true},
            { name: "first_name", type: "text", placeholder: "Given Name", className: "col s6", value:"", required: true },
            { name: "gender", type: "select", placeholder: "Gender", className: "col s6", required: true },
            { name: "nationality", type: "select", placeholder: "Nationality", className: "col s6", required: true, dictionaryId: '-MMceMKLCbFo_1c9yJYq' },
            { name: "dob", type: "date", placeholder: "Date Of Birth", className: "col s6", value:"", required: true },
            { name: "nameOfBussiness", type: "text", placeholder: "Name Of Bussiness", className: "col s6", value:"", required: true },
            { name: "shares", type: "number", placeholder: "Percentage Of Shares Held", className: "col s6", value:"", required: true },
            { name: "email", type: "text", placeholder: "Email Address", sensitive: true, className: "col s6", value:"", required: true },
            { name: 'profile_image', placeholder: 'Profile Image', type: 'uploader', className: "col s7", autoUpload: true},
        ],
        pageButtons:[
            { name: 'save' }
        ]
    },
    {
        title: "Proof Of Identity",
        inputs:[
            { name: "identityType", type: "select", placeholder: "Identity Type", className: "col s6", required: true },
            { name: "identityNumber", type: "text", sensitive: true, placeholder: "ID Number", className: "col s6", value: "", required: true }
        ]
    },
        {
        title: "Source Of Income",
        inputs:[
            { 
                name: "sourceOfIncome",
                type: "select", 
                placeholder: "Source Of Income", 
                className: "col s6",
                required: true, 
                dictionaryId: '-MMcuukWgwmNV1ABSulL' 
            },
            { name: "nature", type: "select", placeholder: "Occupation / Industry", className: "col s6", value:"", required: true, dictionaryId: '-MMceB1mgqtim5iJYb0r' },
        ]
    },
    {
        title: 'Address',
        inputs:[
            { name: "address", type: "address", className: "col s6", value: "", required: true }
        ]
    },
    {
        title: "Professional/Banker Reference",
        inputs:[
            { name: "name", type: "text", placeholder: "Full Name", className: "col s6", value: "", required: true },
            { name: "relationship", type: "text", placeholder: "Relationship", className: "col s6", value: "", required: true },
            { name: "bankerAddress", type: "address", className: "col s6", value: "" },
        ]
    },
];

export default template;