const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const inputs = [
    { name: "name", type: "text", placeholder: "Contract Name", className: "col s12", inputClass: "col s6",  innerStyle: halfStyle, required: true},
    { name: "vendor_type", type: "select", placeholder: "Vendor Type",className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true, dictionaryId: '-MKQKAsao69E9pr5yqKd'},
    { name: "start_date", type: "date", placeholder: "Start Date", className: "col s6", value:"", required: true },
    { name: "expiration_date", type: "date", placeholder: "Expiration Date", className: "col s6", value:"", required: true },

    { name: "vendor_id", type: "select", placeholder: "Vendor", className: "col s6", required: true, dictionaryId: '-MKQOjbv744AnI3geCzX'},

    { name: "renewal", type: "select", yesNo: true, placeholder: "Renewal", className: "col s6", required: true},
    { name: "notify_days", type: "number", placeholder: "Notification Sent Before (Days)", className: "col s6", condition: (state)=>{
        return state.renewal == 'yes';
    }}, 
    { name: "is_active", type: "select", yesNo: true, placeholder: "Is Active", className: "col s6"},
];

const template = (methods, state) => {
    if(!inputs.find(x => x.name =='vendor_lead')){
        inputs.push(
            { name: "vendor_lead", type: "select", yesNo: true, placeholder: "Lead", className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true, dictionary: ()=>{
                return state.employees;
            }}
        );
    }
   
    return [
        {
            permission: [
                {feature: 'clientManagement', part: 'general'}
            ],
            title: "General Information",
            inputs,
            pageButtons: [
                { name: 'delete', deletes: true, edits: true, action: ()=>{ methods.onDeleteRecord()}, text: 'Delete Record', className: 'right ppf-danger-button' },
                { name: 'save' }
            ]
        }
    ];
}

export default template;