/**
 * Default response handler.
 *
 * @param {Object} response
 * @param {Function} successCB
 * @param {Function} errorCB
 */
export const successWrapper = (
        response,
        successCB,
        errorCB = () => alert('Error: Request Failed')
    ) => {
    const {data = {}, result, error} = response.data;

    // All responses must have a result prop
    if(!result){
        return errorCB('Invalid Response Format');
    }

    if(result == 'Success' && !error && !data.error){
        return successCB(data);
    } else {
        return errorCB(error || data.error);
    }
}