import React, { Component } from 'react';
import Record from './Record';
import axios from 'axios';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux'
import Select from '../../Generics/Select';
import { getRecordName } from '../../../utils/Formatter';
import WithDictionary from '../DealsManagement/withDictionary';

class VendorsMangement extends Component {
  constructor(props){
    super(props);

    
    this.SelectWithDictionary = WithDictionary(Select, this.props.auth.token);

    this.state = {
        modal:false,
        filters:{
            searchText: null,
            classType: null,
            recordType: null,
            clientType: null,
        },
        records:[]
    };
  }

  componentDidMount() {
    this.getRecords();
  }

  getRecords() {
    const {searchText} = this.state.filters;
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.post('/api/vendors', {data: { searchText } })
        .then(
            (res) => {
                this.state.records = res.data;
                this.setState(this.state);
            })
        .catch((e) => console.log(e));
  }


  handleDropdown(e){
    const name = e.target.name;
    let value = e.target.value;

    this.state.filters[name] = value;
    this.setState(this.state);
  }

  renderRecords(){
    const { history } = this.props;
    let records = [];
    const {vendor_type = ''} = this.state.filters;

    _.forEach(this.state.records, function(value, key) {
      var form = {vendor: value, key};
      records.push(form);
    });

    return _.chain(records)
      .map((form) => {
        if(
            vendor_type === null 
            || vendor_type === '' 
            || form.vendor.vendor_type === vendor_type
        ){
        return(
            <Record key={form.key} vendor={form} history={history}/>
          );
        }
      })
      .compact()
      .value();
  }

  renderTable(){
    return(
      <table>
        <thead>
          <tr>
            <th className="center">Vendor Name</th>
            <th className="center mobile-hide">Type</th>
            <th className="center mobile-hide">Is Active</th>
            <th className="center mobile-hide">Date</th>
          </tr>
        </thead>
        <tbody>
          {this.renderRecords()}
        </tbody>
      </table>
    )
  }

  filter(searchText){
    this.state.filters.searchText = searchText;
    this.setState(this.state, ()=>{
      this.getRecords();
    });
  }

  render(){
    const {vendorType, vendorGroup} = this.state.filters;
    const SelectWithDictionary = this.SelectWithDictionary;
    
    return(
      <div className = 'row' style={{marginTop: '7%'}}>
        <div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
            <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
        </div>
        <div className="container row">
          <h2>Vendors Management</h2>
          <div className="filters">
            <div className='col s12' style={{paddingBottom:"10px"}}>
            </div>
            <div className="col s2">
                <SelectWithDictionary
                  key="vendorTypeFilter"
                  placeholder="Vendor Type"
                  name ="vendor_type"
                  value={vendorType}
                  errors={[]}
                  dictionaryId= '-MKQKAsao69E9pr5yqKd'
                  onChange={(e)=>this.handleDropdown(e)}
                />
            </div>
          </div>
          {this.renderTable()}
          <div className="row">
            <div className="col l8">
            </div>
            <div className="col l4 s12">
              <Link to="/vendor/new">
                <button
                  className="ppf-primary-button right col s12"
                >New Vendor</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state,{ history }){
  return {
    auth:state.auth,
    files:state.files
  };
}

export default connect(mapStateToProps)(VendorsMangement);