import React, { Component } from 'react';
import {connect} from 'react-redux'
import { fetchUserAndLoad } from '../../../../actions';
import { successWrapper } from '../../../../utils/SuccessWrapper';
import axios from 'axios';

class Setup2FA extends Component {
    constructor(props){
        super(props);

        this.onNext = this.onNext.bind(this);

        this.state= {};
    }

    componentDidMount(){
        // Fetch QRCode
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post('/api/auth/generateCode').then((res)=>{
            successWrapper(res, (data)=>{
                this.setState({qrUrl: data.url, secret: data.secret});
            }, () => {
                alert('Error getting QR Code');
            });
        },(err)=>{
            console.log("Error: ",err);
        });
    }

    saveSecret(cb){
        const { secret } = this.state;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post('/api/auth/saveSecret', { secret }).then((res)=>{
            successWrapper(res, ()=>{
                cb();
            }, () => {
                alert('Error: Could not save secret code.');
            });
        },(err)=>{
            console.log("Error: ",err);
        });
    }

    onNext(){
        if(window.confirm('Ensure that you see a 6 digit code in the Google Authenticator App before proceeding. You will not be able to log in without this code. Continue?')){
            this.saveSecret(()=>{
                const { dispatch, history, auth } = this.props;
                fetchUserAndLoad(dispatch, history, auth, '/verifyWithSecret')
            });
        }
    }

    renderQRCode(){
        const { qrUrl } = this.state;
        if(!qrUrl){
            return <div className = 'center'>Loading QR Code...</div>
        }

        return (
            <div className = 'col s12 center'>
                <img src = {`${qrUrl}`}/>
            </div>
        );
    }

    render() {
        return (
            <div className="row" style={{marginTop:"7%"}}>
                <div className = 'col l6 offset-l3'>
                    <h3 className='center'>2 Factor Authentication</h3>
                    <br/>
                    <h5>Kindly complete the below steps to finish the authentication process.</h5>
                    <div className = 'col l12'>
                        <ol>
                            <li>Download the "Google Authenticator" App from the Apple Store(iOS) or the Google Play Store (Android)</li>
                            <li>Click the "+" button on the bottom right hand corner</li>
                            <li>Choose "Scan QR Code"</li>
                            <li>Scan the below QR code</li>
                            <li>Add Account</li>
                        </ol>
                    </div>
                    { this.renderQRCode() }
                    <button className="ppf-nav-button right" onClick={this.onNext}>Next</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(Setup2FA);