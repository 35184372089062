import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { hasPermission } from '../../utils/PermissionUtil';

class ApplicationManagement extends Component{
    render(){
        const items = [];
        const { user } = this.props.auth;
        const options = [
            { title: 'Dictionary', key: 'dictionary', path: '/dictionaries', feature: 'dictionary', icon: 'dictionary_icon.png'},
            { title: 'Analytics', key: 'analytics', path: '/analytics', feature: 'analytics', part: 'analytics', permission: null, icon: 'analytics_icon.png' },
            { title: 'Database', key: 'database', path: '/database/search', feature: 'database', part: 'queryTool', permission: null, icon: 'cloud_icon.png' },
            { title: 'Users', key: 'admin', path: '/admin', feature: 'userManagement', part: 'userManagement', permission: null, icon: 'users_icon.png' },
            { title: 'Publications / Alerts', key: 'publications', path: '/publications', feature: 'publications', part: 'publications', permission: null, icon: 'publish_icon.png' },
            { title: 'Risk Rating Criteria', key: 'riskRating', path: '/riskRating', feature: 'riskRating', part: 'riskRating', permission: null, icon: 'risk_icon.png' },
            { title: 'Data Room Access', key: 'dataroomAdmin', path: '/admin', feature: 'userManagement', part: 'dataRoomManagement', permission: null, icon: 'file_management_icon.png' }
        ];

        options.forEach(option => {
            const {title, key, path, feature, part, permission, icon} = option;
            if(!feature || hasPermission(user, feature, part, permission)){
                items.push(
                    <div key={key} className = "col s12 m6 l4" style={{padding: '15px'}}>
                        <Link to={path}><div className="card">
                            <div class="waves-effect waves-block waves-light" style={{padding: '25px', backgroundColor: '#6671ad'}}>
                                <img class="activator responsive-img" src={`/static/images/${icon}`} style={{height: '200px', width:'auto'}}/>
                            </div>
                            <div class="card-content">
                                <span class="card-title activator grey-text text-darken-4">{title}</span>
                                </div>
                        </div>
                        </Link>
                    </div>
                );
            }
        });
        
        return (
            <div style={{backgroundColor: '#F5F8F8', minHeight:'100vh'}}>
                <div className = 'container center' style = {{paddingTop: '7%', fontWeight: '300'}}>
                    <h4>Application Management</h4>
                    <div className='row'>
                        {items}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { history }){
    return {
        auth:state.auth,
        history
    };
}

export default connect(mapStateToProps)(ApplicationManagement);