import React, { Component } from 'react';
import {connect} from 'react-redux'
import _ from 'lodash';
import axios from 'axios';
import { hasPermission } from '../../../../../utils/PermissionUtil';
import Input from '../../../../Generics/Input';
import Select from '../../../../Generics/Select';
import Measures from '../../../../Generics/Measures';
import { datasetTypes, getIndustries } from '../../../../Dictionaries/datasetTypes';
import { baseTypes, currencies } from '../../../../Dictionaries/yDataTypes.js';
import xDataTypes from '../../../../Dictionaries/xDataTypes';
import yLabelTypes from '../../../../Dictionaries/yLabelTypes';
import Rules from '../../../../Dictionaries/rules';
import {Icon} from 'react-materialize';
import * as css from './dataset.css';

class Schema extends Component {
    constructor(props){
        super(props);

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleMeasurementChange = this.handleMeasurementChange.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onSave = this.onSave.bind(this);

        // default state
        this.state = {
            schema:{
                name: '',
                source: '',
                type: '',
                groups: [
                ],
            },
            errors: [],
            viewOnly: false,
            loading: false
        };
        const { id, viewOnly } = this.props.match.params;
        if(viewOnly){
            this.state.viewOnly = viewOnly == 'true';
        }
        // Write Permission Check
        if(!hasPermission(this.props.auth.user, 'database', 'schemas', 'write')){
            this.state.viewOnly = true;
        }
        if(id != 'new'){
            this.state.loading = true;
            this.loadSchema(id);
        }
    }

    onBack() {
        const { edited } = this.state;

        if(edited){
            if (window.confirm('Any unsaved changes will be removed. Continue?')){
                this.props.history.goBack();
            }
        } else {
            this.props.history.goBack();
        }
    }

    async onSave(showAlert = true, refresh = true) {
        const {schema} = this.state;

        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        return axios.post(`/api/schema`,{
            data: schema
        }).then(
            (res) => {
                const { id, result} = res.data;
                if(result == 'Success'){
                    if(showAlert){
                        alert("Saved!");
                    }
                    if(refresh){
                       return this.loadSchema(id);
                    }
                } else {
                    alert("Error: Could not save.")
                }
            }
        ).catch((e)=>console.log(e));
    }

    onDelete(){
        const {id} = this.state.schema;
        let message = "This will completely delete this schema. Do you want to continue?";

        if(!window.confirm(message)){
            return;
        }
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.delete(`/api/schema?id=${id}`).then(
            (res) => {
                this.props.history.goBack();
            }
        ).catch((e)=>{
            alert("Could not delete Schema")
        })
        .finally(()=>{
            this.setState({loading: false});
        });
    }

    async loadSchema(id){
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        return axios.get(`/api/schema?id=${id}`).then(
            (res) => {
                if(res.data && res.data.schema){
                    const { schema } = res.data;
                    this.setState({schema});
                } else {
                    alert("Could not find Record");
                }
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false});
        });
    }

    handleTextChange(e, group, layer){
        const target = e.target;
        const name = target.name;
        let value = target.value;
        if(name === 'phonenumber'){
            value = target.value.replace(/\D/,'')
        }

        const schema = this.state.schema;
        if(group != undefined && layer != undefined){
            schema.groups[group].layers[layer-1][name] = value;
        } else if(group != undefined){
            schema.groups[group][name] = value;
        } else {
            schema[name] = value;
        }
        this.setState({schema});
        if(_.size(this.state.errors) > 0){
            this.setState({errors:{}});
        }
    }

    handleSelectChange(e, group, layer){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        const schema = this.state.schema;
        if(group != undefined && layer != undefined){
            schema.groups[group].layers[layer-1][name] = value;
        } else if(group != undefined){
            schema.groups[group][name] = value;
        } else {
            schema[name] = value;
        }
        if(name === 'yDataType'){
            delete schema.groups[group].measurement;
            delete schema.groups[group].measurementClass;
            delete schema.groups[group].currency;
            if(value === 'currency'){
                schema.groups[group].currency = 'bzd';
            }
        }
        this.setState({schema});
        if(_.size(this.state.errors) > 0){
            this.setState({errors:{}});
        }
    }

    handleMeasurementChange(key, measuresState){
        const { schema } = this.state;
        schema.groups[key] = Object.assign(schema.groups[key], measuresState);
        this.setState({schema});
    }

    deleteGroup(key){
        const { id } = this.state.schema;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.delete(`/api/schema/group`, {
            data:{
                schema_id: id,
                group_id: key
            }
        }).then(
            (res) => {
                this.loadSchema(id);
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false});
        });
    }

    async insertGroup(){
        let {id} = this.state.schema;
        if(!id){
            await this.onSave(false, true);
            id = this.state.schema.id;
        }
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post(`/api/schema/group`, {
            data:{
                schema_id: id,
                group: {
                    name: `New Group`,
                    description: 'Description',
                    xDataType: 'date',
                    yDataType: 'number',
                    layers: [
                        {
                            index: 0,
                            yLabelType: 'text',
                            description: '',
                        }
                    ]
                }
            }
        }).then(
            (res) => {
                this.loadSchema(id);
                /*
                if(res.data && res.data.schema){
                    const { schema } = res.data;
                    this.setState({schema});
                } else {
                    alert("Could not find Record");
                }*/
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false});
        });

        this.setState(this.state);
    }

    deleteLayer(group, layer){
        this.state.schema.groups[group].layers.splice(layer, 1);
        this.setState(this.state);
    }

    insertLayer(group){
        const i = this.state.schema.groups.layers ? this.state.groups.layers.length + 1 : 0;
        this.state.schema.groups[group].layers.push(
            {
                yLabelType: 'text',
                description: '',
            }
        );
        this.setState(this.state);
    }

    renderDatasetDetails(){
        const { schema, errors, viewOnly } = this.state;
        const { name, source, industry, type, custom_keys} = schema;
        return(
            <div>
                <Input
                    key= 'dataset-name'
                    className={'col l3'}
                    type = 'text'
                    name= 'name'
                    placeholder= 'Schema Name'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={name}
                    handleChange={this.handleTextChange}
                />
                <Input
                    key= 'dataset-source'
                    className={'col l3'}
                    type = 'text'
                    name= 'source'
                    placeholder= 'Default Source'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={source}
                    handleChange={this.handleTextChange}
                />
                <Input
                    key= 'dataset-keys'
                    className={'col s8'}
                    type = 'text'
                    name= 'custom_keys'
                    placeholder= 'Custom Keys (ex. energy, solar)'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={custom_keys}
                    handleChange={this.handleTextChange}
                />
                <div className="col l12" style={{paddingBottom: '1rem'}}>
                    <Select
                        key= 'dataset-type'
                        className= 'col l3'
                        name= 'type'
                        placeholder= 'Default Data Type'
                        errors={errors}
                        value={type}
                        disabled={viewOnly}
                        options={datasetTypes}
                        onChange={this.handleSelectChange}
                    />
                    <Select
                        key= 'dataset-industry'
                        className= 'col l3'
                        name= 'industry'
                        placeholder= 'Default Industry'
                        errors={errors}
                        value={industry}
                        disabled={viewOnly}
                        options={getIndustries(type)}
                        onChange={this.handleSelectChange}
                    />
                </div>
            </div>
        );
    }

    renderLayers(group, layers, locked){
        const { errors, viewOnly } = this.state;
        const layerElements=[];
        layers.forEach((layer, i)=>{
            i += 1;
            const {yLabelType, description, rule} = layer;
            layerElements.push(
                <div key={`layer-container-${group}-${i}`} style={{paddingLeft: `${i*2}rem`}}>
                    <div key={`layer-${group}-${i}`}>
                        <div className="col s12 valign-wrapper">
                            <h5 className="left" style={{paddingRight:'1rem'}}>Layer {i}</h5>
                            {
                                !locked
                                && !viewOnly
                                && hasPermission(this.props.auth.user, 'database', 'schemas', 'delete')
                                && <span onClick={()=>this.deleteLayer(group, i-1)} style={{cursor: 'pointer'}}>
                                <Icon className="left" >delete</Icon>
                            </span>}
                        </div>
                        <Select
                            className= 'col l2'
                            name= 'yLabelType'
                            placeholder= 'Y Label Type'
                            errors={errors}
                            value={yLabelType}
                            disabled={viewOnly || locked}
                            options={yLabelTypes}
                            onChange={(e)=>this.handleSelectChange(e, group, i)}
                        />
                        <Select
                            className= 'col l2'
                            name= 'rule'
                            placeholder= 'Data Rule'
                            errors={errors}
                            value={rule}
                            disabled={viewOnly || locked}
                            options={Rules}
                            onChange={(e)=>this.handleSelectChange(e, group, i)}
                        />
                        <Input
                            className={'col l4 s12'}
                            type = 'text'
                            name= 'description'
                            placeholder= {`Layer ${i} Description`}
                            inputClass={'col s12'}
                            errors={errors}
                            disabled={viewOnly || locked}
                            value={description}
                            handleChange={(e)=>this.handleTextChange(e, group, i)}
                        />
                    </div>
                </div>
            );
        });
        return(
            <div>
                <div className="col s12">
                    {!viewOnly && !locked && <button
                        style={{marginTop: '0px'}}
                        className="ppf-primary-button col l3 offset-l9"
                        onClick={()=>this.insertLayer(group)}
                    >Add Layer</button>}
                </div>
                {layerElements}
            </div>
        )
    }

    renderGroups(){
        const groupElements = [];
        const { schema, errors, viewOnly } = this.state;
        const { groups = {}} = schema;
        let i = 0;
        for(const key in groups){
            const group = groups[key];
            const {name, description, layers, xDataType, yDataType, measurementClass, measurement, currency, locked = false, custom_keys} = group;
            groupElements.push(
                <div key={`group-container-${i}`} className="col s12" style={{paddingTop: '2rem'}}>
                    <div className = "col l12" style={{paddingBottom: '1rem'}}>
                        <hr className="ppf-hr"/>
                    </div>
                    <div className="col s12 valign-wrapper">
                        <h5 className="left" style={{paddingRight:'1rem'}}>{name}</h5>
                        {
                            hasPermission(this.props.auth.user, 'database', 'schemas', 'delete')
                            &&<span onClick={()=>this.deleteGroup(key)} style={{cursor: 'pointer'}}>
                            <Icon className="left">delete</Icon>
                        </span>}
                    </div>
                    <Input
                        className={'col l3 s12'}
                        type = 'text'
                        name= 'name'
                        placeholder= 'Group Name'
                        inputClass={'col l12 s12'}
                        errors={errors}
                        disabled={viewOnly}
                        value={name}
                        handleChange={(e)=>this.handleTextChange(e, key)}
                    />
                    <Input
                        className={'col l4 s12'}
                        type = 'text'
                        name= 'description'
                        placeholder= 'Group Description'
                        inputClass={'col l12 s12'}
                        errors={errors}
                        disabled={viewOnly}
                        value={description}
                        handleChange={(e)=>this.handleTextChange(e, key)}
                    />
                    <Input
                        key= 'dataset-keys'
                        className={'col s8'}
                        type = 'text'
                        name= 'custom-keys'
                        placeholder= 'Group Custom Keys (ex. energy, solar)'
                        inputClass={'col s12'}
                        errors={errors}
                        disabled={viewOnly}
                        value={custom_keys}
                        handleChange={(e)=>this.handleTextChange(e, key)}
                    />
                    <div className = 'col s12'>
                        {locked &&
                        <div className="col s12">
                            <span className="red-text text-lighten-1 left-align">
                                Note: This group has a linked dataset and cannot be modifed.
                            </span>
                        </div>
                        }
                        <Select
                            className= 'col l2'
                            name= 'xDataType'
                            placeholder= 'X Data Type'
                            errors={errors}
                            value={xDataType}
                            disabled={viewOnly || locked}
                            options={xDataTypes}
                            onChange={(e)=>this.handleSelectChange(e, key)}
                        />
                        <Select
                            className= 'col l2'
                            name= 'yDataType'
                            placeholder= 'Y Data Type'
                            errors={errors}
                            value={yDataType}
                            disabled={viewOnly || locked}
                            options={baseTypes}
                            onChange={(e)=>this.handleSelectChange(e, key)}
                        />
                        {yDataType == 'measurement' && <Measures
                            disabled={viewOnly || locked}
                            measurementClass ={measurementClass}
                            measurement = {measurement}
                            onChange = {(state)=>this.handleMeasurementChange(key, state)}
                        />}

                        {yDataType == 'currency' && <Select
                            className= 'col l2'
                            name= 'currency'
                            placeholder= 'Currency Type'
                            errors={errors}
                            value={currency}
                            disabled={viewOnly || locked}
                            options={currencies}
                            onChange={(e)=>this.handleSelectChange(e, key)}
                        />}
                    </div>
                    <div className="col s12" style={{border: '1px solid #dedede', marginTop: '1rem', padding: '2rem'}}>
                        {this.renderLayers(key, layers, locked)}
                    </div>
                </div>
            );
            i++;
        }
        return(
            <div className = "col s12">
                {groupElements}
            </div>
        );
    }

    render(){
        const { schema, loading, viewOnly } = this.state;
        const { id } = schema;
        if(loading){
            return(
                <div className="row">
                    <div className="container" style={{marginTop: '5%'}}>
                        <h4>Loading...</h4>
                    </div>
                </div>
            )
        }
        return(
            <div className="row">
                <div className="container" style={{marginTop: '5%'}}>
                    <div className="col s12" style={{paddingBottom: '2rem'}}>
                        <button className="ppf-nav-button col s1" onClick={this.onBack}>Back</button>
                        {!viewOnly && <button className="ppf-primary-button col s1 offset-s8 padded-right" onClick={()=>this.onSave()}>Save</button>}
                        {
                            !viewOnly
                            && id
                            && hasPermission(this.props.auth.user, 'database', 'schemas', 'delete')
                            && <button className="ppf-danger-button col s1" onClick={()=>this.onDelete()}>Delete</button>}
                    </div>
                    <h2>{id ? 'Modify Schema' : 'Create Schema'}</h2>
                    {this.renderDatasetDetails()}
                    {this.renderGroups()}
                    <div className="col s12" style={{paddingTop: '1.5rem'}}>
                        {!viewOnly && <button
                            className="ppf-primary-button col l3 offset-l9"
                            onClick={()=>this.insertGroup()}
                        >Add Group</button>}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(Schema);