import React, {Component} from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import uid from 'uid';
import * as _ from 'lodash';
import firebase from 'firebase';
import FileDownload from 'js-file-download';
import { hasPermission } from '../../../../../utils/PermissionUtil';

import { uploadSingleFile, deleteFile } from '../../../../../utils/FileHandler';
import PPFView from '../../../../Generics/PPFView';
import chargeFields from './Templates/fixedRateInvoiceFields';

class ChargesView extends Component {
    constructor(props){
        super(props);
        
        this.onSave = this.onSave.bind(this);

        this.state = {
            key: this.props.state.data.key,
            charges: this.props.state.data.charges || [],
            charge: {}
        };
    }

    onCloseModal(){
        const { charges = [] } = this.state;
        const filteredCharges =  charges.filter(doc=>{
            return !doc.pending;
        })

        // Clean Up state
        this.setState({modal: false, charges: filteredCharges, charge: {}});
    }

    onChange(e){
        const { charge } = this.state;
        const { name, value } = e.target;

        charge[name] = value;

        if(charge.created_date){
            charge.modified_date = Date.now();
        } else {
            charge.created_date = Date.now();
        }
        this.setState({charge});
    }

    onSave(charge){
        const promises = [];
        const { charges, key } = this.state;

        if(!charge.key){
            const id = uid(25);
            charge.key = id;
            charge.created_date = Date.now();
            charges.push(charge);
        }

        if(!key){
            alert('Please save record before adding charges.');
            return;
        }

        const fileUploadPromise = (charge)=>{
            const { key, invoice } = charge;
            const file = invoice.files[0];

            return new Promise ((res, rej)=>{
                uploadSingleFile(firebase, key+"/images/", file, "invoice", ({error, path, url})=>{
                    if(error){
                        const error = 'Error: Could not upload file. Please try again.';
                        alert(error);
                        return rej(error);
                    }

                    const updatedDocs = charges.map(docMap=>{
                        if(docMap.key === charge.key){
                            docMap.path = path;
                            delete docMap.invoice;
                        }

                        return docMap;
                    })
                    this.state.charges = updatedDocs;
                });
            });
        }

        charges.forEach(doc=>{
            if(doc.invoice){
                promises.push(fileUploadPromise(doc));
            }
        });

        Promise.all(promises).then(res=>{
            this.props.methods.onSave(this.state, {inputs:[{name: 'charges'}]}, false, ()=>{
                this.setState({modal: false, charge: {}});
            });
        }).catch(err=>{
            console.log(err);
            alert("Error uploading files");
        });
    }

    onDelete(charge){
        let { charges } = this.state;
        const { path, key } = charge;

        if(!window.confirm('This will permanently delete the file. Do you want to continue?')){
            return;
        }

        charges = charges.filter(doc=>{
            if(doc.key == key){
                if(path){
                    deleteFile(firebase, path, () => {
                        alert('Successfully deleted file.');
                    });
                }
                return false;
            }
            return true;
        });
        
        this.props.methods.onSave({charges}, {inputs:[{name: 'charges'}]}, true, (state)=>{
            this.setState({charges: state.charges || []});
        });
    }

    onView(charge){
        const data = { id: charge.path };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/getFileURL", {data})
            .then(
                (res) => {
                    window.open(res.data.downloadURL);
                })
            .catch((e)=>console.log(e));
    }

    onEdit(charge, modalViewOnly){
        this.setState({
            charge,
            modal: true,
            modalViewOnly
        });
    }

    onDownload(charge) {
        const { path } = charge;        
        const fileName = path ? path.split(/[\\\/]/).pop() : '';
        const data = { id: path };
        
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/downloadRecordFile",
            {data},
            {responseType: 'arraybuffer'},
        )
            .then(
                (res) => {
                    FileDownload(res.data, fileName);
                })
            .catch((e)=>console.log(e));
    }

    fetchDictionary(dictionaryId) {
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        let data = { key: dictionaryId };
        axios.post('/api/getDictionary', {
            data
        }).then(
            (res) => {
                if (res.data.error) {
                    return alert(res.data.error);
                }
                this.setState({ taxDictionary: res.data.options});
            })
            .catch((e) => {
                this.setState({ data: []});
                console.log(e);
            });
    }

    renderTable(){
        const { viewOnly } = this.props.state;
        const { charges = [] } = this.state;
        const rows = [];

        if(charges.length == 0){
            return;
        }
        // Sort by Pending
        charges.sort((a, b) => {
            if ((a.pending || false) > (b.pending || false)) {
                return -1;
            }
            if ((a.pending || false) < (b.pending || false)) {
                return 1;
            }
            return 0;
        });

        charges.forEach((charge) => {
            if(!charge){
                return;
            }
            let { charge_name, charge_amount, created_date, path} = charge;
            const createdDate = new Date(created_date).toLocaleDateString();
            rows.push(
                <tr key={`${charge_name}`}>
                    <td>{charge_name}</td>
                    <td>{charge_amount}</td>
                    <td>{createdDate}</td>
                    <td>
                        {path && <button className='ppf-primary-button padded-right' onClick={()=>this.onDownload(charge)}>Download Invoice</button>}
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(charge, true)}>View</button>
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'charges', 'write') &&
                            <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(charge)}>Edit</button>
                        }
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'charges', 'delete') &&
                            <button className='ppf-danger-button' onClick={()=>this.onDelete(charge)}>Delete</button>
                        }
                    </td>
                </tr>
            );
        });

        return(
            <table key={this.props.title.toLowerCase()}>
                <thead>
                <tr>
                    <th className="center">Name</th>
                    <th className="center mobile-hide">Amount</th>
                    <th className="center mobile-hide">Created Date</th>
                    <th className="center mobile-hide">Actions</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderModal(){
		if(!this.state.modal){
		  return(
			<div></div>
		  )
        }

        const { charge, modalViewOnly = false, taxDictionary, milestones } = this.state;
        const state = {
            milestones,
            taxDictionary,
            data: charge,
            viewOnly: modalViewOnly
        };

        if(!taxDictionary){
            this.fetchDictionary('-MKQQe4zg0JtW5aDdgFq');
            return;
        }

        const methods = {
            onRefresh: () => {},
            onSave: (charge)=> this.onSave(charge),
            onChange: (options, cb)=>{
                const { newState } = options;
                const amount = parseInt(newState.charge_amount);
                if( amount > 0 && newState.tax_type){
                    const taxItem = taxDictionary.find(x=> x.value == newState.tax_type);

                    if(!taxItem){
                        alert('Could not find associated tax type and value.')
                        return;
                    }

                    if(isNaN(parseFloat(taxItem.tracked_value))){
                        alert('Invalid tax rate set in dictionary! Value must be a number or decimal.');
                        return;
                    }

                    newState.charge_estimate = ( amount * taxItem.tracked_value) + amount;
                } else {
                    newState.charge_estimate = amount;
                }
                cb(newState);
            }
        }

        const inputs = 
            <PPFView
                key ={'charge-modal'}
                superProps = {this.props.superProps}
                template = {chargeFields(methods, state)}
                state = {state}
                methods = {methods}
            />;

       
		return(
		  <div id="myModal" className="modal row">
			<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			  <span className="close" onClick={()=>this.onCloseModal()}>&times;</span>
                {inputs}
			</div>
		  </div>
		)
	}

    render(){
        const { viewOnly, data } = this.props.state;
        const { milestones } = this.state;

        if(!milestones){
            axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
            axios.post("/api/getMilestones", {dealId: data.key})
                .then(
                    (res) => {
                        this.setState({milestones: res.data.milestones});
                    })
                .catch((e)=>console.log(e));

            return <div>Loading...</div>;
        }

        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {this.renderModal()}
                <h5>{this.props.title}</h5>
                {this.renderTable()}
                {
                    !viewOnly 
                    && <button className='ppf-primary-button right' onClick={()=>this.setState({modal: true, modalViewOnly: false})}>Add Charge</button>
                }
            </div>
        );
    }
}

export default ChargesView;