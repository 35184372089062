import React, { Component } from 'react';
import Select from './Select';
import Input from './Input';
import { typeMap } from '../Dictionaries/clientFormOptions';
import WithDictionary from '../App/DealsManagement/withDictionary';

class Address extends Component {
	componentWillMount(){
		this.SelectWithDictionary = WithDictionary(Select, this.props.superProps.auth.token);
	}
	getDropdownOptions(type) {

		if (typeMap[type] === null) {
			return [];
		}
		return typeMap[type];
	}

	handleSelectChange(e) {
		let value = e.target.value;
		let { number, street, city, state, country, zip, postalcode } = this.props.address;
		const address = { number, street, city, state, country: value, zip, postalcode }

		this.props.onAddressChange(address);
	}

	handleTextChange(e) {
		const name = e.target.name;
		const value = e.target.value;
		let { number, street, city, state, country, zip, postalcode } = this.props.address;
		switch (name) {
			case this.props.name + "Number":
				number = value;
				break;
			case this.props.name + "Street":
				street = value;
				break;
			case this.props.name + "City":
				city = value;
				break;
			case this.props.name + "State":
				state = value;
				break;
			case this.props.name + "Country":
				country = value;
				break;
			case this.props.name + "Zip":
				zip = value;
				break;
			case this.props.name + "PostalCode":
				postalcode = value;
				break;
		}
		const address = { number, street, city, state, country, zip, postalcode }
		this.props.onAddressChange(address);
	}

	render() {
		const { errors, name, className, address, disabled } = this.props;
		const SelectWithDictionary = this.SelectWithDictionary;
		return (
			<div className="question">
				<Input
					key={name + "Number"}
					className={className}
					inputClass={""}
					placeholder="Number"
					name={name + "Number"}
					errors={errors}
					value={address.number}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
				<Input
					key={name + "Street"}
					className={className}
					inputClass={""}
					placeholder="Street"
					name={name + "Street"}
					errors={errors}
					value={address.street}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
				<Input
					key={name + "City"}
					className={className}
					inputClass={""}
					placeholder="City"
					name={name + "City"}
					errors={errors}
					value={address.city}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
				<Input
					key={name + "State"}
					className={className}
					inputClass={""}
					placeholder="State"
					name={name + "State"}
					errors={errors}
					value={address.state}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
				<SelectWithDictionary
					key={name + "Country"}
					className={className}
					inputClass={""}
					placeholder="Country (Use three-letter country ISO code ex. BLZ)"
					name={name + "Country"}
					errors={errors}
					value={address.country}
					disabled={disabled}
					onChange={(e) => this.handleSelectChange(e)}
					options={this.getDropdownOptions('country')}
					dictionaryId="-MMceMKLCbFo_1c9yJYq"
				/>
				<Input
					key={name + "Zip"}
					className={className}
					inputClass={""}
					placeholder="ZIP"
					name={name + "Zip"}
					errors={errors}
					value={address.zip}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
				<Input
					key={name + "PostalCode"}
					className={className}
					inputClass={""}
					placeholder="Postal Code"
					name={name + "PostalCode"}
					errors={errors}
					value={address.postalcode}
					handleChange={(e) => this.handleTextChange(e)}
					disabled={disabled}
				/>
			</div>
		)
	}
}

export default Address;