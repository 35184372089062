import axios from 'axios';
import React, {Component, useState} from 'react';
import { Navbar, NavItem, Dropdown, Divider } from 'react-materialize';
import { hasPermission } from '../../../utils/PermissionUtil';

const style = {
    slideOut:{
        transform: "translateX(0%)",
        position: "relative",
        height: "99vh",
        width: "auto",
        marginRight: "35px"
    },
}

export default function SideNav(props) {
    const [state, setState] = useState({profile_image: undefined});
    const optionsElements = [];
    const { data, selected } = props;
    const { profile_image } = state;
    const { businessName, name, email, recordClass, key, isClient, peps, sanctions, recordName } = data;

    if(!profile_image){
        if(data.profile_image){
            const document = data && data.documents && data.documents.find(x => x.name === data.profile_image.name);
            if(!document){
                setState({profile_image: 'https://www.o8.agency/sites/default/themes/o8/images/default-user.png'});
            } else {
                const data = { id: document.id };
                axios.defaults.headers.common['Authorization'] = props.superProps.auth.token;
                axios.post("/api/getFileURL", {data})
                    .then(
                        (res) => {
                            setState({profile_image: res.data.downloadURL});
                        })
                    .catch((e)=>console.log(e));
            }
        } else {
            setState({profile_image: 'https://www.o8.agency/sites/default/themes/o8/images/default-user.png'});
        }
    }

    const individualOptions = [
        {id: 'general', icon: 'folder_open', title: 'General'},
        {id: 'clientInfo', icon: 'assignment', title: 'Client Information'},
        {id: 'documents', icon: 'insert_drive_file', title: 'Documents'},
        {id: 'reports', icon: 'insert_drive_file', title: 'Reports', clientOnly: true},
        {id: 'questions', icon: 'question_answer', title: 'Questions'},
        {id: 'risk', icon: 'warning', title: 'Risk Rating'},
        {id: 'contacts', icon: 'contact_phone', title: 'Contacts'},
        {id: 'sanctions', icon: 'check_circle', title: 'AML Screenings'},
    ];

    const entityOptions = [
        {id: 'general', icon: 'folder_open', title: 'General'},
        {id: 'clientInfo', icon: 'assignment', title: 'Client Information'},
        {id: 'documents', icon: 'insert_drive_file', title: 'Documents'},
        {id: 'reports', icon: 'insert_drive_file', title: 'Reports', clientOnly: true},
        {id: 'risk', icon: 'warning', title: 'Risk Rating'},
        {id: 'entities', icon: 'layers', title: 'Ownership And Control'},
        {id: 'users', icon: 'folder_open', title: 'Users'},
        {id: 'contacts', icon: 'contact_phone', title: 'Contacts'},
        {id: 'sanctions', icon: 'check_circle', title: 'AML Screenings'},
    ];

    // Dynamic Record Structure Changes
    let options = entityOptions;

    if(recordClass == 'individual'){
        options = individualOptions;
    }

    options.forEach(({id, title, icon, clientOnly}) => {
        if(clientOnly && !isClient){
            return;
        }
        const canEdit = hasPermission(props.superProps.auth.user, 'clientManagement', id);
        if(!canEdit){
            return;
        }
        optionsElements.push(
            <li
                key={id}
                onClick={ () => props.onChange(id) }
                style={
                    id == selected ?
                        { backgroundColor: 'rgba(0,0,0,0.05)' }
                        : {}
                }
            >
                <a>
                    <i className="material-icons">{icon}</i>
                    <span className ='hide-on-small-only'>{title}</span>
                </a>
            </li>
        );
    });

    return(
        <div className="row">
            <ul id="slide-out" className="side-nav"
                style={{ transform: 'translateX(0px)', zIndex:'5', paddingTop: '65px', width: 'auto'}}>
                <div className="user-view hide-on-small-only" style={{backgroundColor:'#4051B5'}}>
                    <img className="circle responsive-img" src={profile_image}/>
                    <div className="col s12">
                        {peps && <span class="badge red white-text email">PEPS</span>}
                        {sanctions && <span class="badge orange white-text email">SANCTION</span>}
                    </div>
                    <span className="white-text name">{recordName}</span>
                    <span className="white-text email">{key}</span>
                </div>
                {optionsElements}
            </ul>
        </div>
    );
}