import React, { Fragment } from 'react'

const EntityGeneralDetails = ({ data }) => {
	const {
		matchScore, nameScore, countryScore,
		imageUrl = '', 
		original_name, country, city,
		alias,
		sanctioning_program, sanctions,
	} = data;
	return <Fragment>
		<div style={{height: "120px", overflow: 'hidden'}}>
			<div className='col s4'>
				<img
					className="responsive-img" 
					src={imageUrl.toUpperCase().includes('HTTP') ? imageUrl : 'https://i.stack.imgur.com/l60Hf.png'}
				/>
			</div>
			<div className='col s8'>
				<b>Company Name:</b> {original_name ?? 'N/A'}<br/>
				<b>Country:</b> {country ?? 'N/A'}<br/>
				<b>City:</b> {city ?? 'N/A'}<br/>
			</div>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "85px", overflow: 'hidden'}}>
			<br/>
			<b>Alias:</b> {alias ?? 'N/A'}
			<br/>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "50px", overflow: 'hidden'}}>
			<b>Source:</b> {sanctioning_program ?? 'N/A'}<br/>
			<b>Sanctions:</b> {sanctions ?? 'N/A'}
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div className = "valign-wrapper" style={{height: "55px", overflow: 'hidden'}}>
			<div className='col s8 valign-wrapper' style={{padding: '0px'}}>
				<div className='col s6' style={{padding: '0px'}}>
					<b>Name:</b> {nameScore ? 'Yes' : 'No'}<br/>
				</div>
				<div className='col s6' style={{padding: '0px'}}>
					<b>Country:</b> {countryScore ? 'Yes' : 'No'}<br/>
				</div>
			</div>
			<div className='col s4'>
				<h3>{matchScore * 100}%</h3>
			</div>
		</div>
	</Fragment>;
}

const SanctionMatchEntityCard = (props) => {
	const {
		viewOnly,
		onView,
		onConfirm,
		onDeny,
	} = props;
	return (
		<div class="col s12 m6">
			<div class="card">
				<div class="card-content" style={{ textAlign: 'left'}}>
					<EntityGeneralDetails data={props} />
				</div>
				<div className="card-action right-align">
						{onView && <a className="btn-flat" onClick={onView}>View</a>}
						{	!viewOnly 
							&& onConfirm
							&& <a className="btn-flat" onClick={onConfirm}>Confirm</a>
						}
						{
							!viewOnly 
							&& onDeny
							&& <a className="btn-flat" onClick={onDeny}>Deny</a>
						}
				</div>
			</div>
		</div>
	)
}

export {
	SanctionMatchEntityCard,
	EntityGeneralDetails
}
