import React, {Component} from 'react'

const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const taxTypes = [
    {name: 'GST', value: 'gst', rate: .25},
    {name: 'NONE', value: 'none', rate: 0}
];

const template = (methods, state) => {
    const inputs  = [
        { name: "autopay", type: "checkbox", placeholder: "Is Autopay", className: "col s12 right"}, 

        { name: "charge_name", type: "text", placeholder: "Name", className: "col s6", value: "", required: true },
        {  name: "charge_description", type: "text", placeholder: "Description", className: "col s6", value:"", required: true },

        { name: "charge_type", type: "select", placeholder: "Type", className: "col s6", required: true, dictionary: (model) => {
                return [
                    { name: "Fixed", value: "fixed" },
                    { name: "Variable", value: "variable" }
                ]
            }
        },
        { name: "charge_frequency", type: "select", placeholder: "Recurring", className: "col s6", required: true, dictionary: (model) => {
                return [
                    { name: "Monthly", value: "monthly" },
                    { name: "Yearly", value: "yearly" },
                    { name: "One-Time", value: "one_time" }
                ]
            }
        },

        { name: "notify_days", type: "number", placeholder: "Notification Sent Before (Days)", className: "col s12", inputClass: 'col s6', innerStyle: halfStyle, condition: (state)=>{
            return (state.charge_frequency == 'monthly' || state.charge_frequency == 'yearly') && !state.autopay;
        }}, 

        { name: "issued_date", type: "date", placeholder: "Issue Date", className: "col s6", value: "", required: true },
        {  name: "due_date", type: "date", placeholder: "Due Date", className: "col s6", value:"", required: true },

        { name: "charge_amount", type: "number", placeholder: "Amount", className: "col s6", value: "", required: true },
        { name: "payment_source", type: "select", placeholder: "Payment Source", className: "col s6", value: "", dictionaryId: '-MLd0DUccbjWGqNFsrDB', required: true },

        {  name: "charge_currency", type: "select", dictionary: ()=>[
            {name: "BZD", value: "bzd"},
            {name: "US", value: "us"}
        ], placeholder: "Currency", className: "col s6", value:"", required: true },
        { name: "is_paid", type: "select", yesNo: true, placeholder: "Is Paid", className: "col s6", required: true, condition: (state)=>{
            return !state.autopay;
        }},
        { name: "tax_type", type: "select", yesNo: true, placeholder: "Tax Type", className: "col s6", required: true, dictionaryId: '-MKQQe4zg0JtW5aDdgFq'},
        { name: "charge_estimate", type: "text", isMoney: true, placeholder: "Estimate", className: "col s6", disabled: true}
    ];

    if(!state.viewOnly){
        inputs.push({ name: "invoice", type: "uploader", placeholder: "Invoice", className: "col s12", multipleSupport: false, inputClass: "ppf-primary-button left" });
    }

    return [{
        permission: [
            {feature: 'vendorsManagement', part: 'charges'}
        ],
        title: state.data.charge_name || `New Charge`,

        inputs,
        buttons: [
            {
                name: 'save',
                text: "Save",
                action: methods.onSave,
                className: "ppf-primary-button right",
                edits: true
            }
        ]
    }];
}


export default template;