import React, {Component} from 'react'

const template = (methods, state) => {
    return [{
        permission: [
            {feature: 'clientManagement', part: 'contacts'}
        ],
        title: state.data.fullName || `New Contact`,
        inputs:[
            { name: "fullName", type: "text", placeholder: "Full Name", className: "col s6", value: "", required: true },
            {  name: "email", type: "text", placeholder: "Email Address", className: "col s6", value:"", required: true },


            { name: "mobileNumber", type: "text", placeholder: "Mobile Number", className: "col s6", value: "", required: true },
            {  name: "officeNumber", type: "text", placeholder: "Office Number", className: "col s6", value:"", required: true },


            { name: "role", type: "text", placeholder: "Role", className: "col s6", value: "", required: true },
            { name: "isPrimary", type: "select", yesNo: true, placeholder: "Primary Contact", className: "col s6", required: true }
        ],
        buttons: [
            {
                name: 'delete',
                text: <i class="red-text material-icons">delete</i>,
                action: methods.onDelete,
                className: "ppf-flat right",
                deletes: true
            }
        ]
    }];
}


export default template;