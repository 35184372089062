import React, { Component } from 'react';
import Record from './Record';
import axios from 'axios';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux'
import Select from '../../Generics/Select';
import { getRecordName } from '../../../utils/Formatter';
import WithDictionary from '../DealsManagement/withDictionary';

class ClientManagement extends Component {
	constructor(props) {
		super(props);

		this.onSanctionListUpload = this.onSanctionListUpload.bind(this);
		this.selectWithDictionary = WithDictionary(Select, this.props.auth.token);

		this.state = {
			modal: false,
			filters: {
				searchText: null,
				classType: null,
				recordType: null,
				clientType: null,
				company: null,
				activeOnly: 'yes',
			},
			records: []
		};
	}

	componentDidMount() {
		this.getRecords();
	}

	onSanctionListUpload(e) {
		const file = e.target.files[0];
		if (window.confirm('This will overwrite the current sanction list. Do you wish to continue?')) {
			this.fileUpload(file);
		}
	}

	fileUpload(file) {
		const formData = new FormData();
		formData.append('file', file)
		const config = {
			headers: {
				'content-type': 'multipart/form-data'
			}
		}
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post("/api/sanctionsList", formData, config)
			.then(
				(res) => {
					alert("Update Successful");
				})
			.catch((e) => console.log(e));
	}

	getDropdownOptions(type) {
		const typeMap = {
			class: [
				{ name: "All", value: "all" },
				{ name: "Individual", value: "individual" },
				{ name: "Entity", value: "entity" }
			],
			individual: [
				{ name: "Shareholder", value: "shareholder" },
				{ name: "Director", value: "director" },
				{ name: "Investor", value: "investor" }
			],
			entity: [
				{ name: "Company", value: "company" },
				{ name: "Partnership", value: "partnership" },
				{ name: "Sole Trader", value: "soleTrader" },
				{ name: "Government Entity", value: "governmentEntity" },
				{ name: "Other", value: "other" }
			],
			clientType: [
				{ name: "Consultancy", value: "consultancy" },
				{ name: "Financial Services", value: "financial_services" },
			]
		};

		if (typeMap[type] === null) {
			return [];
		}
		return typeMap[type];
	}

	getRecords() {
		const { searchText } = this.state.filters;
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post('/api/records', { data: { searchText } })
			.then(
				(res) => {
					this.state.records = res.data;
					this.setState(this.state);
				})
			.catch((e) => console.log(e));
	}

	getCompanies() {
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.get('/api/user/id/companies')
			.then(
				(res) => {
					this.state.companies = res.data.companies;
					this.setState(this.state);
				})
			.catch((e) => console.log(e));
	}

	handleDropdown(e) {
		const name = e.target.name;
		let value = e.target.value;

		if (['all', 'individual', 'entity'].includes(value)) {
			this.state.filters.recordType = null;
		}

		this.state.filters[name] = value;
		this.setState(this.state);
	}

	renderRecords() {
		const { history } = this.props;
		let records = [];
		const { searchText, recordType, classType, clientType, company, activeOnly } = this.state.filters;

		_.forEach(this.state.records, function (value, key) {
			var form = { record: value, key: key }
			records.push(form);
		});

		return _.chain(records)
			.map((form) => {
				const name = getRecordName(form.record);
				if (
					(
						classType === null
						|| classType === 'all'
						|| form.record.recordClass === classType
					)
					&&
					(
						recordType === null
						|| form.record.recordType === recordType
					)
					&&
					(
						clientType === null
						|| clientType === ''
						|| form.record.clientType === clientType
					)
					&&
					(
						company === null
						|| company === ''
						|| form.record.company === company
					)
					&&
					(
						activeOnly === null
						|| activeOnly === ''
						|| (activeOnly === 'yes' ? form.record.status === 'active' :  form.record.status !== 'active')
					)
				) {
					return (
						<Record key={form.key} record={form} history={history} />
					);
				};
			})
			.compact()
			.value();
	}

	renderTable() {
		return (
			<table>
				<thead>
					<tr>
						<th className="center">Username</th>
						<th className="center mobile-hide">Class</th>
						<th className="center mobile-hide">Type</th>
						<th className="center mobile-hide">Start Date</th>
						<th className="center mobile-hide">Status</th>
					</tr>
				</thead>
				<tbody>
					{this.renderRecords()}
				</tbody>
			</table>
		)
	}

	filter(searchText) {
		this.state.filters.searchText = searchText;
		this.setState(this.state, () => {
			this.getRecords();
		});
	}

	render() {
		const { classType, recordType, clientType, company, activeOnly } = this.state.filters;
		const Dic = this.selectWithDictionary;

		if (!this.state.companies) {
			this.getCompanies();
			return <div></div>;
		}

		return (
			<div className='row' style={{ marginTop: '7%' }}>
				<div className='col s12' style={{ paddingLeft: '5%', paddingBottom: '20px' }}>
					<button className="ppf-nav-button left" onClick={() => { this.props.history.goBack() }}>Back</button>
				</div>
				<div className="container row">
					<h2>Client Management</h2>
					<div className="filters">
						<div className='col s12' style={{ paddingBottom: "10px" }}>
							{/*<button className="btn-flat right white-text blue download col s3" onClick={()=>{ this.santionsListInput.click() }}>
                <input
                  id={"sanctionsList"}
                  name={"sanctionsList"}
                  type="file"
                  style={{display:"none"}}
                  ref={input => this.santionsListInput = input}
                  onChange={this.onSanctionListUpload}
                />
                Update Sanctions List
                </button>*/}
						</div>
						<div className="col s2">
							<Select
								key="classTypeFilter"
								placeholder="Record Class"
								name="classType"
								value={classType}
								errors={[]}
								options={this.getDropdownOptions('class')}
								onChange={(e) => this.handleDropdown(e)}
							/>
						</div>
						<div className="col s2">
							<Select
								key="recordTypeFilter"
								placeholder="Record Type"
								name="recordType"
								value={recordType}
								errors={[]}
								options={this.getDropdownOptions(classType)}
								onChange={(e) => this.handleDropdown(e)}
							/>
						</div>
						<div className="col s2">
							{this.state.companies.length > 1 && <Select
								key="company"
								placeholder="Company"
								name="company"
								value={company}
								errors={[]}
								options={this.state.companies.map(company => ({ name: company.toUpperCase().replace(/_/g, " "), value: company }))}
								onChange={(e) => this.handleDropdown(e)}
							/>}
						</div>
						<div className="col s2">
							<Select
								key="activeOnly"
								placeholder="Is Active"
								name="activeOnly"
								value={activeOnly}
								errors={[]}
								options={[
									{ name: "Yes", value: "yes" },
									{ name: "No", value: "no" },
								]}
								onChange={(e) => this.handleDropdown(e)}
							/>
						</div>
						<div className="col s12 l3 offset-l1">
							<input className="col l10 s10" id="seachInput" type="text" placeholder="Search by client name"
								onInput={() => this.filter(document.getElementById("seachInput").value)} />
							<img className="col l2 s2" style={{ marginTop: '10px' }} src="/static/images/search-icon.png" />
						</div>
					</div>
					{this.renderTable()}
					<div className="row">
						<div className="col l8">
						</div>
						<div className="col l4 s12">
							<Link to="/client/new">
								<button
									className="ppf-primary-button right col s12"
								>Create Record</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state, { history }) {
	return {
		auth: state.auth,
		files: state.files
	};
}

export default connect(mapStateToProps)(ClientManagement);