import _ from 'lodash';
import firebase from 'firebase';

const uploadSingleFile = (a, path, file, field, cb) => {
    const storageRef = firebase.storage().ref();
    const fileName = file.fileName || file.name;
    let uploadTask = storageRef.child(path+fileName).put(file);

    uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        function(snapshot){
            /*
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log('Upload is paused');
                break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log('Upload is running');
                break;
            }*/
        },
        function(error){
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                    cb({field, error:"Unauthorized upload"});
                    // User doesn't have permission to access the object
                    break;
                case 'storage/canceled':
                    cb({field, error:"Canceled upload"});
                    // User canceled the upload
                    break;
                case 'storage/unknown':
                    cb({field, error:"Unknown Error with upload"});
                    // Unknown error occurred, inspect error.serverResponse
                    break;
            }
        },
        function(){
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                cb({field, url:downloadURL, path: path+fileName});
            });
        }
    );
}

const uploadFiles = (a, filesGroup, path, callback) => {
    const storageRef = firebase.storage().ref();
    let uploadPromises = [];

    _.forEach(filesGroup, (fileObject)=>{
        const promise = new Promise(function (resolve, reject){
            const {field, files} = fileObject;

            if(!files){
                resolve({field, error:"No Files Found"});
            }
            _.forEach(files, (file)=>{
                uploadSingleFile(storageRef, path, file, field, (res)=>{
                    resolve(res);
                });
            });
        });

        uploadPromises.push(promise);
    });

    Promise.all(uploadPromises).then(
        (data)=>{
            callback(data);
        }, (error)=>{
            console.log(error);
        }
    );
}

const deleteFile = (a, fullPath, cb) => {
    const storageRef = firebase.storage().ref();
    storageRef
        .child(fullPath)
        .delete()
        .then((res)=>{
            cb({path: fullPath, result: 'Success'})
        }).catch((err)=>{
            console.log(err);
        });
}

export {
    uploadFiles,
    uploadSingleFile,
    deleteFile
};