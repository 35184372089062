import React, {Component} from 'react';
import Publication from './Publication';
import _ from 'lodash';
import axios from 'axios';
import {connect} from 'react-redux'
import Header from '../../Header';
import PublicationForm from './PublicationForm';
import { hasPermission } from '../../../utils/PermissionUtil';
import { successWrapper } from '../../../utils/SuccessWrapper';

class Publications extends Component {
    constructor(props) {
        super(props);
        this.state = {modal: false, searchText: "", error: "", alert: {active: false, message: ''}, saveButton: false, publications:null};
    }

    componentDidMount() {
        this.getAlert();
        this.getPublications();
    }

    getAlert() {
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.get('/api/alert')
            .then(
                (res) => {
                    successWrapper(res, (data) => {
                        this.setState({alert: data});
                    });
                })
            .catch((e) => {
                alert('Could not get alert');
            });
    }

    getPublications() {
        // TODO: Reformat all requests to use successWrapper
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.get('/api/getPublications')
            .then(
                (res) => {
                    successWrapper(res, (data) => {
                        this.setState({publications: data});
                    });
                })
                .catch((e) => {
                    alert('Could not get publications');
                });
    }

    setAlert(alert, statusChange) {
        this.setState({error:""});
        axios.post("/api/alert", {
            alert: alert
        }).then(
            (res) => {
                successWrapper(res, (data) => {
                    this.setState({error: ""});
                    this.setState({saveButton: false});
                }, (error)=>{
                    var alert = this.state.alert;
                    alert.active = !alert.active;
                    if (statusChange) {
                        this.setState({alert: alert, error: "Error: Could not save changes."});
                    } else {
                        this.setState({error: "Error: Could not save changes."});
                    }
                });
        },(error)=>{
                if (statusChange) {
                    var alert = this.state.alert;
                    alert.active = !alert.active;
                    this.setState({alert: alert, error: "Error: Could not save changes."});
                } else {
                    this.setState({error: "Error: Could not save changes."});
                }
            });
    }

    handleChange(e) {
        const target = e.target
        const name = target.name;
        const value = target.type === 'checkbox'
            ? target.checked
            : target.value

        var alert = this.state.alert;
        alert[name] = value;
        this.setState({alert: alert});
        if (name === "active") {
            this.setAlert(this.state.alert, "true");
        } else if (name === "message") {
            this.setState({saveButton: true});
        }
    }

    setModal(mode, form) {
        this.setState({modal: mode, form: form});
        if (!mode) {
            this.getPublications();
        }
    }

    renderSaveButton() {
        if (this.state.saveButton)
            return (<button className="ppf-primary-button right"
                            onClick={() => this.setAlert(this.state.alert, false)}>Save</button>)
    }

    renderPublications() {
        var publications = [];

        _.forEach(this.state.publications, function (value, key) {
            var form = {publication: value, key: key}
            publications.push(form);
        });

        return _.chain(publications)
            .map((form) => {
                return (
                    <Publication key={form.key} publication={form.publication}
                                 setModal={() => this.setModal(true, form)}/>
                );
            })
            .compact()
            .value();
    }

    renderModal() {
        if (!this.state.modal) {
            return (
                <div></div>
            )
        }
        return (
            <div id="myModal" className="modal row">
                <div className="modal-content form col l4 s10 offset-s1 offset-l4">
                    <span className="close" onClick={() => this.setModal(false, null)}>&times;</span>
                    <PublicationForm form={this.state.form} auth={this.props.auth} setModal={this.setModal.bind(this)}/>
                </div>
            </div>
        )
    }

    renderAlertSection(){
        return(
            <div className="row">
                <h5>Alert</h5>
                <div className="col l8">
                    <div className="switch col 4">
                        <label>
                            Off
                            <input
                                name="active"
                                type="checkbox"
                                disabled={!hasPermission(this.props.auth.user, 'alerts', 'alerts', 'write')}
                                checked={this.state.alert.active}
                                onChange={this.handleChange.bind(this)}
                            />
                            <span className="lever"/> On
                        </label>
                    </div>
                    <div className="col l8 s12">
                        <textarea
                            className="materialize-textarea"
                            name="message"
                            disabled={!hasPermission(this.props.auth.user, 'alerts', 'alerts', 'write')}
                            value={this.state.alert.message}
                            onChange={this.handleChange.bind(this)}
                        />
                        <div className="col l8">
                            {this.state.error !== '' && <p className="error blink">{this.state.error}</p>}
                        </div>
                        <div className="col l4">
                            {this.renderSaveButton()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderPublicationSection(){
        return(
            <div>
                <h2>Publications</h2>
                <table>
                    <thead>
                    <tr className="row">
                        <th className="center col l3">Title</th>
                        <th className="center col l6 mobile-hide">Description</th>
                        <th className="center col l3 mobile-hide">Created On</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.renderPublications()}
                    </tbody>
                </table>
                <div className="col l4 offset-l8 s12">
                    {
                        hasPermission(this.props.auth.user, 'publications', 'publications', 'write')
                        && <button
                            className="ppf-primary-button right col s12"
                            onClick={() => this.setModal(true, undefined)}
                        >Create Publication
                        </button>
                    }
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className = 'row' style={{marginTop: '7%'}}>
                <div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
                    <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
                </div>
                {this.renderModal()}
                <div className="container row">
                    {
                        hasPermission(this.props.auth.user, 'alerts', 'alerts')
                        && this.renderAlertSection()
                    }
                    {
                        hasPermission(this.props.auth.user, 'publications', 'publications')
                        && this.renderPublicationSection()
                    }
                </div>
            </div>
        );
    }
}


function mapStateToProps(state, {history}) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(Publications);