import React, {Component} from 'react';

const notes = [
    {
        releaseDate: '05/23/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                improvements: [
                    'Added Compare feature to Database Query Tool.',
                ],
                bugFixes: [
                    'Corrected issue with single query filter error'
                ],
            }
        ]
    },
    {
        releaseDate: '04/23/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                improvements: [
                    'Added nested data interaction with query tool.',
                ],
                bugFixes: [
                    'Adding data with quotations should be correctly parsed',
                    'Adding data with extra lines should be ignored',
                    'Creating a dataset navigates to the new dataset instead of search view',
                ],
            }
        ]
    },
    {
        releaseDate: '04/21/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                newFeatures: [
                    'Added new data query tool!',
                ],
            }
        ]
    },
    {
        releaseDate: '04/04/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                improvements: [
                    'Modified Datasets to use location types which includes countries, regions and global',
                    'Added Commodities to Schema Data Types',
                ],
                newFeatures: [
                    'Custom Keys can now be set in Datasets, Schemas and Groups to easily add multiple keys that will work with the coming searching feature',
                ],
                bugFixes: [
                    'Fixed issue with not being able to delete rows in the Dataset Group View'
                ]
            },
            {
                name: 'File Management',
                bugFixes: [
                    'Fixed issue with not being able to download folders or files'
                ]
            }
        ]
    },
    {
        releaseDate: '03/29/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                improvements: [
                    'Added new industries and dataset types in Schema and Dataset views',
                    'Modified Group Data View to display values in 2 decimal places'
                ],
                newFeatures: [
                    'Added view data feature to dateset group view',
                    'Added collapsable items when in view mode to the Group Data view',
                ]
            }
        ]
    },
    {
        releaseDate: '03/27/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                improvements: [
                    'Added currency classifications in Schema and Dataset views',
                    'Added measurement classifications in Schema and Dataset views',
                    "Added locking of schema groups - When a new dataset is created from a schema, the base schema's groups will be locked to ensure the data structure remains the same.",
                ],
                newFeatures: [
                    'Added refresh functionality to Dataset view - This allows users to update datasets with recent changes from their base schema',
                    'Added delete group functionality to Dataset view',
                ]
            }
        ]
    },
    {
        releaseDate: '03/23/2020',
        sectionReleases: [
            {
                name: 'Statistical Database',
                bugFixes: [
                    'Saving data from the modification screen fix'
                ],
                improvements: [
                    'Added new datatypes in Schema and Dataset views'
                ]
            }
        ]
    }
];

class ReleaseNotes extends Component {
    constructor(props) {
        super(props);
    }

    renderReleaseSection(section){
        const {name, bugFixes = [], improvements = [], newFeatures = []} = section;
        const bugFixesElements = [];
        const improvementElements = [];
        const featureElements = [];

        bugFixes.forEach((fix)=>{
            bugFixesElements.push(`-${fix}`);
            bugFixesElements.push(<br/>);
        });

        improvements.forEach((improvement)=>{
            improvementElements.push(`-${improvement}`);
            improvementElements.push(<br/>);
        });

        newFeatures.forEach((feature)=>{
            featureElements.push(`-${feature}`);
            featureElements.push(<br/>);
        });

        return(
            <div>
                <b>{name}</b>
                <p style={{paddingLeft: '1.5rem', margin: '0px'}}>
                    {
                        bugFixesElements.length > 0
                        && <div>
                                <u>Bug Fixes</u><br/>
                                {bugFixesElements}
                            </div>
                    }
                    {
                        improvementElements.length > 0
                        && <div>
                                <u>Improvements</u><br/>
                                {improvementElements}
                            </div>
                    }
                    {
                        featureElements.length > 0
                        && <div>
                                <u>New Features</u><br/>
                                {featureElements}
                            </div>
                    }
                </p>
            </div>
        )
    }

    renderRelease(release){
        const { releaseDate, sectionReleases } = release;
        const sectionsElements = [];

        sectionReleases.forEach((section)=>{
            sectionsElements.push(this.renderReleaseSection(section));
        });

        return(
            <div>
                <hr style={{border:'0', borderTop: '#f1f1f1 1px solid', marginTop: '1.5rem', marginBottom: '1.5rem'}}/>
                <h5>{releaseDate}</h5>
                {sectionsElements}
            </div>
        );
    }

    render() {
        const releaseElements = [];
        notes.forEach(release=>{
            releaseElements.push(this.renderRelease(release));
        });
        return (
            <div className="app">
                <div className="container row" style={{marginTop: '7%'}}>
                    <h2>Release Notes</h2>
                    {releaseElements}
                </div>
            </div>)
    }
}

export default ReleaseNotes;