import React, { Component } from 'react';
import { connect } from 'react-redux'
import firebase from 'firebase';
import Path from 'path-parser';
import { authChange } from '../../../actions';

class Login extends Component{
  constructor(props){
    super(props);
    this.state = {email:"",password:"",error:"",user:null};
  }

  handleChange(e){
    const target = e.target
    const name = target.name
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value
    this.setState({[name]:value});
  }

  handleKeyPress(e){
    var code = e.keyCode || e.which;
    if(code === 13) { //13 is the enter keycode
        this.handleLogin();
    }
  }

  handleLogin(){
    this.setState({error:''});
    const p = new Path('auth/:message');
    const { history, dispatch } = this.props;
    firebase.auth().signInWithEmailAndPassword(this.state.email.trim(),this.state.password.trim())
      .catch(
        e => {
          var error = "";
          if(typeof e.code !== 'undefined'){
            const match = p.test(e.code);

            if(match.message === ""){
              error = "";
            }
            switch(match.message){
              case "wrong-password":
                error = "Incorect Password";
                break;
              case "user-not-found":
                error = "User does not exist";
                break;
              default:
                error = "";
                break;
            }
            this.setState({error:error});
          }
        }
      );
  }
  render(){
    return(
      <div className="app">
        <link rel="stylesheet" href="/static/styles/login.css"/>
        <div className="row">
          <div className="col l4 offset-l4" style={{marginTop:"10%"}}>
            <input
              className="login-form"
              name="email"
              type="text"
              value={this.state.email}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)}
              placeholder="Username"
            />
            <input
              className="login-form"
              name="password"
              type="password"
              value={this.state.password}
              onChange={this.handleChange.bind(this)}
              onKeyPress={this.handleKeyPress.bind(this)}
              placeholder="Password"
            />
            <button className="ppf-primary-button center col s12" onClick={()=> this.handleLogin()}>Login</button>
            {this.state.error !== '' && <p className="error blink">{this.state.error}</p>}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state,{ authChange }){
  return {
    auth:state.auth,
    authChange
  };
}

export default connect(mapStateToProps)(Login);