import React, { Component } from 'react';

class Confirmation extends Component {
    
    render(){
        return(
            <div className="app">
                <div className="container row" style={{marginTop: '7%'}}>
                    <h1>Email Confirmation</h1>
                    <p>`Thank you for confiming your email! ID:{this.props.match.params.id}`</p>
                </div>
            </div>
        )
    }
}

export default Confirmation;