
import React, {Component} from 'react';
import Select from './Select';
import { locations, regions, countries} from '../Dictionaries/locations';

class Locations extends Component{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            locationType: this.props.locationType || '',
            country: this.props.country || '',
            region: this.props.region || ''
        };
    }

    onChange(e){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        this.state[name] = value;
        this.props.onChange(this.state);
        this.setState(this.state);
    }

    render(){
        const { locationType, country, region } = this.state;
        return (
            <div>
                <Select
                    className= {this.props.className || 'col l2'}
                    name= { 'locationType'}
                    placeholder= 'Location Type'
                    value={locationType}
                    disabled={this.props.disabled}
                    options={locations}
                    onChange = {this.onChange}
                />
                {locationType == 'country' && <Select
                    className= {this.props.className || 'col l2'}
                    name= {'country'}
                    placeholder= 'Country'
                    value={country}
                    disabled={this.props.disabled}
                    options={countries}
                    onChange = {this.onChange}
                />}
                {locationType == 'region' && <Select
                    className= {this.props.className || 'col l2'}
                    name= {'region'}
                    placeholder= 'Region'
                    value={region}
                    disabled={this.props.disabled}
                    options={regions}
                    onChange = {this.onChange}
                />}
            </div>
        )
    }
}

export default Locations;