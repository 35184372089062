import React, { Component } from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import { successWrapper } from '../../../../utils/SuccessWrapper';
import { verify2FA } from '../../../../actions';

class Setup2FA extends Component {
    constructor(props){
        super(props);

        this.onNext = this.onNext.bind(this);
        this.onDigitChange = this.onDigitChange.bind(this);

        this.state= {};
    }
    componentDidMount(){
        this.refs[0].focus();
    }

    saveSecret(cb){
        const { secret } = this.state;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post('/api/auth/saveSecret', { secret }).then((res)=>{
            if(res.data.error === "Success") {
                alert('Error getting QR Code');
            }
            cb();
        },(err)=>{
            console.log("Error: ",err);
        });
    }

    onDigitChange(e){
        const target = e.target
        const name = target.name;
        const value = target.value

        this.setState({[name]: value}, () => {
            let reference = this.refs[parseInt(name)+1];
            if(reference){
                return reference.focus();
            }
            this.onNext();
        });
    }

    onNext(){
        let secretCode = '';

        for(const i in this.state){
            secretCode += this.state[i];
        }

        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post('/api/auth/verifySecret', { secretCode }).then((res)=>{
            successWrapper(res, (data)=>{
                if(data.verified){
                    const { dispatch, history, auth } = this.props;
                    verify2FA(dispatch, history, auth);
                    alert('Success!');
                } else {
                    alert('Invalid Code. Please try again.');
                }
            }, () => {
                alert('Invalid Code. Please try again.');
            });
        },(err)=>{
            console.log("Error: ",err);
        });
    }

    renderDigitInputs(){
        return(
            <div className = 'container'>
                <div className = 'col s5'>
                    <div className = 'col s4'>
                        <input className='center v-input' name='0' ref='0' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                    <div className = 'col s4'>
                        <input className='center v-input' name='1' ref='1' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                    <div className = 'col s4'>
                        <input className='center v-input' name='2' ref='2' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                </div>
                <div className = 'col s2'>
                    <h5 className = 'center'>-</h5>
                </div>
                <div className = 'col s5'>
                    <div className = 'col s4'>
                        <input className='center v-input' name='3' ref='3' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                    <div className = 'col s4'>
                        <input className='center v-input' name='4' ref='4' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                    <div className = 'col s4'>
                        <input className='center v-input' name='5' ref='5' onChange={this.onDigitChange} maxLength={1}/>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="row" style={{marginTop:"7%"}}>
                <div className = 'col l6 offset-l3'>
                    <h3 className='center'>2 Factor Authentication</h3>
                    <br/>
                    <p className = 'center'>Enter the 6 digit code appearing in the Google Athuenticator app under PPF Capital</p>
                    <div className = 'col s12'>{this.renderDigitInputs()}</div>
                    <button className="ppf-nav-button right" onClick={this.onNext}>Next</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(Setup2FA);