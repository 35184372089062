import React, {Component} from 'react'
import _ from 'lodash';

class User extends Component{

  render() {
    const { vendor, key } = this.props.vendor;
    const { is_active = "N/A", vendor_type = "NO DATA", name = "NO DATA", start_date = "NO DATA" } = vendor;

    return(
        <tr>
            <td className="center">
                <div className="row" style={{margin:"0px"}}
                  onClick={()=>{
                    this.props.history.push(
                      {
                        pathname: `/vendor/${key}` ,
                        state: {key: this.props.vendor.key}
                      }
                  )}}>
                  {name}
                </div>
            </td>
            <td className="center mobile-hide">{vendor_type.toUpperCase()}</td>
            <td className="center mobile-hide">{is_active.toUpperCase()}</td>
            <td className="center mobile-hide">{start_date}</td>
        </tr>
    )
  }
}

export default User;