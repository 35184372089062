import axios from 'axios';
import firebase from 'firebase';
import React, { useState } from 'react'
import { successWrapper } from '../../../utils/SuccessWrapper';
import Select from '../../Generics/Select';
import Input from '../../Generics/Input';
import MultiTypeGraph from '../../Generics/MultiTypeGraph';

export default function RiskRatings(props) {
	const [state, setState] = useState({
		loading: false, 
		client_type: 'all', 
		incomplete_ratings: '1',
		rating_from: '0',
		rating_to: '45'
	});
	const loadingComp = <div>Loading...</div>;

	const fetchCompanies = () =>{
		axios.defaults.headers.common['Authorization'] = props.superProps.auth.token;;
		axios.get('/api/user/id/companies')
			.then(
				(res) => {
					state.companies = res.data.companies;
					state.loading = false;
					setState(state);
				})
			.catch((e) => console.log(e));
	}

	const fetchData = () => {
		setState({...state, loading: true});
        axios.defaults.headers.common['Authorization'] = props.superProps.auth.token;
		return axios.get('/api/aml/amlRiskRatings')
			.then(res=>{
				successWrapper(res, (data)=>{
					state.records = data;
				});
			})
			.then(()=>fetchCompanies())
			.catch(err => {
				console.log(err);
				alert("Error fetching possible matches.")
				setState({...state, records: [], loading: false});
			});
	}

	if(state.loading){
		return loadingComp;
	}

	if(!state.records && !state.loading){
		fetchData();
		return loadingComp;
	}

	return (
		<div>
			<h3>Client Risk Ratings</h3>
			<p>The following graph displays all current client risk ratings that have been calculated by the PPF Capital application.</p>
			<div className="col s12">	
				<div>
					<MultiTypeGraph
						palette={[
							'#5c6bc0',
							'#ef5350',
							'#ffa726',
							'#4caf50',
						]}
						chartTypes= {['pie ', 'bar ']}
						data={{
							data: state.records.reduce((acc, {riskRating, client}) => {
								const {client_type, incomplete_ratings} = state;
								const map = {
									'Unrated': 0,
									'high': 1,
									'medium': 2,
									'low': 3
								};
								let riskLevel = riskRating.data ?  riskRating.data.riskLevel : 'Unrated';
								const riskScore = riskRating.data ?  riskRating.data.riskScore : -1;

								// Filter by filters
								if(
									(client_type != 'all' && client.clientType != client_type)
									|| (riskRating === "N/A" && incomplete_ratings == '0')
									|| (
										(riskScore < state.rating_from || riskScore > state.rating_to)
										&& incomplete_ratings !== '1'
									)
								){
									return acc;
								}
								if(!acc[map[riskLevel]]){
									riskLevel = 'Unrated';
								}
								acc[map[riskLevel]].y++;
								return acc;
							}, [
								{x: 'Unrated' , y: 0},
								{x: 'high' , y: 0},
								{x: 'medium' , y: 0},
								{x: 'low' , y: 0},
							])
						}}
						buildY = {true}
						xType = "ordinal"
						plottingOptions = {false}
						metaData = {false}
					/>
				</div>
			</div>
			<p>The following table displays customers and their associated Risk Rating that have been generated. Note that a customer must have all the 
required data in order to generate a risk rating.</p>
			<div className="filters">
				<div className="col s3">
					{state.companies && state.companies.length > 1 && <Select
						key="company"
						placeholder="Company"
						name="company"
						value={state.company}
						errors={[]}
						options={state.companies.map(company => ({ name: company.toUpperCase().replace(/_/g, " "), value: company }))}
						onChange={(e)=>{
							setState({...state, company: e.target.value});
						}}
					/>}
				</div>
				<div className="col s3">
					<Select
						key="incomplete_ratings"
						placeholder="Incomplete Ratings"
						name="incomplete_ratings"
						value={state.incomplete_ratings}
						errors={[]}
						type={'number'}
						options={[{value: '1', name: 'Yes'}, {value: '0', name: 'No'}]}
						onChange={(e)=>{
							setState({...state, incomplete_ratings: e.target.value});
						}}
					/>
				</div>
				<div className="col s2"></div>
				<div className="col s2">
					<Input
						key="rating_from"
						placeholder="Ratings From"
						name="rating_from"
						value={state.rating_from}
						errors={[]}
						type={'number'}
						handleChange={(e)=>{
							setState({...state, rating_from: e.target.value});
						}}
					/>
				</div>
				<div className="col s2">
					<Input
						key="rating_to"
						placeholder="Ratings To"
						name="rating_to"
						value={state.rating_to}
						errors={[]}
						handleChange={(e)=>{
							setState({...state, rating_to: e.target.value});
						}}
					/>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th className="center">Client Name</th>
						<th className="center mobile-hide">RiskRating</th>
						<th className="center mobile-hide">Date</th>
						<th className="center mobile-hide">Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						state.records.map(({client, riskRating}) => {
							const { first_name, last_name, businessName } = client;
							const {company, incomplete_ratings} = state;
							let date = 'N/A', rating = 'N/A', riskScore = 0;
					
							if(
								company 
								&& client.company != company
								&& client.company1 != company
							){
								return <div></div>;
							}
					
							if(riskRating !== "N/A"){
								const { created_at, data } = riskRating;
								const { riskLevel, riskScore: r } = data;
								riskScore = r;
								date = new Date(created_at);
								rating = `${riskScore} (${riskLevel})`;
							} else if (incomplete_ratings == '0') {
								return;
							}
							
							if(riskScore < state.rating_from || riskScore > state.rating_to){
								return <div></div>;
							}
					
							return <tr key = {`risk-row-${client.key}`}>
								<td onClick={()=>{
									props.superProps.history.push({
										pathname: `/client/${client.key}` ,
										state: {key: client.key}
									})}
								}><a>{
									client.recordClass == 'individual' 
										? `${first_name} ${last_name}`
										: businessName
								}</a></td>
								<td>{rating}</td>
								<td>{date === 'N/A' ? date : date.toLocaleDateString()}</td>
								<td>
									<button 
										className="ppf-primary-button" 
										onClick={()=>{
											props.superProps.history.push({
												pathname: `/client/${client.key}` ,
												state: {section: 'risk'}
											})
										}
									}>View Rating</button></td>
							</tr>;
						})
					}
				</tbody>
		</table>
		</div>
	)
}