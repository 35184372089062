const inputs = [
    { name: "recordClass", type: "select", placeholder: "Record Class", className: "col s6", value: "all", required: true},
    { name: "recordType", type: "select", placeholder: "Record Type", className: "col s6", required: true},
    { name: "status", type: "select", placeholder: "Status", className: "col s6", required: true},
    { name: "start_date", type: "date", placeholder: "Start Date", className: "col s6", value:"", required: true },
    { name: "is_client", type: "select", yesNo: true, placeholder: "Is Client", className: "col s6", value:"", required: true },
    { name: "clientType", type: "select", placeholder: "Client Type", className: "col s6", required: true},
    { name: "clientServiceType", type: "select", placeholder: "Service Type", className: "col s6", condition: (state)=>{
        return state.clientType == 'financial_services';
    }},
	{ name: "company", type: "select", placeholder: "PPF Company", className: "col s6", required: true},
];

const template = (methods, props, state) => {
	const companiesForUser = () => {
		const companies = [];
		if(props){
			for(const company in props.auth.user.companies){
				if(props.auth.user.companies[company]){
					companies.push({value: company, name: company.toUpperCase().replace(/_/g, " ")});
				}
			}
		}

		return companies;
	}

	inputs[7].dictionary = companiesForUser;

	// Add dynamic companies
	if(state && state.clientData && state.clientData.company){
		let atEnd = false;
		companiesForUser().forEach((element, i) => {
			if(i > 0 && !atEnd){
				const existingInput = inputs.find(x => x.name === `company${i}`);
				if(!existingInput){
					inputs.push(
						{ name: `company${i}`, type: "select", placeholder: `PPF Company ${i+1}`, className: "col s6", required: false, dictionary: () => {
							return companiesForUser().filter((x=>{
								for(const prop in state.clientData){
									if(prop.substring(0, 7) === 'company'){
										return state.clientData[prop] !== x.value;
									}
								}

								return true;
							}));
						}}
					);
					atEnd = true;
				}
			}
		});
	}
	
    return [
        {
            permission: [
                {feature: 'clientManagement', part: 'general'}
            ],
            title: "Record Information",
            inputs,
            pageButtons: [
                { name: 'delete', deletes: true, edits: true, action: ()=>{ methods.onDeleteRecord()}, text: 'Delete Record', className: 'right ppf-danger-button' },
                { name: 'save' },
                { name: 'print', action: ()=>{ methods.onPrint() }, text: 'Print Record'}
            ]
        }
    ];
}

export default template;