import React, { Component } from 'react';
import {connect} from 'react-redux'
import { CSVLink, CSVDownload } from "react-csv";
import _ from 'lodash';
import axios from 'axios';
import Input from '../../../../Generics/Input';
import Checkbox from '../../../../Generics/Checkbox';
import Select from '../../../../Generics/Select';
import Measures from '../../../../Generics/Measures';
import { baseTypes, currencies } from '../../../../Dictionaries/yDataTypes.js';
import xDataTypes from '../../../../Dictionaries/xDataTypes';
import uid from 'uid';

class DataView extends Component {
    constructor(props){
        super(props);

        this.onSave = this.onSave.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.onRowSelected = this.onRowSelected.bind(this);

        // default state
        this.state = {
            dataset_id: this.props.dataset_id,
            viewOnly: this.props.viewOnly,
            group: this.props.group,
            edited: this.props.edited || false,
            addData: false,
            deleteData: false,
            selectedRows: [],
            selectedCols: [],
            collapsedRows: []
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps;
    }

    onSave() {
        const { edited } = this.state;
        const message = "This will overwrite previous data. Do you want to continue?";

        //if(edited){
            this.props.onSave(this.state);
            this.setState({edited: false, addData: false, deleteData: false});
        /*} else {
            alert('No changes to save.');
        }*/
    }

    onEditData(depth, i, pID){
        const { group, viewOnly } = this.state;
        let { rows } = group;
        const parents = pID.split('|');

        if(viewOnly){
            return;
        }

        // Modify dataset group data
        rows = this.findAndSet(rows, parents, i, 0);

        // Mark row as modifed to rerun verification
        rows.map((row)=>{
            if(row.id == parents[0]){
                row.modified = true;
            }
            return row;
        })
        group.rows = rows;
        this.setState({group, edited: true});
    }

    onEditHeader(i){
        const { group, viewOnly } = this.state;
        let { header } = group;

        if(viewOnly){
            return;
        }
        // Mark row as modifed to rerun verification
        header.parent = header.parent.map((h, j)=>{
            if(j == i){
                return {modified: true, original: h, value: h};
            }
            return h;
        })
        group.header = header;
        this.setState({group, edited: true});
    }

    onAddColumn(position ,i){
        const { group } = this.state;
        const { rows } = group;
        const offset = position == 'after' ? 1 : 0;
        group.header.parent.splice(i + offset, 0, "New Date");
        group.children = this.insertColumn(rows, i + offset);

        this.setState({group, addModal: false, edited: true, headerEdited: true});
    }

    onAddData(position, pId){
        const { group } = this.state;
        let { rows } = group;
        let offset, newOffset;
        const parents = pId.split('|');

        // Inserts new row in group data
        switch(position){
            case 'before':
                offset = 0;
                newOffset = -1;
                break;
            case 'after':
                offset = 1;
                newOffset = 1;
                break;
        }// Mark row as modifed to rerun verification
        rows = this.findAndInsert(rows, parents, 0, offset);
        const index = rows.findIndex((item)=>item.id == parents[0]);
        rows.map((row, i)=>{
            if(index + newOffset == i){
                row.modified = true;
            }
            return row;
        })
        group.rows = rows;
        this.setState({addModal: false, group, edited: true});
    }

    onRemoveData(pId){
        const { group } = this.state;
        let { rows } = group;
        const parents = pId.split('|');

        rows = this.findAndRemove(rows, parents, 0, 0);
        group.rows = rows;
        this.setState({addModal: false, group, edited: true});
    }

    onRemoveColumn(i){
        const { group } = this.state;
        const { rows } = group;
        group.header.parent.splice(i, 1);
        group.children = this.deleteColumn(rows, i);

        this.setState({group, addModal: false, edited: true, headerEdited: true});
    }

    onColSelected(e, index){
        const { group, selectedCols } = this.state;
        const { rows } = group;
        const target = e.target;
        let value = target.checked;

        const i = selectedCols.findIndex(x=>x==index);
        if(value && i == -1){
            selectedCols.push(index)
        } else if (!value && i > -1) {
            selectedCols.splice(i, 1);
        }
        this.setState({selectedCols}, ()=>{
            this.selectColumn(rows, index, value);
        });
    }

    onRowSelected(e, id){
        const {selectedRows} = this.state;
        const target = e.target;
        let value = target.checked;

        const index = selectedRows.indexOf(id);
        if(value && index == -1){
            selectedRows.push(id);
        } else if(!value && index > -1) {
            selectedRows.splice(index, 1);
        }

        this.setState({selectedRows}, ()=>{
            if(this.props.onRowSelected){
                this.props.onRowSelected(selectedRows);
            }
        });
    }

    onDownload(){

    }

    handleHeaderTextChange(e, i){
        const { group } = this.state;
        let { header } = group;
        const target = e.target;
        let value = target.value;

        header.parent = header.parent.map((h, j)=>{
            if(j == i){
                h.value = value;
            }
            return h;
        })
        group.header = header;
        this.setState({group, edited: true, headerEdited: true});
    }

    handleTextChange(e){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        const { group } = this.state;
        group[name] = value;
        this.setState({group, groupModified: true, edited: true});
    }

    handleXTextChange(e, pID, i){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        const { group } = this.state;
        let { rows } = group;
        const parents = pID.split('|');

        // Initial click marks as modified and allows user input
        rows = this.findAndSet(rows, parents, i, 0, value);
        group.rows = rows;
        this.setState({group})
    }

    handleYTextChange(e, pID, i){
        const target = e.target;
        const name = target.name;
        let value = target.value;
        value = value.replace(/[^0-9\.]+/g, '')

        const { group } = this.state;
        let { rows } = group;
        const parents = pID.split('|');

        // Initial click marks as modified and allows user input
        rows = this.findAndSet(rows, parents, i, 0, value);
        group.rows = rows;
        this.setState({group})
    }

    findAndRemove(rows, parents, cPId, value){
        let id;
        const { removed = [] } = this.state;
        const index = rows.findIndex((item)=>{
            id = item.id;
            return parents[cPId] == item.id;
        });
        if(index > -1){
            if(cPId+1 === parents.length){
                if(cPId == 0){
                    removed.push(id);
                }
                rows.splice(index, 1);
            } else {
                const {children } = rows[index];
                if(children){
                    rows[index].children = this.findAndRemove(children, parents, cPId+1, value);
                }
            }
        } else {
            // console.log(parents[cPId], rows);
        }

        this.setState({removed});
        return rows;
    }

    findAndInsert(rows, parents, cPId, value){
        const index = rows.findIndex((item)=>{
            return parents[cPId] == item.id;
        });
        if(index > -1){
            if(cPId+1 === parents.length){
                const id = uid(25);
                let newItem = Object.assign({},rows[index]);
                newItem.parent = newItem.parent.map(()=>{
                    return "0";
                })
                newItem.key = 'New Field';
                newItem.id = id;
                newItem.children = [];
                rows.splice(index + value, 0, newItem);
            } else {
                const {children } = rows[index];
                if(children){
                    rows[index].children = this.findAndInsert(children, parents, cPId+1, value);
                }
            }
        } else {
            //console.log(parents, cPId, rows);
        }
        return rows;
    }

    insertColumn(rows, i){
        return rows.map((row)=>{
            const { parent, children } = row;
            parent.splice(i, 0, {modified: true, original: '', value: ''});
            row.parent = parent;
            if(children){
                row.children = this.insertColumn(children, i);
            }
            return row;
        });
    }

    deleteColumn(rows, i){
        return rows.map((row)=>{
            const { parent, children } = row;
            parent.splice(i, 1);
            row.parent = parent;
            if(children){
                row.children = this.deleteColumn(children, i);
            }
            return row;
        });
    }

    selectColumn(rows, index, selected, level = 0){
        let {selectedRows} = this.state;
        rows.forEach((row)=>{
            if(!row){
                return;
            }
            const { parent, children, key } = row;
            if(index == level){
                const i = selectedRows.findIndex(x=>x == key);
                if(selected && i == -1){
                    selectedRows.push(key);
                } else if (!selected && i > -1){
                    selectedRows.splice(i,1);
                }

                this.setState({selectedRows}, ()=>{
                    if(this.props.onRowSelected){
                        this.props.onRowSelected(selectedRows);
                    }
                });
            }
            if(children){
                this.selectColumn(children, index, selected, level + 1);
            }
        });
    }

    findAndSet(rows, parents, i, cPId, value){
        return rows.map((row)=>{
            const {id, key, parent, children} = row;
            if(id == parents[cPId]){
                if(parents.length == cPId + 1){
                    // Handle row label change
                    if(i == -1 && value != undefined){
                        const prevKey = row.key;
                        prevKey.value = value;
                        row.key = prevKey;
                        return row;
                    } else if (i == -1) {
                        row.key = {modified: true, original: row.parent[i], value: key};
                        return row;
                    }

                    // Handle row field change
                    if(value != undefined){
                        const prevRow = row.parent[i];
                        prevRow.value = value;
                        row.parent[i] = prevRow;
                        return row;
                    } else {
                        row.parent[i] = {modified: true, original: row.parent[i], value: row.parent[i]};
                        return row;
                    }
                } else {
                    row.children = this.findAndSet(children, parents, i, cPId+1, value);
                }
            }
            return row;
        });
    }

    collapseRow(id, fold){
        const { collapsedRows = [] } = this.state;
        const i = collapsedRows.findIndex(x => x == id);
        if(!fold && i > -1){
            collapsedRows.splice(i,1);
        } else if (i == -1) {
            collapsedRows.push(id);
        }
        this.setState({collapsedRows}, ()=>{
            if(this.props.onCollapseRow){
                this.props.onCollapseRow(collapsedRows);
            }
        });
    }

    collapseAllRow(depth, rows, fold, currentDepth){
        rows.forEach((row)=>{
            const {id, key, parent, children} = row;
            if(depth == currentDepth){
                this.collapseRow(id, fold);
            }
            if(children && children.length > 0){
                this.collapseAllRow(depth, children, fold, currentDepth + 1);
            }
        });
    }

    renderRows(rows, depth, maxDepth, pID = ""){
        const rowElements = [];
        const { addData, deleteData, viewOnly, selectedRows } = this.state;
        const { selectable } = this.props;

        rows.forEach((item, i) => {
            if(!item){
                return;
            }
            const {id, key, parent = [], children = [], fileName} = item;
            const { collapsedRows = [] } = this.state;
            const collapsed = collapsedRows.findIndex(x => x == id) > -1;
            const fieldElements = [];
            const csvRow = [];

            if(parent.length > 0){
                if(selectable){
                    const val = selectedRows.findIndex(x=>x==key) > -1;
                    fieldElements.push(<td>
                        <Checkbox
                            key={`row-selected-${key}`}
                            className="col s2"
                            name={`row-selected-${key}`}
                            value={val}
                            onChange={(e)=>this.onRowSelected(e, key)}
                        />
                    </td>);
                }
                for(let i = 0; i < maxDepth; i++){
                    if(i == depth){
                        csvRow.push(key);
                        let label = <span onClick={()=>{this.onEditData(depth, -1, pID+id)}}>{key}</span>;
                        if(viewOnly){
                            label = <div className="col s12" style={{padding: '0px'}}>
                                { children.length !== 0 && <div className = 'col s2' style={{padding: '0px'}}>
                                    {!collapsed && <i
                                        className="material-icons tiny"
                                        style={{paddingRight: '0.2rem', cursor: 'pointer', padding: '0px'}}
                                        onClick={()=>this.collapseRow(id, true)}
                                    >unfold_less</i>}
                                     {collapsed && <i
                                        className="material-icons tiny blue-text"
                                        style={{paddingRight: '0.2rem', cursor: 'pointer', padding: '0px'}}
                                        onClick={()=>this.collapseRow(id, false)}
                                    >unfold_more</i>}
                                </div>}
                                <div className = 'col s10 left-align' style={{padding: '0px'}}>{label}</div>
                            </div>;
                        }
                        if(key && key.modified){
                            label = <Input
                                style={{width:'90px', margin: '0px', height: '1rem !important'}}
                                className={'center'}
                                type = 'text'
                                name= {pID+id+-1}
                                inputClass={'center'}
                                errors={[]}
                                value={key.value}
                                handleChange={(e)=>this.handleXTextChange(e, pID+id, -1)}
                            />;
                        }
                        fieldElements.push(
                            <td className="valign-wrapper" style={{minWidth:'120px'}}>
                                {addData &&
                                    <i
                                        className="material-icons tiny blue-text text-darken-2"
                                        style={{paddingRight: '0.2rem', cursor: 'pointer'}}
                                        onClick={()=>this.setState({addModal: true, addPId: pID+id})}
                                    >add_circle_outline</i>}
                                 {deleteData &&
                                    <i
                                        className="material-icons tiny red-text text-darken-2"
                                        style={{paddingRight: '0.2rem', cursor: 'pointer'}}
                                        onClick={()=>this.onRemoveData(pID+id)}
                                    >remove_circle_outline</i>}
                                {label}
                            </td>);
                    } else {
                        csvRow.push('');
                        fieldElements.push(<td></td>);
                    }
                }
                parent.forEach((field, j)=>{
                    csvRow.push(field);
                    if(field && field.modified){
                        fieldElements.push(
                            <td>
                                <Input
                                    style={{width:'90px', margin: '0px', height: '1rem !important'}}
                                    className={'center'}
                                    type = 'text'
                                    name= {pID+id+j}
                                    inputClass={'center'}
                                    errors={[]}
                                    value={field.value}
                                    handleChange={(e)=>this.handleYTextChange(e, pID+id, j)}
                                />
                            </td>
                        );
                    } else {
                        field = field != '' && field ? parseFloat(field).toFixed(2) : '-';
                        fieldElements.push(<td onClick={()=>{this.onEditData(depth, j, pID+id)}}>{field}</td>);
                    }
                });
                this.csvData.push(csvRow);
                rowElements.push(<tr>{fieldElements}</tr>);
            }
            if (children.length !== 0 && !collapsed) {
                rowElements.push(this.renderRows(children, depth + 1, maxDepth, pID+id+'|'))
            }
        });
        return rowElements;
    }

    renderHeader(){
        const {selectable, group, viewOnly} = this.props;
        const { header = {parent:[]}, maxDepth, rows = [] } = viewOnly ? group : this.state.group;
        const { addData, deleteData, selectedCols } = this.state;
        const headerElements = [];
        if(selectable){
            headerElements.push(<th className='center'>Selected</th>);
        }
        for(let i = 0; i < maxDepth; i++){
            const val = selectedCols.findIndex(x=>x==i) > -1;
            headerElements.push(<th className='center'>
                 <div style={{display: 'flow-root'}}>
                    <Checkbox
                        key={`col-selected-${i}`}
                        className="col s2"
                        name={`col-selected-${i}`}
                        value={val}
                        onChange={(e)=>this.onColSelected(e, i)}
                    />
                </div>
                <i
                    key={`col-unfold-${i}`}
                    className="material-icons tiny"
                    style={{ cursor: 'pointer', padding: '10px'}}
                    onClick={()=>this.collapseAllRow(i, rows, true, 0)}
                >unfold_less</i>
                <i
                    key={`col-fold-${i}`}
                    className="material-icons tiny"
                    style={{cursor: 'pointer', padding: '10px'}}
                    onClick={()=>this.collapseAllRow(i, rows, false, 0)}
                >unfold_more</i>
            </th>);
        }

        this.csvData.push([]
            .concat(
                Array(maxDepth).fill('', 0, maxDepth),
                header.parent
            )
        );

        header.parent.forEach((h, i)=>{
            let label = <span onClick={()=>{this.onEditHeader(i)}}>{h}</span>;
            if(h.modified){
                label = <Input
                    style={{width:'90px', margin: '0px', height: '1rem !important'}}
                    className={'center'}
                    type = 'text'
                    name= {h+i}
                    inputClass={'center'}
                    errors={[]}
                    value={h.value}
                    handleChange={(e)=>this.handleHeaderTextChange(e, i)}
                />;
            }
            headerElements.push(
                <th style={{minWidth:'100px'}} className="center">
                    {addData &&
                        <i
                            className="material-icons tiny white-text text-darken-2"
                            style={{paddingRight: '0.2rem', cursor: 'pointer'}}
                            onClick={()=>this.setState({addModal: true, addCId: i})}
                        >add_circle_outline</i>}
                        {deleteData &&
                        <i
                            className="material-icons tiny white-text text-darken-2"
                            style={{paddingRight: '0.2rem', cursor: 'pointer'}}
                            onClick={()=>this.onRemoveColumn(i)}
                        >remove_circle_outline</i>
                    }
                    <span>{label}</span>
                </th>
            );
        });
        return headerElements;
    }

    renderData(){
        const { rows = [], maxDepth } = this.state.group;

        return(
            <div className="col s12" style={{overflowX:'auto'}}>
                <table>
                    <thead>
                        <tr>
                            {this.renderHeader()}
                        </tr>
                    </thead>
                    <tbody>
                        {this.renderRows(rows, 0, maxDepth)}
                    </tbody>
                </table>
            </div>
        );
    }

    renderModal(){
        const { addModal, addPId, addCId } = this.state;
        if(!addModal){
            return(<div></div>)
        }
        return(
            <div id="myModal" className="modal row">
                <div className="modal-content form col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={()=>this.setState({addModal:false})}>&times;</span>
                    <h4>Insert Record</h4>
                    <p>Would you like to insert the record before or after?</p>
                    {addPId && <div className="col s12">
                        <button className="ppf-primary-button col s3 padded-right right" onClick={()=>this.onAddData('before', addPId)}>Before</button>
                        <button className="ppf-primary-button col s3 padded-right right" onClick={()=>this.onAddData('after', addPId)}>After</button>
                    </div>}
                    {addCId != undefined && <div className="col s12">
                        <button className="ppf-primary-button col s3 padded-right right" onClick={()=>this.onAddColumn('before', addCId)}>Before</button>
                        <button className="ppf-primary-button col s3 padded-right right" onClick={()=>this.onAddColumn('after', addCId)}>After</button>
                    </div>}
                </div>
            </div>
        )
    }

    renderPrint(){
        return <div className = 'col s12 right'>
            <CSVLink data={this.csvData} filename='data.csv'>
                <i class="material-icons right waves-effect" onClick={this.onDownload}>file_download</i>
            </CSVLink>
        </div>
    }

    render(){
        this.csvData = [];
        const { loading } = this.props;
        const { group, viewOnly, addData, deleteData } = this.state;
        const { id, rows = [] } = group;
        if(loading){
            return(
                <div className="row">
                    <div className="container" style={{marginTop: '5%'}}>
                        <h4>Loading...</h4>
                    </div>
                </div>
            )
        }
        return(
            <div className="row">
                <div className="col s12">
                        {!viewOnly && <button className="ppf-primary-button col s1 offset-s8 padded-right" onClick={this.onSave}>Save</button>}
                        {!viewOnly && !addData && <button className="ppf-primary-button col s1 padded-right" onClick={()=>this.setState({addData: true})}>Show Add</button>}
                        {!viewOnly && addData && <button className="ppf-primary-button col s1 padded-right" onClick={()=>this.setState({addData: false})}>Hide Add</button>}
                        {!viewOnly &&!deleteData && <button className="ppf-primary-button col s1" onClick={()=>this.setState({deleteData: true})}>Show Delete</button>}
                        {!viewOnly && deleteData && <button className="ppf-primary-button col s1" onClick={()=>this.setState({deleteData: false})}>Hide Delete</button>}
                    </div>
                {this.renderPrint()}
                <div className="col s12" style={{padding: '2rem'}}>
                    {rows.length > 0 ? this.renderData() : <h5 className="center">No Data Found</h5>}
                </div>
                {this.renderModal()}
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(DataView);