/*
Record Form is Initialized with baseFrom.
Modify values to create different form structures.
*/
export const baseForm = [
    {
        title:"Record Information",
        inputs:[
            { name: "recordClass", type: "select", placeholder: "Record Class", className: "col s6", value: "all", required: true},
            { name: "recordType", type: "select", placeholder: "Record Type", className: "col s6", required: true},
            { name: "status", type: "select", placeholder: "Status", className: "col s6", required: true},
            { name: "start_date", type: "date", placeholder: "Start Date", className: "col s6", value:"", required: true },
        ]
    }
];
export const individualForm = [
    {
        title:"Personal Information",
        inputs:[
            { name: "last_name", type: "text", placeholder: "Surname", className: "col s6", value: "", required: true},
            { name: "first_name", type: "text", placeholder: "Given Name", className: "col s6", value:"", required: true },
            { name: "dob", type: "date", placeholder: "Date Of Birth", className: "col s6", value:"", required: true },
            { name: "gender", type: "select", placeholder: "Gender", className: "col s6", required: true },
            { name: "nationality", type: "select", placeholder: "Nationality", className: "col s6", required: true },
            { name: "occupation", type: "text", placeholder: "Occupation", className: "col s6", value:"" },
            { name: "nameOfBussiness", type: "text", placeholder: "Name Of Bussiness", className: "col s6", value:"", required: true },
            { name: "shares", type: "number", placeholder: "Percentage Of Shares Held", className: "col s6", value:"", required: true }
        ]
    },
    {
        title: "Proof Of Identity",
        inputs:[
            { name: "identityType", type: "select", placeholder: "Identity Type", className: "col s6", required: true },
            { name: "identityNumber", type: "text", placeholder: "ID Number", className: "col s6", value: "", required: true },
            { name: "idUpload", type: "uploader", placeholder: "ID Upload", className: "col s6", value: "", required: true },
        ]
    },
    {
        title: "Source Of Income",
        inputs:[
            { name: "sourceOfIncome", type: "text", placeholder: "Source Of Income", className: "col s12", inputClass:"col s6", value: "", required: true },
        ]
    },
    {
        title: "Proof Of Address",
        inputs:[
            { name: "address", type: "address", className: "col s6", value: "", required: true },
            { name: "addressUpload", type: "uploader", inputClassName:"record-form", placeholder: "Address Upload", className: "col s12", value: "", required: true },
        ]
    },
    {
        title: "Contact Information",
        inputs:[
            { name: "email", type: "email", placeholder: "Email Address", className: "col s6", value: "" },
            { name: "telephone", type: "text", placeholder: "Telephone Number", className: "col s6", value: "", required: true }
        ]
    },
    {
        title: "Professional/Banker Reference",
        inputs:[
            { name: "name", type: "text", placeholder: "Full Name", className: "col s6", value: "", required: true },
            { name: "relationship", type: "text", placeholder: "Relationship", className: "col s6", value: "", required: true },
            { name: "bankerAddress", type: "address", className: "col s6", value: "" },
        ]
    },
    {
        title:"Questions",
        renderFunction: "renderQuestions"
    },
];

export const entityForm = [
    {
        title:"General Information",
        inputs:[
            { name: "businessName", type: "text", placeholder: "Business Name", className: "col s6", value: "", required: true },
            {  name: "nature", type: "text", placeholder: "Nature of Business", className: "col s6", value:"", required: true },
            { name: "telephone", type: "text", placeholder: "Telephone/Fax", className: "col s6", value:"", required: true },
            { name: "email", type: "email", placeholder: "Email Address", className: "col s6", value: "" },
            { name: "incorporation_type", type: "select", placeholder: "Incorporation Type", className: "col s6", required: true },
            { name: "business_purpose", type: "select", placeholder: "Business Purpose", className: "col s6", required: true }
        ]
    },
    {
        title: "Proof Of Address",
        inputs:[
            { name: "address", type: "address", className: "col s6", value: "", required: true },
            { name: "addressUpload", type: "uploader", placeholder: "Address Upload", className: "col s12", value: "", required: true },
        ]
    },
    {
        title: "Partner Information",
        renderFunction: "renderTable",
        props: "partners"
    },
    {
        title: "Director/Trustee Information",
        renderFunction: "renderTable",
        props: "directors"
    },
    {
        title: "Debt/Creditor Information",
        renderFunction: "renderTable",
        props: "creditors"
    },
    {
        title:"Required Documents"
    }
];

export const questionCollection = {
    "q1": {text:"Are you a non-resident?", className: "col s12"},
    "q2": {text:"Are you involved in dealing in high value items?", className: "col s12"},
    "q3": {text:"Are you a holder of a senior level public (government) office?", className: "col s12"},
    "q4": {text:"Are any of your family members a holder of a senior level public (government) office?", className: "col s12"},
    "q5": {text:"Have you ever been refused entry to a foreign country?", className: "col s12"},
    "q6": {text:"Have you ever been declared bankrupt?", className: "col s12"},
    "q7": {text:"Have you ever been indicted or convicted of a criminal offense?", className: "col s12"}
};

export const typeMap = {
    recordClass: [
    { name: "Individual", value: "individual" },
    { name: "Entity", value: "entity" }
    ],
    individual: [
    { name: "Shareholder", value: "shareholder" },
    { name: "Director", value: "director" },
    { name: "Investor", value: "investor" }
    ],
    entity: [
    { name: "Company", value: "company" },
    { name: "Non-Profit", value: "nonprofit" },
    { name: "Trust", value: "trust" },
    { name: "Partnership", value: "partnership" },
    { name: "Government Entity", value: "governmentEntity" },
    { name: "Other", value: "other" }
    ],
    gender: [
    { name: "Male", value: "male" },
    { name: "Female", value: "female" }
    ],
    identityType: [
    { name: "Drivers License", value: "driverLicense" },
    { name: "Passport", value: "passport" },
    { name: "Social Security Number", value: "ssn" }
    ],
    incorporation_type: [
    { name: "Sole Trader", value: "SOLE_TRADER" },
    { name: "Private Limited", value: "PRIVATE_LIMITED" },
    { name: "Limited Liability Partnership", value: "LIMITED_LIABILITY_PARTNERSHIP" },
    { name: "Public Limited", value: "PUBLIC_LIMITED" },
    ],
    business_purpose: [
    { name: "Regulated Entity", value: "REGULATED_ENTITY" },
    { name: "Private Entity", value: "PRIVATE_ENTITY" },
    { name: "Unregulated Fund", value: "UNREGULATED_FUND" },
    { name: "Foundatation", value: "FOUNDATION" },
    { name: "Trust", value: "TRUST" },
    { name: "Religuis Body", value: "RELIGIOUS_BODY" },
    { name: "Government Entity", value: "GOVERNMENT_ENTITY" },
    { name: "Charity", value: "CHARITY" },
    { name: "Club", value: "CLUB" },
    { name: "Society", value: "SOCIETY" }
    ],
    status: [
    { name: "Active", value: "active" },
    { name: "Not Active", value: "inactive" }
    ],
    country: [
    { name: 'Afghanistan', value: 'AFG'},
    { name: 'Aland Islands', value: 'ALA'},
    { name: 'Albania', value: 'ALB'},
    { name: 'Algeria', value: 'DZA'},
    { name: 'American Samoa', value: 'ASM'},
    { name: 'Andorra', value: 'AND'},
    { name: 'Angola', value: 'AGO'},
    { name: 'Anguilla', value: 'AIA'},
    { name: 'Antarctica', value: 'ATA'},
    { name: 'Antigua and Barbuda', value: 'ATG'},
    { name: 'Argentina', value: 'ARG'},
    { name: 'Armenia', value: 'ARM'},
    { name: 'Aruba', value: 'ABW'},
    { name: 'Australia', value: 'AUS'},
    { name: 'Austria', value: 'AUT'},
    { name: 'Azerbaijan', value: 'AZE'},
    { name: 'Bahamas', value: 'BHS'},
    { name: 'Bahrain', value: 'BHR'},
    { name: 'Bangladesh', value: 'BGD'},
    { name: 'Barbados', value: 'BRB'},
    { name: 'Belarus', value: 'BLR'},
    { name: 'Belgium', value: 'BEL'},
    { name: 'Belize', value: 'BLZ'},
    { name: 'Benin', value: 'BEN'},
    { name: 'Bermuda', value: 'BMU'},
    { name: 'Bhutan', value: 'BTN'},
    { name: 'Bolivia', value: 'BOL'},
    { name: 'Bonaire, Sint Eustatius and Saba', value: 'BES'},
    { name: 'Bosnia and Herzegovina', value: 'BIH'},
    { name: 'Botswana', value: 'BWA'},
    { name: 'Bouvet Island', value: 'BVT'},
    { name: 'Brazil', value: 'BRA'},
    { name: 'British Indian Ocean Territory', value: 'IOT'},
    { name: 'Brunei Darussalam', value: 'BRN'},
    { name: 'Bulgaria', value: 'BGR'},
    { name: 'Burkina Faso', value: 'BFA'},
    { name: 'Burundi', value: 'BDI'},
    { name: 'Cambodia', value: 'KHM'},
    { name: 'Cameroon', value: 'CMR'},
    { name: 'Canada', value: 'CAN'},
    { name: 'Cape Verde', value: 'CPV'},
    { name: 'Cayman Islands', value: 'CYM'},
    { name: 'Central African Republic', value: 'CAF'},
    { name: 'Chad', value: 'TCD'},
    { name: 'Chile', value: 'CHL'},
    { name: 'China', value: 'CHN'},
    { name: 'Christmas Island', value: 'CXR'},
    { name: 'Cocos (Keeling) Islands', value: 'CCK'},
    { name: 'Colombia', value: 'COL'},
    { name: 'Comoros', value: 'COM'},
    { name: 'Congo', value: 'COG'},
    { name: 'Congo, The Democratic Republic of ', value: 'COD'},
    { name: 'Cook Islands', value: 'COK'},
    { name: 'Costa Rica', value: 'CRI'},
    { name: 'Cote d\'Ivoire', value: 'CIV'},
    { name: 'Croatia', value: 'HRV'},
    { name: 'Cuba', value: 'CUB'},
    { name: 'Curaçao', value: 'CUW'},
    { name: 'Cyprus', value: 'CYP'},
    { name: 'Czechia', value: 'CZE'},
    { name: 'Denmark', value: 'DNK'},
    { name: 'Djibouti', value: 'DJI'},
    { name: 'Dominica', value: 'DMA'},
    { name: 'Dominican Republic', value: 'DOM'},
    { name: 'Ecuador', value: 'ECU'},
    { name: 'Egypt', value: 'EGY'},
    { name: 'El Salvador', value: 'SLV'},
    { name: 'Equatorial Guinea', value: 'GNQ'},
    { name: 'Eritrea', value: 'ERI'},
    { name: 'Estonia', value: 'EST'},
    { name: 'Ethiopia', value: 'ETH'},
    { name: 'Falkland Islands (Malvinas)', value: 'FLK'},
    { name: 'Faroe Islands', value: 'FRO'},
    { name: 'Fiji', value: 'FJI'},
    { name: 'Finland', value: 'FIN'},
    { name: 'France', value: 'FRA'},
    { name: 'French Guiana', value: 'GUF'},
    { name: 'French Polynesia', value: 'PYF'},
    { name: 'French Southern Territories', value: 'ATF'},
    { name: 'Gabon', value: 'GAB'},
    { name: 'Gambia', value: 'GMB'},
    { name: 'Georgia', value: 'GEO'},
    { name: 'Germany', value: 'DEU'},
    { name: 'Ghana', value: 'GHA'},
    { name: 'Gibraltar', value: 'GIB'},
    { name: 'Greece', value: 'GRC'},
    { name: 'Greenland', value: 'GRL'},
    { name: 'Grenada', value: 'GRD'},
    { name: 'Guadeloupe', value: 'GLP'},
    { name: 'Guam', value: 'GUM'},
    { name: 'Guatemala', value: 'GTM'},
    { name: 'Guernsey', value: 'GGY'},
    { name: 'Guinea', value: 'GIN'},
    { name: 'Guinea-Bissau', value: 'GNB'},
    { name: 'Guyana', value: 'GUY'},
    { name: 'Haiti', value: 'HTI'},
    { name: 'Heard and Mc Donald Islands', value: 'HMD'},
    { name: 'Holy See (Vatican City State)', value: 'VAT'},
    { name: 'Honduras', value: 'HND'},
    { name: 'Hong Kong', value: 'HKG'},
    { name: 'Hungary', value: 'HUN'},
    { name: 'Iceland', value: 'ISL'},
    { name: 'India', value: 'IND'},
    { name: 'Indonesia', value: 'IDN'},
    { name: 'Iran, Islamic Republic of', value: 'IRN'},
    { name: 'Iraq', value: 'IRQ'},
    { name: 'Ireland', value: 'IRL'},
    { name: 'Isle of Man', value: 'IMN'},
    { name: 'Israel', value: 'ISR'},
    { name: 'Italy', value: 'ITA'},
    { name: 'Jamaica', value: 'JAM'},
    { name: 'Japan', value: 'JPN'},
    { name: 'Jersey', value: 'JEY'},
    { name: 'Jordan', value: 'JOR'},
    { name: 'Kazakstan', value: 'KAZ'},
    { name: 'Kenya', value: 'KEN'},
    { name: 'Kiribati', value: 'KIR'},
    { name: 'Korea, Democratic People\'s Republic of', value: 'PRK'},
    { name: 'Korea, Republic of', value: 'KOR'},
    { name: 'Kosovo (temporary code)', value: 'XKX'},
    { name: 'Kuwait', value: 'KWT'},
    { name: 'Kyrgyzstan', value: 'KGZ'},
    { name: 'Lao, People\'s Democratic Republic', value: 'LAO'},
    { name: 'Latvia', value: 'LVA'},
    { name: 'Lebanon', value: 'LBN'},
    { name: 'Lesotho', value: 'LSO'},
    { name: 'Liberia', value: 'LBR'},
    { name: 'Libyan Arab Jamahiriya', value: 'LBY'},
    { name: 'Liechtenstein', value: 'LIE'},
    { name: 'Lithuania', value: 'LTU'},
    { name: 'Luxembourg', value: 'LUX'},
    { name: 'Macao', value: 'MAC'},
    { name: 'Macedonia, The Former Yugoslav Republic Of', value: 'MKD'},
    { name: 'Madagascar', value: 'MDG'},
    { name: 'Malawi', value: 'MWI'},
    { name: 'Malaysia', value: 'MYS'},
    { name: 'Maldives', value: 'MDV'},
    { name: 'Mali', value: 'MLI'},
    { name: 'Malta', value: 'MLT'},
    { name: 'Marshall Islands', value: 'MHL'},
    { name: 'Martinique', value: 'MTQ'},
    { name: 'Mauritania', value: 'MRT'},
    { name: 'Mauritius', value: 'MUS'},
    { name: 'Mayotte', value: 'MYT'},
    { name: 'Mexico', value: 'MEX'},
    { name: 'Micronesia, Federated States of', value: 'FSM'},
    { name: 'Moldova, Republic of', value: 'MDA'},
    { name: 'Monaco', value: 'MCO'},
    { name: 'Mongolia', value: 'MNG'},
    { name: 'Montenegro', value: 'MNE'},
    { name: 'Montserrat', value: 'MSR'},
    { name: 'Morocco', value: 'MAR'},
    { name: 'Mozambique', value: 'MOZ'},
    { name: 'Myanmar', value: 'MMR'},
    { name: 'Namibia', value: 'NAM'},
    { name: 'Nauru', value: 'NRU'},
    { name: 'Nepal', value: 'NPL'},
    { name: 'Netherlands', value: 'NLD'},
    { name: 'New Caledonia', value: 'NCL'},
    { name: 'New Zealand', value: 'NZL'},
    { name: 'Nicaragua', value: 'NIC'},
    { name: 'Niger', value: 'NER'},
    { name: 'Nigeria', value: 'NGA'},
    { name: 'Niue', value: 'NIU'},
    { name: 'Norfolk Island', value: 'NFK'},
    { name: 'Northern Mariana Islands', value: 'MNP'},
    { name: 'Norway', value: 'NOR'},
    { name: 'Oman', value: 'OMN'},
    { name: 'Pakistan', value: 'PAK'},
    { name: 'Palau', value: 'PLW'},
    { name: 'Palestinian Territory, Occupied', value: 'PSE'},
    { name: 'Panama', value: 'PAN'},
    { name: 'Papua New Guinea', value: 'PNG'},
    { name: 'Paraguay', value: 'PRY'},
    { name: 'Peru', value: 'PER'},
    { name: 'Philippines', value: 'PHL'},
    { name: 'Pitcairn', value: 'PCN'},
    { name: 'Poland', value: 'POL'},
    { name: 'Portugal', value: 'PRT'},
    { name: 'Puerto Rico', value: 'PRI'},
    { name: 'Qatar', value: 'QAT'},
    { name: 'Republic of Serbia', value: 'SRB'},
    { name: 'Reunion', value: 'REU'},
    { name: 'Romania', value: 'ROU'},
    { name: 'Russia Federation', value: 'RUS'},
    { name: 'Rwanda', value: 'RWA'},
    { name: 'Saint Barthélemy', value: 'BLM'},
    { name: 'Saint Helena', value: 'SHN'},
    { name: 'Saint Kitts & Nevis', value: 'KNA'},
    { name: 'Saint Lucia', value: 'LCA'},
    { name: 'Saint Martin', value: 'MAF'},
    { name: 'Saint Pierre and Miquelon', value: 'SPM'},
    { name: 'Saint Vincent and the Grenadines', value: 'VCT'},
    { name: 'Samoa', value: 'WSM'},
    { name: 'San Marino', value: 'SMR'},
    { name: 'Sao Tome and Principe', value: 'STP'},
    { name: 'Saudi Arabia', value: 'SAU'},
    { name: 'Senegal', value: 'SEN'},
    { name: 'Seychelles', value: 'SYC'},
    { name: 'Sierra Leone', value: 'SLE'},
    { name: 'Singapore', value: 'SGP'},
    { name: 'Sint Maarten', value: 'SXM'},
    { name: 'Slovakia', value: 'SVK'},
    { name: 'Slovenia', value: 'SVN'},
    { name: 'Solomon Islands', value: 'SLB'},
    { name: 'Somalia', value: 'SOM'},
    { name: 'South Africa', value: 'ZAF'},
    { name: 'South Georgia & The South Sandwich Islands', value: 'SGS'},
    { name: 'South Sudan', value: 'SSD'},
    { name: 'Spain', value: 'ESP'},
    { name: 'Sri Lanka', value: 'LKA'},
    { name: 'Sudan', value: 'SDN'},
    { name: 'Suriname', value: 'SUR'},
    { name: 'Svalbard and Jan Mayen', value: 'SJM'},
    { name: 'Swaziland', value: 'SWZ'},
    { name: 'Sweden', value: 'SWE'},
    { name: 'Switzerland', value: 'CHE'},
    { name: 'Syrian Arab Republic', value: 'SYR'},
    { name: 'Taiwan, Province of China', value: 'TWN'},
    { name: 'Tajikistan', value: 'TJK'},
    { name: 'Tanzania, United Republic of', value: 'TZA'},
    { name: 'Thailand', value: 'THA'},
    { name: 'Timor-Leste', value: 'TLS'},
    { name: 'Togo', value: 'TGO'},
    { name: 'Tokelau', value: 'TKL'},
    { name: 'Tonga', value: 'TON'},
    { name: 'Trinidad and Tobago', value: 'TTO'},
    { name: 'Tunisia', value: 'TUN'},
    { name: 'Turkey', value: 'TUR'},
    { name: 'Turkish Rep N Cyprus (temporary code)', value: 'XTX'},
    { name: 'Turkmenistan', value: 'TKM'},
    { name: 'Turks and Caicos Islands', value: 'TCA'},
    { name: 'Tuvalu', value: 'TUV'},
    { name: 'Uganda', value: 'UGA'},
    { name: 'Ukraine', value: 'UKR'},
    { name: 'United Arab Emirates', value: 'ARE'},
    { name: 'United Kingdom', value: 'GBR'},
    { name: 'United States', value: 'USA'},
    { name: 'United States Minor Outlying Islands', value: 'UMI'},
    { name: 'Uruguay', value: 'URY'},
    { name: 'Uzbekistan', value: 'UZB'},
    { name: 'Vanuatu', value: 'VUT'},
    { name: 'Venezuela', value: 'VEN'},
    { name: 'Vietnam', value: 'VNM'},
    { name: 'Virgin Islands, British', value: 'VGB'},
    { name: 'Virgin Islands, U.S.', value: 'VIR'},
    { name: 'Wallis and Futuna', value: 'WLF'},
    { name: 'Western Sahara', value: 'ESH'},
    { name: 'Yemen', value: 'YEM'},
    { name: 'Zambia', value: 'ZMB'},
    { name: 'Zimbabwe', value: 'ZWE'}
    ],
};
