import React, {Component} from 'react'

const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const template = (methods, state) => {
    const inputs  = [
        { name: "estimate_name", type: "text", placeholder: "Name", className: "col s6", value: "", required: true },
        {  name: "estimate_description", type: "text", placeholder: "Description", className: "col s6", value:"", required: true },

        { name: "due_date", type: "date", placeholder: "Milestone Date", className: "col s6", value: "", required: true, disabled: true },
        { name: "estimate_milestone", type: "select", placeholder: "Milestone", className: "col s6", 
            innerStyle: {padding: '0px'}, value: "", required: true, dictionary: ()=>state.milestones 
        },

       { name: "estimate_currency", type: "select", dictionary: ()=>[
            {name: "BZD", value: "bzd"},
            {name: "US", value: "us"}
        ], placeholder: "Currency", className: "col s6", value:"", required: true },
        { name: "tax_type", type: "select", yesNo: true, placeholder: "Tax Type", className: "col s6", required: true, dictionaryId: '-MKQQe4zg0JtW5aDdgFq'},

        { name: "estimate_amount", type: "number", placeholder: "Amount", className: "col s6", innerStyle: halfStyle, value: "", required: true },
        { name: "estimate_estimate", type: "text", isMoney: true, placeholder: "Estimate", className: "col s6", disabled: true}
    ];

    return [{
        permission: [
            {feature: 'dealsManagement', part: 'estimates'}
        ],
        title: state.data.estimate_name || `New Invoice`,

        inputs,
        buttons: [
            {
                name: 'save',
                text: "Save",
                action: methods.onSave,
                className: "ppf-primary-button right",
                edits: true
            }
        ]
    }];
}


export default template;