import React, {Component} from 'react'
import axios from 'axios';
import * as _ from 'lodash';
import { hasPermission } from '../../../../../utils/PermissionUtil';
import { formatAddress, getRecordName } from '../../../../../utils/Formatter';
import SearchRecordForm from '../General/SearchRecordForm';

class EntitiesView extends Component {
    static getTemplate(){
        return [
            {
                table: true,
                title: "Partner Information",
                props: "partners"
            },
            {
                table: true,
                title: "Director/Trustee Information",
                props: "directors"
            },
            {
                table: true,
                title: "Debt/Creditor Information",
                props: "creditors"
            },
            {
                table: true,
                title: "Management",
                props: "management"
            }
        ];
    }

    constructor(props){
        super(props);

        this.getTableRecordsDetails = this.getTableRecordsDetails.bind(this);
        this.onAttachRecord = this.onAttachRecord.bind(this);

        this.state = this.props.state;
    }

    /**
     * This is used to get the most recent data based of the
     * customer keys.
     */
    componentDidMount(){
        this.getTableRecordsDetails('directors');
        this.getTableRecordsDetails('creditors');
        this.getTableRecordsDetails('partners');
        this.getTableRecordsDetails('management');
    }

    onDeleteAttachedRecord(type, {key}){
        const records = this.state.data[type];
        this.state.data[type] = _.filter(records, (rec)=>{
            return key !== rec.key;
        });
        if (window.confirm('Are you sure you wish to delete this item?')){
            this.setState(this.state, ()=>{
                //this.props.methods.onRefresh();
                this.props.methods.onSave(this.state.data, {inputs:[{name: type}]});
            });
        }
    }

    onAttachRecord(type, record){
        this.state.data[type] = this.state.data[type] ? this.state.data[type] : [];
        this.state.searchModal = null;

        if(_.find(this.state.data[type], {key: record.key})){
            this.setState(this.state, ()=>{
                alert("This record is already attached.")
            });
            return;
        }

        if(["directors", "management"].includes(type)){
            this.state.data[type].push({key: record.key});
        } else {
            this.state.data[type].push({key: record.key, shares: record.shares});
        }

        this.setState(this.state, ()=>{
            this.getTableRecordsDetails(type, () => {
                this.props.methods.onSave(this.state.data, {inputs: [{name: type}]});
            });
        });
    }

    getTableRecordsDetails(type, cb  = () => {}){
        let promises = [];

        if(!this.state.data){
            return;
        }

        const records = this.state.data[type] || [];
        const objects = [];
        _.forEach(records, (record)=>{
            let promise = new Promise((resolve, reject)=>{
                this.getRecordData(record.key, false,
                    (response)=>{
                        const obj = {};
                        Object.assign(obj, record);
                        Object.assign(obj, {key: record.key || record});
                        obj.name = getRecordName(response);
                        obj.address = formatAddress(response.address);
                        objects.push(obj);
                        resolve({result: "Success"})
                    }
                );
            });
            promises.push(promise);
        });

        Promise.all(promises).then(
            (values)=>{
                this.state.data[type] = objects;
                this.setState(this.state, ()=>{
                    cb();
                });
            }
        );
    }

    getRecordData(key, details, callback){
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        let data = { key, details};

        axios.post('/api/getRecord',{
            data
        }).then(
        (res) => {
            callback(res.data);
        })
        .catch((e)=>console.log(e));
    }

    renderModal(){
        const { searchModal } = this.state;
        if(!searchModal){
            return(<div></div>);
        }
        return(
            <div id="myModal" className="modal row">
                <div className="modal-content form col l8 s10 offset-s1 offset-l2">
                    <span className="close" onClick={()=>this.setState({searchModal:null})}>&times;</span>
                    <SearchRecordForm
                        auth={this.props.superProps.auth}
                        type={searchModal.type}
                        onAdd={this.onAttachRecord}
                        onNew={()=>this.props.methods.onNew(searchModal.type)}
                        record = {searchModal &&  searchModal.searchRecord ? searchModal.searchRecord.record : null}
                    />
                </div>
            </div>
        );
    }

    renderTable(type){
        if(!this.state.data){
            return;
        }

        const { auth } = this.props.superProps;
        const { viewOnly } = this.props.state;
        const records = this.state.data[type] || [];
        const rows = [];
        _.forEach(records, (record, index)=>{
            rows.push(
                <tr key={record.name+type.capitalize()}>
                    <td onClick={()=>{
                            this.props.history.push({
                                pathname: `/client/${record.key}` ,
                                state: {key: record.key}
                            });
                    }}><a>{record.name}</a></td>
                    <td>{record.address}</td>
                    {!["directors", "management"].includes(type) && <td>{record.shares}</td>}
                    {
                        !viewOnly
                        && hasPermission(auth.user, 'clientManagement', 'entities', 'delete')
                        && <td>
                        <button
                            title='Delete'
                            className="ppf-flat col s12"
                            onClick={()=>this.onDeleteAttachedRecord(type,record)}
                            >
                                <i class="red-text material-icons">delete</i>
                        </button>
                        </td>
                    }
                </tr>
            );
        });

        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {rows.length > 0 && <table>
                    <thead>
                    <tr>
                        <th className="center">Name</th>
                        <th className="center mobile-hide">Address</th>
                        {!["directors", "management"].includes(type) && <th className="center mobile-hide">Shares</th>}
                        {
                            !viewOnly
                            && hasPermission(auth.user, 'clientManagement', 'entities', 'write')
                            && <th className="center mobile-hide">Actions</th>}
                    </tr>
                    </thead>
                    <tbody>
                        {rows}
                    </tbody>
                </table>}
                {
                    !viewOnly
                    && hasPermission(auth.user, 'clientManagement', 'entities', 'write')
                    && <div className="col l4 s12 right">
                        <button
                            className="ppf-primary-button col s12"
                            onClick={()=>this.setState({searchModal: {type}})}
                        >Add Record</button>
                    </div>
                }
            </div>
        );
    }

    render(){
        const { recordClass } = this.state.data;
        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {this.renderModal()}

                <h5>Partner Information</h5>
                {this.renderTable('partners')}

                <h5>Director/Trustee Information</h5>
                {this.renderTable('directors')}

                <h5>Debt/Creditor Information</h5>
                {this.renderTable('creditors')}

                <h5>Management</h5>
                {this.renderTable('management')}
        </div>
        );
    }
}

export default EntitiesView;