import axios from 'axios';
import React, { Fragment, useState } from 'react'
import { IndividualGeneralDetails } from './SanctionMatchIndCard';
import { EntityGeneralDetails } from './SanctionsMatchEntityCard';

const SanctionDBRecordDetails = ({data}) => {
	console.log(data);
	const { 
		additional_comments, description, place_of_birth, 
		sanctioned_date, sanction_last_updated, 
		title, position, identities, 
		associations, related_individuals, conflict_of_interest,
		nationality,
		address_1, address_2, city, state, province, country, zip,
		peps
	} = data;
	return <Fragment>
		{description && [
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>,
			<div style={{height: "auto", overflow: 'hidden'}}>
				<b>Description: </b>{description ?? 'N/A'}
				<br/>
			</div>]
		}
		{(title || position || identities) && [
			<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>,
			<div style={{height: "auto", overflow: 'hidden'}}>
				<b>Titles(s): </b>{title ?? 'N/A'}<br/>
				<b>Position(s): </b>{position ?? 'N/A'}<br/>
				<b>Identity Docs: </b>{identities ?? 'N/A'}<br/>
			</div>]
		}
		{(conflict_of_interest || related_individuals || associations) && [
			<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>,
			<div style={{height: "auto", overflow: 'hidden'}}>
				<b>Conflicts: </b>{conflict_of_interest ?? 'N/A'}<br/>
				<b>Related Individuals: </b>{related_individuals ?? 'N/A'}<br/>
				<b>Associations: </b>{associations ?? 'N/A'}<br/>
			</div>]
		}
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "auto", overflow: 'hidden'}}>
			<b>Place(s) Of Birth: </b>{place_of_birth ?? 'N/A'}<br/>
			{nationality && <Fragment><b>Nationality:</b> {nationality ?? 'N/A'}<br/></Fragment>}
			<b>Address: </b>
			{address_1 && <Fragment>{address_1}<br/></Fragment>}
			{address_2 && <Fragment>{address_2}<br/></Fragment>}
			{city && <Fragment>{city}<br/></Fragment>}
			{state && <Fragment>{state}<br/></Fragment>}
			{province && <Fragment>{province}<br/></Fragment>}
			{country && <Fragment>{country}<br/></Fragment>}
			{zip && <Fragment>{zip}<br/></Fragment>}
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "auto", overflow: 'hidden'}}>
			<b>Additional Comments: </b>{additional_comments ?? 'N/A'}
			<br/>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		{!peps && <div style={{height: "auto", overflow: 'hidden'}}>
			<b>Sanctioned Date: </b>{sanctioned_date ?? 'N/A'}<br/>
			<b>Last Updated Date: </b>{sanction_last_updated ?? 'N/A'}<br/>
		</div>}
	</Fragment>
}

const InterpolRecordDetails = ({data}) => {
	const [additionalData, setAdditionalData] = useState();
	const { 
		arrest_warrants = [], 
		nationalities = [], place_of_birth, country_of_birth_id, 
		date_of_birth,
		distinguishing_marks, eyes_colors_id = [], 
		sex_id, height, weight,
		languages_spoken_ids = []
	} = additionalData || {};

	if(!additionalData){
		axios.post('/api/aml/getInterpolDetails', {url:  data.additional_comments}).then(additionalDetailsResult=>{
			setAdditionalData(additionalDetailsResult.data.data);
		}).catch(err=>{
			console.log(`Error fetching record details`);
		});
	}

	return <Fragment>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "auto", overflow: 'hidden'}}>
			<u><b>Arrest Warrants</b></u><br/>
			{arrest_warrants.length > 0 ? arrest_warrants.map(warrant => {
				return <div className = "col s12">
						<b>Issuing Country: </b>{warrant.issuing_country_id ?? 'N/A'}<br/>
						<b>Charge: </b>{warrant.charge_translation ?? warrant.charge ?? 'N/A'}<br/>
					</div>
			}) : 'None'}
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "auto", overflow: 'hidden'}}>
			<b>Distinguishing Marks: </b>{distinguishing_marks ?? 'N/A'}<br/>
			<b>Height: </b>{height ?? 'N/A'}<br/>
			<b>Weight: </b>{weight ?? 'N/A'}<br/>
			<b>Eye Color(s) </b>{eyes_colors_id && eyes_colors_id.length > 0 ? eyes_colors_id.join(', ') : 'N/A'}<br/>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "auto", overflow: 'hidden'}}>
			<b>Languages: </b> {languages_spoken_ids.length > 0 ? languages_spoken_ids.join(', ') : 'N/A'}<br/>
			<b>Country Of Birth: </b>{country_of_birth_id ?? 'N/A'}<br/>
			<b>Place(s) Of Birth: </b>{place_of_birth ?? 'N/A'}<br/>
			<b>Nationality:</b> {nationalities.length > 0 ? nationalities.join(', ') : 'N/A'}<br/>
		</div>
		{/*<div style={{height: "auto", overflow: 'hidden'}}>
			<br/>
			<b>Sanctioned Date: </b>{sanctioned_date ?? 'N/A'}<br/>
			<b>Last Updated Date: </b>{sanction_last_updated ?? 'N/A'}<br/>
			<br/>
		</div>*/}
	</Fragment>
}

const SanctionRecordDetails = ({ data, onClose }) => {
	const { sanctioning_program, type } = data;

	return <div className="limit-print">
		{ type === "Entity" ? <EntityGeneralDetails data={data}/> : <IndividualGeneralDetails data={data}/> }
		{ sanctioning_program === 'INTERPOL RED NOTICES' ? <InterpolRecordDetails data={data}/> : <SanctionDBRecordDetails data={data}/>}
		<button className="ppf-primary-button" onClick={()=>window.print()}>Print</button>
	</div>
}

const SanctionRecordDetailsPopUp = ({data, onClose=()=>{}}) => {
	return <div id="myModal" className="modal row">
		<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			<span className="close no-print" onClick={()=>onClose()}>&times;</span>
			<h3 className='no-print'>Record Details</h3>
			<SanctionRecordDetails data={data} />
		</div>
	</div>
}

export {
	SanctionRecordDetails,
	SanctionRecordDetailsPopUp
}