import React, { useState } from 'react';

const Input = ({
	isMoney, shouldAutoFocus, className, inputStyle, style = {}, 
	type, placeholder, name, handleChange, handleKeyPress, handleClick, 
	handleFocus, errors, value, inputClass, disabled, description, innerStyle,
	sensitive
}) => {
    const [display, setDisplay] = useState(false);
	let val = value || "";
	const elements = [];
	const inputElement = <input
		key={name}
		id={name}
		style={inputStyle}
		type={type}
		name={name}
		value={val}
		onChange={
			(e) => {
				if (isMoney) {
					e.value = parseInt(e.value)
				}
				handleChange(e)
			}
		}
		onKeyPress={handleKeyPress}
		onClick={handleClick}
		onFocus={handleFocus}
		disabled={disabled}
		autoFocus={shouldAutoFocus}
	/>;
	const buttonElement = <button 
			className='ppf-primary-button col s4 right' 
			onClick={()=>{setDisplay(!display)}}
			style={style}
		>{display ? 'Hide Data' : 'Show Data'}</button>;

	if(isMoney){
		val = val || 0;
		val = val.toLocaleString();
	}

	if(sensitive && val && val != ''){
		if(!display){
			elements.push(
				<div className = 'col s12 row'>
					<input className = 'col s8' value={`********${val.substr(val.length - 4, val.length)}`} disabled/>
					{buttonElement}
				</div>
			);
		} else {
			elements.push(
				<div className = 'col s12 row'>
					<div className="col s8">
						{inputElement}
					</div>
					{buttonElement}
				</div>
			);
		}
	} else {
		elements.push(inputElement);
	}
	
	return (
		<div className={"form-input " + className} style={style}>
			<div className={inputClass || 'col s12'} style={innerStyle}>
				<label className="textBox" htmlFor={name}>
					{placeholder}
				</label>
				{elements}
				{errors && typeof errors[name] !== 'undefined' && (
					<p className="error">
						{errors[name]}
					</p>
				)}
			</div>
		</div>)
};

export default Input;
