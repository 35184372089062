import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import { hasPermission } from '../../utils/PermissionUtil';

class DataManagement extends Component{
    render(){
        const items = [];
        const { user } = this.props.auth;
        const options = [
            { title: 'Client Management', key: 'client_management', path: '/client_management', feature: 'clientManagement', icon: 'clients_icon.png'},
            { title: 'Deals Management', key: 'deals_management', path: '/deals_management', feature: 'dealsManagement', icon: 'deal_icon.png'},
            { title: 'Vendor Management', key: 'vendors_management', path: '/vendors_management', feature: 'vendorsManagement', icon: 'vendor_icon.png'},
            { title: 'AML Management', key: 'aml_management', path: '/aml_management', feature: 'amlManagement', icon: 'aml_icon.png'},
        ];

        options.forEach(option => {
            const {title, key, path, feature, part, permission, icon} = option;
            if(!feature || hasPermission(user, feature, part, permission)){
                items.push(
                    <div key={key} className = "col s12 m6 l4" style={{padding: '15px'}}>
                        <Link to={path}><div className="card">
                            <div class="waves-effect waves-block waves-light" style={{padding: '25px', backgroundColor: '#6671ad'}}>
                                <img class="activator responsive-img" src={`/static/images/${icon}`} style={{height: '200px', width:'auto'}}/>
                            </div>
                            <div class="card-content">
                                <span class="card-title activator grey-text text-darken-4">{title}</span>
                                </div>
                        </div>
                        </Link>
                    </div>
                );
            }
        });

        
        return (
            <div style={{backgroundColor: '#F5F8F8', minHeight:'100vh'}}>
                <div className = 'container center' style = {{paddingTop: '7%', fontWeight: '300'}}>
                    <h4>Data Management</h4>
                    <div className='row'>
                        {items}
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { history }){
    return {
        auth:state.auth,
        history
    };
}

export default connect(mapStateToProps)(DataManagement);