import React, {Component} from 'react';
import Checkbox from './Checkbox';
import Input from './Input';

class Question extends Component{
  handleTextChange(e){
    const details = e.target.value;
    this.props.onTextChange(details);
  }
  render() {
    const { id, value, onCheckChange, disabled, question, details, className } = this.props;
    return(
        <div className={disabled ? "col s12 question disabled" : "col s12 question"}>
            <div className="col s10 offset-s1">
              <div className={"col s9 "+className}>
                <p>{ question }</p>
              </div>
              <Checkbox
                  key={id+'cb'}
                  className="col s2"
                  name={id}
                  value={value}
                  onChange={onCheckChange}
                  disabled={disabled}
              />
            </div>
            {
              value &&
              <Input
                key={id+"Value"}
                placeholder="Enter additional details"
                className="col s10 offset-s1"
                inputClass={""}
                name={id}
                errors={[]}
                value={details}
                handleChange={(e)=>this.handleTextChange(e)}
                disabled={disabled}
              />
            }
        </div>
    )
  }
}

export default Question;