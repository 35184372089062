import React, {Component} from 'react'
const style = {
    minHeight: '40px',
};

const template = (history, options) => {
    const {title, link, name } = options;
    const inputs = [
        { key: `label`, name: `label`, type: "message", text: title, className: "col m12 l3 form-input valign-wrapper", style},
        { key: `low`, name: `low`, type: "number", placeholder: "Low", className: "col m4 l2", value: "", required: true, style, inputStyle: {marginBottom: '5px'}},
        { key: `medium`, name: `medium`, type: "number", placeholder: "Medium", className: "col m4 l2", value: "", required: true, style, inputStyle: {marginBottom: '5px'}},
        { key: `high`, name: `high`, type: "number", placeholder: "High", className: "col m4 l2", value: "", required: true, style, inputStyle: {marginBottom: '5px'}},
    ];

    if(link){
        inputs.push({ name: `link`, type: "button", placeholder: "See Dictionary", className: "col m12 l3", inputClass: "ppf-primary-button right", onClick: ()=>{
            history.push(link);
        }});
    }
    return [{
        permission: [
            {feature: 'riskRating', part: 'riskRating'}
        ],
        title: undefined,
        inputs
    }];
}


export default template;