import React, {Component} from 'react';
import { connect } from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';
import Twitter from './TwitterComponent';
import Subscribe from './Subscribe';
import Publications from './PublicationsComponent';
import { authChange } from '../../actions';
import { successWrapper } from '../../utils/SuccessWrapper';

class Landing extends Component {
  constructor(params){
    super(params);
    this.state ={alert:{messsage:"",active:false}};
  }
  componentDidMount(){
    this.getAlert();
  }

  dismissAlert(){
      var div = this.refs.notification;
      div.style.opacity = "0";
      setTimeout(function () {
          div.style.display = "none";
      }, 600);
  }

  getAlert(){
    axios.get("/api/alert").then((res)=>{
      successWrapper(res, (data) => {
        this.setState({alert: data});
      });
    }, (err)=>{
      console.log(err);
    })
  }


  render(){
    return (
      <div>
        <section id="landing" className="row">
          {
              this.state.alert.active && <div className="alert info" id="notification" ref="notification">
              <span className="closebtn" onClick={()=>this.dismissAlert()}>&times;</span><Link to="/#publications" style={{color:'white'}}>
              {this.state.alert.message}</Link></div>
          }
          <div className="col l6 offset-l3 center content">
            <img width="80%" src="/static/images/logo-l-new.png" alt="PPF Capital Belize logo"/>
            <h4>Mobilizing Capital&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;Creating Value</h4>
          </div>
        </section>
        <section id="mission" className="row">
          <div className="content">
            <div className="col l12 center">
              <h1>Our Mission</h1>
              <p className="mission">To provide financial solutions that create value for our customers and stakeholders</p>
            </div>
          </div>
        </section>
        <section id="whatwedo">
          <div className="row">

            <div className="col l6 box" style={{background:'url("/static/images/productivity.png")', backgroundSize:'cover'}}>
            </div>
            <div className="col l6 box wedo">
              <h2>What We Do</h2>
              <span style={{fontSize:'15px'}}>________________________</span>
              <div className="row">
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/debt2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Private Debt
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/equity2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Private Equity
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/outreach2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Outreach
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/risk2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                  Risk Management
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/research2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Research & Consultancy
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/greenfinance2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Green Finance
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/strategy2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Strategy
                  </div>
                </div>
                <div className="col m6">
                  <div className="col l4 s3">
                    <img src="/static/images/advisory2.png" width="100%"/>
                  </div>
                  <div className="col l8 s9" style={{marginTop:'10px'}}>
                    Advisory
                  </div>
                </div>
              </div>
            </div>
            <div className="col l6 difference box">
              <h2>The PPF Difference</h2>
              ________________________
              <p>
                We seek to deliver value to our customers and stakeholders through our commitment to excellence which is reflected in our core principles:<br/>
                <span style={{fontWeight:'400'}}>
                · Innovation and creativity,<br/>
                · Quality,<br/>
                · Sustainability,<br/>
                · Continuous learning and development and,<br/>
                · Integrity.<br/>
                </span>
                We know that every client is different and we seek to offer bespoke solutions that deliver results and rewards while seeking to minimize risk.
              </p>
            </div>
            <div className="col l6 box" style={{background:'url("/static/images/belize.png")', backgroundSize:'cover'}}>
            </div>
          </div>
        </section>
        <section id="publications" className="row">
          <Publications/>
        </section>
      <section id="tweets" className="row" style={{marginBottom:'0px'}}>
          <Twitter/>
          <Subscribe/>
        </section>
      <section id="contactus" className="row">
          <div className="content center">
            <h3>Contact Us</h3><br/>
            <div style={{marginTop:10}}>
              <div className="col l4 s4 center" style={{overflow:"hidden"}}>
                <img className="contact-icon" src="/static/images/phone.png"/><br/>
                <span className="header">Phone</span>
                <a href="tel:+5016154630"><p>+(501)615-4630</p></a>
              </div>
              <div className="col l4 s4 center" style={{overflow:"hidden"}}>
                <img className="contact-icon" src="/static/images/email.png"/><br/>
                <span className="header">Email</span>
                <a href="mailto:info@ppfcapital.com"><p>info@ppfcapital.com</p></a>
              </div>
              <div className="col l4 s4 center" style={{overflow:"hidden"}}>
                <img className="contact-icon" src="/static/images/location.png"/><br/>
                <span className="header">Address</span>
                <a href="https://www.google.com/maps/?q=17.5104637,-88.2073355"><p>1296 Marbee Cone Ave, Belize City</p></a>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state, { history }){
  return {
    auth:state.auth,
    histoy: history
  };
}

export default connect(mapStateToProps)(Landing);