import React, {Component} from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import * as _ from 'lodash';
import SideNav from './SideNav';
import { Switch } from 'react-materialize';
import PPFView from '../../Generics/PPFView';
import generalFields from './Sections/General/Templates/generalFields';
import ContactsView from './Sections/General/Contacts';
import DocumentsView from './Sections/General/Documents';
import { hasPermission } from '../../../utils/PermissionUtil';
import { addFormLayer } from '../../../actions';
import ChargesView from './Sections/General/Charges';

const style = {
    content:{
        background: '#F4F5F8',
        margin: "0px",
        minHeight: "100vh",
    },
    container:{
        margin:"85px 0px 0px 0px"
    },
    wrapper:{
        background: "white",
        marginBottom: "15px"
    },
    padded:{
        padding: "40px",
    },
}


class VendorView extends Component{
    constructor(props){
        super(props);

        this.onBack = this.onBack.bind(this);
        this.onDeleteRecord = this.onDeleteRecord.bind(this);
        this.onNew = this.onNew.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.savePartial = this.savePartial.bind(this);
        this.onChangeSectionChange = this.onChangeSectionChange.bind(this);

        this.state = {
            selected: 'general'
        };
    }

    componentDidMount(){
        let key = null;
        let section = null;

        if(this.props.match && this.props.match.params && this.props.match.params.vendorId ){
            key = this.props.match.params.vendorId;
            section = this.props.match.params.section || 'general';
            this.getRecordData(key, true, (data)=>{
                data.key = key;
                this.setState({key, vendorData: data, selected: section});
            });
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(
            this.props.location
            && (prevProps.location.state != this.props.location.state)
        ){
            const { formLayer } = this.props;
            const { key } = this.props.location.state || {};
            let { selected, vendorData } = this.state;

            if(!key){
                selected = 'general';
            }
            if(key){
                this.getRecordData(key, true, (data)=>{
                    data.key = key;
                    this.setState({key, vendorData: data});
                });
            } else {
                this.setState({key, selected: 'general', vendorData: undefined});
            }
        }
    }

    buildAuth(){
        return { auth: this.props.auth.token, access_token: this.state.access_token};
    }

    async savePartial(partialData, template, silent = false, cb = ()=>{}){
        const { inputs, titles = [] } = template;
        const { vendorData: form = {}, key, changed = {} } = this.state;
        let updatedData = {};

        inputs.forEach(({name}) => {
            updatedData[name] = partialData[name];
        });

        this.save(updatedData, (state)=>{
            if(!silent){
                alert('Successfully Saved!');
            }
            cb(state);
        });
    }

    save(updatedData, cb = ()=>{}){
        const { vendorData: form = {}, key} = this.state;
        const update = key ? true : false;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;

        let endpoint = update ? '/api/updateVendor' : '/api/createVendor';
        let data = update ? { key, changed: updatedData} : form;

        axios.post(endpoint, { data }).then(
            (res) => {
                this.state.key = res.data.key;
                if(!update){
                    this.props.history.replace(`/vendor/${this.state.key}`)
                }
                this.state.searchModal = null;
                this.setState(this.state, ()=>{
                    this.onRefresh((state)=>{
                        cb(state)
                    });
                });
            })
            .catch(
                ({response}) => {
                    alert("Error");
                    console.log(response);
            });
    }

    onBack() {
        this.props.history.goBack();
    }

    /**
     *
     * @param {Object} e    includes changes, new state and type of change
     * @param {*} cb    Callback used to send back data to triggering view.
     */
    onChangeSectionChange(e, cb = () => {}){
        let {type, changed: newChanges, newState: data} = e;
        let { changed = {}, key } = this.state;

        changed = Object.assign(changed, newChanges);

        /* Handles record Class Changes */
        if(newChanges && newChanges.vendorType != null && !newChanges.vendorGroup){
            data.recordType = null;
            data = Object.assign(data, this.cleanUpClientInfo(data));
        }

        /* If this is new record then set the current state */
        if(!key){
            this.setState({vendorData: data}, ()=>{
                cb(data);
            });
        } else {
            cb(data);
        }
    }

    onDeleteRecord(){
        if(!window.confirm("Do you wish to completely remove this vendor and all of it's assocaited data?")){
            return;
        }

        const data = { key: this.state.key, form: this.state.vendorData};
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post("/api/deleteVendor",{
            data
        }
        ).then(
        (res) => {
            this.setState({error:''});
            this.setState({error:res.data.result}, ()=>{
                alert("Delete Successful!");
                this.props.history.goBack();
            });
        })
        .catch((e)=>console.log(e));
    }

    onRefresh(cb = ()=>{}){
        const { key } = this.state;

        this.getRecordData(key, true, (data)=>{
            data.key = key;
            this.setState({key, vendorData: data}, cb(data));
        });
    }

    onNew(type){
        const { form, key } = this.state;
        let formLayer = this.props.formLayer || [];

        formLayer.push(JSON.parse(JSON.stringify({parent: key, record:{}, type})));
        addFormLayer(formLayer);

        this.props.history.push('/client/new');
    }

    cleanUpClientInfo(updatedData){
        const { vendorData = {}, form, key, changed  } = this.state;
        const { vendorGroup } = vendorData;

        if(vendorGroup == 'receivable'){
            
        } else {
            updatedData.is_active = undefined
        }

        return updatedData;
    }

    getRecordData(key, details, callback){
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        let data = { key, details};

        axios.post('/api/getVendor',{
            data
        }).then(
        (res) => {
            if(res.data.error){
                return alert(res.data.error);
            }
            callback(res.data);
        })
        .catch((e)=>{
            console.log(e);
        });
    }

    renderControls(){
        const { edit_mode = false, selected } = this.state;
        const canEdit = hasPermission(this.props.auth.user, 'vendorsManagement', selected, 'write');

        return(
            <div className = 'col s12 valign-wrapper'>
                {canEdit && <div className='col l1 offset-l7 hide-on-small-only'>
                    <span className = 'col s12 ppf-control-title'>Edit:</span>
                </div>}
                {canEdit && <div className='col s8 l2'>
                    <Switch
                        id="edit_mode"
                        checked={edit_mode}
                        name="edit_mode"
                        offLabel="Off"
                        onChange={(e)=>{
                            let value = e.target.checked;
                            this.setState({edit_mode: value});
                        }}
                        onLabel="On"
                    />
                </div>}
                <button className="ppf-nav-button col l2 s4" onClick={this.onBack}>Back</button>
            </div>
        )
    }

    renderSection(){
        const { auth, firebase } = this.props;
        const { user } = auth;
        const { selected, vendorData = {}, edit_mode = false, key, searchModal, employees } = this.state;
        const { vendorGroup } = vendorData;
        const sectionElement = [];
        const methods = {
            onDeleteRecord: this.onDeleteRecord,
            onRefresh: this.onRefresh,
            onSave: this.savePartial,
            onChange: this.onChangeSectionChange,
            onPrint: this.onPrint,
            onNew: this.onNew
        }
        const superProps = this.props;
        const state = {viewOnly: !edit_mode, data: vendorData, searchModal, employees};
        const handlers = { state, methods, superProps };

        const sectionMap = [
            {id: 'general', component: <PPFView key = "general" {...handlers} template = { generalFields(methods, state) } />},
            {
                id: 'documents',
                component: <DocumentsView
                    key='rdocuments'
                    title='Documents'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                />,
                title: 'Client Documents'
            },
            {
                id: 'charges',
                component: <ChargesView
                    key='rcharges'
                    title='Charges'
                    state={state}
                    superProps={superProps}
                    methods={methods}
                />,
                title: 'Client Documents'
            },
            {id: 'contacts', component:  <ContactsView state={state} superProps={superProps} methods={methods} />},
        ];

        this.viewMapping = sectionMap;
        this.viewMapping.forEach(({id, component, condition = ()=>true}) => {
            if(id == selected){
                const hasAccess = hasPermission(this.props.auth.user, 'vendorsManagement', id);
                const conditionPassed = condition(vendorData);
                if(hasAccess && conditionPassed){
                    sectionElement.push(component);
                } else {
                    alert('Permission denied. Contact you admin.')
                }
            }
        });

        return(
            <div className = 'col s12 ppf-section'>
                {
                    sectionElement.length == 0 ?
                    <div>
                        <h5>No Data Found</h5>
                    </div>
                    : sectionElement
                }
            </div>
        )
    }

    render() {
        const { vendorData = {}, selected, key, employees } = this.state;
        const { name, vendorGroup } = vendorData;

        const generalInfo = {
            key,
            name,
            vendorGroup
        }
        
        if(!employees){
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.get("/api/employees")
                .then(
                    (res) => {
                        this.setState({employees: res.data.employees});
                    })
                .catch((e)=>console.log(e));

            return <div>Loading...</div>;
        }

        if(key && _.isEmpty(vendorData)){
            return (
                <div className="row white" style={{height: '400px', marginTop: '20%'}}>
                    <div className = 'valign-wrapper' style={style.container}>
                        <h3 className = 'center col s12'>Loading...</h3>
                    </div>
                </div>
            );
        }

        return(
            <div className="row" style={style.content}>
                <div class="col l2 m4 s3">
                    <SideNav
                        superProps = {this.props}
                        data = { generalInfo }
                        selected = { selected }
                        onChange = { (type) => { this.setState({selected: type, edit_mode: false })} }
                    />
                </div>
                <div className="col l10 m8 s9 center">
                    <div style={style.container}>
                        {this.renderControls()}
                        {this.renderSection()}
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
      auth:state.auth,
      formLayer:state.formLayer
    };
  }

  export default connect(mapStateToProps)(VendorView);