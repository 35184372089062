
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import HttpsRedirect from 'react-https-redirect';

import reducers from './reducers';

import App from './components/App';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));

ReactDOM.render(
  <div>
    <HttpsRedirect>
      <Provider store = {store}>
        <App />
      </Provider>
    </HttpsRedirect>
  </div>,
  document.getElementById('root')
);
