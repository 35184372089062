export const hasPermission = (user, feature, part, permission) => {
    let hasAccess = false;
    const types = ['admin', 'employee', 'client'];

    if(!user || !feature){
        return hasAccess;
    }

    types.forEach(type => {
        if(hasAccess){
            return;
        }
        
        if(!user.permissions){
            return false;
        }

        const userTypeAccess = user.permissions[type];

        if(userTypeAccess){
            const featureAccess = Object.keys(userTypeAccess[feature] || {}).length > 0;
            hasAccess = featureAccess;

            if(!hasAccess){
                return;
            }

            if(part){
                const partAccess = Object.keys(userTypeAccess[feature][part] || {}).length > 0;
                hasAccess = partAccess;

                if(!hasAccess){
                    return;
                }

                // Check for active permission
                hasAccess = false;
                for(const prop in userTypeAccess[feature][part]){
                    if(!hasAccess){
                        hasAccess = !!userTypeAccess[feature][part][prop];
                    }
                }

                if(permission){
                    const permissionAccess = userTypeAccess[feature][part][permission];
                    hasAccess = permissionAccess;

                    if(!hasAccess){
                        return;
                    }
                }
            } else {
                return hasAccess;
            }
        }
    })

    return hasAccess;
}