import React, { Component } from 'react';
import {connect} from 'react-redux'
import { HashLink as Link } from 'react-router-hash-link';
import axios from 'axios';
import _ from 'lodash';
import { hasPermission } from '../../../../utils/PermissionUtil';
import TabSelector from '../../../Generics/TabSelector';
import { getLocation } from '../../../Dictionaries/locations';
import { Button, Modal, Icon } from "react-materialize";
import Schema from './Schema';

const defaultType = 'datasets';
class StatisticalDatebase extends Component {

    constructor(props){
        super(props);
        this.onTabSelelectorChange = this.onTabSelelectorChange.bind(this);
        this.selectSchema = this.selectSchema.bind(this);
        const dt = hasPermission(this.props.auth.user, 'database', defaultType) ? defaultType :
            hasPermission(this.props.auth.user, 'database', 'schemas') ? 'schemas' : null;
        this.state = {
            object_type: dt,
            loading: false,
            modal: false,
            next: true,
            prev: false
        };
    }

    onTabSelelectorChange(value){
        this.setState({object_type: value},()=>{
            this.loadData(value);
        });
    }

    selectSchema(schema){
        const { name } = schema;
        console.log("selected schema");
        if(window.confirm(`Do you wish to create your dataset based of the ${name} schema?`)){
            this.createDatasetFromSchema(schema);
        }
    }

    createDatasetFromSchema(dataset){
        const { history } = this.props;
        dataset.schema_id = dataset.id;
        delete dataset.id;
        this.setState({loading: true}, ()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.post(`/api/dataset`,{
                data: dataset
            }).then(
                (res) => {
                    const { id }= res.data;
                    history.push(`/database/dataset/${id}/false`);
                }
            ).catch((e)=>{
                console.log(e);
                alert("Could not create dataset")
            })
            .finally(()=>{
                this.setState({loading: false});
            });
        });
    }

    handleView(type, id, modify){
        const { history } = this.props;
        let path;
        if(type == 'schemas'){
            path = 'schema';
        } else if (type == 'datasets'){
            path = 'dataset';
        } else {
            return;
        }
        history.push(`/database/${path}/${id}/${!modify}`);
    }

    handleDelete(type, id){
        let endpoint;
        if(type == 'schemas'){
            endpoint = 'schema';
        } else if (type == 'datasets'){
            endpoint = 'dataset';
        } else {
            return;
        }

        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.delete(`/api/${endpoint}?id=${id}`).then(
            (res) => {
                this.loadData(type);
            }
        ).catch((e)=>{
            console.log(e);
            alert("Could not delete "+endpoint)
        })
        .finally(()=>{
            this.setState({loading: false});
        });
    }

    onPrev(type){
        const { lastIndex, currentIndex } = this.state;
        this.setState({currentIndex: lastIndex}, ()=>{
            this.loadData(type);
        });
    }

    onNext(type){
        const {currentIndex: lastIndex = 'A' } = this.state;
        this.setState({prev: true, currentIndex: this.state[type][this.state[type].length-1].name, lastIndex}, ()=>{
            this.loadData(type);
        });
    }

    updateGridNav(type){
        const { lastIndex, currentIndex = 'A' } = this.state;
        if(lastIndex == currentIndex){
            this.setState({prev: false});
        }
        if(this.state[type].length != 10){
            this.setState({next: false});
        } else {
            this.setState({next: true});
        }
    }

    loadData(type){
        const { currentIndex = 'A' } = this.state;
        this.setState({loading: true},()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.get(`/api/${type}?lastIndex=${currentIndex}`).then(
                (res) => {
                    if(res.data && res.data.schemas){
                        let { schemas } = res.data;
                        schemas.sort((a, b)=>{
                            if(a.name > b.name){
                                return 0;
                            }
                            return -1;
                        });
                        this.setState({schemas}, ()=>{
                            this.updateGridNav(type);
                        });
                    } else if (res.data && res.data.datasets) {
                        let { datasets } = res.data;
                        datasets.sort((a, b)=>{
                            if(a.name > b.name){
                                return 0;
                            }
                            return -1;
                        });
                        this.setState({datasets}, ()=>{
                            this.updateGridNav(type);
                        });
                    } else {
                        alert("Could not find Record");
                    }
                }
            ).catch((e)=>this.setState({error: true}))
            .finally(()=>{
                this.setState({loading: false});
            });
        });
    }

    renderRows(type, selectable){
        const { schemas = [], datasets = []} = this.state;
        const records =  type == 'schemas' ? schemas : datasets;
        const rowElements = [];

        const hasDelete = hasPermission(this.props.auth.user, 'database', type, 'delete') ? 1 : 0;
        const hasWrite = hasPermission(this.props.auth.user, 'database', type, 'write') ? 1 : 0;
        const hasRead = hasPermission(this.props.auth.user, 'database', type, 'read') ? 1: 0;

        _.forEach(records, (record)=>{
            const isDataset = type === 'datasets';
            const tdWidth = isDataset ? '25' : '37';
            const location = getLocation(record);
            const actionCol = 12 / (hasDelete + hasWrite + hasRead);
            rowElements.push(
                <tr onClick={()=>{ return selectable ? this.selectSchema(record) : null}}>
                    <td width={`${tdWidth}%`}>{record.name}</td>
                    {isDataset &&<td width={`${tdWidth}%`}>{location}</td>}
                    <td width={`${tdWidth}%`}>{record.source}</td>
                    {!selectable && <td width={`${tdWidth}%`}>
                        { !!hasRead && <div className={`col s${actionCol}`}>
                            <button className='ppf-primary-button col s12' onClick={()=>this.handleView(type, record.id)}>View</button>
                        </div>}
                        { !!hasWrite && <div className={`col s${actionCol}`}>
                            <button className='ppf-primary-button col s12' onClick={()=>this.handleView(type, record.id, true)}>Modify</button>
                        </div>}
                        { !!hasDelete && <div className={`col s${actionCol}`}>
                            <button className='ppf-danger-button col s12' onClick={()=>this.handleDelete(type, record.id)}>Delete</button>
                        </div>}
                    </td>}
                </tr>
            );
        });
        return rowElements;
    }

    renderTable(type, selectable){
        const {next, prev} = this.state;
        const prefix = type.substring(0, 1).toUpperCase()+type.substring(1, type.length-1);
        return (
            <div className="col s12" style={{padding:"15px"}}>
                    <div className = 'col s12 right'>
                        {next && <button className='ppf-primary-button col s2 right' onClick={()=>this.onNext(type)}>Next</button>}
                        {prev && <button className='ppf-primary-button col s2 right padded-right ' onClick={()=>this.onPrev(type)}>Prev</button>}
                    </div>
                <table className="col s12">
                        <thead>
                            {type != 'datasets' && <tr>
                                <th width="37%" className="center "> {`${prefix} Name`}</th>
                                <th width="37%" className="center mobile-hide">{`${prefix} Source`}</th>
                                {!selectable && <th width="25%" className="center mobile-hide">Actions</th>}
                            </tr>}
                            {type == 'datasets' && <tr>
                                <th width="25%" className="center "> {`${prefix} Name`}</th>
                                <th width="25%" className="center mobile-hide">{`Location`}</th>
                                <th width="25%" className="center mobile-hide">{`${prefix} Source`}</th>
                                {!selectable && <th width="25%" className="center mobile-hide">Actions</th>}
                            </tr>}
                        </thead>
                        <tbody>
                            {this.renderRows(type, selectable)}
                        </tbody>
                    </table>
            </div>
        )
    }

    renderModal(){
        const { modal, schemas, loading } = this.state;
        if(!modal){
            return (<div/>);
        }
        if(!schemas && !loading){
            this.loadData('schemas');
            return (<h4>Loading...</h4>);
        }
        return(
            <div id="myModal" className="modal row">
                <div className="modal-content form col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={()=>this.setState({modal:false})}>&times;</span>
                    <h4>Choose the dataset's base schema</h4>
                    {this.renderTable('schemas', true)}
                </div>
            </div>
        );
    }

    renderByContext(){
        const { object_type, schemas, datasets, loading, error }= this.state;
        if(loading){
            return (
                <h3 className="col s12 center">Loading...</h3>
            )
        }
        if(!schemas && !datasets && !error){
            this.loadData(object_type);
            return;
        }
        const table = this.renderTable(object_type);
        const actionButton = object_type == 'schemas' ?
            <Link to="/database/schema/new"><button className="ppf-primary-button right col l3 offset-l9">Create Schema</button></Link>
            : <button className="ppf-primary-button right col l3 offset-l9" onClick={()=>this.setState({modal: true})}>Create Dataset</button>;
        return[
            table,
            actionButton
        ]
    }

    render(){
        const options = [];
        if(hasPermission(this.props.auth.user, 'database', 'datasets')){
            options.push('datasets');
        }
        if(hasPermission(this.props.auth.user, 'database', 'schemas')){
            options.push('schemas');
        }
        const { object_type }= this.state;
        return(
            <div className = 'row' style={{marginTop: '7%'}}>
                <div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
                    <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
                </div>
                <div className="container row">
                    <div className="col l12">
                        <h2 className="left">Statistical Database</h2>
                        <Link to="/database/search">
                            <button className="ppf-primary-button right">Search Data</button>
                        </Link>
                    </div>

                    <div className="col l12">
                        <TabSelector
                            options={options}
                            value={object_type}
                            className = "col l4"
                            placeholder=""
                            onChange={this.onTabSelelectorChange}
                        />
                        {this.renderByContext()}
                    </div>
                    {this.renderModal()}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(StatisticalDatebase);