import React, { Component, Fragment } from 'react';
import CustomInput from './Input';
import _ from 'lodash';
import FileUpload from './FileUpload';
import Select from './Select';
import Question from './Question';
import Checkbox from './Checkbox';
import Radiobutton from './Radiobutton';
import Address from './Address';
// TODO: Move this dropdown mapping out of this location
import { typeMap } from '../Dictionaries/clientFormOptions';
import { hasPermission } from '../../utils/PermissionUtil';
import WithDictionary from '../App/DealsManagement/withDictionary';
import { uploadSingleFile } from '../../utils/FileHandler';
import axios from 'axios';

class PPFView extends Component {
    constructor(props) {
        super(props);

        this.handleQuestionChange = this.handleQuestionChange.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.handleUpload = this.handleUpload.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

        this.onEdit = this.onEdit.bind(this);
        this.onSave = this.onSave.bind(this);

        const { template = {}, state = {} } = this.props;
        const { data = {} } = state;

        this.selectWithDictionary = WithDictionary(Select, this.props.superProps.auth.token);

        this.state = {
            data,
            errors: {},
            template
        };
    }

	componentDidUpdate() {
		if (Object.keys(this.state.data).length == 0 && Object.keys(this.props.state.data).length > 0) {
            this.setState({ data: this.props.state.data });
        }
	}

    onChange(type) {
        const { changed, data, template } = this.state;

        if (type == 'question') {
        }
        if (this.props.methods.onChange) {
            this.props.methods.onChange({ type, changed, newState: data, template }, (state) => {
                this.setState({...this.state, ...state});
            });
        }
    }

    onSave() {
        const { template, changed } = this.state;
        let inputs = [];
        let titles = [];
        template.forEach(section => {
            titles.push(section.title);
            inputs = inputs.concat(section.inputs);
        });

        const hasQuestion = inputs.find(x => x.type == 'question');
        if (hasQuestion) {
            inputs.push({ name: 'questions' });
        }

        if (this.validate()) {
            this.props.methods.onSave(this.state.data, { titles, inputs }, false, changed);
        } else {
            alert("Missing Fields Found. Please fill out all fields in red.");
            window.scrollTo(0, 0);
        }
    }

    onEdit() {
        this.state.edited = true;
        this.state.changed = {};
        this.setState(this.state);
    }

    hasPermission(type) {
        const { template = [{}], state } = this.props;
        const { permission = [] } = template[0];

        let has1Permission = false || permission.length == 0;
        permission.forEach((per) => {
            const { feature, part } = per;
            if (hasPermission(this.props.superProps.auth.user, feature, part, type)) {
                has1Permission = true;
            }
        });

        return has1Permission;
    }

    handleUpload(event, i, autoUpload) {
        const uploadedFiles = event.target.files;
        let value = null;

        if (uploadedFiles.length > 0) {
            value = { files: uploadedFiles, name: uploadedFiles[0].name };
            this.state.data[i] = value;
            delete this.state.errors[i];
        } else {
            this.state.data[i] = value;
        }

        if(autoUpload){
            this.saveUpload(i, uploadedFiles[0].name, uploadedFiles[0], i);
        }

        this.setState(this.state, () => {
            this.onChange('upload')
        });
    }

    saveUpload(typeKey, name, file, type){
        const { firebase } = this.props.superProps;
        const { documents, key, fileMap = [] } = this.state.data;

        uploadSingleFile(firebase, key+"/images/", file, type, ({error, path, })=>{
            if(error){
                alert('Error: Could not upload file. Please try again.');
                return;
            }

            const exists = fileMap.find(x =>
                x.name == name
                && x.type == type
            );

            if(!exists){
                fileMap.push({name, type, id: typeKey, created_data: Date.now()});
            }

            const id = path;
            const updatedDocs = documents.map(doc=>{
                if(doc.name == name){
                    return { id, name };
                }
                return doc;
            });
            this.state.data.fileMap = fileMap;
            this.state.data.documents = updatedDocs;
            this.setState(this.state, ()=>{
                this.props.methods.onSave(
                    this.state.data, 
                    {inputs:[{name: 'fileMap'},{name: 'documents'},{name: 'profile_image'}]},
                    false,
                    () => {
                        window.location.reload();
                    }
                );
            });
        });
    }

    handleViewFile(file, download = false){
        if(!file){
            return alert('Unable to find file.');
        }
        const document = this.state.data.documents.find(x => x.name === file.name);
        if(!document){
            return alert('Unable to find file.');
        }
        const data = { id: document.id };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/getFileURL", {data})
            .then(
                (res) => {
                    window.open(res.data.downloadURL);
                })
            .catch((e)=>console.log(e));
    }

    handleAddressChange(id, address) {
        // TODO: set changes attr to state

        this.state.data[id] = address;
        let errors = this.state.errors;
        _.forOwn(address, (value, i) => {
            if (value != "" && value !== undefined) {
                delete errors[id + i.capitalize()];
            }
        });

        this.setState(this.state, () => {
            this.onChange('address')
        });
    }

    handleCheckboxChange(e, id) {
        let value = e.target.checked;
        this.state.changed = this.state.changed || {};
        this.state.changed[id] = { prev: this.state.data[id], value };

        this.state.data[id] = value;

        this.setState(this.state, () => {
            this.onChange('checkbox');
            if (_.size(this.state.errors) > 0) {
                this.setState({ errors: {} });
            }
        });
    }

    handleQuestionChange(id, value, details) {
        this.state.data.questions = this.state.data.questions || {}
        const prev = JSON.parse(JSON.stringify(this.state.data.questions));

        this.state.data.questions[id] = { value, details };

        this.state.changed = this.state.changed || {};
        this.state.changed.questions = { prev, value: this.state.data.questions };

        this.setState(this.state, () => {
            this.onChange('question')
        });
    }

    handleSelectChange(e) {
        const name = e.target.name;
        let value = e.target.value;
        delete this.state.errors[name];

        this.state.changed = this.state.changed || {};
        this.state.changed[name] = { prev: this.state.data[name], value };

        this.state.data[name] = value;
        this.setState(this.state, () => {
            this.onChange('select')
        });
    }

    handleTextChange(e) {
        const target = e.target;
        const name = target.name;
        let value = target.value;

        if (name === 'phonenumber') {
            value = target.value.replace(/\D/, '')
        }

        this.state.changed = this.state.changed || {};
        this.state.changed[name] = { prev: this.state.data[name], value };

        this.state.data[name] = value;

        this.setState(this.state, () => {
            this.onChange('text');
            if (_.size(this.state.errors) > 0) {
                this.setState({ errors: {} });
            }
        });
    }

    getDropdownOptions(type) {
        const { data = {} } = this.state;
        const { recordClass = 'entity' } = data;

        if (type === "nationality") {
            type = "country";
        }

        if (type === "recordType") {
            return typeMap[recordClass];
        }

        if (typeMap[type] === null) {
            return [];
        }
        return typeMap[type];
    }

    showPopup(content) {
        this.setState({ popup: true, popupRender: content });
    }

    validate() {
        let errors = {};
        let allInputs = [];
        const { template, data } = this.state;
        _.forEach(template, ({ inputs }) => {
            _.forEach(inputs, ({ name, required, type }) => {
                if (required && !data[name]) {
                    errors[name] = `Required Field`;
                }
                if (type === "address") {
                    const addressFields = ['Number', 'Street', 'City', 'State', 'Country', 'Zip', 'PostalCode'];
                    _.forEach(addressFields, (addressField) => {
                        const addressValue = data[name] || {};
                        if (!addressValue[addressField.toLowerCase()]) {
                            errors[name + addressField] = `Required Field`;
                        }
                    })
                }
                allInputs.push({ name, required, type });
            });
        });
        this.state.errors = errors;
        this.state.allInputs = allInputs;
        this.setState(this.state);
        return _.keys(this.state.errors).length === 0;
    }

    renderPopup() {
        const { popup = false, popupRender = () => { } } = this.state;

        if (popup) {
            return (
                <div id="myModal" className="modal row">
                    <div className="modal-content form col l4 s10 offset-s1 offset-l4">
                        <span
                            className="close"
                            onClick={() => this.setState({ popup: false, popupRender: () => { } })}
                        >&times;</span>
                        {popupRender()}
                    </div>
                </div>
            );
        }
    }

    renderButtons(buttons = []) {
        const { state } = this.props;
        const { viewOnly } = state;
        const buttonElements = [];

        if (viewOnly) {
            return;
        }

        buttons.forEach(btn => {
            let { name, text, action, className = "", edits = false, deletes = false } = btn;

            if (name == 'save') {
                edits = true;
                text = 'Save'
                action = this.onSave;
            }

            if(action){
                action.bind(this);
            }

            if (edits && !this.hasPermission('write')) {
                return;
            }

            if (deletes && !this.hasPermission('delete')) {
                return;
            }

            buttonElements.push(
                <button
                    key={name}
                    name={name}
                    className={`${className || 'ppf-primary-button right padded-right'}`}
                    onClick={action}
                >
                    {text}
                </button>
            )
        });

        return (
            <div className='col l12'>
                { buttonElements}
            </div>
        );
    }

    renderInputs(inputs) {
        const viewState = this.state.data || {};
        let { viewOnly, key = '0' } = this.props.state;
        const { review, errors } = this.state;

        if (!viewOnly) {
            viewOnly = !this.hasPermission('write');
        }

        let inputArray = [];
        let index = 0;
        _.forEach(inputs, (input) => {
            index++;
            let { className, name, placeholder, inputClass, type, text,
                popupIcon, popupComponent, condition, dictionary, dictionaryId,
                multipleSupport, innerStyle, disabled: inputDisabled, value: inputValue,
                isMoney, onClick = () => { }, style, inputStyle, sensitive,
                autoUpload
            } = input;
            if (condition) {
                if (!condition(viewState)) {
                    return;
                }
            }
            const inputName = input.name;
            if (type == 'button') {
                inputArray.push(
                    <div className={className}>
                        <button className={inputClass} onClick={() => { onClick() }}>{placeholder}</button>
                    </div>
                );
            } else if (type == 'message') {
                inputArray.push(
                    <div className={className} style={style}>
                        <p>
                            {text}
                            {
                                popupIcon ?
                                    <span onClick={() => this.showPopup(popupComponent)}>{popupIcon}</span> :
                                    ""
                            }
                        </p>
                    </div>
                );
            } else if (type == 'hr') {
                inputArray.push(
                    <div className='col s12'>
                        <hr style={{ border: '0', borderTop: '#f1f1f1 1px solid' }} />
                    </div>);
            } else if (['text', 'password', 'number', 'date', 'email'].includes(type)) {
                inputArray.push(
                    <CustomInput
						key={name + index}
                        sensitive={sensitive}
                        className={className}
                        type={type}
                        name={inputName}
                        placeholder={placeholder}
                        inputClass={inputClass}
                        inputStyle={inputStyle}
                        innerStyle={innerStyle}
                        style={style}
                        errors={errors}
                        disabled={inputDisabled || viewOnly}
                        value={viewState[inputName] || inputValue}
                        handleChange={this.handleTextChange}
                        isMoney={isMoney}
                    />,
                );
            } else if (type === 'checkbox') {
                inputArray.push(
                    <Checkbox
                        key={name + index}
                        className={className}
                        name={inputName}
                        placeholder={placeholder}
                        errors={this.state.errors}
                        disabled={viewOnly}
                        value={viewState[inputName]}
                        onChange={(e) => this.handleCheckboxChange(e, inputName)}
                    />,
                );
            } else if (type === 'radio') {
                inputArray.push(
                    <Radiobutton
                        key={name + index}
                        className={className}
                        name={inputName}
                        placeholder={placeholder}
                        errors={this.state.errors}
                        value={viewState[inputName]}
                        disabled={viewOnly}
                        // params={{ type, index, tooltip: input.tooltip }}
                        options={input.options}
                        handleChange={this.handleRadioChange}
                    />,
                );
            } else if (type === 'select') {
                let options = [];

                if (dictionary) {
                    options = dictionary(viewState);
                } else if (input.yesNo) {
                    options = [
                        { name: "Yes", value: "yes" },
                        { name: "No", value: "no" }
                    ];
                } else {
                    options = this.getDropdownOptions(name)
                }

                if (dictionaryId) {
                    const Dic = this.selectWithDictionary;
                    inputArray.push(
                        <Dic
                            dictionaryId={dictionaryId}
                            key={name + index}
                            className={className}
                            inputClass={inputClass}
                            innerStyle={innerStyle}
                            name={inputName}
                            placeholder={placeholder}
                            errors={this.state.errors}
                            value={viewState[inputName] || ''}
                            disabled={viewOnly}
                            onChange={this.handleSelectChange}
                        />
                    );
                } else {
                    inputArray.push(
                        <Select
                            key={name}
                            className={className}
                            inputClass={inputClass}
                            innerStyle={innerStyle}
                            name={inputName}
                            placeholder={placeholder}
                            errors={this.state.errors}
                            value={viewState[inputName] || ''}
                            disabled={viewOnly}
                            options={options}
                            onChange={this.handleSelectChange}
                        />
                    );
                }
            } else if (input.type === 'uploader') {
                inputArray.push(
                    <FileUpload
                        hideFileName={false}
                        text={placeholder || 'Add Files'}
                        key={name + index}
                        value={viewState[inputName]}
                        name={inputName}
                        className={className}
                        inputClass={inputClass}
                        placeholder={placeholder}
                        errors={this.state.errors}
                        disabled={viewOnly}
                        onFileClick={()=>this.handleViewFile(viewState[inputName])}
                        onChange={(e) => this.handleUpload(e, name, autoUpload)}
                        multipleSupport={multipleSupport}
                    />,
                );
            }
            else if (input.type === 'address') {
                inputArray.push(
                    <Address
                        key={inputName}
                        name={inputName}
                        superProps={this.props.superProps}
                        className={className}
                        address={
                            viewState[inputName] ||
                            {
                                number: "",
                                street: "",
                                city: "",
                                state: "",
                                country: "",
                                zip: "",
                                postal_code: ""
                            }
                        }
                        errors={this.state.errors}
                        onAddressChange={address =>
                            this.handleAddressChange(
                                inputName,
                                address)}
                        disabled={viewOnly}
                    />
                );
            }
            else if (type == 'question') {
                const questionsState = viewState['questions'] || [];
                const questionValue = questionsState[name] || {};
                const { value = false, details } = questionValue;
                inputArray.push(
                    <Question
                        key={name}
                        id={name}
                        value={value}
                        className={className}
                        details={details}
                        question={text}
                        onCheckChange={() => this.handleQuestionChange(name, !value, "")}
                        onTextChange={(details) => this.handleQuestionChange(name, value, details)}
                        disabled={viewOnly}
                    />
                );
            }
        });
        return inputArray;
    }

    renderSections() {
        const { template } = this.state;
        let sectionElements = [];
        _.forEach(template, (t) => {
            const { title, inputs, buttons, pageButtons, renderFunction, props, questions } = t;
            sectionElements.push(
                <Fragment key={title} >
                    {this.renderButtons(pageButtons)}
                    <div className="row">
                        <h5 style={{ textAlign: "center" }}>{title}</h5>
                        {
                            inputs && <div className="inputs">
                                {this.renderButtons(buttons)}
                                {this.renderInputs(inputs)}
                            </div>
                        }
                        {renderFunction ? this[renderFunction](props) : null}
                    </div>
				</Fragment>
            )
        });
        return sectionElements;
    }

    render() {
        return (
            <div className="col l12 form">
                {this.renderPopup()}
                {this.renderSections()}
                {typeof this.state.errors.general !== 'undefined' && <p className="error">{this.state.errors.general}</p>}
            </div>
        )
    }
}

export default PPFView;