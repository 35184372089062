
import React, {Component} from 'react';

class FileUpload extends Component{
    render(){
    const {
        hideFileName = false,
        shouldAutoFocus,
        className,
        placeholder,
        name,
        onChange,
        inputClass,
        onClick,
        text,
        onFileClick,
        handleFocus,
        errors,
        value,
        disabled,
        description,
        multipleSupport = true
    } = this.props;
        let input = 
            <input
                id={name}
                name={name}
                type="file"
                onChange={onChange}
                onClick={onClick}
                onFocus={handleFocus}
                disabled={disabled}
                autoFocus={shouldAutoFocus}
                multiple
            />;

        if(!multipleSupport){
            input = 
            <input
                id={name}
                name={name}
                type="file"
                onChange={onChange}
                onClick={onClick}
                onFocus={handleFocus}
                disabled={disabled}
                autoFocus={shouldAutoFocus}
            />;
        }
        
        return(
            <div className={className+" form-input "}>
                {placeholder &&<label className="textBox" htmlFor={name}>
                    {placeholder}
                </label>}
                <div className="file-field input-field upload-button">
                    <div className={inputClass || "ppf-primary-button" +" "+(disabled? "disabled":"")} style={{height:'2.5rem', lineHeight:'2.5rem'}}>
                        <span>{text ? text : 'File'}</span>
                        {input}
                    </div>
                    <div className="file-path-wrapper">
                        <input className="file-path validate" type="text"/>
                    </div>
                    {typeof description !== 'undefined' && (
                        <p className="description">
                        {description}
                        </p>
                    )}
                    {!hideFileName && value && value.name && <p
                        id={name+"Display"}
                        style={{color:'grey', textAlign:"center", cursor:"pointer"}}
                        type="text"
                        onClick={onFileClick}
                    >{value.name}</p>}
                    {errors && typeof errors[name] !== 'undefined' && (
                        <p className="error">
                        {errors[name]}
                        </p>
                    )}
                </div>
            </div>
        );
    }
}

export default FileUpload;