import React, {Component} from 'react';
import File from './File';
import _ from 'lodash';
import axios from 'axios';
import config from '../../../config';
import {connect} from 'react-redux'
import FileDownload from 'js-file-download';
import {fetchFiles, authChange} from '../../../actions';
import FileDetails from './FileDetails';
import CreateFolder from './CreateFolder';
import UploadFiles from './UploadFiles';
import UserAgreement from './UserAgreement';
import { successWrapper } from '../../../utils/SuccessWrapper';
import Toggle from '../../Generics/Toggle';
import { hasPermission } from '../../../utils/PermissionUtil';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {editMode: false, files: null, modal: false, searchText: "", file: null, user:this.props.auth.user, download:false, error:undefined};
        this.state.fileId = '';
        this.pathHistory = [];
        this.level = -1;
    }

    componentDidMount() {
        this.level++;
        this.pathHistory.push({ level: this.level, fileId: null, fileName: 'Home' });
        this.listFiles(true);
    }

    goToLevel(level){
        if(level >= 0){
            while(this.level > level){
                this.level--;
                this.pathHistory = _.dropRight(this.pathHistory);
                this.fileId = _.takeRight(this.pathHistory)[0].fileId;
            }
            this.listFiles(true);
        }
    }

    renderBack() {
        if (this.level >= 0) {
            this.level--;
            this.pathHistory = _.dropRight(this.pathHistory);
            this.fileId = _.takeRight(this.pathHistory)[0].fileId;
            this.listFiles(true);
        }
    }

    setModal(mode) {
        this.setState({modal: mode, file: this.state.file});
        if (!mode) {
            this.listFiles(false);
        }
    }

    onUploadFiles() {

    }

    listFiles(refresh) {
        const file = this.pathHistory[this.pathHistory.length - 1].file;
        var globe = this;
        const data = {
            fileId: this.fileId,
            fileHistory: this.pathHistory,
            filePath: file?.path,
            level: this.level
        }

        fetchFiles(data, this.props.auth.token, this.props.dispatch, function () {
            globe.setState((state) => ({...state, file, files: globe.props.files || [], editMode: !refresh}));
        });
    }

    renderFiles() {
        let { files, editMode = false } = this.state;
        return _.chain(files)
            .sortBy('modifiedTime')
            .reverse()
            .map((file) => {
                const isPPF = file.storageType == undefined
                    || file.storageType == 'drive'
                    || (
                        file.storageType == 'firebase'
                        && file.metadata?.createdBy != undefined
                        && file.metadata?.createdBy?.indexOf('ppfcapital.com') != -1
                    );
                file.createdBy = isPPF ? 'PPF' : file.metadata?.createdBy || 'NA';
                if (
                    file.name
                    && file.name.includes(this.state.searchText)
                    // Hide thumbnails
                    && file.name.indexOf('200x200') == -1
                ) {
                    return (
                        <File
                            key={file.id} file={file}
                            fileClick={() => this.fileDetailsClick('details', file)}
                            onDelete = {(file) => {this.fileDelete(file)}}
                            folderDownload={()=>this.folderDownload(true, file)}
                            details={true}
                            downloading={file.downloading}
                            editMode={editMode}
                        />
                    );
                };
            })
            .compact()
            .value();
    }

    renderTable() {
        const { level, pathHistory } = this;
        let returnElement = [];
        let breadcrumbs=[];
        if(level > 0){
            for(let i = 0; i < this.level; i ++){
                const { fileName } = pathHistory[i];
                const breadcrum = ` ${fileName} `;
                breadcrumbs.push(
                    <span style={{color: 'grey', cursor: 'pointer'}}>
                        <u onClick={()=>this.goToLevel(i)}>{breadcrum}</u> >
                    </span>
                );
            }
            const currentFileName = pathHistory[level].fileName;
            returnElement.push(
                <div className="row col s12">
                <h3>{currentFileName}</h3>
                    {breadcrumbs} {currentFileName}
                </div>
            );
           
        }
        const editMode = this.state.editMode;
        const { user } = this.props.auth;
        const rootAccess = this.level == 0 && hasPermission(user, 'dataroom', 'dataRoomRoot');
        returnElement.push(
            [<div className="col s12 right-align" >
               {
                    this.writable || rootAccess
                    ? <Toggle
                        value = {editMode}
                        label='Edit Mode'
                        onChange={
                            (value) => this.setState((state) =>({...state, editMode: value}))
                        }
                    />
                    : null
                }
            </div>,
            <div className="col s6 l6" style={{padding: '0px', paddingBottom:'5px'}}>
                {
                    level > 0 ?
                    <button className="ppf-nav-button padded-right" onClick={() => this.renderBack()}>Back
                    </button> : null
                }
                {
                    editMode
                    ? <button className="ppf-nav-button padded-right" onClick={() => this.setModal('createFolder')}>Create Folder
                    </button>
                    : null
                }
                {
                    editMode
                    ? <button className="ppf-nav-button padded-right" onClick={() => this.setModal('uploadFiles')}>Upload Files
                    </button>
                    : null
                }
            </div>]
        );
        returnElement.push(
            <table>
                <thead>
                <tr>
                    <th className="center">File Name</th>
                    <th className="center mobile-hide">Date Added</th>
                    <th className="center mobile-hide">Date Modified</th>
                    { editMode ? <th className="center mobile-hide">Actions</th> : null}
                </tr>
                </thead>
                <tbody>
                {this.renderFiles()}
                </tbody>
            </table>
        )
        return returnElement;
    }

    filter(searchText) {
        this.setState({searchText: searchText});
    }

    fileDetailsClick(mode, file) {
        if (mode === false) {
            this.setState({modal: mode, file: file});
        } else {
            if (typeof file.fileExtension === 'undefined') {
                this.fileId = file.id;
                this.level++;
                this.pathHistory.push({level: this.level, fileId: this.fileId, fileName: file.name, file});
                this.listFiles(true);
                return;
            }
            this.setState({modal: mode, file: file});
        }
    }

    fileDelete(file) {
        if(!window.confirm("You are about to delete this item. This will permanently remove this file and sub files (if applicable). Continue?")){
            return;
        }
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post('/api/file/delete', {
            responseType: 'application/json',
            data: {
                file
            }
        }).then(
            (res) => {
                this.listFiles();
            })
            .catch(
              (e) => {
                alert("Could not delete file. Please try again.")
              });
    }

    folderDownload(mode, file) {
        this.state.files.map((f)=>{
            if(f.id == file.id){
                f.downloading = true;
            }
            return f;
        })
        this.setState({download:true, files: this.state.files});
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.get('/api/downloadFolder', {
            responseType: 'arraybuffer',
            params: {
                file,
                filePath: this.pathHistory
            }
        }, {timeout: 120000}).then(
            (res) => {
                FileDownload(res.data, file.name+".zip");
                this.state.files.map((f)=>{
                        f.downloading = false;
                        return f;
                    });
                this.setState({download:false});
            })
            .catch(
              (e) => {
                alert("Got Error");
                this.state.files.map((f)=>{
                    f.downloading = false;
                    return f;
                });
                this.setState({download:false});
              });
    }

    renderModal() {
        if (!this.state.modal) {
            return (
                <div></div>
            )
        }
        if(this.state.modal == 'details'){
            return <FileDetails
                file ={this.state.file}
                onBack = {()=>this.fileDetailsClick(false, null)}
                path = {this.path}
            />
        }
        if(this.state.modal == 'createFolder'){
            return <CreateFolder
                path = {this.path}
                onBack = {()=>this.setModal(false)}
            />
        }
        if(this.state.modal == 'uploadFiles'){
            return <UploadFiles
                path = {this.path}
                onBack = {()=>this.setModal(false)}
            />
        }
    }

    renderMain() {
        const { user } = this.props.auth;
        const curr = this.pathHistory[this.pathHistory.length - 1];
        const file = curr?.file;
        this.deducedPath = this.pathHistory
            ? this.pathHistory.reduce((acc, curr, i) => {
                let pre = "";
                if(i > 0){
                    pre = "/";
                }
                return `${acc}${pre}${curr.fileName}`;
            }, "")
            : "";
        this.path = file?.path || this.deducedPath;
        this.writable = user?.writableFiles?.filter(filePath => {
            let hasWrite = false;
            this.pathHistory.forEach(fileHist => {
                if(fileHist.level > curr.level){
                    return;
                }
                const hisFile = fileHist?.file;
                if (hisFile?.path == filePath || hisFile?.id == filePath) {
                    hasWrite = true;
                }
            });
            return hasWrite;
        }).length > 0;
        return (
            <div className="app">
                {this.renderModal()}
                <div className="container row" style={{marginTop: '7%'}}>
                    <h2>Welcome, {user.clientname}</h2>
                    <div className="col s12 l4 offset-l8 offset-s1">
                        <input className="col l10 s10" id="seachInput" type="text" placeholder="Enter File Name"
                               onInput={() => this.filter(document.getElementById("seachInput").value)}/>
                        <div className = 'col l2 s2'>
                            <img className="responsive-img"style={{ marginTop: '10px'}} src="/static/images/search-icon.png"/>
                        </div>
                    </div>
                    {this.renderTable()}
                </div>
            </div>)
    }

    renderComponent(){
        if(this.props.auth.user.agreement){
            return(this.renderMain())
        } else {
            return <UserAgreement {...this.props} user={this.state.user}/>
        }
    }

    render() {
        return (
            <div>
                {this.renderComponent()}
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(Home);