import React, {Component} from 'react';
import {Icon} from 'react-materialize';
import _ from 'lodash';
import MdCloudDownload from 'react-ionicons/lib/MdCloudDownload';
import Toggle from '../../Generics/Toggle';

class File extends Component{
  componentWillMount(){

  }
  renderImage(){
    var docType = this.props.file.fileExtension;

    var documentTypes = [
      {extension:"doc", image:"document"},
      {extension:"docx", image:"document"},
      {extension:"pages", image:"document"},
      {extension:"xls", image:"excel"},
      {extension:"xlm", image:"excel"},
      {extension:"xlsx", image:"excel"},
      {extension:"png", image:"image"},
      {extension:"jpg", image:"image"},
      {extension:"jpeg", image:"image"},
      {extension:"bmp", image:"image"},
      {extension:"gif", image:"image"},
      {extension:"zip", image:"zip"},
      {extension:"pdf", image:"pdf"}
    ];
    if(typeof docType === 'undefined'){
      return(
        <img key="image" src={"/static/images/folder.png"} alt="folder" style={{paddingTop:'3px'}}/>
      );
    }
    var extFound = _.chain(documentTypes)
      .map((type) => {
        if(type.extension.toLowerCase() === docType){
          return(
            <img key="image" alt="type" src={"/static/images/"+type.image+".png"} />
          );
        }
      })
      .compact()
      .value();
    if(extFound.length > 0){
      return extFound;
    }
    return(
      <img key="image" alt="type" src={"/static/images/unknown.png"} />
    );
  }

  renderDetails(){
    if(this.props.details){
      const modified = new Date(this.props.file.modifiedTime);
      const created = new Date(this.props.file.createdTime);
        return(
          <>
            <td key={this.props.file.name+"name"} className="center">
              <div className="row" style={{margin:"0px"}}>
                <div className="col s3 l2 file-name-img" style={{textAlign:"left"}} onClick={()=>this.props.fileClick(this.props.file)}>
                  {this.renderImage()}
                </div>
                <div className="col s7 l8 file-name-text" onClick={()=>this.props.fileClick(this.props.file)}>
                  <p>{this.props.file.name}</p>
                </div>
                {this.props.file.createdBy == 'PPF' &&  <div className="col s2 l1">
                    <div class="chip blue white-text file-name-tag">PPF</div>
                  </div>
                }
                {
                  typeof this.props.file.fileExtension ==='undefined' &&
                  <div className="col s2 l1 file-name-img" style={{textAlign:"left", paddingLeft:"5px", paddingRight:"5px"}} onClick={()=>this.props.folderDownload(this.props.file)}>
                    {this.props.downloading ? <MdCloudDownload color="green" beat={true} /> : <Icon>cloud_download</Icon>}
                  </div>
                }
              </div>
            </td>
            <td key={this.props.file.name+"created"} className="center  mobile-hide">{created.toLocaleDateString()}</td>
            <td key={this.props.file.name+"modified"} className="center  mobile-hide">{modified.toLocaleDateString()}</td>
            {
              this.props.editMode == true
              ? <td key={this.props.file.name+"action"} className="center  mobile-hide">
                <button className='ppf-danger-button' onClick={()=>this.props.onDelete(this.props.file)}>Delete</button>
              </td>
              :null
            }
         </>
       )
    }
    return(
      <td className="center">
        <div className="row" style={{margin:"0px"}}>
          <div className="col l1">
            <input
              id={this.props.file.id || this.props.file.path}
              name={this.props.file.id || this.props.file.path}
              type="checkbox"
              defaultChecked={this.props.access}
              onClick={(e) => {this.props.checkboxClick(e, this.props.file)}}
              disabled={this.props.disabled}
            />
            <label className="col l10" htmlFor={this.props.file.id || this.props.file.path}/>
          </div>
          <div className="col l11" onClick={()=>this.props.fileClick(this.props.file)}>
            <div className="col l2 file-name-img" style={{textAlign:"left"}}>
              {this.renderImage()}
            </div>
            <div className="col l8 file-name-text">
              <p>{this.props.file.name}</p>
            </div>
            {
              this.props.access && this.props.writeAccess
                ? <div className="col s2 file-name-text">
                  <button
                    className='ppf-danger-button'
                    style={{padding: '5px', fontSize: '0.8rem', margin: '0px', lineHeight: 'normal', height:'fit-content'}}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.setReadOnly(this.props.file)
                    }}
                  >Write</button>
                </div>
                : null
            }
            {
              this.props.access && !this.props.writeAccess
                ? <div className="col s2 file-name-text">
                  <button
                    className='ppf-primary-button'
                    style={{padding: '5px', fontSize: '0.8rem', margin: '0px', lineHeight: 'normal', height:'fit-content'}}
                    onClick={(e) => {
                      e.stopPropagation();
                      this.props.setWrite(this.props.file)
                    }}
                  >Read</button>
                </div>
                : null
            }
          </div>
        </div>
      </td>
    )
  }

  render() {
    return(
      <tr>
        {this.renderDetails()}
      </tr>
    )
  }
}

export default File;