import React, {Component} from 'react';
import _, { filter } from 'lodash';
import axios from 'axios';
import { formatAddress, getRecordName } from '../../../../../utils/Formatter';

class SearchRecordForm extends Component{
  constructor(props){
    super(props);

    this.onAdd = this.onAdd.bind(this);

    this.state = {
      searchText:"",
      records: [],
      record: this.props.record,
    }
  }

  handleTextChange(e){
    const name = e.target.name;
    const value = e.target.value;

    this.state[name] = value;
    if(name == 'searchText'){
      if(value.length > 0 && value.length < 3){
        this.state.filteredItems = [];
      }
    }
    this.setState(this.state);
  }

  getRecords(cb = ()=>{}) {
    const {searchText} = this.state;
    axios.defaults.headers.common['records'] = this.props.auth.token;
    axios.post('/api/records', {data:{searchText, client_only: false}})
        .then(
            (res) => {
                const filteredItems = [];

                this.state.records = res.data;

                const {records} = this.state;
                _.forEach(records, function(value, key) {
                  var form = {record:value, key:key}
                  const nameValue = getRecordName(form.record);
                  if(searchText === "" || (nameValue.toLowerCase().includes(searchText.toLowerCase()))){
                    filteredItems.push(form);
                  }
                });

                this.state.filteredItems = filteredItems;
                this.setState(this.state, cb);
            })
        .catch((e) => console.log(e));
  }

  onAdd({record, key}, shares){
    const { type } = this.props;
    if(["directors", "management"].includes(type)){
      this.onSubmit({key});
    } else if(record && shares){
      this.onSubmit({key, shares});
    }
    this.state.record = {record, key};
    this.setState(this.state);
  }

  onSubmit(record){
    const { type } = this.props;
    this.props.onAdd(type, record);
  }

  renderRecords(){
    const recordElements = [];
    const { history } = this.props;
    const { filteredItems = [] } = this.state;
    filteredItems.forEach(item => {
      const nameValue = getRecordName(item.record);
      recordElements.push(
        <div className="search-results">
          <div className="col s4 result">
            {nameValue}
          </div>
          <div className="col s5 result">
            {formatAddress(item.record.address)}
          </div>
          <div className="col s3 result">
            <button
              className="ppf-primary-button col s12"
              onClick={()=>this.onAdd(item)}
            >Add</button>
          </div>
          <hr/>
        </div>
      )
    });
    return recordElements;
  }

  renderSearchView(){
    const {errors, searchText, filteredItems = []} = this.state;
    return (
      <div className="search-form">
        <h4 style={{textAlign:'center'}}>Search Form</h4>
        <div className="row">
          <input
            key="searchText"
            class="search-container col s9"
            id={"searchText"}
            type={"text"}
            placeholder="Enter Name"
            name={"searchText"}
            value={searchText}
            onChange={(e)=>this.handleTextChange(e)}
          />
          <button className="col s3 ppf-primary-button" onClick={()=>this.getRecords()}>Search</button>
        </div>
        <br/>
        {filteredItems.length > 0 && <div className="records-container">
          <p>Matches:</p>
          <div className = 'col s12'>
            <div className = 'col s4 center'>NAME</div>
            <div className = 'col s5 center'>ADDRESS</div>
            <div className = 'col s3 center'>ACTIONS</div>
          </div>
          {this.renderRecords()}
        </div>}
        <div className="col s12">
          <button
            className="ppf-primary-button"
            onClick={this.props.onNew}
          >Create Record</button>
        </div>
      </div>
    );
  }

  renderSetDetailsView(){
    const {record, sharesAmount} = this.state;
    return (
      <div className="search-form">
        <h4 style={{textAlign:'center'}}>Search Form</h4>
        <input
          key="sharesAmount"
          class="search-container"
          id={"sharesAmount"}
          type={"number"}
          placeholder="Enter Shares Amount"
          name={"sharesAmount"}
          value={sharesAmount}
          onChange={(e)=>this.handleTextChange(e)}
        />
        <br/>
        <div className="col s12">
          <button
            className="ppf-primary-button"
            onClick={()=>this.onAdd({key: record.key, record}, sharesAmount)}
          >Attach Record</button>
        </div>
      </div>
    );
  }

  render() {
    return(
        <div>
        {!this.state.record && this.renderSearchView()}
        {this.state.record && this.renderSetDetailsView()}
      </div>
    )
  }
}

export default SearchRecordForm;