import axios from 'axios';
import Cookies from 'universal-cookie';
import FirebaseTokenGenerator from 'firebase-token-generator';
import jwtToken from 'jsonwebtoken';
import { AUTH_CHANGE, FETCH_FILES, ADD_FORM_LAYER } from './types';
import { hasPermission } from '../utils/PermissionUtil';

export const loginAudit = (auth) => {
    axios.defaults.headers.common['Authorization'] = auth.token;
    axios.get('/api/loginAudit');
}

// TODO: clean up history
export const authChange = (auth, history, dispatch) =>{
  const cookies = new Cookies();
  if(auth === null){
    if(history){
      history.push('/login');
    }

    cookies.set('session', null, { path: '/' });
    dispatch({type:FETCH_FILES, payload:[]});
    dispatch({type:AUTH_CHANGE, payload:null});
  } else {
    jwtToken.verify(auth.token, 'XS(mXV<4@jec)EXL', (err, decoded) => {
      if(err){
        if(auth.fbUser){
          var generator = new FirebaseTokenGenerator("XS(mXV<4@jec)EXL");
          auth.token = generator.createToken({uid: auth.fbUser.email, firebaseId: auth.fbUser.uid});
          loginAudit(auth);
        } else {
          console.log('Could not generate token.');
          return;
        }
      }

      auth.user = null;
      axios.defaults.headers.common['Authorization'] = auth.token;
        axios.get('/api/user')
          .then(
          (res) => {
            auth.user = res.data.user;
            auth.key = res.data.key;
            dispatch({type:AUTH_CHANGE, payload:auth});

            // default routing for login
            if(history.location.pathname == '/login'){
              if(hasPermission(auth.user, 'dataroom')){
                history.push('/data_room');
              } else {
                history.push('/login');
              }
            }
          })
        .catch((e)=>console.log(e));
    });
  }
}

export const fetchUserAndLoad = (dispatch, history, auth, path)=>{
  const cookies = new Cookies();
  axios.defaults.headers.common['Authorization'] = auth.token;
  axios.get('/api/user')
    .then(
    (res) => {
      auth.user = res.data.user;
      auth.key = res.data.key;
      cookies.set('session', auth, { path: '/' });
      dispatch({type:AUTH_CHANGE, payload:auth});
      history.push(path);
    })
  .catch((e)=>console.log(e));
}

export const verify2FA = (dispatch, history, auth)=>{
    auth.verified_2FA = true;
    dispatch({type:AUTH_CHANGE, payload:auth});

    if(history.location.pathname == '/verifyWithSecret'){
      if(hasPermission(auth.user, 'dataroom')){
        history.push('/data_room');
      } else {
        history.push('/login');
      }
    }
    history.push(history.location.pathname);
}

export const fetchFiles = (data, token, dispatch, returnFunction) => {
  const { fileId, filePath, fileHistory, level } = data;
  axios.defaults.headers.common['Authorization'] = token;
      axios.get('/api/files',{
          params: {
          fileId,
          fileHistory,
          filePath,
          level
        }
      }).then(
        (res) => {
          dispatch({type:FETCH_FILES, payload:res.data});
          returnFunction();
        })
      .catch((e)=>console.log(e));
}

export const addFormLayer = (form) => dispatch => {
  dispatch({type:ADD_FORM_LAYER, payload:form});
}