import React from 'react'
import _ from 'lodash';

export default function EstimateHistory({estimates, onClick, estimate, current}) {
    let estimateElements = [];

    if (!estimates || estimates.length === 0) {
        return <div></div>;
    }
    _.forEach(estimates, (estimate) => {
        const { outdated_date, estimate_amount } = estimate;
        const formattedDate = new Date(outdated_date);
        estimateElements.push(
            <tr>
                <td className="estimate-row">
                    {`${formattedDate.toDateString()}`}
                </td>
                <td className="estimate-row">
                    ${estimate_amount}
                </td>
            </tr>
        );
    });

    return (
        <div className="row" style={{paddingTop: '25px'}}>
            <h5>History</h5>
            <table className="striped" >
                <tbody>
                    {estimateElements}
                </tbody>
            </table>
        </div>
    );
}
