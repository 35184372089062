import React, {Component} from 'react'

const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const template = (methods, state) => {
    const inputs  = [
        { name: "charge_name", type: "text", placeholder: "Name", className: "col s6", value: "", required: true },
        {  name: "charge_description", type: "text", placeholder: "Description", className: "col s6", value:"", required: true },

        { name: "issued_date", type: "date", placeholder: "Issue Date", className: "col s6", value: "", required: true },
        { name: "due_date", type: "date", placeholder: "Due Date", className: "col s6", value:"", required: true },
        { name: "notify_after", type: "number", placeholder: "Notification Sent After (Days)", className: "col s12", inputClass: 'col s6', innerStyle: halfStyle}, 

        { name: "charge_amount", type: "number", placeholder: "Amount", className: "col s6", innerStyle: {padding: '0px'}, value: "", required: true },
        { name: "charge_milestone", type: "select", placeholder: "Milestone", className: "col s6", 
            innerStyle: {padding: '0px'}, value: "", required: true, dictionary: ()=>state.milestones 
        },
        
        { name: "charge_currency", type: "select", dictionary: ()=>[
            {name: "BZD", value: "bzd"},
            {name: "US", value: "us"}
        ], placeholder: "Currency", className: "col s6", value:"", required: true },
        { name: "tax_type", type: "select", yesNo: true, placeholder: "Tax Type", className: "col s6", required: true, dictionaryId: '-MKQQe4zg0JtW5aDdgFq'},
        { name: "is_paid", type: "select", yesNo: true, placeholder: "Is Paid", className: "col s6", required: true },
        { name: "payment_date", type: "date", placeholder: "Paid Date", className: "col s6", value:"", required: true, condition: (state)=>{
            return state.is_paid == 'yes';
        }},
        { name: "charge_estimate", type: "text", isMoney: true, placeholder: "Estimate", className: "col s6", disabled: true}
    ];

    if(!state.viewOnly){
        inputs.push({ name: "invoice", type: "uploader", placeholder: "Invoice", className: "col s12", multipleSupport: false, inputClass: "ppf-primary-button left" });
    }

    return [{
        permission: [
            {feature: 'dealsManagement', part: 'charges'}
        ],
        title: state.data.charge_name || `New Invoice`,

        inputs,
        buttons: [
            {
                name: 'save',
                text: "Save",
                action: methods.onSave,
                className: "ppf-primary-button right",
                edits: true
            }
        ]
    }];
}


export default template;