import React, { Component } from 'react';
import "../../../node_modules/react-vis/dist/style.css";
import downloadSVG from "export-svg-with-styles";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import * as d3Shape from 'd3-shape';
import {
    Crosshair,
    FlexibleXYPlot,
    RadialChart,
    VerticalBarSeries,
    LineSeries,
    MarkSeries,
    AreaSeries,
    VerticalGridLines,
    HorizontalGridLines,
    XAxis,
    YAxis,
    DiscreteColorLegend
} from "react-vis";
import Select from './Select';

const graphSeriesColors = [
    '#ef5350',
    '#5c6bc0',
    '#ab47bc',
    '#26a69a',
    '#ffa726',
    '#ff7043',
    '#8d6e63',
    '#ec407a',
];

class Graph extends Component {
    constructor(props) {
        super(props);

        this._onNearestX = this._onNearestX.bind(this);
        this._onChartClick = this._onChartClick.bind(this);
        this.onPrint = this.onPrint.bind(this);
        this.onDownloadGraph = this.onDownloadGraph.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);

        this.state = {
            crosshairValues: [],
            chartOptions: [],
        };
    }

    onDownloadGraph() {
        const plot = document.getElementsByClassName('rv-xy-plot__inner')[0];

        const optionsA = {
            width: 1100,
            height: 375,
            svg: plot,
            filename: "graph.png"
        };

        downloadSVG(optionsA);
    }
    onPrint() {

        /*let y = 0;
        let x = 10;

        var plot = document.getElementsByClassName('rv-xy-plot__inner')[0];
        var legend = document.getElementsByClassName('rv-discrete-color-legend horizontal')[0];

        const optionsA = {
            width: 1100,
            height: 375,
            svg: plot,
            filename: "graph.png"
        };

        const pdf = new jsPDF('l', 'pt', 'a4');;
        downloadSVG(optionsA).then((img)=>{
            pdf.addImage(img, 'PNG', x, y);
            //pdf.addPage();
        }).then(()=>{
            y += 300;

            this.legendColors.forEach((color, i)=>{
                console.log(color);
                const item = this.legendItems[i];
                y += 10;
                pdf.setFontSize('10');
                pdf.setTextColor(color || '#000000');
                pdf.text(item, x, y);
            });

        }).then(()=>{
            x = 10;
            y += 50;
            pdf.setTextColor(256,256,256,0.87);

            if(this.props.groups.length > 0){
                pdf.setFontSize('16');
                pdf.text('Groups', x, y);
            }

            this.props.groups.forEach(group=>{
                y += 20;
                pdf.setFontSize('12');
                pdf.text(group, x, y);
            });

            // New Section
            y += 10;

            if(this.props.sources.length > 0){
                y += 15;
                pdf.setFontSize('16');
                pdf.text('Sources', x, y);
            }
            this.props.sources.forEach(src=>{
                y += 20;
                pdf.setFontSize('12');
                pdf.text(src, x, y);
            });

            // New Section
            y += 15;

            if(this.props.notes.length > 0){
                y += 15;
                pdf.setFontSize('16');
                pdf.text('Notes', x, y);
            }
            this.props.notes.forEach(note=>{
                y += 20;
                pdf.setFontSize('12');
                const splitNotes = pdf.splitTextToSize(note, 525);
                pdf.text(splitNotes, x, y);
            });
        }).then(()=>{
            pdf.save("download.pdf");
        });*/
        window.print();
    }

    getDomain() {
        const { dataGroup = this.props.dataGroup, scaledCharts = [], scalesToRender = {} } = this.state;
        const { buildY, data: dataObj } = this.props;
        let max = 0, min = 0;
        let domainGroup;
        if (dataGroup && dataGroup.groups) {
            dataGroup.groups.forEach(({ data, name, groupName }) => {
                data.forEach(({ x, y }) => {
                    // Transform data based on scale
                    const baseName = groupName;
                    const scaleValue = scaledCharts.includes(name);
                    const scale = scalesToRender[baseName];

                    if (scaleValue) {
                        y = y * scale
                    }

                    y = parseFloat(y);
                    max = parseFloat(max);

                    if (y >= max) {
                        // This is not the real domain group because if the scale
                        if (!scaleValue) {
                            domainGroup = groupName;
                        }
                        max = y;
                    }
                    if (y < 0 && ((y * -1) > (min * -1))) {
                        min = y;
                    }
                });
            });
        }

        if (dataObj) {
            dataObj.data.forEach(({ x, y }) => {
                y = parseFloat(y);
                max = parseFloat(max);

                if (y > max) {
                    max = y;
                }
                if (y < 0 && ((y * -1) > (min * -1))) {
                    min = y;
                }
            });
        }
        this.setState({ domain: [parseFloat(min), parseFloat(max)], domainGroup });
    }

    determineScales() {
        const { dataGroup = this.props.dataGroup, domain, domainGroup } = this.state;

        if (!dataGroup || !domain) {
            return;
        }

        const { ytitle, groups = [] } = dataGroup;
        const scalesToRender = {};
        const scaledCharts = [];
        const scaleMe = [];

        // Check which chart options are to be scaled
        groups.forEach((grp, i) => {
            const { chartOptions: chartStateOptions } = this.state;
            const { data: rawData, name, groupName } = grp;
            let data = JSON.parse(JSON.stringify(rawData));
            const options = chartStateOptions[name];
            if (options && options.scale == 'on') {
                scaledCharts.push(name);
                scaleMe.push({ data, groupName });
            }
        });

        // Create scales for each group type
        scaleMe.forEach(scalable => {
            const baseName = scalable.groupName;
            const scale = scalesToRender[baseName];

            // Do not transform if already in the domain
            if (baseName == domainGroup) {
                return;
            }

            if (scale > 0) {
                const min = this.getScale(domain, scalable.data);
                if (!min || min < scale) {
                    scalesToRender[baseName] = min;
                }
            } else {
                scalesToRender[baseName] = this.getScale(domain, scalable.data);
            }
        });

        this.setState({ scalesToRender, scaledCharts }, () => {
            this.getDomain();
        });
    }

    getScale(domain, data) {
        const domainMax = domain[1];
        let max = 0, min = 0;
        data.forEach(({ x, y }) => {
            y = parseFloat(y);
            max = parseFloat(max);

            if (y > max) {
                max = y;
            }
            if (y < 0 && ((y * -1) > (min * -1))) {
                min = y;
            }
        });
        return domainMax / max;
    }

    getXType() {
        const { type, dataGroup, dataObj } = this.props;
        const { mixed } = this.state;

        if (mixed) {
            return undefined;
        }

        switch (type) {
            case "line":
                return 'time';
            case "bar":
                return 'ordinal';
            case "area":
                return 'time';
        }
    }

    handleOptionChange(e, series, groupName) {
        let { chartOptions, scalesToRender } = this.state;
        const target = e.target;
        const name = target.name;
        let value = target.value;

        chartOptions[series] = chartOptions[series] || {};
        chartOptions[series][name] = value;

        if (name == 'scale' && Object.keys(scalesToRender).length > 0 && !scalesToRender[groupName]) {
            alert('Note that only 1 scale will be displayed on the right per data type.');
        }

        if (series == 'gridLines') {
            return this.setState({ gridLines: value });
        }

        this.setState({ chartOptions, mixed: true, domain: undefined, scalesToRender: undefined });
    }

    _onChartClick = () => {
        const { getCrosshairValues = false, neartestValue: value } = this.state;
        const { data: dataObj, dataGroup, xType = 'time' } = this.props;
        const crosshairValues = [];

        if (dataGroup) {
            const { groups } = dataGroup;
            groups.forEach(({ data }) => {
                const item = data.find(({ x }) => {
                    if (xType == 'time') {
                        const xVal = new Date(x);
                        const val = new Date(value.x);
                        const bool = xVal.toLocaleDateString("en-US") == val.toLocaleDateString("en-US")
                        return bool;
                    }

                    return x == value.x;
                });
                if (item) {
                    const i = JSON.parse(JSON.stringify(item));
                    if (xType == 'time') {
                        const date = new Date(i.x);
                        i.x = date;
                    }
                    i.y = parseFloat(i.y).toFixed(2);
                    crosshairValues.push(i);
                }

            });
            this.setState({ crosshairValues });
        } else {
            if (xType == 'time') {
                value.x = new Date(value.x);
            }
            this.setState({ crosshairValues: [value] });
        }
    };

    _onNearestX = (value, props) => {
        this.setState({ neartestValue: value });
    };

    renderGraph(data, options) {
        const {
            chartType,
            lineType,
            lineCurve,
            colorType = 'category',
            color,
            focused: isFocused,
            name
        } = options;

        // Line Options
        let style = {};
        let strokeDasharray = '';
        const curveOptions = {
            soft: d3Shape.curveCatmullRom.alpha(0.5),
            sharp: d3Shape.line().curve()
        };
        switch (lineType) {
            case "dotted":
                strokeDasharray = "2 2";
                break;
            case "dashed":
                strokeDasharray = "7, 3";
                break;
        }

        switch (chartType) {
            case "line":
                return (
                    <LineSeries
                        color={color}
                        opacity={isFocused ? 1 : 0.7}
                        strokeWidth={isFocused ? '4px' : '2px'}
                        onNearestX={this._onNearestX}
                        data={data}
                        style={style}
                        strokeDasharray={strokeDasharray}
                        curve={curveOptions[lineCurve]}
                    />);
            case "bar":
                data.map(a => {
                    a.y0 = 0;
                    return a;
                });

                return (
                    <VerticalBarSeries
                        colorType={colorType}
                        y0={'rice'}
                        color={color}
                        onNearestX={this._onNearestX}
                        opacity={isFocused ? 1 : 0.7}
                        strokeWidth={isFocused ? '4px' : '2px'}
                        data={data}
                    />);

            case "area":
                data.map(a => {
                    a.y0 = 0;
                    return a;
                });

                return (
                    <AreaSeries
                        opacity={1}
                        strokeStyle="solid"
                        opacity={isFocused ? 1 : 0.7}
                        strokeWidth={isFocused ? '4px' : '2px'}
                        onNearestX={this._onNearestX}
                        style={style}
                        strokeDasharray={strokeDasharray}
                        curve={curveOptions[lineCurve]}
                        color={color}
                        data={data}
                    />);
            case "pie":
                return (
                    <div className="col s6" style={{ marginTop: '15px' }}>
                        {name && <h5>{name && name.toUpperCase()}</h5>}
                        <RadialChart
                            showLabels={true}
                            width={300}
                            height={300}
                            innerRadius={70}
                            colorType="literal"
                            radius={140}
                            data={data.reduce((acc, { x, y }, i) => {
                                if (y > 0) {
                                    return [...acc, { angle: y, label: x, color: this.palette[i] }];
                                }
                                return acc;
                            }, [])}
                        />
                    </div>
                );
        }
    }

    renderMultipleGraph() {
        let dataElements = [];
        this.legendItems = [];
        this.legendColors = [];
        const scaleMe = [];
        const { dataGroup = this.props.dataGroup, scalesToRender = {}, scaledCharts = [] } = this.state;
        const { buildY, data: dataObj, type: chartType, xType = 'time' } = this.props;

        let { xtitle = "Date", ytitle = "Value", groups } = dataGroup

        if (!groups) {
            return;
        }

        groups.sort(function (a, b) {
            return b.data.length - a.data.length;
        });

        if (chartType != 'pie') {
            dataElements = [
                <YAxis
                    orientation="left"
                    position="middle"
                    style={{
                        text: { textAnchor: 'end' }
                    }}
                    title={ytitle}
                />,
                <XAxis
                    title={xtitle}
                    tickLabelAngle={-35}
                    orientation='bottom'
                    tickFormat={
                        xType == 'time' ? (value) => {
                            return value.toLocaleDateString("en-US");
                        } : (value) => value
                    }
                />
            ];

            // Render necessary scales to the right
            let tickPadding = 0;
            Object.keys(scalesToRender).forEach((key, i) => {
                if (i > 0) {
                    return;
                }
                const scaleValue = scalesToRender[key];
                dataElements.push(
                    <YAxis
                        orientation="right"
                        position="middle"
                        tickSizeOuter={1}
                        tickLabelAngle={-45}
                        tickPadding={tickPadding}
                        tickFormat={(t, i) => {
                            return (<tspan>
                                <tspan x="0" dy="1em">{(t / scaleValue).toFixed(2)}</tspan>
                            </tspan>);
                        }}
                        title={`${key} [${ytitle}]`}
                    />
                );

                tickPadding += 60;
            });
        }

        groups.forEach((grp, i) => {
            const { focused, chartOptions: chartStateOptions } = this.state;
            const { data: rawData, name, color, groupName } = grp;
            let data = JSON.parse(JSON.stringify(rawData));
            const options = chartStateOptions[name];
            const baseName = groupName;
            const scale = scalesToRender[baseName];
            const scaleValue = scaledCharts.includes(name);

            // Chart Option Defaults
            let chartOptions = {
                chartType: this.props.type,
                lineType: 'solid',
                lineCurve: 'sharp',
                color: color || this.palette[i],
                focused: name == focused,
                scale: 'off',
                name: groupName
            };

            chartOptions = Object.assign(chartOptions, options);
            data = data.sort((a, b) => {
                if (a.x < b.x) {
                    return -1;
                }
                return 0;
            });

            // Transform data based on scale
            if (scaleValue) {
                data = data.map(d => {
                    d.y = d.y * scale
                    return d;
                });
            }

            this.legendItems.push(name);
            this.legendColors.push(this.palette[i]);
            const graph = this.renderGraph(data, chartOptions);
            dataElements.push(graph);
        });

        if (chartType !== 'pie') {
            dataElements.push(
                <div id='graph-legend' className='col l12'>
                    <DiscreteColorLegend
                        colors={this.legendColors}
                        items={this.legendItems}
                        orientation="horizontal"
                        onItemMouseEnter={(item, index) => {
                            this.setState({ focused: item });
                        }}
                        onItemMouseLeave={() => {
                            this.setState({ focused: '' });
                        }}
                    />
                </div>
            );
        }

        return dataElements;
    }

    renderSingleGraph() {
        let dataElements = [];
        const { buildY, data: dataObj, xType = 'time', type: chartType } = this.props;

        let { xtitle, ytitle, xtickValues, ytickValues, xtickTotal, ytickTotal, data } = dataObj;
        dataElements = [
            <YAxis
                orientation="left"
                position="middle"
                style={{
                    text: { textAnchor: 'end' }
                }}
                title={ytitle} />,
            <XAxis
                tickLabelAngle={-45}
                orientation='bottom'
                tickFormat={
                    xType == 'time' ? (value) => {
                        return value.toLocaleDateString("en-US");
                    } : (value) => value
                }
            />
        ];

        data = data.map((x, i) => {
            x.color = this.palette[i];
            return x;
        });

        let chartOptions = {
            colorType: "literal",
            chartType: this.props.type,
            lineType: 'solid',
            lineCurve: 'sharp',
            focused: false,
            scale: 'off'
        };

        const graph = this.renderGraph(data, chartOptions);
        if (chartType === 'pie') {
            return graph;
        }
        dataElements.push(graph);

        return dataElements;
    }

    renderPlot() {
        const { buildY, data: dataObj, dataGroup, xType = 'time', type } = this.props;
        const { domain, domainGroup, scalesToRender, scaledCharts } = this.state;
        let dataElements = [];
        if (!domain && dataGroup) {
            this.getDomain();
            return;
        }

        if (!scalesToRender && dataGroup) {
            this.determineScales();
            return;
        }

        if (!dataGroup && dataObj) {
            dataElements = this.renderSingleGraph();
        } else if (dataGroup) {
            dataElements = this.renderMultipleGraph();
        }

        if (type === 'pie') {
            return dataElements;
        }

        return (
            <FlexibleXYPlot
                margin={{ left: 70, top: 50, right: 70, bottom: 50 }}
                style={{ overflow: 'visible' }}
                xType={xType ?? 'time'}
                onClick={this._onChartClick}
                onMouseLeave={() => {
                    this.setState({ crosshairValues: [] });
                }}
                yDomain={domain}
                allowDecimals={true}
            >
                {this.state.gridLines == 'on' && <VerticalGridLines />}
                {this.state.gridLines == 'on' && <HorizontalGridLines />}

                {dataElements}

                {this.state.crosshairValues.length > 0
                    && <Crosshair
                        values={this.state.crosshairValues}
                        className={'test-class-name'}
                    />
                }
            </FlexibleXYPlot>
        );
    }

    renderGraphOptions() {
        let optionElements = [];
        const { dataGroup = this.props.dataGroup, domainGroup, gridLines = 'off' } = this.state;
        if (!dataGroup || !dataGroup.groups) {
            return;
        }

        optionElements = [

            <div className="col l12">
                <Select
                    className='col l3'
                    name={'gridLines'}
                    placeholder='Grid Lines'
                    value={gridLines}
                    options={[
                        { name: 'On', value: 'on' },
                        { name: 'Off', value: 'off' },
                    ]}
                    onChange={(e) => this.handleOptionChange(e, 'gridLines')}
                />
                <br />
            </div>
        ];

        dataGroup.groups.forEach(({ data, name, color, groupName }, i) => {
            const { chartOptions, gridLines = 'off' } = this.state;
            const { chartType = this.props.type, lineType = 'solid', lineCurve = 'sharp', scale = 'off' } = chartOptions[name] || {};

            color = color || this.palette[i];
            const lineOptions = ['line', 'area'].includes(chartType);

            optionElements.push(
                <div className="col l12">
                    <div className="col s12"><b>{name}</b></div>
                    <Select
                        className='col l3'
                        name={'chartType'}
                        placeholder='Chart Type'
                        value={chartType}
                        options={[
                            { name: 'Line', value: 'line' },
                            { name: 'Bar', value: 'bar' },
                            { name: 'Area', value: 'area' },
                        ]}
                        onChange={(e) => this.handleOptionChange(e, name)}
                    />
                    {lineOptions && <Select
                        className='col l3'
                        name={'lineType'}
                        placeholder='Line Type'
                        value={lineType}
                        options={[
                            { name: 'Dashed', value: 'dashed' },
                            { name: 'Solid', value: 'solid' },
                            { name: 'Dotted', value: 'dotted' }
                        ]}
                        onChange={(e) => this.handleOptionChange(e, name)}
                    />}
                    {lineOptions && <Select
                        className='col l3'
                        name={'lineCurve'}
                        placeholder='Line Curve'
                        value={lineCurve}
                        options={[
                            { name: 'Sharp', value: 'sharp' },
                            { name: 'Soft', value: 'soft' }
                        ]}
                        onChange={(e) => this.handleOptionChange(e, name)}
                    />}
                    {groupName != domainGroup && <Select
                        className='col l3'
                        name={'scale'}
                        placeholder='Secondary Axis'
                        value={scale}
                        options={[
                            { name: 'On', value: 'on' },
                            { name: 'Off', value: 'off' }
                        ]}
                        onChange={(e) => this.handleOptionChange(e, name, groupName)}
                    />}
                </div>
            );
        });

        return (
            <div class='no-print'>
                <h4>Plotting Options</h4>
                <div>
                    {optionElements}
                </div>
            </div>
        );
    }

    renderMetaData() {
        const metaElements = [<h4>Meta Data</h4>];
        const grps = this.props.groups || [];
        if (grps.length == 0) {
            return;
        }
        grps.forEach(({ dsName = '', grpName = '', source = '', notes = '', description = '' }) => {
            metaElements.push(
                <p>
                    <b>Dataset Name:</b> {dsName}<br />
                    <b>Group Name:</b> {grpName}<br />
                    <b>Description:</b> {description.trim() != '' ? description : 'None'}<br />
                    <b>Source:</b> {source}<br />
                    <b>Notes</b>: {notes.trim() != '' ? notes : 'None'}<br />
                </p>
            );
        });

        return (
            <div id='graph-meta-data' className='col s12'>
                {metaElements}
            </div>
        );
    }

    renderPrint() {
        return <div className='row col s12 right hide-print' >
            <i class="material-icons right waves-effect" onClick={this.onDownloadGraph}>image</i>
            <i class="material-icons right waves-effect" onClick={this.onPrint}>print</i>
        </div>
    }

    render() {
        const { plottingOptions = true, metaData = true, palette = graphSeriesColors } = this.props;
        this.palette = palette;
        let notSupported = false;

        if (notSupported) {
            return ('Not Supported');
        }

        return (
            <div style={{ paddingRight: '50px', paddingLeft: '50px', overflow: 'visible' }}>
                <div id='xy-plot'
                    ref="xy_plot"
                    className="col s12"
                    style={{ height: "400px" }}
                >
                    {this.renderPlot()}
                </div>
                {this.renderPrint()}
                {metaData && this.renderMetaData()}
                {plottingOptions && this.renderGraphOptions()}
            </div>
        )
    }
}

export default Graph;