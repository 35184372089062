//withdLoading.js
import React, {Component} from 'react';
import axios from 'axios';

function WithDictionary(Comp, token) {
	return class CompWithDictionary extends Component {
		constructor(props) {
			super(props);
		
			this.state = {
				loading: false
			}
		}
		
		fetchDictionary() {
			this.setState({ loading: true });
			const { dictionaryId } = this.props;
			
			axios.defaults.headers.common['Authorization'] = token;
			let data = { key: dictionaryId };
			axios.post('/api/getDictionary', {
				data
			}).then(
				(res) => {
					if (res.data.error) {
						return alert(res.data.error);
					}
					this.setState({ data: res.data.options || [], loading: false });
				})
				.catch((e) => {
					this.setState({ data: [], loading: false });
					console.log(e);
				});
		}

		render() {
			const { dictionaryId } = this.props;
			const { loading, data } = this.state;

			if (!dictionaryId || dictionaryId == '') {
				return 'Invalid ID';
			}

			if (loading) {
				return <div>Loading Options...</div>;
			}

			if(!data){
				this.fetchDictionary();
				return <div></div>;
			}

			return <Comp {...this.props} options={data}/>;
		}
	};
}

export default WithDictionary;