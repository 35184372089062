import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux'
import PPFView from '../../Generics/PPFView';
import TabSelector from '../../Generics/TabSelector';
import inherentFields from './templates/inherentFields';
import { successWrapper } from '../../../utils/SuccessWrapper';

class RiskRatingMangement extends Component {
	constructor(props) {
		super(props);

		this.onSave = this.onSave.bind(this);

		this.state = {
			overall: {},
			inherent: {},
			kyc: {},
			criteria_type: 'entity '
		};
	}

	componentDidMount() {
		this.getRiskRatingCriteria();
	}

	onChange(changes, type, prop) {
		this.state[type][prop] = changes.newState;
		this.setState(this.state);
	}

	onSave() {
		const data = this.state;
		delete data.criteria_type;
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post('/api/setRiskRating', { data })
			.then(
				(res) => {
					successWrapper(res, ()=>{
						alert("Save Successful");
					});
				})
			.catch((e) => console.log(e));
	}

	onTabSelectorChange(prop, val){
		this.state[prop] = val;
		this.setState(this.state);
	}

	getRiskRatingCriteria() {
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post('/api/getRiskRating')
			.then(
				(res) => {
					successWrapper(res, (data)=>{
						this.state = data;
						this.setState(this.state);
					});
				})
			.catch((e) => console.log(e));
	}

	renderInputs(inputs, type, title) {
		if (!this.state) {
			return;
		}

		let { criteria_type } = this.state;
		const elements = [];

		inputs.forEach((input, i) => {
			const { name } = input;
			const methods = {
				onRefresh: () => { },
				onSave: this.onSave,
				onChange: (changes) => this.onChange(changes, type, name),
			};

			elements.push(
				<PPFView
					key={`inherent-risk-factors-${criteria_type}-${i}`}
					superProps={this.props}
					template={inherentFields(this.props.history, input)}
					state={{ data: this.state[type][name] || {}}}
					methods={methods}
				/>
			);
		});

		return <div>
			<h4>{title}</h4>
			{elements}
		</div>
	}

	renderAverageRiskFactors() {
		let { criteria_type } = this.state;
		criteria_type = criteria_type.trim();
		const inputs = [
			{ name: `${criteria_type}-average`, title: ""}
		];

		const type = 'overall';
		const title = 'Overall Average Risk Factors';

		return this.renderInputs(inputs, type, title);
	}

	renderInherentFactors() {
		const { criteria_type } = this.state;
		const entityInputs = [
			{ name: 'purpose', title: "Purpose of Relationship", /*link:"/dictionary/-MMce3U-MffW_ZLZJlIi"*/},
			{ name: 'nature_of_business', title: "Nature of Business", link:"/dictionary/-MMceB1mgqtim5iJYb0r" },
			{ name: 'incorporation_type', title: "Complexity of Legal & Owner Structure", link:"/dictionary/-MMceHRdkvWMPUzx60gC" },
			{ name: 'country', title: "Country of Incorporation", link:"/dictionary/-MMceMKLCbFo_1c9yJYq" },
		];
		const individualInputs = [
			{ name: 'purpose', title: "Purpose of Relationship", /*link:"/dictionary/-MMce3U-MffW_ZLZJlIi"*/},
			{ name: 'occupation', title: "Occupation", /*link:"/dictionary/-MMceSCLEMeQBLNyXFTL"*/ },
			{ name: 'nature_of_business', title: "Nature of Business/Industry", link:"/dictionary/-MMceB1mgqtim5iJYb0r" },
			{ name: 'country_of_residency', title: "Country of Residency", link:"/dictionary/-MMceMKLCbFo_1c9yJYq" },
			{ name: 'country', title: "Country of Nationality / Incorporation", link:"/dictionary/-MMceMKLCbFo_1c9yJYq" },
		];

		let inputs = entityInputs;
		if(criteria_type == 'individual '){
			inputs = individualInputs;
		}

		const type = 'inherent';
		const title = 'Inherent Risk Factors';

		return this.renderInputs(inputs, type, title);
	}

	renderKYCFactors() {
		const { criteria_type } = this.state;
		const entityInputs = [
			{ name: 'adverse_media', title: "Adverse Media", /*link:"/dictionary/-MMcuDwxVHt7noHtb8hf"*/ },
			{ name: 'pep', title: "PEP", /*link:"/dictionary/-MMcuNS9sp3rCYsOxM5d"*/ },
			{ name: 'financially_stable', title: "Financial Stability - Corporate", /*link:"/dictionary/-MMd-HKoowURi2u0hQLt"*/ },
			{ name: 'watchlist', title: "Watchlist", /*link:"/dictionary/-MMcuzxt9-tmwSZsxyJK"*/ },
		];
		const individualInputs = [
			{ name: 'source_of_funds', title: "Source Of Funds", link:"/dictionary/-MMcuukWgwmNV1ABSulL"},
			{ name: 'adverse_media', title: "Adverse Media", /*link:"/dictionary/-MMcuDwxVHt7noHtb8hf"*/ },
			{ name: 'risk', title: "Risk", /*link:"/dictionary/-MMcuzxt9-tmwSZsxyJK"*/ },
			{ name: 'pep', title: "PEP", /*link:"/dictionary/-MMcuNS9sp3rCYsOxM5d"*/ },
			{ name: 'criminal_record', title: "Criminal Record", /*link:"/dictionary/-MMcuQGsK079WacFxVEv"*/ },
			{ name: 'watchlist', title: "Watchlist", /*link:"/dictionary/-MMcuzxt9-tmwSZsxyJK"*/ },
		];
		
		let inputs = entityInputs;
		if(criteria_type == 'individual '){
			inputs = individualInputs;
		}
		
		const type = 'kyc';
		const title = 'KYC';

		return this.renderInputs(inputs, type, title);
	}

	render() {
		const { criteria_type } = this.state;
		return (
			<div className="app row" style={{ marginTop: '7%' }}>
				<div className = 'col s12' style={{ paddingLeft: '8%', paddingBottom: '20px'  }}>
					<button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
				</div>
				<div className="row">
					<h2 className="center">{`Risk Rating Critieria (${criteria_type})`}</h2>
					<div className = 'col s12'>
                </div>
					<div className = 'container'>
						<div className ='col s12' style={{paddingBottom: '10px'}}>
							<TabSelector
								options={['entity ', 'individual ']}
								value={criteria_type}
								className = "left col s4"
								placeholder= ""
								onChange={(val)=>this.onTabSelectorChange('criteria_type', val)}
							/>
							<button className = 'col l2 ppf-primary-button right' onClick={()=>this.onSave()}>Save</button> 
						</div>
						{this.renderAverageRiskFactors()}
						{this.renderInherentFactors()}
						<div className='col s12'>
							<br/>
							<hr style={{ border: '0', borderTop: '#f1f1f1 1px solid' }} />
							<br/>
						</div>
						{this.renderKYCFactors()}
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state, { history }) {
	return {
		auth: state.auth,
		files: state.files
	};
}

export default connect(mapStateToProps)(RiskRatingMangement);