import React, {Component} from 'react'
import { typeMap } from './recordFormValues';
import _ from 'lodash';

class User extends Component{

  render() {
    const { record } = this.props.record;
    return(
        <tr>
            <td className="center">
                <div className="row" style={{margin:"0px"}}
                  onClick={()=>{
                    this.props.history.push(
                      {
                        pathname: `/client/${this.props.record.key}` ,
                        state: {key: this.props.record.key}
                      }
                  )}}>
                  {record.first_name ? record.first_name + " " + record.last_name : record.businessName || "No Data"}
                </div>
            </td>
            <td className="center mobile-hide">{record.recordClass ? _.find(typeMap.recordClass, {value: record.recordClass}).name : "No Data"}</td>
            <td className="center mobile-hide">{record.recordType ? _.find(typeMap[record.recordClass], {value: record.recordType}).name : "No Data"}</td>
            <td className="center mobile-hide">{record.start_date ||  "No Data"}</td>
            <td className="center mobile-hide">{record.status || "No Data"}</td>
        </tr>
    )
  }
}

export default User;