import React, { useState } from 'react'
import { connect } from 'react-redux';
import { hasPermission } from '../../../utils/PermissionUtil';
import AMLScreenings from './AMLScreenings';
import RiskRatings from './RiskRatings';
import SanctionsSearch from './Sanctions/SanctionsSearch';
import SideNav from './SideNav'

const style = {
    content:{
        background: '#F4F5F8',
        margin: "0px",
        minHeight: "100vh",
    },
    container:{
        margin:"85px 0px 0px 0px"
    },
    wrapper:{
        background: "white",
        marginBottom: "15px"
    },
    padded:{
        padding: "40px",
    },
}

const AMLManagement = (props) => {
	const [selected, setSelected] = useState('amlScreenings');
	const viewMapping = [
		{id: 'amlScreenings', component: <AMLScreenings superProps={props}/>},
		{id: 'amlRiskRatings', component: <RiskRatings superProps={props}/>},
		{id: 'amlSearch', component: <SanctionsSearch />},
	];
	const sectionElement = [];

	viewMapping.forEach(({id, component, condition = ()=>true}) => {
		if(id == selected){
			const hasAccess = hasPermission(props.auth.user, 'amlManagement', id);
			if(hasAccess){
				sectionElement.push(component);
			} else {
				alert('Permission denied. Contact you admin.')
			}
		}
	});

	return (
		<div className="row" style={style.content}>
			<div class="col l2 m4 s3 no-print">
				<SideNav
					superProps = {props}
					data = { {} }
					selected = { selected }
					onChange = { (type) => { setSelected(type)} }
				/>
			</div>
			<div className="col l10 m8 s9 center">
				<div style={style.container}>
					<div className = 'col s12 no-print'>
						<button className="ppf-nav-button left" onClick={()=>{props.history.goBack()}}>Back</button>
					</div>
					<div className = 'col s12 ppf-section'>
						{
							sectionElement.length == 0 ?
							<div>
								<h5>No Data Found</h5>
							</div>
							: sectionElement
						}
					</div>
				</div>
			</div>
		</div>
	)
}

function mapStateToProps(state){
    return {
      auth:state.auth,
      formLayer:state.formLayer
    };
}

export default connect(mapStateToProps)(AMLManagement);
