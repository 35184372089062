import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux'
import Input from '../../Generics/Input';

class DictionaryView extends Component {
    constructor(props) {
        super(props);

        this.onSave = this.onSave.bind(this);

        this.state = {
            modal: false,
            records: []
        };
    }

    componentDidMount() {
        let key = null;

        if(this.props.match && this.props.match.params && this.props.match.params.dictionaryId ){
            key = this.props.match.params.dictionaryId;
            this.getRecordData(key, (data) => {
                data.key = key;
                this.setState({ key, dictionaryData: data });
            });
        }
    }

    getRecordData(key, callback) {
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        let data = { key };

        axios.post('/api/getDictionary', {
            data
        }).then(
            (res) => {
                if (res.data.error) {
                    return alert(res.data.error);
                }
                callback(res.data);
            })
            .catch((e) => {
                console.log(e);
            });
    }

    onDelete(option){
        const { dictionaryData } = this.state;
        const { value } = option;

        const options = dictionaryData.options.filter(doc=>{
            if(doc.value == value){
                return false;
            }
            return true;
        });

        dictionaryData.options = options
        this.setState({dictionaryData}, ()=>{
            this.onSave();
        });
    }

    onChange(e, isOption = false){
        const { dictionaryData, dictionaryOption = {}, isNew = false } = this.state;
        const { name, value } = e.target;

        dictionaryOption.modified_by = this.props.auth.key;

        if(isOption){
            dictionaryOption[name] = value;

            // Set value if not present
            if(isNew && name == 'name'){
                dictionaryOption.value = value.toLowerCase().replace(/ /g, "_");
                dictionaryOption.created_date = Date.now();
            }

            if(!dictionaryOption.created_date){
                dictionaryOption.created_date = Date.now();
            } else {
                dictionaryOption.modified = Date.now();
            }

            this.setState({dictionaryOption});
        } else {
            dictionaryData[name] = value;
            this.setState({dictionaryData});
        }
    }

    onEdit(option){
        this.setState({dictionaryOption: option, modal: true});
    }

    onCloseModal(){
        this.setState({dictionaryOption: undefined, modal: false});
    }

    onSave() {
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        const { dictionaryOption, dictionaryData: updatedData, key, isNew } = this.state;

        // Update Options List
        if(dictionaryOption){
            let found = false;
            let isDuplicate = false;

            updatedData.options = updatedData.options || [];
            updatedData.options.map(item => {
                if(item.value == dictionaryOption.value || item.name == dictionaryOption.name){
                    if(isNew){
                        isDuplicate = true;
                        return item;
                    }
                    found = true;
                    return dictionaryOption;
                }
                return item;
            });

            if(isDuplicate){
                alert('This value already exists in the dictionary');
                return;
            }

            if(isNew){
                updatedData.options.push(dictionaryOption);
            }
        }

        let data = { key, changed: updatedData};
        axios.post('/api/updateDictionary', {
            data
        }).then(
            (res) => {
                if (res.data.error) {
                    return alert(res.data.error);
                }
                
                this.setState({modal: false, dictionaryOption: undefined, isNew: undefined}, ()=>{
                    alert('Save Successful!');
                });
            })
            .catch((e) => {
                console.log(e);
            });
    }

    renderValues() {
        const { history } = this.props;
        let records = this.state.dictionaryData ? this.state.dictionaryData.options : [];

        return _.chain(records)
            .map((option) => {
                const { value, tracked_value = "N/A", name, created_date } = option;
                const createdDate = created_date ? new Date(created_date).toLocaleDateString() : 'N/A';

                return (
                    <tr key={value}>
                        <td>{name}</td>
                        <td>{tracked_value}</td>
                        <td>{createdDate}</td>
                        <td>
                            <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(option)}>Edit</button>
                            <button className='ppf-danger-button' onClick={()=>this.onDelete(option)}>Delete</button>
                        </td>
                    </tr>
                );
            })
            .compact()
            .value();
    }

    renderTable() {
        return (
            <table>
                <thead>
                    <tr>
                        <th className="center">Option Name</th>
                        <th className="center mobile-hide">Option Value</th>
                        <th className="center mobile-hide">Date Added</th>
                        <th className="center mobile-hide">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderValues()}
                </tbody>
            </table>
        )
    }

    renderDetails() {
        if(!this.state.dictionaryData){
            return;
        }

        const { name, description, components } = this.state.dictionaryData;

        return (
            <div>
                <div className = 'col s12'>
                    <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
                    <button className = 'ppf-primary-button right' onClick = {this.onSave}>Save</button>
                </div>

                <Input
                    className = 'col s6'
                    value = {name}
                    placeholder = 'Dictionary Name'
                    name = 'name'
                    handleChange = {(e)=>this.onChange(e)}
                />
                <Input
                    className = 'col s6'
                    value = {components}
                    placeholder = 'Components Related'
                    disabled = {false}
                    name = 'components'
                    handleChange = {(e)=>this.onChange(e)}
                />
                <Input
                    className = 'col s12'
                    value = {description}
                    placeholder = 'Dictionary Description'
                    disabled = {false}
                    name = 'description'
                    handleChange = {(e)=>this.onChange(e)}
                />
            </div>
        );
    }

    renderModal(){
		if(!this.state.modal){
		  return(
			<div></div>
		  )
        }

        const { dictionaryOption = {} } = this.state;
        const { name = '', tracked_value = '' } = dictionaryOption;
		return(
		  <div id="myModal" className="modal row">
			<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			  <span className="close" onClick={()=>this.onCloseModal()}>&times;</span>
			  <h3>Modify Option</h3>
                <Input
                    key = "name"
                    name = 'name'
                    className = "col s12"
                    placeholder = "Option Name"
                    handleChange = {(e) => this.onChange(e, true)}
                    value = {name}
                />
                {this.state.dictionaryData.track_value && <Input
                    key = "name"
                    name = 'tracked_value'
                    className = "col s12"
                    placeholder = "Option Value"
                    handleChange = {(e) => this.onChange(e, true)}
                    value = {tracked_value}
                />}
                <button className = 'ppf-primary-button col s4 right' onClick={()=>this.onSave()}>Save</button> 
			</div>
		  </div>
		)
	}

    render() {
        return (
            <div className="app">
                <div className="container row" style={{ marginTop: '7%' }}>
                    <h2>Dictionary</h2>
                    {this.renderModal()}
                    {this.renderDetails()}
                    {this.renderTable()}
                    <button className = 'ppf-primary-button right'  onClick={()=>this.setState({modal: true, isNew: true})}>ADD OPTION</button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, { history }) {
    return {
        auth: state.auth,
        files: state.files
    };
}

export default connect(mapStateToProps)(DictionaryView);