import axios from 'axios';
import React, { useState } from 'react';
import { successWrapper } from '../../../../../../utils/SuccessWrapper';
import RiskProfileChart from './RiskProfileBarChart';
import RiskProfileSummary from './RiskProfileSummary';
import RiskRatings from './RiskScreenings';

export const RiskRating = ({superProps, state, methods}) => {
    const [data, setData] = useState();
    const [attached, setAttached] = useState();
    const [current, setCurrent] = useState();
    const [error, setError] = useState();
    const { viewOnly } = state;
    const elements = [];

    const calculateRiskRating = ()=>{
        const { token } = superProps.auth;
        axios.defaults.headers.common['Authorization'] = token;
        axios.post('/api/calculateRiskRating', {data:{clientId: state.data.key}}).then(res=>{
            successWrapper(res, (data)=>{
                setData(data);
            }, err =>{
                setError(err);
            });
        }).catch(err=>{
            setError(err);
        });
    }

    const attachRiskRating = ()=>{
        const { user, token } = superProps.auth;
        axios.defaults.headers.common['Authorization'] = token;
        axios.post('/api/attachRiskRating', {
            data:{
                clientId: state.data.key, 
                riskData: data,
                userId: user.key,
                userName: user.username
            }}).then(res=>{
            successWrapper(res, (data)=>{
                methods.onRefresh();
                alert("Success!");
            }, err =>{
                alert("Error: Could not attach risk rating to client record");
            });
        }).catch(err=>{
            setError(err);
        });
    }

    if(error){
        return <div>
            <h5>
                {typeof error == 'object' 
                    ? JSON.stringify(error) 
                    : error}
            </h5>
        </div>;
    }

    // Attach Risk Profile Data
    if(data){
        const { riskCategoriesSummary, riskLevel, riskScore } = data;
        elements.push(
            <div className = 'col s12 row'>
                <div className = 'col s12 left'>
                    <p className ='left'>
                        Created On: {current ? new Date(current.created_at).toLocaleDateString() : 'Just Now'}
                    </p>
                </div>
                {!attached &&<button className = 'right ppf-primary-button' onClick = {()=>{attachRiskRating()}}>Attach To Record</button>}
                <RiskProfileChart data={riskCategoriesSummary} overall_rating={riskScore} category={riskLevel && riskLevel.toUpperCase()}/>
                <RiskProfileSummary data={riskCategoriesSummary}/>,
                <hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
            </div>
        );
    }

    if(!state.data.riskRatings && !data){
        return <div>
            <h4>Risk Rating</h4>
                <p>This record does not have any risk rating attached.</p>
                {!viewOnly && <button
                    className = 'ppf-primary-button'
                    onClick={()=>{
                       calculateRiskRating();
                       setAttached(false);
                    }}
                >Run Risk Rating</button>}
        </div>;
    } else {
        // Show
        elements.push(
            <RiskRatings 
                current={current}
                ratings={state.data.riskRatings} 
                onClick={
                    rating=>{
                        setData(rating.data)
                        setCurrent(rating);
                        setAttached(true);
                    }
                } />
        );
        if(!viewOnly){
            elements.push(
                <button
                    className = 'ppf-primary-button right'
                    onClick={()=>{
                        calculateRiskRating();
                        setAttached(false);
                        setCurrent(null);
                    }}>
                Run New Risk Rating</button>
            );
        }
    }

return <div>{elements}</div>;
};

export default RiskRating;
