import axios from 'axios';
import firebase from 'firebase';
import React, { useState } from 'react'
import Select from '../../Generics/Select';
import Graph from '../../Generics/Graph';
import MultiTypeGraph from '../../Generics/MultiTypeGraph';


export default function AMLScreenings(props) {
	const [matchType, setMatchType] = useState('potential')
	const [records, setRecords] = useState();
	const [loading, setLoading] = useState(false);
	const loadingComp = <div>Loading...</div>;
	const fetchData = () => {
		setLoading(true);
        axios.defaults.headers.common['Authorization'] = props.superProps.auth.token;
		return axios.get('/api/aml/amlScreenings')
			.then(res=>{
				setRecords(res.data.data)
;			})
			.catch(err => {
				console.log(err);
				alert("Error fetching possible matches.")
				setRecords([]);
			})
			.finally(()=>{
				setLoading(false);
			});
	}

	if(loading){
		return loadingComp;
	}

	if(!records && !loading){
		fetchData();
		return loadingComp;
	}

	const pepsData = [
		{x: 'confirmed', y: 0},
		{x: 'denied', y: 0},
		{x: 'potential', y: 0},
	];
	
	const sanctionsData = [
		{x: 'confirmed', y: 0},
		{x: 'denied', y: 0},
		{x: 'potential', y: 0},
	];

	records.forEach((current) => {
		const {client, possible_matches = [], confirmed_matches, denied_matches} = current;
		if(confirmed_matches.length > 0){
			pepsData[0].y += confirmed_matches.filter(x => x.peps).length;
			sanctionsData[0].y += confirmed_matches.filter(x => !x.peps).length;
		}

		if(denied_matches.length > 0){
			pepsData[1].y += denied_matches.length;
			sanctionsData[1].y += denied_matches.filter(x => !x.peps).length;
		}

		if(possible_matches.length > 0){
			pepsData[2].y += possible_matches.length;
			sanctionsData[2].y += possible_matches.filter(x => !x.peps).length;
		}
	});

	return (
		<div>
			<h3>Client Compliance Screenings</h3>
			<p>The following graph displays all client matches that have been detected by the PPF Capital application. This data displayed is for the current year.</p>
			<div className="col s12">	
				<div>
					<MultiTypeGraph
						palette={[
							'#ef5350',
							'#4caf50',
							'#5c6bc0',
						]}
						chartTypes= {['pie ', 'bar ']}
						dataGroup={{
							ytitle: 'Number of Matches',
							xtitle: 'Type',
							groups: [
								{
									groupName: 'sanctions',
									name:'sanctions',
									data: sanctionsData,
								},
								{
									groupName: 'peps',
									name:'peps',
									data: pepsData,
								}
							]
						}}
						buildY = {true}
						xType = "ordinal"
						plottingOptions = {false}
						metaData = {false}
					/>
				</div>
			</div>
			<p>The following table displays Customer’s that have potential sanction matches. You may confirm or deny the match to associate or ignore the 
sanctions record.</p>
			<div className ="row">
				<div className='col s12 valign-wrapper'>
					<Select 
						className ="col s3 right"
						inputClass ="col s12"
						name='screening-type'
						value={matchType}
						onChange={e=>setMatchType(e.target.value)}
						options={[
							{name: 'Potential', value: 'potential'},
							{name: 'Confirmed', value: 'confirmed'},
							{name: 'Denied', value: 'denied'}
						]}
					/>
					{/* <button 
						className="ppf-primary-button right"
						onClick={()=>{
							// Move to backend
							const runScreenings = firebase.app().functions('us-central1').httpsCallable('sanctionsCheck-manualSanctionsCheck');
							setLoading(true);
							runScreenings({})
								.then(res=>{
									fetchData().then(res=>{
										alert('Screenings Ran');
									});
								}).catch(err=>{
									console.log(err);
									alert('Error when running screenings.');
								})
								.finally(()=>{
									setLoading(false);
								});
						}}
					>Run Screenings
					</button> */}
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th className="center">Client Name</th>
						<th className="center mobile-hide">Possible Matches</th>
						<th className="center mobile-hide">Date</th>
						<th className="center mobile-hide">Actions</th>
					</tr>
				</thead>
				<tbody>
					{
						records.map(({client, possible_matches = [], confirmed_matches, denied_matches}) => {
							let matches;
							switch(matchType){
								case 'potential':
									matches = possible_matches;
									break;
								case 'confirmed':
									matches = confirmed_matches;
									break;
								case 'denied':
									matches = denied_matches;
									break;
							}
							const { first_name, last_name, businessName } = client;
							let matchDate;

							if(!matches || matches == 0){
								return;
							}

							matches.forEach(match=>{
								if(!matchDate || match.match_date > matchDate){
									matchDate = match.match_date;
								}
							});
							matchDate = new Date(matchDate);
							return <tr>
								<td onClick={()=>{
									props.superProps.history.push({
										pathname: `/client/${client.key}` ,
										state: {key: client.key}
									})}
								}><a>{
									client.recordClass == 'individual' 
										? `${first_name} ${last_name}`
										: businessName
								}</a></td>
								<td>{possible_matches.length}</td>
								<td>{matchDate.toLocaleDateString()}</td>
								<td>
									<button 
										className="ppf-primary-button" 
										onClick={()=>{
											props.superProps.history.push({
												pathname: `/client/${client.key}` ,
												state: {section: 'sanctions'}
											})
										}
									}>View Matches</button></td>
							</tr>;
						})
					}
				</tbody>
		</table>
		</div>
	)
}
