import React from 'react';
const style = {height: '1rem', paddingLeft: '25px', position: 'relative'};
const Checkbox = ({name, value, className, onChange, disabled, placeholder, group}) =>{
    return(
        <div className={`${className}`} style={{padding: '0px'}}>
            <div className = "col s12" style={{padding: '0px'}}>
                <input type="checkbox" style={style} id={name} name={name} value={group} checked={value} onChange={!disabled ? onChange : ()=>{alert('Disabled')}}/>
                <label htmlFor={name} style={{height: 'auto', display:'inline'}}>{placeholder}</label>
            </div>
        </div>
    );
};

export default Checkbox;