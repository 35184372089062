export function formatAddress(address){
    if(!address){
        return "";
    }
    let formattedAddress = (address.street ? (address.street + ", ") : "") +
    (address.city ? (address.city + ", ") : "") +
    (address.state ? (address.state + ", ") : "") +
    (address.country ? (address.country + ", ") : "") +
    (address.zip ? (address.zip + ", ") : "");
    formattedAddress = formattedAddress.substr(0, formattedAddress.length - 2 );

    return formattedAddress;
}

export function getRecordName(record){
    const  fullName = record.first_name ? record.first_name + " " + record.last_name : record.businessName || "No Data";
    return fullName;
}