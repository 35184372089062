import React, {Component} from 'react'
import * as _ from 'lodash';
import PPFView from '../../../../Generics/PPFView';
import contactFields from './Templates/contactFields';

class ContactsView extends Component {
    static getTemplate(){
        return [
            {
                grouped: true,
                title: "Contacts",
                props: "contacts",
                template: {
                    title: '',
                    inputs:[
                        { name: "fullName", type: "text", placeholder: "Full Name", className: "col s6", value: "", required: true },
                        {  name: "email", type: "text", placeholder: "Email Address", className: "col s6", value:"", required: true },


                        { name: "mobileNumber", type: "text", placeholder: "Mobile Number", className: "col s6", value: "", required: true },
                        {  name: "officeNumber", type: "text", placeholder: "Office Number", className: "col s6", value:"", required: true },


                        { name: "role", type: "text", placeholder: "Role", className: "col s6", value: "", required: true },
                        { name: "isPrimary", type: "select", yesNo: true, placeholder: "Primary Contact", className: "col s6", required: true }
                    ],
                }
            }
        ];
    }

    constructor(props){
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onDeleteContact = this.onDeleteContact.bind(this);
        this.onAddContact = this.onAddContact.bind(this);

        this.state = {
            data: this.props.state.data
        };
    }

    onAddContact(){
        const { contacts = [] } = this.state.data;
        contacts.push({
            index: contacts.length,
            name: '',
            email: '',
            mobile: '',
            office: '',
            role: '',
            isPrimary: false
        });

        this.state.data.contacts = contacts;
        this.setState(this.state);
    }

    onDeleteContact(index){
        const { contacts = [] } = this.state.data;
        const i = contacts.findIndex(x => x.index == index);

        if(i == -1){
            return;
        }

        contacts.splice(i,1);

        this.state.data.contacts = contacts;
        this.setState(this.state);
    }

    onContactChange(changes, i){
        const { newState } = changes;
        const { contacts = [] } = this.state.data;
        contacts[i] = newState;

        this.state.data.contacts = contacts;
        this.setState(this.state);
    }

    onSave(){
        this.props.methods.onSave(this.state.data, {inputs:[{name: 'contacts'}]});
    }

    renderContacts(){
        const { viewOnly = false } = this.props.state;
        const { data = {} } = this.state;
        const {
            contacts = [],
            email = '',
            telephone = ''
        } = data;
        const contactElements = [];

        // Handle some default setting from previous record structure
        if(this.state.data && contacts.length == 0){
            contacts.push({
                index: 0,
                name: '',
                email,
                mobile: telephone,
                office: '',
                role: '',
                isPrimary: true
            });

            this.state.data.contacts = contacts;
            this.setState(this.state);
            return;
        }

        const superProps = this.props;

        contacts.forEach((contact, i) => {
            const methods = {
                onRefresh: () => {},
                onSave: this.onSave,
                onChange: (changes) => this.onContactChange(changes, i),
                onDelete: () => {this.onDeleteContact(contact.index)}
            }

            const state = {key: contact.index, viewOnly, data: contact};
            contactElements.push(
                <PPFView
                    key={contact.index}
                    superProps = {this.props.superProps}
                    template={contactFields(methods, state)}
                    state={state}
                    methods={methods}
                />
            )
        });

        return contactElements;
    }

    renderContactsView(){
        const { viewOnly } = this.props.state;
        return (
            [
                !viewOnly && <button className = 'ppf-primary-button right' onClick = {this.onSave}>Save</button>,
                this.renderContacts(),
                !viewOnly && <button className = 'ppf-primary-button right padded-right' onClick={this.onAddContact}>Add Contact</button>
            ]
        )
    }

    render(){
        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {this.renderContactsView()}
            </div>
        );
    }
}

export default ContactsView;