import React from 'react'

export default function RiskProfileSummary({data: riskDetails = []}) {
    const rows = [];
    riskDetails.forEach((riskDetail) => {
        if(!riskDetail){
            return;
        }
        let { name, value, score, fieldValue} = riskDetail;
        rows.push(
            <tr key={name}>
                <td>{name.toUpperCase().replace(/_/g, " ")}</td>
                <td>{fieldValue.toUpperCase().replace(/_/g, " ")}</td>
                <td>{value}</td>
                <td>{score}</td>
            </tr>
        );
    });

    return(
        <div className = 'row' style={{paddingTop: '25px'}}>
            <h4>Risk Profile Details</h4>
            <table key={'riskSummary'}>
                <thead>
                <tr>
                    <th className="center">Name</th>
                    <th className="center mobile-hide">Field Value</th>
                    <th className="center mobile-hide">Normalized Value</th>
                    <th className="center mobile-hide">Risk Score</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        </div>
    );
}
