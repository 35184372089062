import React, {Component} from 'react';
import { Navbar, NavItem, Dropdown, Divider } from 'react-materialize';
import { hasPermission } from '../../../utils/PermissionUtil';

const style = {
    slideOut:{
        transform: "translateX(0%)",
        position: "relative",
        height: "99vh",
        width: "auto",
        marginRight: "35px"
    },
}

class SideNav extends Component{
    render(){
        const optionsElements = [];
        const { data, selected } = this.props;

        const options = [
            {id: 'amlScreenings', icon: 'check_circle', title: 'AML Screenings'},
            {id: 'amlRiskRatings', icon: 'assignment', title: 'Risk Ratings'},
            {id: 'amlSearch', icon: 'search', title: 'AML Search'},
        ];

        options.forEach(({id, title, icon}) => {
            const canEdit = hasPermission(this.props.superProps.auth.user, 'amlManagement', id);
            if(!canEdit){
                return;
            }
            optionsElements.push(
                <li
                    onClick={ () => this.props.onChange(id) }
                    style={
                    id == selected ?
                        { backgroundColor: 'rgba(0,0,0,0.05)' }
                        : {}}
                >
                    <a>
                        <i class="material-icons">{icon}</i>
                        <span className ='hide-on-small-only'>{title}</span>
                    </a>
                </li>
            );
        });

        return(
            <div className="row">
                 <ul id="slide-out" className="side-nav"
                    style={{ transform: 'translateX(0px)', zIndex:'5', paddingTop: '65px', width: 'auto'}}>
					<div style = {{height: '20%'}}></div>
                    <li>
                        {optionsElements}
                    </li>
                </ul>
            </div>
        );
    }
}

export default SideNav;