import React, {Component} from 'react';

class Publication extends Component{
  render() {
    return(
      <tr className="row">
        <td key="name" className="center col l3">
          <div className="row" style={{margin:"0px"}} onClick={()=>this.props.setModal(true,this.props.publication)}>
            <div className="col l10 file-name-text">
              <p>{this.props.publication.title}</p>
            </div>
          </div>
        </td>
        <td key="description" className="center col l6 mobile-hide">{this.props.publication.description}</td>
        <td key="modified"className="center col l3 mobile-hide">{this.props.publication.createdOn}</td>
      </tr>
    )
  }
}

export default Publication;