import React, {Component} from 'react';

class User extends Component{

  render() {
    return(
      <tr>
        <td className="center">
          <div className="row" style={{margin:"0px"}} onClick={()=>this.props.onClick(this.props.user)}>
            {this.props.user.username}
          </div>
        </td>
        <td className="center mobile-hide">{this.props.user.createdBy}</td>
        <td className="center mobile-hide">{this.props.user.modifiedBy}</td>
      </tr>
    )
  }
}

export default User;