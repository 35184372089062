
import React, { Component } from 'react';
import { Router, Route, Switch} from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase';
import * as actions from '../actions';
import * as appCss from '../styles/app.css'
import { hasPermission } from '../utils/PermissionUtil';
import { authChange } from '../actions';
import history from '../utils/History';

import Header from './Header';
import DataRoom from './App/Home/Home';
import UserManagement from './App/Admin/Admin';
import UserPermissions from './App/Admin/UserPermissions';
import Analytics from './App/Analytics';
import ReleaseNotes from './App/ReleaseNotes';
import SearchStatisticalDatabase from './App/StatisticalDatabase/Search';
import ManageStatisticalDatabase from './App/StatisticalDatabase/Manage';
import Schema from './App/StatisticalDatabase/Manage/Schema';
import Dataset from './App/StatisticalDatabase/Manage/Dataset';
import Group from './App/StatisticalDatabase/Manage/Group';
import Publications from './App/Publications/Publications';
import Landing from './Front/Landing';
import Login from './App/Auth/Login';
import TFSetup from './App/Auth/2FA/Setup2FA';
import VerifyWithSecret from './App/Auth/2FA/VerifyWithSecret';
import Confirmation from './App/Auth/Confirmation';
import PrivateRoute from './App/Auth/PrivateRoute';
import ClientManagement from './App/ClientManagement/ClientManagement';
import DealsMangement from './App/DealsManagement/DealsMangement';
import DealView from './App/DealsManagement/DealView';
import ClientView from './App/ClientManagement/ClientView';
import Support from './App/Support/Support';
import VendorManagement from './App/VendorManagement/VendorManagement';
import VendorView from './App/VendorManagement/VendorView';
import DictionaryMangement from './App/DictionaryManagement/DictionaryMangement';
import DictionaryView from './App/DictionaryManagement/DictionaryView';
import DataManagement from './App/DataManagement';
import ApplicationManagement from './App/ApplicationManagement';
import AMLManagement from './App/AMLManagement';
import RiskRatingManagement from './App/RiskRatingManagement/RiskRatingManagement';

/*
const Permissions = {
  read: "read",
  write: "write",
  update: "update",
  delete: "delete"
};

const AdminFeatures = {
  user_management: "user_management",
  analytics: "analytics",
  publications: "publications",
  identity_management: "identity_management",
  statistical_data: "statistical_database"
};
*/

class App extends Component {
  constructor(props){
    super(props);

    var config = {
      apiKey: "AIzaSyCV-qWeAdGcfledlngd5WQYl7yuadhrud0",
      authDomain: "todofirebaseproject-df3f1.firebaseapp.com",
      databaseURL: "https://todofirebaseproject-df3f1.firebaseio.com",
      projectId: "todofirebaseproject-df3f1",
      storageBucket: "todofirebaseproject-df3f1.appspot.com",
      messagingSenderId: "108526754507"
    };

    if(firebase.apps.length === 0){
      firebase.initializeApp(config);
	  
      firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    }
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged((user)=>{
      if(user){
        const { dispatch } = this.props;
        var auth = { uid:user.uid, user:null, token:null, fbUser: user };
        authChange(auth, history, dispatch);
      }
    });

    String.prototype.capitalize = function() {
        return this.charAt(0).toUpperCase() + this.slice(1);
    }
  }

  createProtectedRoutes(){
    const protectedRoutes = [];
    const protectedRoutesMap = [
      {path: '/data_management', component: DataManagement, permissions: []},
      {path: '/application_management', component: ApplicationManagement, permissions: []},
      {path: '/aml_management', component: AMLManagement, permissions: []},
      {path: '/data_room', component: DataRoom, permissions: [{feature: 'dataroom'}]},
      {path: '/data_room/:fileId', component: DataRoom, permissions: [{feature: 'dataroom'}]},
      {path: '/admin/user_permissions/:userId', component: UserPermissions, permissions: [{feature: 'userManagement'}]},
      {path: '/admin', component: UserManagement, permissions: [{feature: 'userManagement'}]},
      {path: '/auth/setup', component: TFSetup, permissions: []},
      {path: '/analytics', component: Analytics, permissions: [{feature: 'analytics', part: 'analytics'}]},
      {path: '/deal/:dealId/:section', component: DealView, permissions: [{feature: 'dealsManagement'}]},
      {path: '/deal/:dealId', component: DealView, permissions: [{feature: 'dealsManagement'}]},
      {path: '/deals_management', component: DealsMangement, permissions: [{feature: 'dealsManagement'}]},
      {path: '/vendor/:vendorId/:section', component: VendorView, permissions: [{feature: 'vendorsManagement'}]},
      {path: '/vendor/:vendorId', component: VendorView, permissions: [{feature: 'vendorsManagement'}]},
      {path: '/vendors_management', component: VendorManagement, permissions: [{feature: 'vendorsManagement'}]},
      {path: '/client/:clientId', component: ClientView, permissions: [{feature: 'clientManagement'}]},
      {path: '/client_management', component: ClientManagement, permissions: [{feature: 'clientManagement'}]},
      {path: '/database/dataset/:dataset_id/group/:group_id/:viewOnly', component: Group, permissions: [{feature: 'database', part: 'datasets'}]},
      {path: '/database/dataset/:id/:viewOnly', component: Dataset, permissions: [{feature: 'database', part: 'datasets'}]},
      {path: '/database/manage', component: ManageStatisticalDatabase, permissions: [{feature: 'database'}]},
      {path: '/database/schema/:id/:viewOnly?', component: Schema, permissions: [{feature: 'database', part: 'schemas'}]},
      {path: '/database', component: SearchStatisticalDatabase, permissions: [{feature: 'database', part: 'queryTool'}]},
      {path: '/dictionaries', component: DictionaryMangement, permissions: [{feature: 'dictionary', part: 'dictionary'}]},
      {path: '/dictionary/:dictionaryId/', component: DictionaryView, permissions: [{feature: 'dictionary', part: 'dictionary'}]}, 
      // {path: '/publications', component: Publications, permissions: [
      //   {feature: 'alerts', part: 'alerts'},
      //   {feature: 'publications', part:'publications'}
      // ], orPermissions: true},
      {path: '/verifyWithSecret', component: VerifyWithSecret, permissions: []},
      {path: '/release', component: ReleaseNotes, permissions: [{feature: 'release'}]},
      {path: '/riskRating', component: RiskRatingManagement, permissions: [{feature: 'riskRating'}]},
      {path: '/support', component: Support, permissions: []},
      // {path: '/welcome', component: Welcome, permissions: []},
    ];

    protectedRoutesMap.forEach(route=>{
      const { component, path, permissions, orPermissions = false } = route;
      protectedRoutes.push(
        <PrivateRoute
          key={path}
          user={this.props.auth.user}
          component = {component}
          path={path}
          permissions={permissions}
          orPermissions={orPermissions}
        />
      );
    });

    return protectedRoutes;
  }


  render() {
    return (
      <div>
        <Router history={history}>
          <div>
            <div id="content">
                <div>
                <Route component={Header} style="landing"/>
                  <Switch>
                    <Route exact path='/' component={()=>
                      <div>
                          <br/><br/>
                          <h2>Please Log In</h2>
                      </div>} />
                    <Route exact path='/login' component={Login}/>
                    <Route exact path='/confirmation/:id' component={Confirmation}/>
                    { this.createProtectedRoutes() }
                  </Switch>
                </div>
            </div>
          </div>
        </Router>
      </div>
    );
  }
}

function mapStateToProps(state){
  return {
    auth:state.auth
  };
}

export default connect(mapStateToProps)(App);