import React, {Component} from 'react';
import _ from 'lodash';
import axios from 'axios';
import { authChange } from '../../../actions';

class UserAgreement extends Component {

    saveUser(){
        var token = this.props.auth.token;
        var user = this.props.user;

        user.agreement = true;
        axios.post("/api/user",{
            form:{
                user: user,
                mode: "modify",
                key: this.props.auth.key
            }
        }).then((res)=>{
            if(res.data.result === "Success") {
                var auth = { uid:this.props.auth.uid, user:null, token:token };
                authChange(auth, this.props.history, this.props.dispatch);
            }
        },(err)=>{
            console.log("Error: ",err);
        });
    }

    render(){
        return (
            <div className="container" style={{marginTop:'100px'}}>
                <h1>Welcome to PPF Capital</h1>
                <h5>Agreement for PPF Capital’s Deal Room Service</h5>
                <p>
                This Agreement (“Agreement”) entered into as of the Contract Date listed on the Non-Disclosure Agreement (NDA),
                is between PPF Capital Belize Ltd., located at 381 E Street, Belize City, Belize (“PPF”) and you “the User”.
                Use of the Site constitutes an agreement with the terms and conditions outlined in the (NDA). By using the PPF
                Deal Room, all Visitors and Users do hereby represent, warrant, understand, agree to and accept and abide by
                all terms and conditions contained in the signed Non-Disclosure Agreement. By using this service you agree
                that you will not redistribute, transfer or share any information obtained in a manner inconsistent with the
                NDA.  If you object to anything in the NDA, do not use this service. </p>
                <button className="ppf-primary-button right" onClick={()=>this.saveUser()}>Agree</button>
            </div>
        );
    }
}

export default UserAgreement;