import React, { Component } from 'react';
import * as _ from 'lodash';
import { Input } from 'react-materialize';

class Radiobutton extends Component {
  constructor(props) {
    super(props);
  }

  handleChange(type, name, value) {
    this.props.onChange(type, name, value);
  }

  renderOptions() {
    const {
      disabled, inputClass, options, group, value: selectedValue
    } = this.props;

    const optionElements = [];
    _.forEach(options, (option) => {
      const {value, name} = option;
      if (selectedValue == value) {
        optionElements.push(
          <div className = {inputClass || ''} onClick={() => this.handleChange(group, name, value)}>
            <input className="with-gap" style={{ height: '1rem' }} disabled={disabled} id={name + group + value} name={group} type="radio" checked />
            <label key={name} for={name + group }>{name}</label>
          </div>
        );
      } else {
        optionElements.push(
          <div className = {inputClass || ''} onClick={() => this.handleChange(group, name, value)}>
            <input className="with-gap" style={{ height: '1rem' }} disabled={disabled} id={name + group + value} name={group} type="radio" />
            <label key={name} for={name + group}>{name}</label>
          </div>
        );
      }
    });
    return optionElements;
  }

  render() {
    const { className, params, placeholder } = this.props;
    return (
      <div className={`form-input ${className}`} style={{ marginBottom: '10px', marginTop: '5px' }}>
        {placeholder && params && <label className="left simple" title={params.tooltip}>
          {params.tooltip && (
          <i className="material-icons" style={{ color: '#000000', fontSize: '14px' }}>
            info_outline
          </i>
          )}
          {`${placeholder}:`}
        </label>}
        <div className="radio left col s12">
          {this.renderOptions()}
        </div>
      </div>
    );
  }
}

export default Radiobutton;
