import React from 'react';

const Chip = ({name, className, onRemove, disabled, placeholder}) =>{
    return(
        <div key={name} class={`chip ${className}`}>
            {placeholder}
            <i class="close material-icons" onClick={onRemove}>close</i>
        </div>
    )
};

export default Chip;