import React from 'react';
import * as _ from 'lodash';

/**
 * 
 * @param {name, value, options, onChange, className} props 
 * @returns 
 */
const Select = (props) => {
  const {
    inputClass, className, type, placeholder, name, onChange, errors, value, options, params, disabled, defaultValue, disableDefault = false, innerStyle
  } = props;
  let optionsHTML = [];
  if(!value){
    optionsHTML = [<option key={"defaultOption"} value="">{ defaultValue ? defaultValue : "Choose option"}</option>];
  } else if (!disableDefault) {
    optionsHTML = [<option key={"defaultOption"} value="">{defaultValue ? defaultValue : "Choose option"}</option>];
  }

  let defaultOption;
  _.forEach(options, (option) => {
      if(option.default){
        defaultOption = option.value;
      }
      optionsHTML.push(
        <option key={option.value+"Option"} value={option.value}>{option.name}</option>
      );
  });

  return (
    <div className={`form-input ${className}`}>
      <div className={inputClass || "col s12"} style={innerStyle || { padding: '0' }}>
        <label>
          {placeholder}
        </label>
        <select
          className= {"simple "}
          name={name}
          onChange={onChange}
          disabled={disabled}
          style={{display:"block", height:"2rem"}}
          value={value || defaultOption}
        >
            {optionsHTML}
        </select>
      </div>
      {errors && typeof errors[name] !== 'undefined' && (
        <p className="error">
          {errors[name]}
        </p>
      )}
    </div>
  );
};

export default Select;