import React, { Component } from 'react';
import axios from 'axios';
import { hasPermission } from '../../../utils/PermissionUtil';
import config from '../../../config.js';
import File from '../Home/File';
import _ from 'lodash';

class UserFileAccess extends Component{
  constructor(props){
    super(props);
    this.state = {
      level: -1,
      files: [],
      pathHistory: [],
      form: this.props.form
    };
    this.state.fileId = config.PPFCapitalDriveID;
    this.state.level++;
    this.state.pathHistory.push({level:this.state.level, fileId:this.state.fileId, fileName: 'Home'});
  }

  componentWillMount(){
    if(this.state.files.length == 0){
      this.listFiles();
    }
  }

  onSave(){
    this.props.onSave(this.state);
  }

  renderBack(){
    if(this.state.level > 0 ){
      this.state.level--;
      this.state.fileId = this.state.pathHistory[this.state.level].fileId;
      this.state.pathHistory = _.dropRight(this.state.pathHistory);
      this.listFiles();
    }
  }

  fileClick(file){
    if(typeof file.fileExtension === 'undefined'){
      this.state.fileId = file.id;
      this.state.level++;
      this.state.pathHistory.push({level:this.state.level, fileId:this.state.fileId, fileName: file.name});
      this.listFiles(file)
    }
  }

  listFiles(file) {
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.get('/api/files', {
      params:{
        dataRoomAccess: true,
        filePath: file?.path
      }
    }).then(
      (res) => {
        this.setState({files:res.data});
      })
    .catch((e)=>console.log(e));
  }

  setReadOnly(file) {
    const { files, storageFiles } = this.state.form.user;
    var form = this.state.form;
    var writableFiles = this.state.form.user.writableFiles;
    if(file.storageType != 'firebase') {
      writableFiles = _.pull(writableFiles, file.id);
    } else {
      writableFiles = _.pull(writableFiles, file.path);
    }
    form.user.writableFiles = writableFiles;

    this.setState({form}, ()=>{
      this.props.onChange(files, storageFiles, form.user.writableFiles);
    });
  }

  setWrite(file) {
    const { files, storageFiles } = this.state.form.user;
    var form = this.state.form;
    var writableFiles = form.user.writableFiles || [];
    if(file.storageType != 'firebase') {
      writableFiles.push(file.id);
    } else {
      writableFiles.push(file.path);
    }

    form.user.writableFiles = writableFiles;
    this.setState({form}, ()=>{
      this.props.onChange(files, storageFiles, form.user.writableFiles);
    });
  }

  checkboxClick(e, file) {
    const target = e.target
    const name = target.name;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.value

    var form = this.state.form;
    var files = this.state.form.user.files || [];
    var storageFiles = this.state.form.user.storageFiles || [];
    var writableFiles = this.state.form.user.writableFiles || [];
    if(file.storageType != 'firebase') {
      if(value){
        files.push(name);
      } else {
        files = _.pull(files,name);
      }
  
      form.user.files = files;
    } else {
      if(value){
        storageFiles.push(file.path);
      } else {
        storageFiles = _.pull(storageFiles, file.path);
      }
  
      form.user.storageFiles = storageFiles;
    }

    this.setState({form}, ()=>{
      this.props.onChange(files, storageFiles, writableFiles);
    });
  }

  renderTable(){
    return(
      <table>
        <thead>
          <tr>
            <th className="center">
              <div>
                <button className="btn-flat left white-text" onClick={()=>this.renderBack()}>Back</button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {this.renderFiles()}
        </tbody>
      </table>
    )
  }

  renderFiles(){
    const hasWrite = hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
    || hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write') ;
    return _.chain(this.state.files)
      .map((file) => {
        var match = false;
        var writable = false;
        _.map(this.state.form.user.files, (aFile)=>{
          if(aFile === file.id){
            match = true;
          }
        });
        _.map(this.state.form.user.storageFiles, (aFile)=>{
          if(aFile === file.path){
            match = true;
          }
        });
        _.map(this.state.form.user.writableFiles, (aFile)=>{
          if(aFile === file.path){
            writable = true;
          }
          if(aFile === file.id){
            writable = true;
          }
        });
        const disabled = !hasWrite;
        const fileClick = this.fileClick.bind(this);
        return(
          <File
            key={file.id || file.path}
            file={file}
            disabled={disabled}
            fileClick={fileClick}
            checkboxClick={this.checkboxClick.bind(this)}
            setReadOnly={this.setReadOnly.bind(this)}
            setWrite={this.setWrite.bind(this)}
            details={false}
            access={match}
            writeAccess={writable}
          />
        );
      })
      .compact()
      .value();
  }

  render() {
    return(
      <div className = "row">
        {this.renderTable()}
        {this.state.error !== '' && <p className="error blink">{this.state.error}</p>}
      </div>
    )
  }
}

export default UserFileAccess;