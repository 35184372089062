import React, {Component} from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import * as _ from 'lodash';
import firebase from 'firebase';
import FileDownload from 'js-file-download';
import { hasPermission } from '../../../../../utils/PermissionUtil';

import { uploadSingleFile, deleteFile } from '../../../../../utils/FileHandler';
import PPFView from '../../../../Generics/PPFView';
import FileUpload from '../../../../Generics/FileUpload';
import Input from '../../../../Generics/Input';
import Select from '../../../../Generics/Select';
import WithDictionary from '../../withDictionary';

class DocumentsView extends Component {
    constructor(props){
        super(props);
        
        this.selectWithDictionary = WithDictionary(Select, this.props.superProps.auth.token);

        this.state = {
            key: this.props.state.data.key,
            documents: this.props.state.data.documents || [],
            document: {}
        };
    }

    onCloseModal(){
        const { documents = [] } = this.state;
        const filteredDocuments =  documents.filter(doc=>{
            return !doc.pending;
        })

        // Clean Up state
        this.setState({modal: false, documents: filteredDocuments, document: {}});
    }

    onChange(e){
        const { document } = this.state;
        const { name, value } = e.target;

        document[name] = value

        if(document.created_date){
            document.modified_date = Date.now();
        } else {
            document.created_date = Date.now();
        }

        this.setState({document});
    }

    /**
     * Handles the action of dropping a file on the uploader
     * or selecting a file through the uploader. All files
     * start in a pending state until onUpload is called.
     *
     * @param {Event} event
     * @param {Object} document
     */
    handleUpload(event, document) {
        let { documents = [] } = this.state;
        const uploadedFiles = event.target.files;
        let value = null;

        if(uploadedFiles.length > 0){
            let files = [];
            let duplicate = false;

            for(let i = 0; i < uploadedFiles.length; i++){

                const doc = JSON.parse(JSON.stringify(document));
                const fileName = uploadedFiles.item(i).name;

                doc.pending = true;
                doc.document_name = fileName;
                doc.file = uploadedFiles.item(i)
                files.push(doc);
            }

            // Check for dupes
            if(documents.length > 0){
                documents.forEach((a, i)=>{
                    if(a.pending){
                        documents.splice(i, 1);
                        return;
                    }
                    files.forEach((b, j)=>{
                        if (a.document_name === b.document_name){
                            duplicate = true;
                            files.splice(j, 1);
                        }
                    })
                });
            };

            if(duplicate){
                alert("Duplicate file found. You cannot upload the same file twice.");
            }

            documents = documents ? documents.concat(files) : files;

            this.state.documents = documents;
            this.setState(this.state);
        } else {
            this.state.document.file = value;
            this.setState(this.state);
        }
    }

    onSave(){
        const promises = [];
        const { documents, key } = this.state;

        if(!key){
            alert('Please save record before adding documents.');
            return;
        }

        const promise = (document)=>{
            const { file, document_name } = document;
            return new Promise ((res, rej)=>{
                uploadSingleFile(firebase, key+"/images/", file, document_name, ({error, path, url})=>{
                    if(error){
                        const error = 'Error: Could not upload file. Please try again.';
                        alert(error);
                        return rej(error);
                    }

                    const updatedDocs = documents.map(docMap=>{
                        if(docMap.document_name === document.document_name){
                            docMap.path = path;
                            delete docMap.pending;
                            delete docMap.file;
                            
                            if(docMap.created_date){
                                docMap.modified_date = Date.now();
                            } else {
                                docMap.created_date = Date.now();
                            }
                        }

                        return docMap;
                    })
                    this.state.documents = updatedDocs;
                });
            });
        }

        documents.forEach(doc=>{
            if(doc.pending){
                promises.push(promise(doc));
            }
        });

        Promise.all(promises).then(res=>{
            this.props.methods.onSave(this.state, {inputs:[{name: 'documents'}]}, false, ()=>{
                this.setState({modal: false, document: {}});
            });
        }).catch(err=>{
            console.log(err);
            alert("Error uploading files");
        });
    }

    onDelete(document){
        let { documents } = this.state;
        const { path, document_name } = document;

        if(!window.confirm('This will permanently delete the file. Do you want to continue?')){
            return;
        }

        documents = documents.filter(doc=>{
            if(doc.document_name == document_name){
                deleteFile(firebase, path, () => {
                    alert('Successfully deleted file.');
                });
                return false;
            }
            return true;
        });
        
        this.props.methods.onSave({documents}, {inputs:[{name: 'documents'}]}, true, (state)=>{
            this.setState({documents: state.documents || []});
        });
    }

    onView(document){
        const data = { id: document.path };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/getFileURL", {data})
            .then(
                (res) => {
                    window.open(res.data.downloadURL);
                })
            .catch((e)=>console.log(e));
    }

    onEdit(document){
        this.setState({
            document,
            modal: true
        });
    }

    onDownload(document) {
        const {document_name , path } = document;
        const data = { id: path };
        
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/downloadRecordFile",
            {data},
            {responseType: 'arraybuffer'},
        )
            .then(
                (res) => {
                    FileDownload(res.data, document_name);
                })
            .catch((e)=>console.log(e));
    }

    renderTable(){
        const { viewOnly } = this.props.state;
        const { documents = [] } = this.state;
        const rows = [];

        if(documents == 0){
            return;
        }

        // Sort by Pending
        documents.sort((a, b) => {
            if ((a.pending || false) > (b.pending || false)) {
                return -1;
            }
            if ((a.pending || false) < (b.pending || false)) {
                return 1;
            }
            return 0;
        });

        documents.forEach((document) => {
            if(!document){
                return;
            }
            let { document_name, document_catergory = "", document_description = "", document_url, pending = false, file = null} = document;

            rows.push(
                <tr key={`${document_name}_${document_catergory}`}>
                    <td>{document_name}</td>
                    <td>{document_description}</td>
                    <td>{document_catergory.toUpperCase()}</td>
                    <td>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onDownload(document)}>Download</button>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onView(document)}>View</button>
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'documents', 'write') &&
                            <button className='ppf-primary-button padded-right' onClick={()=>this.onEdit(document)}>Edit</button>
                        }
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'documents', 'delete') &&
                            <button className='ppf-danger-button' onClick={()=>this.onDelete(document)}>Delete</button>
                        }
                    </td>
                </tr>
            );
        });

        return(
            <table key={this.props.title.toLowerCase()}>
                <thead>
                <tr>
                    <th className="center">Name</th>
                    <th className="center mobile-hide">Description</th>
                    <th className="center mobile-hide">Category</th>
                    <th className="center mobile-hide">Actions</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    renderModal(){
		if(!this.state.modal){
		  return(
			<div></div>
		  )
        }
        const { document } = this.state;
        const { document_description, document_catergory, document_name } = document;
        const SelectWithDictionary = this.selectWithDictionary;
        
		return(
		  <div id="myModal" className="modal row">
			<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			  <span className="close" onClick={()=>this.onCloseModal()}>&times;</span>
			  <h3>New Document</h3>
                <Input
                    key = "document_desc"
                    name = 'document_description'
                    className = "col s12"
                    placeholder = "Document Description"
                    handleChange = {(e) => this.onChange(e)}
                    value = {document_description}
                />
                <SelectWithDictionary
					dictionaryId='-MKQK9RLfYXp290qteV6'
                    name = "document_catergory"
                    placeholder = "Document Category"
                    value = {document_catergory}
                    onChange = {(e) => this.onChange(e)}
                    className = "col s12"
                    inputClass = "col s6"
                />
                <div>
                    <br/>
                </div>
                 {document_catergory && <FileUpload
                    hideFileName={false}
                    text= {'Add Files'}
                    placeholder= "File"
                    name = 'File'
                    className={'col s12'}
                    inputClass={"ppf-primary-button left"}
                    onChange={(e)=>this.handleUpload(e, document)}
                    multipleSupport = {false}
                    value = {{name: document_name}}
                    onFileClick = {()=>{
                        this.onView(document);
                    }}
                />}
                <button className = 'ppf-primary-button col s4 right' onClick={()=>this.onSave()}>Save</button> 
			</div>
		  </div>
		)
	}

    render(){
        const { viewOnly } = this.props.state;

        return (
            <div className="inputs row" style={{margin:"15px"}}>
                {this.renderModal()}
                <h5>{this.props.title}</h5>
                {this.renderTable()}
                {
                    !viewOnly 
                    && <button className='ppf-primary-button right' onClick={()=>this.setState({modal: true})}>Add File</button>
                }
            </div>
        );
    }
}

export default DocumentsView;