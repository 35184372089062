import React, {Component} from 'react'

const template = [{
    title: 'General Questions',
    inputs: [
        {name: "q1", type: "question", text:"Are you a non-resident?", className: "left-align"},
        {name: "q2", type: "question", text:"Are you involved in dealing in high value items?", className: "left-align"},
        {name: "q5", type: "question", text:"Have you ever been refused entry to a foreign country?", className: "left-align"},
        {name: "q6", type: "question", text:"Have you ever been declared bankrupt?", className: "left-align"},
        {name: "q7", type: "question", text:"Have you ever been indicted or convicted of a criminal offense?", className: "left-align"}
    ],
    pageButtons:[
        { name: 'save' }
    ]
},
{
    title: 'PEP Questions',
    inputs: [
        {
            name: 'message',
            type: 'message',
            text: 'Do you, any family member or close associate currently hold or have once held any of the following positions by Belize or by a country other than Belize? (See Guidance Notes)',
            className: "left-align",
            popupIcon: <a><i className="material-icons yellow-text text-darken-1">lightbulb</i></a>,
            popupComponent: ()=>{
                return (
                    <div className = 'row'>
                        <h5>Guidance Notes</h5>
                        <ol className='col s12'>
                            <li className='col s12'>
                                <div className='col s12'>
                                    <span>Family members of such an individual include the following:</span>
                                    <ul className='col s12'>
                                        <li className='left' style={{listStyleType: 'circle'}}>The spouse or common-law or co-habitant of such an individual</li>
                                        <li className='left' style={{listStyleType: 'circle'}}>The child (birth, adopted or through marriage) of such an individual</li>
                                        <li className='left' style={{listStyleType: 'circle'}}>The mother or father (birth, adopted or through marriage) of such an individual</li>
                                        <li className='left' style={{listStyleType: 'circle'}}>The mother or father (birth, adopted or through marriage) of the spouse of such an individual</li>
                                        <li className='left' style={{listStyleType: 'circle'}}>The brother, sister, half-brother or half-sister of such an individual</li>
                                        <li className='left' style={{listStyleType: 'circle'}}>Any other close family member of such an individual</li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                A close associate of such an individual would be any natural person who commonly benefits from the assets or from an established business relationship or from another form of close business contact with the individual.
                            </li>
                            <li>
                                A State-Owned Corporation is any company or organization where the Government has 50% or more shareholding.
                            </li>
                        </ol>
                    </div>
                );
            }
        },
        {name: "pep1", type: "question", text:"Head of State or Government, Government Minister, Deputy or Assistant Government Minister", className: "left-align"},
        {name: "pep2", type: "question", text:"Senior Political position (e.g. Member of Parliament, Standard Bearer, House Speaker, Area Representative)", className: "left-align"},
        {name: "pep3", type: "question", text:"Senior Government position (e.g. Minister, CEO)", className: "left-align"},
        {name: "pep4", type: "question", text:"Judicial or Legislative Official (e.g. Judge, Magistrate)", className: "left-align"},
        {name: "pep5", type: "question", text:"Military Official with rank of General or above (e.g. Brigadier General, Chief of Staff, Commandant) ", className: "left-align"},
        {name: "pep6", type: "question", text:"Important Political Party Official (e.g. Party Chairman or Campaign Manager)", className: "left-align"},
        {name: "pep7", type: "question", text:"Ambassador or Chargés D’ Affairs or counselor of an ambassador (does NOT include Foreign Consular)", className: "left-align"},
        {name: "pep8", type: "question", text:"Members of Courts of Auditors or Boards of Central Banks", className: "left-align"}
    ],
}];


export default template;