//import twitter from 'twitter';
import React, {Component} from 'react';
import PublicationItem from './PublicationItem';
import { successWrapper } from '../../utils/SuccessWrapper';
import axios from 'axios';
import _ from 'lodash';

class PublicationsComponent extends Component{
  constructor(props){
    super(props);
    this.state={publications:[]};
  }
  componentDidMount(){
    axios.get('/api/getPublications')
      .then(
      (res) => {
        successWrapper(res, (data) => {
          this.setState({publications: data});
      });
      })
    .catch((e)=>console.log(e));
  }
  
  renderPublications(){
    return _.map(this.state.publications, (publication) => {
      var numCol = 12/_.size(this.state.publications);
      numCol = (numCol < 3) ? 4 : numCol;
      this.class = "darken col s12 l"+ numCol;
      this.style={backgroundImage:"url("+publication.imageURL+")", backgroundSize:'cover'};
      return(
        <PublicationItem key={publication.title} publication={publication} class={this.class} style={this.style}/>
      );
    });
  }
  
  render() {
    return(
      <div className="col l12 left"  style={{padding:'0px'}}>
        <h1 className="center">Publications</h1>
        <div className="publications col l12">
          {this.renderPublications()}
        </div>
      </div>
    )
  }
}

export default PublicationsComponent;