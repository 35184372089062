//import twitter from 'twitter';
import React, {Component} from 'react';
import Tweet from './Tweet';
import axios from 'axios';
import _ from 'lodash';

class TwitterComponent extends Component{
  constructor(props){
    super(props);
    this.state={tweets:[]};
  }

  componentDidMount(){
    axios.get('/api/tweets')
      .then(
      (res) => {
        //this.setState({tweets:res.data});
      })
    .catch((e)=>console.log(e));
  }

  renderTweets(){
    return _.map(this.state.tweets, (tweet) => {
        return(
          <Tweet key={tweet.id} tweet={tweet}/>
        );
      });
  }

  render() {
    return(
      <div className="content">
        <div style={{textAlign:"center"}}>
          <h4>Follow us <a href="https://twitter.com/intent/user?screen_name=ppfcapital">@PPFCapital</a></h4>
        </div>
        {this.renderTweets()}
      </div>
    )
  }
}

export default TwitterComponent;