import React, { Component } from 'react';
import Record from './Record';
import axios from 'axios';
import _ from 'lodash';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux'
import Select from '../../Generics/Select';
import { getRecordName } from '../../../utils/Formatter';
import WithDictionary from './withDictionary';

class DealsMangement extends Component {
	constructor(props) {
		super(props);

		this.state = {
			modal: false,
			filters: {
				deal_type: '',
				activeOnly: 'yes'
			},
			records: []
		};
	}

	componentDidMount() {
		this.getRecords();
	}

	getRecords() {
		const { searchText } = this.state.filters;
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post('/api/deals', { data: { searchText } })
			.then(
				(res) => {
					this.state.records = res.data;
					this.setState(this.state);
				})
			.catch((e) => console.log(e));
	}


	handleDropdown(e) {
		const name = e.target.name;
		let value = e.target.value;

		this.state.filters[name] = value;
		this.setState(this.state);
	}

	renderRecords() {
		const { history } = this.props;
		let records = [];
		const { deal_type = '',  activeOnly = ''} = this.state.filters;

		_.forEach(this.state.records, function (value, key) {
			var form = { deal: value, key };
			records.push(form);
		});

		return _.chain(records)
			.map((form) => {
				if (
					(
						deal_type === ''
						|| form.deal.deal_type === deal_type
					)
					&& 
					(
						activeOnly === ''
						|| form.deal.is_active === activeOnly
					)
				) {
					return (
						<Record key={form.key} deal={form} history={history} />
					);
				}
			})
			.compact()
			.value();
	}

	renderTable() {
		return (
			<table>
				<thead>
					<tr>
						<th className="center">Deal Name</th>
						<th className="center mobile-hide">Type</th>
						<th className="center mobile-hide">Is Active</th>
						<th className="center mobile-hide">Date</th>
					</tr>
				</thead>
				<tbody>
					{this.renderRecords()}
				</tbody>
			</table>
		)
	}

	filter(searchText) {
		this.state.filters.searchText = searchText;
		this.setState(this.state, () => {
			this.getRecords();
		});
	}

	render() {
		const { filters } = this.state;
		const { deal_type, activeOnly } = filters;
		const SelectWithDictionary = WithDictionary(Select, this.props.auth.token);

		return (
			<div className = 'row' style={{marginTop: '7%'}}>
				<div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
					<button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
				</div>
				<div className="container row">
					<h2>Deals Management</h2>
					<div className="filters">
						<div className='col s12' style={{ paddingBottom: "10px" }}>
						</div>
						<div className="col s3">
							<SelectWithDictionary
								dictionaryId='-MK2cO7cLgqQn4rJIQUd'
								key="dealTypeFilter"
								placeholder="Deal Type"
								name="deal_type"
								value={deal_type}
								errors={[]}
								onChange={(e) => this.handleDropdown(e)}
							/>
						</div>
						<div className="col s3">
							<Select
								key="activeOnly"
								placeholder="Is Active"
								name="activeOnly"
								value={activeOnly}
								errors={[]}
								options={[
									{ name: "Yes", value: "yes" },
									{ name: "No", value: "no" },
								]}
								onChange={(e) => this.handleDropdown(e)}
							/>
						</div>
					</div>
					{this.renderTable()}
					<div className="row">
						<div className="col l8">
						</div>
						<div className="col l4 s12">
							<Link to="/deal/new">
								<button
									className="ppf-primary-button right col s12"
								>New Deal</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}


function mapStateToProps(state, { history }) {
	return {
		auth: state.auth,
		files: state.files
	};
}

export default connect(mapStateToProps)(DealsMangement);