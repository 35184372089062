import React, {Component} from 'react';
import { Navbar, NavItem, Dropdown, Divider } from 'react-materialize';
import { hasPermission } from '../../../utils/PermissionUtil';

const style = {
    slideOut:{
        transform: "translateX(0%)",
        position: "relative",
        height: "99vh",
        width: "auto",
        marginRight: "35px"
    },
}

class SideNav extends Component{
    render(){
        const optionsElements = [];
        const { data, selected } = this.props;
        const { dealGroup: group, name, key } = data;

        const map = [
            {id: 'general', icon: 'folder_open', title: 'General'},
            {id: 'documents', icon: 'insert_drive_file', title: 'Documents'},
            {id: 'charges', icon: 'attach_money', title: 'Charges'},
            {id: 'contacts', icon: 'contact_phone', title: 'Contacts'}
        ];

        let options = map;
        options.forEach(({id, title, icon}) => {
            const canEdit = hasPermission(this.props.superProps.auth.user, 'vendorsManagement', id);
            if(!canEdit){
                return;
            }
            optionsElements.push(
                <li
                    onClick={ () => this.props.onChange(id) }
                    style={
                    id == selected ?
                        { backgroundColor: 'rgba(0,0,0,0.05)' }
                        : {}}
                >
                    <a>
                        <i class="material-icons">{icon}</i>
                        <span className ='hide-on-small-only'>{title}</span>
                    </a>
                </li>
            );
        });

        return(
            <div className="row">
                 <ul id="slide-out" class="side-nav"
                    style={{ transform: 'translateX(0px)', zIndex:'5', paddingTop: '65px', width: 'auto'}}>
                    <li>
                        <div class="user-view hide-on-small-only" style={{backgroundColor:'#4051B5'}}>
                            <span class="white-text name">{name}</span>
                            <span class="white-text email">{key}</span>
                        </div>
                        {optionsElements}
                    </li>
                </ul>
            </div>
        );
    }
}

export default SideNav;