import React from 'react'
import _ from 'lodash';

export default function RiskRatings({ratings, onClick, current}) {
    let ratingElements = [];

    if (!ratings) {
        return <div></div>;
    }
    _.forEach(ratings, (rating) => {
        const { created_at, created_by_username, data: {riskScore, riskLevel} } = rating;
        const formattedDate = new Date(created_at);
        ratingElements.push(
            <tr
                className={
                    (current ? current.created_at : '') 
                    == created_at 
                        ? "selected" : ""
                }
                onClick={() => onClick(rating)}
            >
                <td className="rating-row">
                    {`${formattedDate.toDateString()}`}
                </td>
                <td className="rating-row">
                    {riskScore}
                </td>
                <td className="rating-row">
                    {riskLevel.toUpperCase()}
                </td> 
                <td className="rating-row">
                    {created_by_username}
                </td>
            </tr>
        );
    });

    if (ratings.length === 0) {
        ratingElements.push(<tr><td>No ratings have been run for this customer.</td></tr>);
    }

    return (
        <div className="row" style={{paddingTop: '25px'}}>
            <h4>Risk Ratings</h4>
            <table className="striped" >
                <tbody>
                    {ratingElements}
                </tbody>
            </table>
        </div>
    );
}
