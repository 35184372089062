import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import uid from 'uid';
import * as _ from 'lodash';
import firebase from 'firebase';
import FileDownload from 'js-file-download';
import { hasPermission } from '../../../../../../utils/PermissionUtil';

import { uploadSingleFile, deleteFile } from '../../../../../../utils/FileHandler';
import PPFView from '../../../../../Generics/PPFView';
import estimateFields from '../Templates/fixedRateEstimateFields';
import rateBasedEstimateFields from '../Templates/rateBasedEstimateFields.js'
import EstimateHistory from './EstimateHistory';

class EstimatesView extends Component {
    constructor(props) {
        super(props);

        this.onSave = this.onSave.bind(this);
        this.onAddEstimate = this.onAddEstimate.bind(this);

        this.state = {
            key: this.props.state.data.key,
            estimates: this.props.state.data.estimates || [],
            estimate: {}
        };
    }

    onCloseModal() {
        const { estimates = [] } = this.state;
        const filteredEstimates = estimates.filter(doc => {
            return !doc.pending;
        })

        // Clean Up state
        this.setState({ modal: false, estimates: filteredEstimates, estimate: {} });
    }

    onChange(e) {
        const { estimate } = this.state;
        const { name, value } = e.target;

        estimate[name] = value;

        if (estimate.created_date) {
            estimate.modified_date = Date.now();
        } else {
            estimate.created_date = Date.now();
        }
        this.setState({ estimate });
    }

    onSave(estimate, options, changed) {
        const promises = [];
        const { estimates, key, original_estimate } = this.state;

        if (!estimate.key) {
            const id = uid(25);
            estimate.key = id;
            estimate.created_date = Date.now();
            estimates.push(estimate);
        } else {
            // Create history record if estimate amount changes
            if(changed.estimate_amount){
                const { estimate_history = [] } = estimate;
                original_estimate.outdated_date = Date.now();
                estimate_history.push(original_estimate);
                estimate.estimate_history = estimate_history;
            }
        }

        if (!key) {
            alert('Please save record before adding estimates.');
            return;
        }

        const fileUploadPromise = (estimateData) => {
            const { key, estimate } = estimateData;
            const file = estimate.files[0];

            return new Promise((res, rej) => {
                uploadSingleFile(firebase, key + "/images/", file, "estimate", ({ error, path, url }) => {
                    if (error) {
                        const error = 'Error: Could not upload file. Please try again.';
                        alert(error);
                        return rej(error);
                    }

                    const updatedDocs = estimates.map(docMap => {
                        if (docMap.key === estimateData.key) {
                            docMap.path = path;
                            delete docMap.estimate;
                        }

                        return docMap;
                    })
                    this.state.estimates = updatedDocs;
                });
            });
        }

        estimates.forEach(doc => {
            if (doc.estimate) {
                promises.unshift(fileUploadPromise(doc));
            }
        });

        Promise.all(promises).then(res => {
            this.props.methods.onSave(this.state, { inputs: [{ name: 'estimates' }] }, false, () => {
                this.setState({ modal: false, estimate: {}, original_estimate: undefined });
            });
        }).catch(err => {
            console.log(err);
            alert("Error uploading files");
        });
    }

    onDelete(estimate) {
        let { estimates } = this.state;
        const { path, key } = estimate;

        if (!window.confirm('This will permanently delete the file. Do you want to continue?')) {
            return;
        }

        estimates = estimates.filter(doc => {
            if (doc.key == key) {
                if (path) {
                    deleteFile(firebase, path, () => {
                        alert('Successfully deleted file.');
                    });
                }
                return false;
            }
            return true;
        });

        this.props.methods.onSave({ estimates }, { inputs: [{ name: 'estimates' }] }, true, (state) => {
            this.setState({ estimates: state.estimates || [] });
        });
    }

    onAddEstimate() {
        this.setState({ modal: true, modalViewOnly: false, rateType: undefined });
    }

    onEdit(estimate, modalViewOnly) {
        const rateType = estimate.rate_type || 'fixed';
        this.setState({
            rateType,
            estimate,
            modal: true,
            modalViewOnly,
            original_estimate: JSON.parse(JSON.stringify(estimate))
        });
    }

    onDownload(estimate) {
        const { path } = estimate;
        const fileName = path ? path.split(/[\\\/]/).pop() : '';
        const data = { id: path };

        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/downloadRecordFile",
            { data },
            { responseType: 'arraybuffer' },
        )
            .then(
                (res) => {
                    FileDownload(res.data, fileName);
                })
            .catch((e) => console.log(e));
    }

    fetchDictionary(dictionaryId) {
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        let data = { key: dictionaryId };
        axios.post('/api/getDictionary', {
            data
        }).then(
            (res) => {
                if (res.data.error) {
                    return alert(res.data.error);
                }
                this.setState({ taxDictionary: res.data.options });
            })
            .catch((e) => {
                this.setState({ data: [] });
                console.log(e);
            });
    }

    renderTable() {
        const { viewOnly } = this.props.state;
        const { estimates = [] } = this.state;
        const rows = [];

        if (estimates.length == 0) {
            return;
        }
        // Sort by Pending
        estimates.sort((a, b) => {
            if ((a.pending || false) > (b.pending || false)) {
                return -1;
            }
            if ((a.pending || false) < (b.pending || false)) {
                return 1;
            }
            return 0;
        });

        estimates.forEach((estimate) => {
            if (!estimate) {
                return;
            }
            let { estimate_name, estimate_amount, created_date, path } = estimate;
            const createdDate = new Date(created_date).toLocaleDateString();
            rows.push(
                <tr key={`${estimate_name}`}>
                    <td>{estimate_name}</td>
                    <td>{estimate_amount}</td>
                    <td>{createdDate}</td>
                    <td>
                        {path && <button className='ppf-primary-button padded-right' onClick={() => this.onDownload(estimate)}>Download Estimate</button>}
                        <button className='ppf-primary-button padded-right' onClick={() => this.onEdit(estimate, true)}>View</button>
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'estimates', 'write') &&
                            <button className='ppf-primary-button padded-right' onClick={() => this.onEdit(estimate)}>Edit</button>
                        }
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'dealsManagement', 'estimates', 'delete') &&
                            <button className='ppf-danger-button' onClick={() => this.onDelete(estimate)}>Delete</button>
                        }
                    </td>
                </tr>
            );
        });

        return (
            <table key={this.props.title.toLowerCase()}>
                <thead>
                    <tr>
                        <th className="center">Name</th>
                        <th className="center mobile-hide">Amount</th>
                        <th className="center mobile-hide">Created Date</th>
                        <th className="center mobile-hide">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    handleEstimateCalc(newState){
        const { taxDictionary } = this.state;

        if (!taxDictionary) {
            this.fetchDictionary('-MKQQe4zg0JtW5aDdgFq');
            return;
        }

        const amount = parseFloat(newState.estimate_amount);
        if (amount > 0 && newState.tax_type) {
            const taxItem = taxDictionary.find(x => x.value == newState.tax_type);

            if (!taxItem) {
                alert('Could not find associated tax type and value.')
                return;
            }

            if (isNaN(parseFloat(taxItem.tracked_value))) {
                alert('Invalid tax rate set in dictionary! Value must be a number or decimal.');
                return;
            }

            newState.estimate_estimate = (amount * taxItem.tracked_value) + amount;
        } else {
            newState.estimate_estimate = amount;
        }

        return newState;
    }

    handleMilestoneChange(state){
        const { milestones } = this.state;
        const { estimate_milestone } = state;

        if(!estimate_milestone){
            return state;
        }

        const match = milestones.find(x=>x.data.key == estimate_milestone);
        if(match){
            state.due_date = match.data.due_date;
        }

        return state;
    }

    handleRateUnitChange(newState, template){
        let max = 0;
        let amount = 0;
        const { inputs = [] } = template[0];

        inputs.forEach(({ name }) => {
            if (name.includes('estimate_rate_')) {
                const estimateCount = parseInt(name.replace('estimate_rate_', ''));
                const rate = parseFloat(newState[`estimate_rate_${estimateCount}`]);
                const units = parseFloat(newState[`estimate_units_total_${estimateCount}`]);
                if(units > 0 && rate > 0){
                    amount += rate * units;
                }
                if (estimateCount > max) {
                    max = estimateCount;
                }
            }
        });

        if (max > 0) {
            const val = newState['estimate_units_total_' + max.toString()];
            const next = max + 1;
            const nextValRate = 'estimate_rate_' + next.toString();
            const nextValUnits = 'estimate_units_total_' + next.toString();

            if(val > 0){
                template[0]['inputs'].splice(
                    4 + (max * 2), 0, 
                    { name: nextValRate, type: "select", placeholder: `Rate ${max+1}`, className: "col s6", dictionaryId: '-MLDmfftbdcZFTcOv361'},
                    { name: nextValUnits, type: "number", placeholder: `Unit Amount ${max+1}`, className: "col s6", value: ""}
                );
            }
            newState.template = template;
        }
        newState.estimate_amount = amount;

        return newState;
    }

    renderModal() {
        if (!this.state.modal) {
            return (
                <div></div>
            )
        }

        let inputs;
        const { estimate, modalViewOnly = false, taxDictionary, rateType, milestones } = this.state;

        const state = {
            milestones,
            taxDictionary,
            data: estimate,
            viewOnly: modalViewOnly || estimate.outdated_date
        };
        
        if (!taxDictionary) {
            this.fetchDictionary('-MKQQe4zg0JtW5aDdgFq');
            return;
        }

        if (!rateType) {
            inputs = <div className="center">
                <h3>Choose Estimate Rate</h3>
                <button className="ppf-primary-button col s6 offset-s3" onClick={() => this.setState({ rateType: 'fixed' })}>
                    Fixed Amount
                </button>
                <button className="ppf-primary-button col s6 offset-s3" onClick={() => this.setState({ rateType: 'rate' })}>
                    Rate Based Amount
                </button>
            </div>;
        } else {
            const methods = {
                onRefresh: () => { },
                onSave: (estimate, options, changed) => this.onSave(estimate, options, changed),
            }

            if (rateType == 'fixed') {
                // Cutom change callback for Fixed
                methods.onChange = (options, cb) => {
                    let { newState } = options;
                    newState = this.handleEstimateCalc(newState);
                    newState = this.handleMilestoneChange(newState);

                    cb(newState);
                };

                state.data.rate_type = 'fixed';
                inputs = <PPFView
                    key={'estimate-modal'}
                    superProps={this.props.superProps}
                    template={estimateFields(methods, state)}
                    state={state}
                    methods={methods}
                />;
            } else if (rateType == 'rate') {

                // Cutom change callback for Rate based
                methods.onChange = (options, cb) => {
                    let { newState, template } = options;
                    newState = this.handleRateUnitChange(newState, template)
                    newState = this.handleEstimateCalc(newState);
                    newState = this.handleMilestoneChange(newState);

                    cb(newState);
                };

                state.data.rate_type = 'rate';
                inputs = <PPFView
                    key={'estimate-modal'}
                    superProps={this.props.superProps}
                    template={rateBasedEstimateFields(methods, state)}
                    state={state}
                    methods={methods}
                />;
            }
        }

        return (
            <div id="myModal" className="modal row">
                <div className="modal-content form col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={() => this.onCloseModal()}>&times;</span>
                    {inputs}
                    <EstimateHistory estimates={estimate.estimate_history || []} estimate={estimate}/>
                </div>
            </div>
        )
    }

    render() {
        const { viewOnly, data } = this.props.state;
        const { milestones } = this.state;

        if(!milestones){
            axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
            axios.post("/api/getMilestones", {dealId: data.key})
                .then(
                    (res) => {
                        this.setState({milestones: res.data.milestones});
                    })
                .catch((e)=>console.log(e));

            return <div>Loading...</div>;
        }


        return (
            <div className="inputs row" style={{ margin: "15px" }}>
                {this.renderModal()}
                <h5>{this.props.title}</h5>
                {this.renderTable()}
                {
                    !viewOnly
                    && <button className='ppf-primary-button right' onClick={this.onAddEstimate}>Add Estimate</button>
                }
            </div>
        );
    }
}

export default EstimatesView;