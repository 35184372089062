import React, {Component} from 'react'
import _ from 'lodash';

class User extends Component{

  render() {
    const { dictionary, key } = this.props.dictionary;
    const { name = "NO DATA", description, modified_date, components = 'N/A' } = dictionary;
    const modifiedDate = modified_date ? new Date(modified_date).toLocaleDateString() : 'N/A';

    return(
        <tr>
            <td className="center">
                <div className="row" style={{margin:"0px"}}
                  onClick={()=>{
                    this.props.history.push(
                      {
                        pathname: `/dictionary/${key}` ,
                        state: {key: this.props.dictionary.key}
                      }
                  )}}>
                  {name}
                </div>
            </td>
            <td className="center mobile-hide">{components}</td>
            <td className="center mobile-hide">{description}</td>
            <td className="center mobile-hide">{modifiedDate}</td>
        </tr>
    )
  }
}

export default User;