import React, { Component } from 'react';
import User from './User';
import _ from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux'
import UserPermissions from './UserPermissions';
import { hasPermission } from '../../../utils/PermissionUtil';

class Admin extends Component {
  constructor(props){
    super(props); 
    this.state = {modal:false, searchText:"",users:null};
  }

  componentDidMount(){
    this.getUsers();
  }

  getUsers(){
    const { clientId = '' } = this.props;
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.get('/api/users?clientId='+clientId)
      .then(
      (res) => {
        this.setState({users:res.data});
      })
    .catch((e)=>console.log(e));
  }

  renderUsers(){
    var users = [];

    _.forEach(this.state.users, function(value, key) {
      var form = {user:value, key:key}
      users.push(form);
    });

    return _.chain(users)
      .map((form) => {
        if(form.user.username.includes(this.state.searchText)){
          return(
            <User
              key={form.key}
              user={form.user}
              onClick={()=>{
                if(hasPermission(this.props.auth.user, 'userManagement')){
                  this.props.history.push(`/admin/user_permissions/${form.key}`);
                }
              }}/>
          );
        };
      })
      .compact()
      .value();
  }
  renderTable(){
    return(
      <table>
        <thead>
          <tr>
            <th className="center">Username</th>
            <th className="center mobile-hide">Created By</th>
            <th className="center mobile-hide">Modified By</th>
          </tr>
        </thead>
        <tbody>
          {this.renderUsers()}
        </tbody>
      </table>
    )
  }

  filter(searchText){
    this.setState({searchText:searchText});
  }

  setModal(mode,user){
    this.setState({modal:mode,form:user});
    if(!mode){
      this.getUsers();
    }
  }

  renderModal(){
    if(!this.state.modal){
      return(
        <div></div>
      )
    }
    return(
      <div id="myModal" className="modal row">
        <div className="modal-content form col l4 s10 offset-s1 offset-l4">
          <span className="close" onClick={()=>this.setModal(false,null)}>&times;</span>
        </div>
      </div>
    )
  }
  render(){
    const { viewOnly = false } = this.props;
    return(
      <div className = 'row' style={{marginTop: '7%'}}>
        <div className = 'col s12' style={{ paddingLeft: '5%', paddingBottom: '20px'  }}>
            <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
        </div>
        {this.renderModal()}
        <div className="container row">
          {!viewOnly && <h2>Admin Portal</h2>}
          {viewOnly && <h5>Users</h5>}
          {!viewOnly && <div className="col s12 l4 offset-l8">
              <input className="col l10 s10" id="seachInput" type="text" placeholder="Enter User Name"
                      onInput={() => this.filter(document.getElementById("seachInput").value)}/>
              <img className="col l2 s2"style={{ marginTop: '10px'}} src="/static/images/search-icon.png"/>
          </div>}
          {this.renderTable()}
          <div className="row">
            <div className="col l8">
            </div>
            <div className="col l4 s12">
              {
                hasPermission(this.props.auth.user, 'userManagement')
                && !viewOnly
                && <button
                  className="ppf-primary-button right col s12"
                  onClick={()=>this.props.history.push('/admin/user_permissions/new')}
                >Create User</button>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}


function mapStateToProps(state,{ history }){
  return {
    auth:state.auth
  };
}

export default connect(mapStateToProps)(Admin);