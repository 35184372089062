import React from 'react';
import {
	BarChart, Bar, XAxis, YAxis, CartesianGrid, Cell, Text
} from 'recharts';

const CustomizedLabel = props => {
	// eslint-disable-next-line react/prop-types
	const truncLength = 13;
	const { x, y, payload } = props;
	let value = payload.value.length > truncLength 
		? `${payload.value.slice(0, truncLength)}..`
		: payload.value
	value= value.toUpperCase().replace(/_/g, " ");

	return (
	  <Text
		x={x}
		y={y + 10}
		fill="#002960"
		fontSize={11}
		textAnchor="middle"
	  >{value}</Text>
	);
  };

export default function RiskProfileBarChart({ data, overall_rating, category, }) {
	return (
		<div className="row">
			<h2 style={{ textAlign: "center" }}>Risk Profile</h2><br />
			<div className="row container" style={{ height: "25em", width: "100%" }}>
				<div className="col s8" style={{ height: "100%" }}>
					<BarChart width={800} height={400} data={data}>
						<CartesianGrid strokeDasharray="3 3" />
						<XAxis dataKey="name" tick={<CustomizedLabel/>} interval ={0}/>
						<YAxis type="number" domain={[0,3]} allowDecimals={false} />
						<Bar dataKey="value">
							{
								data.map((entry, index) => (
									<Cell fill={entry.value == 3 ? "#E8707F" : entry.value == 2 ? "#FDD492" : "#A2CE72"} />
								))
							}
						</Bar>
					</BarChart>
				</div>
				<div className="col s4 center">
					<br />
					<h1>{overall_rating}</h1>
					<h3>{category}</h3><br />
				</div>
			</div>
		</div>
	);
}