import units from 'convert-units';
import currency from 'currency-codes'

const baseTypes = [
    {name:'Currency', value:'currency'},
    {name:'Measurement', value:'measurement'},
    {name:'Number', value:'number'}
];

const formatMeasure = (measure) =>{
    const value = measure.toLowerCase().replace(' ', '_');
    const name = measure.substr(0, 1).toLocaleUpperCase() + measure.substr(1, measure.length);
    return {name, value};
}

const measureTypes = units().measures().map((measure)=>{
    return formatMeasure(measure);
});

const getMeasures = (measureClass) => {
    if(!measureClass){
        return [];
    }
    return units().possibilities(measureClass).map((measure)=>{
        return formatMeasure(measure);
    });
};

const currencies = currency.codes().map((code)=>{
    const name = code;
    const value = code.toLowerCase();
    return {name, value};
});

export { baseTypes, measureTypes, getMeasures, currencies };