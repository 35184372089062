import React, { Component } from 'react';

class Support extends Component {
    render() {
        return (
            <div className = 'container center' style = {{marginTop: '7%'}}>
                <h3>PPF Capital Support</h3>
                <iframe>
                    <script
                        data-jsd-embedded
                        data-key="ec14744c-19e6-4d0d-b6d4-2c84485269f4"
                        data-base-url="https://jsd-widget.atlassian.com"
                        src="https://jsd-widget.atlassian.com/assets/embed.js"/>
                </iframe>
            </div>
        );
    }
}

export default Support;