import React, {Component} from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import * as _ from 'lodash';
import FileDownload from 'js-file-download';
import { hasPermission } from '../../../../../utils/PermissionUtil';

import { uploadSingleFile, deleteFile } from '../../../../../utils/FileHandler';
import PPFView from '../../../../Generics/PPFView';
import FileUpload from '../../../../Generics/FileUpload';

class DocumentsView extends Component {
    static getTemplate(){
        let inputs = [
            {name: 'articles_of_association', placeholder: 'Articles Of Association', type: 'uploader'},
            {name: 'memo_of_association', placeholder: 'Memo Of Association', type: 'uploader'},
            {name: 'certificate_of_incorporation', placeholder: 'Certificate Of Incorporation', type: 'uploader'},
            {name: 'proof_of_address', placeholder: 'Proof Of Address', type: 'uploader'},
            {name: 'annual_returns', placeholder: 'Annual Returns', type: 'uploader'},
            {name: 'proof_of_address', placeholder: 'Proof Of Address', type: 'uploader'},
            {name: 'proof_of_identity', placeholder: 'Proof Of Identity', type: 'uploader'},
            {name: 'other', placeholder: 'Other', type: 'uploader'}
        ];

        return [
            {
                grouped: true,
                title: "Documents",
                props: "fileMap",
                template: {
                    inputs
                }
            }
        ];
    }

    constructor(props){
        super(props);

        this.state = {
            data: this.props.state.data || {},
            mapping: this.props.mapping || []
        };
    }

    /**
     * Handles the action of dropping a file on the uploader
     * or selecting a file through the uploader. All files
     * start in a pending state until onUpload is called.
     *
     * @param {Event} event
     * @param {String} key
     */
    handleUpload(event, key) {
        let { documents = [] } = this.state.data;
        const uploadedFiles = event.target.files;
        let value = null;
        if(uploadedFiles.length > 0){
            let files = [];
            let duplicate = false;
            const fileCat = key.replace(/_/g, ' ').toUpperCase();

            for(let i = 0; i < uploadedFiles.length; i++){
                const fileName = uploadedFiles.item(i).name;

                files.push({
                    name: fileName,
                    type: fileCat,
                    key,
                    pending: true,
                    file: uploadedFiles.item(i)
                });
            }

            // Check for dupes
            if(documents.length > 0){
                documents.forEach((a)=>{
                    files.forEach((b)=>{
                        if (a.name === b.name){
                            duplicate = true;
                        }
                    })
                });
            };

            if(duplicate){
                alert("Duplicate file found. You cannot upload the same file twice.");
                return;
            } else {
                // Merge new upload and previos upload
                documents = documents ? documents.concat(files) : files;
            }

            this.state.data.documents = documents;
            this.setState(this.state);
        } else {
            this.state[key] = value;
            this.setState(this.state);
        }
    }

    /**
     * Uses firebase api to upload the raw file.
     * Creates a mapping using the file name and file type
     * to keep track of file details from storage.
     *
     * @param {File} file
     * @param {String} type
     */
    onUpload(typeKey, name, file, type){
        const { firebase } = this.props.superProps;
        const { documents, key, fileMap = [] } = this.state.data;

        uploadSingleFile(firebase, key+"/images/", file, type, ({error, path, })=>{
            if(error){
                alert('Error: Could not upload file. Please try again.');
                return;
            }

            const exists = fileMap.find(x =>
                x.name == name
                && x.type == type
            );

            if(!exists){
                fileMap.push({name, type, id: typeKey});
            }

            const id = path;
            const updatedDocs = documents.map(doc=>{
                if(doc.name == name){
                    return { id, name };
                }
                return doc;
            });
            this.state.data.fileMap = fileMap;
            this.state.data.documents = updatedDocs;
            this.setState(this.state, ()=>{
                this.props.methods.onSave(this.state.data, {inputs:[{name: 'fileMap'}]});
            });
        });
    }

    /**
     * Removes a file from the documents list.
     * The file must be in pending state.
     *
     * @param {String} name
     */
    onRemoveFile(name){
        const { documents } = this.state.data;

        const filtered = documents.filter((doc, i)=>{
            if(doc.pending && doc.name == name){
                return false;
            }
            return true
        });

        this.state.data.documents = filtered;
        this.setState(this.state);
    }

    onDelete(path, name){
        let { fileMap = [] } = this.state.data;
        const { firebase } = this.props.superProps;
        if(window.confirm('This will permanently delete the file. Do you want to continue?')){
            deleteFile(firebase, path, () => {
                fileMap = fileMap.filter(x => x.name != name);
                alert('Successfully deleted file.');
                this.props.methods.onSave({fileMap}, {inputs:[{name: 'fileMap'}]}, true, (state)=>{
                    this.setState({data: state});
                });
            });
        }
    }

    onView(id, download = false){
        const data = { id };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/getFileURL", {data})
            .then(
                (res) => {
                    window.open(res.data.downloadURL);
                })
            .catch((e)=>console.log(e));
    }

    onDownload(id, name) {
        const data = { id };
        axios.defaults.headers.common['Authorization'] = this.props.superProps.auth.token;
        axios.post("/api/downloadRecordFile",
            {data},
            {responseType: 'arraybuffer'},
        )
            .then(
                (res) => {
                    FileDownload(res.data, name);
                })
            .catch((e)=>console.log(e));
    }

    renderFileUploaders(){
        const { mapping = [] } = this.state;
        const uploadElements = [];

        mapping.forEach(({id, title, text}) =>{
            uploadElements.push(
                <FileUpload
                    hideFileName={true}
                    text= {text || 'Add Files'}
                    value={''}
                    placeholder= {title}
                    name={id}
                    className={'left col l4 offset-l1 s12'}
                    inputClassName={"ppf-primary-button left"}
                    onChange={(e)=>this.handleUpload(e, id, title)}
                />
            )
        });

        return (
            <div className = 'row' style={{marginBottom: '50px'}}>
                {uploadElements}
            </div>
        )
    }

    renderTable(){
        const { viewOnly } = this.props.state;
        const { data, mapping = [] } = this.state;
        const { fileMap = [], documents = [] } = data;
        const rows = [];

        // Sort by Pending
        documents.sort((a, b) => {
            if ((a.pending || false) > (b.pending || false)) {
                return -1;
            }
            if ((a.pending || false) < (b.pending || false)) {
                return 1;
            }
            return 0;
        });

        documents.forEach((document) => {
            let file_cat = 'other';
            let { key, name = '', type = 'OTHER', id = '', pending = false, file = null} = document;
            const mappedFile = fileMap.find(x => x.name == name);

            if(mappedFile){
                file_cat = mappedFile.id;
                type = mappedFile.type.toUpperCase();
            }
            if(!mapping.find(x => x.id == file_cat || x.id == key)){
                return;
            }
            rows.push(
                <tr key={`${name}_${type}`}>
                    <td>{name}</td>
                    <td>{type.toUpperCase()}</td>
                    {pending && !viewOnly && <td>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onUpload(key, name, file, type)}>Upload</button>
                        <button className='ppf-danger-button' onClick={()=>this.onRemoveFile(name)}>Remove</button>
                    </td>}
                    {!pending && <td>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onDownload(id, name)}>Download</button>
                        <button className='ppf-primary-button padded-right' onClick={()=>this.onView(id)}>View</button>
                        {
                            !viewOnly &&
                            hasPermission(this.props.superProps.auth.user, 'clientManagement', 'documents', 'delete') &&
                            <button className='ppf-danger-button' onClick={()=>this.onDelete(id, name)}>Delete</button>
                        }
                    </td>}
                </tr>
            );
        });

        return(
            <table key={this.props.title.toLowerCase()}>
                <thead>
                <tr>
                    <th className="center">Name</th>
                    <th className="center mobile-hide">Type</th>
                    <th className="center mobile-hide">Actions</th>
                </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }

    render(){
        const { viewOnly } = this.props.state;
        const { data } = this.state;
        const rows = [];

        if(!data){
            return <div>Loading...</div>;
        }

        return (
            <div className="inputs row" style={{margin:"15px"}}>
                <h5>{this.props.title}</h5>
                {!viewOnly && this.renderFileUploaders()}
                {this.renderTable()}
            </div>
        );
    }
}

export default DocumentsView;