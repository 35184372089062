
import React, {Component} from 'react';
import Select from './Select';
import { measureTypes, getMeasures} from '../Dictionaries/yDataTypes';

class Measures extends Component{
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);

        this.state = {
            measurementClass: this.props.measurementClass || '',
            measurement: this.props.measurement || ''
        };
    }

    onChange(e){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        this.state[name] = value;
        this.props.onChange(this.state);
        this.setState(this.state);
    }

    render(){
        const { measurementClass, measurement } = this.state;
        return (
            <div>
                <Select
                    className= {this.props.className || 'col l2'}
                    name= {this.props.name ||  'measurementClass'}
                    placeholder= 'Measurement Class'
                    value={measurementClass}
                    disabled={this.props.disabled}
                    options={measureTypes}
                    onChange = {this.onChange}
                />
                <Select
                    className= {this.props.className || 'col l2'}
                    name= {this.props.name ||  'measurement'}
                    placeholder= 'Measurement'
                    value={measurement}
                    disabled={this.props.disabled}
                    options={getMeasures(measurementClass)}
                    onChange = {this.onChange}
                />
            </div>
        )
    }
}

export default Measures;