import React, {Component} from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import axios from 'axios';
import { authChange } from '../../../actions';

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleChange(e){
        const target = e.target
        const name = (target.name === "isAdmin") ? "admin" : target.name;
        const value = target.type === 'checkbox'
          ? target.checked
          : target.value

        this.setState({[name]:value});
    }

    saveNewPassword(){
        this.setState({error: undefined});
        var password = this.state.password;
        var passwordConfirmation = this.state.passwordConfirm;
        var token = this.props.auth.token;

        if(password === passwordConfirmation && password !== ""){
            axios.defaults.headers.common['Authorization'] = token;
            axios.post('/api/modifyPassword',{
                password: password,
                uid: this.props.auth.uid,
                key: this.props.auth.key,
                phonenumber: this.props.auth.user.phonenumber
            }).then((res)=>{
                if(res.data.result === "Success") {
                    var auth = { uid:this.props.auth.uid, user:null, token:token };
                    authChange(auth, this.props.history, this.props.dispatch);
                }
            },(err)=>{
                console.log("Error: ",err);
            });
        } else {
            this.setState({error: "Passwords do not match."});
        }
    }

    render(){
        return(
            <div className="container row center" style={{marginTop:'100px'}}>
                <h2>Create new password</h2>
                <div className="col l6 offset-l3">
                    <input
                        name="password"
                        type="password"
                        value={this.state.password}
                        onChange={this.handleChange.bind(this)}
                        placeholder="New Password"
                    />
                    <input
                        name="passwordConfirm"
                        type="password"
                        value={this.state.passwordConfirmation}
                        onChange={this.handleChange.bind(this)}
                        placeholder="Confrim Password"
                    />
                    {typeof this.state.error !== "undefined" && <p className="error">{this.state.error}</p>}
                    <button className="ppf-primary-button col l12" style={{backgroundColor: "#3f51b5"}} onClick={this.saveNewPassword.bind(this)}>Save</button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state){
    return {
        auth:state.auth,
        formLayer:state.formLayer
    };
}

export default connect(mapStateToProps)(PasswordReset);