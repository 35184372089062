import React, { Component } from 'react';
import Record from './Record';
import axios from 'axios';
import _ from 'lodash';
import { connect } from 'react-redux'

//TODO: Add component category - read only

class DictionaryMangement extends Component {
  constructor(props){
    super(props);

    this.state = {
        modal:false,
        records:[]
    };
  }

  componentDidMount() {
    this.getRecords();
  }

  getRecords() {
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.post('/api/dictionaries')
        .then(
            (res) => {
                this.state.records = res.data;
                this.setState(this.state);
            })
        .catch((e) => console.log(e));
  }

  renderRecords(){
    const { history } = this.props;
    let records = [];

    _.forEach(this.state.records, function(value, key) {
      var form = {dictionary: value, key};
      records.push(form);
    });

    return _.chain(records)
      .map((form) => {
        return(
            <Record key={form.key} dictionary={form} history={history}/>
          );
      })
      .compact()
      .value();
  }

  renderTable(){
    return(
      <table>
        <thead>
          <tr>
            <th className="center">Dictionary Name</th>
            <th className="center">Related Feature</th>
            <th className="center mobile-hide">Description</th>
            <th className="center mobile-hide">Modified On</th>
          </tr>
        </thead>
        <tbody>
          {this.renderRecords()}
        </tbody>
      </table>
    )
  }

  render(){
    return(
      <div className="app row" style={{marginTop:'7%'}}>
        <div className = 'col s12' style={{ paddingLeft: '8%', paddingBottom: '20px'  }}>
          <button className="ppf-nav-button left" onClick={()=>{this.props.history.goBack()}}>Back</button>
        </div>
        <div className="container row">
          <h2>Dictionaries</h2>
          {this.renderTable()}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state,{ history }){
  return {
    auth:state.auth,
    files:state.files
  };
}

export default connect(mapStateToProps)(DictionaryMangement);