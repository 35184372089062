const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const template = (methods, state) => {
    const inputs  = [
        { name: "milestone_name", type: "text", placeholder: "Name", className: "col s6", value: "", required: true },
        { name: "due_date", type: "date", placeholder: "Due Date", className: "col s6", value:"", required: true },
        { name: "milestone_description", type: "text", placeholder: "Description", className: "col s12", value:"", required: true },
        { name: "assigned_to", type: "select", placeholder: "Assigned To", className: "col s6", required: true, dictionary: ()=>{
            return state.employees;
        }},
        { name: "client_id", type: "select", placeholder: "Client", className: "col s6", required: true, dictionary: ()=>{
            return state.clients;
        }},
        { name: "assigned_contact", type: "select", placeholder: "Assigned Contact", className: "col s6", required: true, dictionary: ()=>{
            return state.contacts || [];
        }},
        { name: "status", type: "select", yesNo: true, placeholder: "Milestone Status", className: "col s6", required: true, dictionary: ()=>{
            return [
                {name: "Open", value: 'open'},
                {name: "In Progress", value: 'in_progress'},
                {name: "Completed", value: 'completed'}
            ];
        }},
        
        { name: "notify_before", type: "number", placeholder: "Notification Sent Before (Days)", className: "col s12", inputClass: 'col s6', innerStyle: halfStyle}, 
        
        { name: "notes", type: "text", placeholder: "User Notes", className: "col s12", required: false},
        { name: "contact_emails", type: "text", yesNo: true, placeholder: "Contact Emails", className: "col s12", required: false}
    ];

    return [{
        permission: [
            {feature: 'dealsManagement', part: 'milestones'}
        ],
        title: state.data.name || `New Milestone`,

        inputs,
        buttons: [
            {
                name: 'save',
                text: "Save",
                action: methods.onSave,
                className: "ppf-primary-button right",
                edits: true
            }
        ]
    }];
}


export default template;