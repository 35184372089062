import React from 'react';

const TabSelector = ({name, value, options, className, onChange, disabled, placeholder}) =>{
    const optionsElements = []
    let optionsClass = 'col s'+(Math.floor(12 / options.length));
    options.forEach((option, i)=>{
        let oClass = optionsClass;
        let opiontText = option.substring(0, 1).toUpperCase()+option.substring(1, option.length-1);
        if(option == value){
            oClass += ' selected'
        }
        if(i != options.length-1){
            oClass += ' bordered';
        }
        optionsElements.push(
            <div className = {`${oClass} center option`} onClick={()=>onChange(option)}>
                {opiontText}
            </div>
        )
    });
    return(
        <div className={`form-input ${className}`}>
            <label className="textBox">
                {placeholder}
            </label>
            <div className = 'col s12 tab-selector' >
                {optionsElements}
            </div>
        </div>
    )
};

export default TabSelector;