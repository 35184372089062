import React from 'react';

const Toggle = ({ label = '', onChange = ()=>{}, value = false }) => {
    const handleChange = (event) => {
        onChange(event.target.checked);
    };
    return (
        <div class="switch">
            <label>
                {label}
                <input type="checkbox" checked={value} onChange={handleChange} />
                <span class="lever"></span>
            </label>
        </div>
    )
};

export default Toggle;