import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';
import { hasPermission } from '../../../utils/PermissionUtil';
import Input from '../../Generics/Input';

class PublicationForm extends Component{
  constructor(props){
    super(props);
    this.state = {form: this.props.form, fileId:'',  error:'', alert:{active:false, message:''},create:false};
  }

  componentWillMount(){
    if(typeof this.props.form === 'undefined'){
      this.setState({
        form:{
          publication:{
            title:'',
            description:'',
            image:'',
            file:'',
            createdOy:new Date()
          },
          mode:'create'
        },
      });
    } else{
      this.props.form.mode = 'modify';
      this.setState({form:this.props.form});
    }
    this.documentTypes = [
      {extension:".doc", type:"document"},
      {extension:".docx", type:"document"},
      {extension:".pages", type:"document"},
      {extension:".xls", type:"excel"},
      {extension:".xlm", type:"excel"},
      {extension:".xlsx", type:"excel"},
      {extension:".png", type:"image"},
      {extension:".jpg", type:"image"},
      {extension:".jpeg", type:"image"},
      {extension:".bmp", type:"image"},
      {extension:".gif", type:"image"},
      {extension:".zip", type:"zip"},
      {extension:".pdf", type:"pdf"}
    ];
  }

  getImageLink(){
    return _.chain(this.documentTypes)
      .map((docTypes) => {
        if(this.state.form.publication.image.includes(docTypes.extension) && docTypes.type === 'image'){
          axios.defaults.headers.common['Authorization'] = this.props.auth.token;
          axios.get('/api/getLink',{
              params: {
              fileName: this.state.form.publication.image,
              fileType: 'image'
            }
          }).then(
            (res) => {
              var form = this.state.form;
              form.publication.imageURL = res.data;
              this.setState({form:form});
            })
          .catch((e)=>console.log(e));
        } else {
          var form = this.state.form;
          form.publication.imageURL = "";
          this.setState({form:form});
        }

      })
      .compact()
      .value();
  }

  getFileLink(){
    return _.chain(this.documentTypes)
      .map((docTypes) => {
        if(this.state.form.publication.file.includes(docTypes.extension)){
          axios.defaults.headers.common['Authorization'] = this.props.auth.token;
          axios.get('/api/getLink',{
              params: {
              fileName: this.state.form.publication.file,
              fileType: 'file'
            }
          }).then(
            (res) => {
              var form = this.state.form;
              form.publication.fileURL = res.data;
            })
          .catch((e)=>console.log(e));
        }else{
          var form = this.state.form;
          form.publication.fileURL = "";
        }
      })
      .compact()
      .value();
  }

  savePublication(){
    var form = this.state.form;
    this.setState({form:form, create:true});
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.post('/api/publication',{
        form: this.state.form
      }
    ).then(
      (res) => {
        if(res.data.result === "Success"){
          this.props.setModal(false,null);
        } else {
          this.setState({error:''});
          this.setState({error:res.data.result, create:false});
        }
      })
    .catch((e)=>
      {
        console.log(e);
        this.setState({create:false});
      });
  }

  deletePublication(){
    var form = this.state.form;
    this.setState({form:form});
    axios.defaults.headers.common['Authorization'] = this.props.auth.token;
    axios.delete('/api/publication',{
      params: {
        pubId: this.state.form.key
      }
    }).then(
      (res) => {
        if(res.data.result === "Success"){
          this.props.setModal(false,null);
        } else {
          this.setState({error:''});
          this.setState({error:res.data.result});
        }
      })
    .catch((e)=>
      {
        console.log(e);
        this.setState({create:false});
      });
  }

  handleChange(e){
      const target = e.target
      const name = target.name;
      const value = target.type === 'checkbox'
          ? target.checked
          : target.value

      var form = this.state.form;
      form.publication[name] = value;
      this.setState({form: this.state.form});
      if(name === 'image'){
          this.getImageLink();
      }else if(name === 'file'){
          this.getFileLink();
      }
    }


  render() {
    return(
      <div>
        <form
          onSubmit={this.props.handleSubmit}
        >
          <div>
            <h4>Publication Details</h4>
              <Input
                key= "publication-details"
                placeholder="Publication Details"
                className="col s12"
                name='title'
                type="text"
                errors={[]}
                disabled={!hasPermission(this.props.auth.user, 'publications', 'publications', 'write')}
                value={this.state.form.publication.title}
                handleChange={this.handleChange.bind(this)}
              />
              <Input
                key= "publication-description"
                className="col s12"
                inputClass={"materialize-textarea"}
                name="description"
                disabled={!hasPermission(this.props.auth.user, 'publications', 'publications', 'write')}
                type="text"
                value={this.state.form.publication.description}
                placeholder="Description"
                handleChange={this.handleChange.bind(this)}
              />
              <Input
                key= "publication-image"
                className="col s12"
                name="image"
                disabled={!hasPermission(this.props.auth.user, 'publications', 'publications', 'write')}
                type="text"
                value={this.state.form.publication.image}
                placeholder="Image Name"
                handleChange={this.handleChange.bind(this)}
              />
              <Input
                key= "publication-image"
                className="col s12"
                name="file"
                disabled={!hasPermission(this.props.auth.user, 'publications', 'publications', 'write')}
                type="text"
                value={this.state.form.publication.file}
                placeholder="File Name"
                handleChange={this.handleChange.bind(this)}
              />
            <div className="row">
              <h5>Image:</h5>
              <div className="col l8">
                <img key="image" alt="imageURL" src={this.state.form.publication.imageURL} width="100%"/>
              </div>
              <div className="col l4">
                <a key="file" href={this.state.form.publication.fileURL} target="_blank">File Link</a>
              </div>
            </div>
          </div>
        </form>
        {this.state.error !== '' && <p className="error blink">{this.state.error}</p>}
        {!this.create &&
        <div>
          {hasPermission(this.props.auth.user, 'publications', 'publications', 'write') && <button
            className="ppf-primary-button col l6 s6"
            onClick={this.savePublication.bind(this)}
            style={{marginTop:'15px'}}
          >Save Publication</button>}
          {hasPermission(this.props.auth.user, 'publications', 'publications', 'delete') && <button
            className="btn-flat white-text red col l6 s6"
            onClick={this.deletePublication.bind(this)}
            style={{marginTop:'15px'}}
          >Delete</button>}
        </div>
        }
        {
          this.create
          && <p>Creating Publication..</p>
        }
      </div>
    )
  }
}

export default PublicationForm;