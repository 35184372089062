import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HashLink as Link } from 'react-router-hash-link';
import firebase from 'firebase';
import { authChange } from '../actions';
import { hasPermission } from '../utils/PermissionUtil';
import { withRouter } from 'react-router-dom';

class Header extends Component {
	logout() {
		const history = this.props.history;
		const dispatch = this.props.dispatch;

		firebase.auth().signOut().then(function () {
			authChange(null, history, dispatch);
		}, function (error) {

		});
	}

	renderHeaderOptions() {
		const { user } = this.props.auth;
		const authOptionsMap = [
			// { title: 'Home', key: 'welcome', path: '/welcome' },
			{ title: 'Data Room', key: 'dataRoom', path: '/data_room', feature: 'dataroom', part: null, permission: null },
			{ title: 'User Management', key: 'admin', path: '/admin', feature: 'userManagement', part: 'userManagement', permission: null, mobileOnly: true },
			{ title: 'Data Room Management', key: 'dataroomAdmin', path: '/admin', feature: 'userManagement', part: 'dataRoomManagement', permission: null, mobileOnly: true  },
			{ title: 'Analytics', key: 'analytics', path: '/analytics', feature: 'analytics', part: 'analytics', permission: null, mobileOnly: true  },
			{ title: 'Publications', key: 'publications', path: '/publications', feature: 'publications', part: 'publications', permission: null, mobileOnly: true  },
			{ title: 'Deals Management', key: 'deals_management', path: '/deals_management', feature: 'dealsManagement', mobileOnly: true  },
			{ title: 'Vendor Management', key: 'vendors_management', path: '/vendors_management', feature: 'vendorsManagement', mobileOnly: true  },
			{ title: 'Client Management', key: 'client_management', path: '/client_management', feature: 'clientManagement', mobileOnly: true  },
			{ title: 'Dictionary', key: 'dictionary', path: '/dictionaries', feature: 'dictionary', mobileOnly: true  },
			{ title: 'Database', key: 'database', path: '/database/search', feature: 'database', part: 'queryTool', permission: null, mobileOnly: true },
			{ title: 'Release', key: 'release', path: '/release', feature: 'release', part: null, permission: null },
		];

		const notAuthOptionsMap = [
			// { title: 'Our Mission', key: 'missionBtn', path: '/#mission' },
			// { title: 'What We Do', key: 'whatBtn', path: '/#whatwedo' },
			// { title: 'Publications', key: 'publicationsBtn', path: '/#publications' },
			// { title: 'Follow Us', key: 'followBtn', path: '/#tweets' },
			// { title: 'Contact Us', key: 'contactusBtn', path: '/#contactus' },
			{ title: 'Login', key: 'loginBtn', path: '/login' },
		];
		const liElements = [];
		const mobileLiElements = [];

		if (user) {
			authOptionsMap.forEach(option => {
				const { title, key, path, feature, part, permission, mobileOnly } = option;
				if (!feature || hasPermission(user, feature, part, permission)) {
					if(!mobileOnly){
						liElements.push(<li key={key} id={key}><Link to={path}>{title}</Link></li>);
					}
					mobileLiElements.push(<li key={key} id={key}><Link to={path}>{title}</Link></li>);
				}
			});
			
			if (
				user.permissions.employee || user.permissions.admin
				&& (
					user.permissions.admin.userManagement
					|| user.permissions.admin.publications
					|| user.permissions.admin.alerts
					|| user.permissions.admin.analytics
					|| user.permissions.admin.dictionary
					|| user.permissions.admin.riskRating
					|| user.permissions.employee.database
				)
			) {
				liElements.push(<li key={'application_management'} id={'application_management'}><Link to={'/application_management'}>Application Management</Link></li>);
			}

			if (
				user.permissions.employee
				&& (
					user.permissions.employee.clientManagement
					|| user.permissions.employee.dealsManagement
					|| user.permissions.employee.vendorsManagement
				)
			) {
				liElements.push(<li key={'data_management'} id={'data_management'}><Link to={'/data_management'}>Data Management</Link></li>);
			}

			if (user.permissions.employee || user.permissions.admin) {
				liElements.push(
					<li key='help_desk' id='help_desk'>
						<a href="https://sites.google.com/ppfcapital.com/ppfcapitalwebhelp/home?authuser=1" target="blank">App Guide</a>
					</li>
				);
				liElements.push(
					<li key='help_desk' id='help_desk'>
						<a href="https://ppfcapital.atlassian.net/servicedesk/customer/portals" target="blank">Help Desk</a>
					</li>
				);
			}
			const logout = <li key="loginBtn" id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>;
			liElements.push(logout);
			mobileLiElements.push(logout);

		} else {
			notAuthOptionsMap.forEach(option => {
				const { title, key, path } = option;
				liElements.push(<li key={key} id={key}><Link to={path}>{title}</Link></li>);
				mobileLiElements.push(<li key={key+'mobile'} id={key}><Link to={path}>{title}</Link></li>);
			});
		}

		const ulElements = [
			<ul key="1" id="nav-mobile" className="right hide-on-med-and-down" style={{ maxHeight: '65px' }}>{liElements}</ul>,
			<ul key="2" className="side-nav" id="mobile-demo">{mobileLiElements}</ul>
		];

		return ulElements;
	}

	renderLinks() {
		var user = this.props.auth.user;
		if (typeof user !== 'undefined') {
			if (user.admin) {
				return ([
					<ul key="2" className="side-nav" id="mobile-demo">
						<li id="dataRoom"><Link to="dataRoom">Data Room</Link></li>
						<li id="admin"><Link to="/admin">User Management</Link></li>
						<li id="analytics"><Link to="/analytics">Analytics</Link></li>
						<li id="publications"><Link to="/publications">Publications</Link></li>
						<li id="compliance"><Link to="/compliance">Compliance</Link></li>
						<li id="database"><Link to="/database/search">Database</Link></li>
						<li id="release"><Link to="/release">Release Notes</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>,
					<ul key="2" className="side-nav" id="mobile-demo">
						<li id="dataRoom"><Link to="dataRoom">Data Room</Link></li>
						<li id="admin"><Link to="/admin">User Management</Link></li>
						<li id="analytics"><Link to="/analytics">Analytics</Link></li>
						<li id="publications"><Link to="/publications">Publications</Link></li>
						<li id="compliance"><Link to="/compliance">Compliance</Link></li>
						<li id="release"><Link to="/release">Release Notes</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>
				])
			} else if (user.database_query) {
				return ([
					<ul key="1" id="nav-mobile" className="right hide-on-med-and-down">
						<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
						<li id="database"><Link to="/database/search">Database</Link></li>
						<li id="release"><Link to="/release">Release Notes</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>,
					<ul key="2" className="side-nav" id="mobile-demo">
						<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
						<li id="release"><Link to="/release">Release Notes</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>
				]);
			} else {
				return ([
					<ul key="1" id="nav-mobile" className="right hide-on-med-and-down">
						<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>,
					<ul key="2" className="side-nav" id="mobile-demo">
						<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
						<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
					</ul>
				])
			}
		} else if (typeof this.props.auth.user !== 'undefined') {
			return ([
				<ul id="nav-mobile" className="right hide-on-med-and-down">
					<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
					<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
				</ul>,
				<ul className="side-nav" id="mobile-demo">
					<li id="dataRoom"><Link to="/data_room">Data Room</Link></li>
					<li id="loginBtn"><a onClick={this.logout.bind(this)}>Logout</a></li>
				</ul>
			])
		} else {
			return ([
				<ul key="1" id="nav-mobile" className="right hide-on-med-and-down">
					{/* <li id="missionBtn"><Link to="/#mission">Our Mission</Link></li>
					<li id="whatBtn"><Link to="/#whatwedo">What We Do</Link></li>
					<li id="publicationsBtn"><Link to="/#publications">Publications</Link></li>
					<li id="followBtn"><Link to="/#tweets">Follow Us</Link></li>
					<li id="contactusBtn"><Link to="/#contactus">Contact Us</Link></li> */}
					<li id="loginBtn"><Link to="/login">Login</Link></li>
				</ul>,
				<ul key="2" className="side-nav" id="mobile-demo">
					{/* <li id="missionBtn"><Link to="/#mission">Our Mission</Link></li>
					<li id="whatBtn"><Link to="/#whatwedo">What We Do</Link></li>
					<li id="publicationsBtn"><Link to="/#publications">Publications</Link></li>
					<li id="followBtn"><Link to="/#tweets">Follow Us</Link></li>
					<li id="contactusBtn"><Link to="/#contactus">Contact Us</Link></li> */}
					<li id="loginBtn"><Link to="/login">Login</Link></li>
				</ul>
			]
			)
		}
	}

	renderStyle() {
		if (this.props.style === "landing") {
			return { backgroundColor: "transparent", boxShadow: "none", paddingRight: "2%", paddingLeft: "2%" }
		}
		return { backgroundColor: "#3F51B5", paddingRight: "2%", paddingLeft: "2%" }
	}

	$

	render() {
		const hostname = window.location.hostname;
		let logSrc = "/static/images/logo-sv.png";
		if(hostname.indexOf('ppf.financial') > -1){
			logSrc = "/static/images/logo-2.png";
		}
		return (
			<nav className="navbar-fixed no-print" style={{ backgroundColor: "white", paddingRight: "2%", paddingLeft: "2%" }}>
				<div className="nav-wrapper">
					<Link to="/#landing" className="brand-logo"><img src={logSrc} style={{ height: "65px", padding: "10px" }} /></Link>
					<a data-activates="mobile-demo" className="button-collapse"><i className="material-icons" style={{ color: "#000000" }}>menu</i></a>
					{this.renderHeaderOptions()}
				</div>
			</nav>

		);
	}
}

function mapStateToProps(state, { history }) {
	return {
		auth: state.auth,
		history
	};
}

export default connect(mapStateToProps)(withRouter(Header));