const halfStyle = {
    paddingLeft: '0.75rem',
    paddingRight: '9%'
}

const template = (methods, state) => {
    const inputs = [
        { name: "name", type: "text", placeholder: "Contract Name", className: "col s12", inputClass: "col s6",  innerStyle: halfStyle, required: true},
        { name: "deal_type", type: "select", placeholder: "Deal Type",className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true, dictionaryId: '-MK2cO7cLgqQn4rJIQUd'},
    
        { name: "start_date", type: "date", placeholder: "Start Date", className: "col s6", value:"", required: true },
        { name: "expiration_date", type: "date", placeholder: "Expiration Date", className: "col s6", value:"", required: true },
        { name: "deal_company", type: "select", placeholder: "PPF Company", className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true, dictionaryId: '-MKQK8hqSPK2WQadhUdv'},
        { name: "contact_sides", type: "number", placeholder: "Contract Parties", className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true},
    
        { name: "renewal", type: "select", yesNo: true, placeholder: "Renewal", className: "col s6", required: true},
        { name: "notify_days", type: "number", placeholder: "Notification Sent Before (Days)", className: "col s6", condition: (state)=>{
            return state.renewal == 'yes';
        }}, 
        { name: "is_active", type: "select", yesNo: true, placeholder: "Is Active", className: "col s6"},
    ];

    if(!inputs.find(x => x.name =='deal_lead')){
        inputs.push(
            { name: "deal_lead", type: "select", yesNo: true, placeholder: "Lead", className: "col s12", inputClass: "col s6", innerStyle: halfStyle, required: true, dictionary: ()=>{
                return state.employees;
            }}
        );
    }

    const clients = state.clients;
    // Add all the contract party inputs dynamically
    if(state.data.contact_sides > 1){
        const { data } = state;
        for (let i = 0; i < data.contact_sides - 1; i++){
            let displayDigit = i + 1;
            let attrName = `contract_party_${displayDigit}`;
            if(!inputs.find(x => x.name == attrName)){
                inputs.splice(
                    6 + i, 0, 
                    { name: attrName , type: "select", placeholder: `Party ${displayDigit}`, className: "col s6", required: true, dictionary: ()=>{
                        return clients;
                    }}
                );
            }
        }
    }
   
    return [
        {
            permission: [
                {feature: 'clientManagement', part: 'general'}
            ],
            title: "General Information",
            inputs,
            pageButtons: [
                { name: 'delete', deletes: true, edits: true, action: ()=>{ methods.onDeleteRecord()}, text: 'Delete Record', className: 'right ppf-danger-button' },
                { name: 'save' }
            ]
        }
    ];
}

export default template;