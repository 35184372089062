import React, { Fragment, useState } from 'react';
import {CSVLink, CSVDownload} from 'react-csv';
import { confirmMatch, denyMatch, SanctionMatchIndCard } from '../../../AMLManagement/Sanctions/SanctionMatchIndCard';
import { SanctionRecordDetailsPopUp } from '../../../AMLManagement/Sanctions/SanctionRecordDetails';
import { SanctionMatchEntityCard } from '../../../AMLManagement/Sanctions/SanctionsMatchEntityCard';

export {
    ClientSanctionsView,
    SanctionsMatches
}

/**
 * This component displays Sanctions History in an interactive
 * and responsive manner.
 * 
 * @param {*} props 
 * @returns 
 */
const ClientSanctionsView = (props) => {
    const [selectedMatch, setSelectedMatch] = useState();
    const { 
        recordClass,
        sanctions = {},
        peps = {}
    }  = props.state.data;

    const { 
        history: sanction_history = [], 
        potential_matches = [],
        confirmed_matches = [],
        denied_matches = []
    } = sanctions;

    const {
        history: peps_history = [],
        potential_matches: peps_matches = [],
        confirmed_matches: peps_confirmed = [],
        denied_matches: peps_denied = []
    } = peps;

    const isPep = (records) => {
		records.map(rec => {
			rec.peps = true;
			return rec;
		});
	}
    
    isPep(peps_matches)
    isPep(peps_confirmed)
    isPep(peps_denied)

	let lastScreenedMessage;
	const {batch_id, date} = sanction_history.length > 0 ? sanctions.history[sanctions.history.length-1] : {};
	if(!batch_id){
		lastScreenedMessage = "No Batches Found"
	} else {
		const dt = new Date(date);
		lastScreenedMessage = `Last Screened: ${dt.toLocaleDateString()}`;
	}
	
    const cliId = props.superProps.match.params.clientId;
    
	const deniedMatches = denied_matches || [];
    const confirmedMatches = confirmed_matches || [];
    const potentialMatches = potential_matches || [];

    deniedMatches.push(...denied_matches);
    confirmedMatches.push(...peps_confirmed);
    potentialMatches.push(...peps_matches);
    sanction_history.map(x => {
        const pep = peps_history.find(y=> y.batch_id === x.batch_id);
        if(pep){
            x.peps_matches = pep.potential_matches_found;
        }
    })

    return (
        <div className="inputs row" style={{margin:"15px"}}>
			{selectedMatch && <SanctionRecordDetailsPopUp data={selectedMatch} onClose={()=>setSelectedMatch()}/>}
			<div className = 'no-print'>
				{
					sanction_history.length > 0
					&& <div className = 'col s12'>
						<CSVLink 
							data={(sanction_history).map(x => {
								const dt = new Date(x.date);
								x.date = dt;
								return x;
							})}
							filename={`${cliId}-aml-screenings.csv`}
						>Download Screening History</CSVLink>
					</div>
				}
				<div className = 'col s12 '>
					{lastScreenedMessage}<br/><br/>
				</div>
				<SanctionsMatches
					potentialMatches = {potentialMatches}
					deniedMatches  = {deniedMatches}
					confirmedMatches  = {confirmedMatches}
					clientId  = {cliId}
					recordClass  = {recordClass}
					onRefresh= {props.methods.onRefresh}
					onView={(match)=>setSelectedMatch(match)}
				/>
			</div>
        </div>
    )
}

/**
 * This component is used to display a set of matches for a specified Client. You
 * can interact with the potential matches by confirming or denying the match. You
 * can also view all details pertaining to the match for all cards.
 * 
 * @param {*}  
 * @returns 
 */
const SanctionsMatches = ({potentialMatches, deniedMatches, confirmedMatches, clientId, recordClass, onRefresh, onView}) => {
    return <Fragment>
		{
			 !potentialMatches.length &&
			 !confirmedMatches.length &&
			 !deniedMatches.length &&
			 <div className='col s12 center'>
				 <h4>No Matches Found</h4>
			 </div>
		}
        {
            !!potentialMatches.length &&
            <div className ='col s12'>
                <h4>Potential Matches</h4>
                {potentialMatches.map(
                    match => {
                        const props = {
                            ...match,
                            onView: ()=>onView(match),
                            onConfirm: ()=>{
                                confirmMatch(clientId, match, ()=>{
                                    onRefresh(()=>{
                                        alert("Confirmed Match!")
                                    });
                                })
                            },
                            onDeny: ()=>{
                                denyMatch(clientId, match, ()=>{
                                    onRefresh(()=>{
                                        alert("Denied Match!")
                                    });
                                })
                            },
                            status: 'pending'
                        };

                        const card = recordClass === 'individual' ? <SanctionMatchIndCard {...props}/> : <SanctionMatchEntityCard {...props}/>;
                        return card;
                })
            }
            </div> 
        }
        {
            !!confirmedMatches.length &&
            <div className='col s12'>
                <h4>Confirmed Matches</h4> 
                {confirmedMatches.map(
                     match => {
                        const props = {
                            ...match,
                            onView: ()=>onView(match),
                            status:'confirmed'
                        };

                        const card = recordClass === 'individual' ? <SanctionMatchIndCard {...props}/> : <SanctionMatchEntityCard {...props}/>;
                        return card;
                })
            }
            </div>
        }
        {
            !!deniedMatches.length &&
            <div className='col s12'>
                <h4>Denied Matches</h4>
                {deniedMatches.map(
                    match => {
                        const props = {
                            ...match,
                            onView: ()=>onView(match),
                            status: 'denied'
                        }

                        const card = recordClass === 'individual' ? <SanctionMatchIndCard {...props}/> : <SanctionMatchEntityCard {...props}/>;
                        return card;
                })
            }
            </div> 
        }
    </Fragment>
}