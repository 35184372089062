import React, { Component } from 'react';
import reactStringReplace  from 'react-string-replace';

class Tweet extends Component{
  renderText(text){
    let replacedText;
    
    replacedText = reactStringReplace(text, /(https?:\/\/\S+)/g, (match, i) => (
      <a key={match + i} href={match}>{match}</a>
    ));
    
    replacedText = reactStringReplace(replacedText, /@(\w+)/g, (match, i) => (
      <a key={match + i} href={`https://twitter.com/${match}`}>@{match}</a>
    ));

    replacedText = reactStringReplace(replacedText, /#(\w+)/g, (match, i) => (
      <a key={match + i} href={`https://twitter.com/hashtag/${match}`}>#{match}</a>
    ));
    return (
      <p>"{replacedText}"</p>
    )
  }
  render(){
    return(
      <div className="col l4">
        <div className="info">
          <div className="col l11">
            <div className="header">
              <img className="tweet" alt="twitter-icon" src="/static/images/twitter-icon.png"/>
              {this.props.tweet.user.name} <a href={"https://twitter.com/intent/user?screen_name="+this.props.tweet.user.screen_name}>{this.props.tweet.user.screen_name}</a>
            </div>
            <div>
              {this.renderText(this.props.tweet.text)}
            </div>
            <div className="col l12 created">
              {this.props.tweet.created_at.slice(0,-19)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Tweet;