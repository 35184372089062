import React, { Component } from 'react';
import axios from 'axios';

class Subscriber extends Component{
    constructor(props){
        super(props);
        this.state={email:""};
    }
    onClick(){
        axios.post('/api/subscribe', {
            email:this.state.email
        }).then((res)=>{
            if(res.data.success){
                alert("You have successfully subscribed to PPF Capital!")
            } else if (res.data.error){
                if(res.data.message.title === "Member Exists"){
                    alert("You are already subscribed to PPF Capital!")
                } else {
                    alert("There was an isse adding you to the list. Please try again");
                }
            }
        }, (err)=>{
            console.log(err);
        });
    }
    onChange(e){
        const target = e.target
        const name =  target.name;
        const value = target.value

        this.setState({[name]: value});
    }
    render(){
        return(
            <div className="row subscribe">
                <div className="col l6 offset-l3 center">
                    <h4>Subscribe</h4>
                    <div className="col s12">
                        <p style={{fontSize:'22px'}}>Subscribe to PPF Capital to get the latest from our newsletter.</p>
                    </div>
                    <div className="col l6 s12 offset-l3">
                        <div className="col s7 l8">
                            <input 
                                type="text" 
                                name = "email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.onChange.bind(this)}
                            />
                        </div>
                        <div className="col l4 s5">
                            <button className="btn flat white-text green" onClick={()=>this.onClick()}>Subscribe</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Subscriber;