import axios from 'axios';
import React, { Fragment } from 'react'
import { successWrapper } from '../../../../utils/SuccessWrapper';

const IndividualGeneralDetails = ({ data }) => {
	const {
		first_name = '', second_name = '', third_name = '', fourth_name = '',
		matchScore, nameScore, countryScore, genderScore, dobScore,
		imageUrl = '', 
		name, original_name, gender, dob, country, nationality,
		alias,
		sanctioning_program, sources, datasource
	} = data;
	
	const indName = `${first_name} ${second_name} ${third_name} ${fourth_name}`;

	return <Fragment>
		<div style={{height: "120px", overflowY: 'auto'}}>
			<div className='col s4'>
				<img
					className="responsive-img" 
					src={imageUrl.toUpperCase().includes('HTTP') ? imageUrl : 'https://i.stack.imgur.com/l60Hf.png'}
				/>
			</div>
			<div className='col s8'>
				<b>Full Name:</b> {name ?? original_name ?? indName ?? 'N/A'}<br/>
				<b>Country:</b> {country ?? nationality ?? 'N/A'}<br/>
				{
					(sanctioning_program 
					&& sanctioning_program.trim() !== 'INTERPOL RED NOTICES')
					|| !sanctioning_program
					&& <Fragment>
						<b>Gender:</b> {gender && gender != '' ? gender : 'N/A'}<br/>
					</Fragment>
				}
				<b>DOB:</b> {dob ?? 'N/A'}
			</div>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "85px", overflow: 'hidden'}}>
			<br/>
			<b>Alias:</b> {alias ?? 'N/A'}
			<br/>
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div style={{height: "25px", overflow: 'hidden'}}>
			<b>Source:</b> {(sanctioning_program ?? datasource ?? sources ?? 'N/A').replace(/_/g, " ")}
		</div>
		<hr style={{border:'0', borderTop: '#f1f1f1 1px solid'}}/>
		<div className = "valign-wrapper" style={{height: "55px", overflow: 'hidden'}}>
			<div className='col s8 valign-wrapper' style={{padding: '0px'}}>
				<div className='col s6' style={{padding: '0px'}}>
					<Fragment><b>Gender:</b> {genderScore ? 'Yes' : 'No'}<br/></Fragment>
					<b>DOB:</b> {dobScore ? 'Yes' : 'No'}<br/>
				</div>
				<div className='col s6' style={{padding: '0px'}}>
					<b>Name:</b> {nameScore ? 'Yes' : 'No'}<br/>
					<b>Country:</b> {countryScore ? 'Yes' : 'No'}<br/>
				</div>
			</div>
			<div className='col s4'>
				<h3>{parseInt(matchScore * 100)}%</h3>
			</div>
		</div>
	</Fragment>;
}

const SanctionMatchIndCard = (props) => {
	const {
		viewOnly,
		onView,
		onConfirm,
		onDeny,
		status,
		peps
	} = props;
	
	return (
		<div class="col s12 m6">
			<div class="card">
				{peps && <span class="badge red white-text">PEPS</span>}
				{!peps && <span class="badge orange white-text">SANCTION</span>}
				<div class="card-content" style={{ textAlign: 'left'}}>
					<IndividualGeneralDetails data = {props}/>
				</div>
				<div className="card-action right-align">
						{onView && <a className="btn-flat" onClick={onView}>View</a>}
						{	!viewOnly && status != 'confirmed' && onConfirm &&
							<a className="btn-flat" onClick={onConfirm}>Confirm</a>
						}
						{
							!viewOnly && status != 'denied' && onDeny &&
							<a className="btn-flat" onClick={onDeny}>Deny</a>
						}
				</div>
			</div>
		</div>
	)
}

const confirmMatch = (clientId, sanctionRecord, success) => {
	if(!window.confirm('This will permanently associate this sanction record to this client. Do you want to continue?')){
		return;
	}

	axios.post('/api/aml/confirmSanctionMatch', {
		data:
		{
			clientId, record: sanctionRecord
		}
	}).then((res) => {
		successWrapper(res, success);
	}).catch((err) => {
		console.log(err);
		alert("Error confirming sanction record.")
	});
}

const denyMatch = (clientId, sanctionRecord, success) => {
	if(!window.confirm('This will permanently disassociate this sanction record to this client. Do you want to continue?')){
		return;
	}
	axios.post('/api/aml/denySanctionMatch', {
		data:
		{
			clientId, record: sanctionRecord
		}
	}).then((res) => {
		successWrapper(res, success);
	}).catch((err) => {
		console.log(err);
		alert("Error denying sanction record.")
	});
}

export {
	SanctionMatchIndCard,
	IndividualGeneralDetails,
	confirmMatch,
	denyMatch
}
