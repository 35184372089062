import React, { Component } from 'react';
import {connect} from 'react-redux'
import _ from 'lodash';
import axios from 'axios';
import Input from '../../../../Generics/Input';
import Select from '../../../../Generics/Select';
import DataView from './DataView';
import Measures from '../../../../Generics/Measures';
import { baseTypes, currencies } from '../../../../Dictionaries/yDataTypes.js';
import xDataTypes from '../../../../Dictionaries/xDataTypes';
import uid from 'uid';

class Group extends Component {
    constructor(props){
        super(props);

        this.onBack = this.onBack.bind(this);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.generateAndSendUpdateRequests = this.generateAndSendUpdateRequests.bind(this);

        // default state
        this.state = {
            group:{},
            addData: false
        };
        const { dataset_id, group_id, viewOnly } = this.props.match.params;
        if(!dataset_id || !group_id){
            this.props.history.replace('/datasets');
            return;
        }
        this.state.viewOnly = viewOnly == 'true';
        this.state.dataset_id = dataset_id;
        this.state.loading = true;
        this.loadGroupData(dataset_id, group_id);
    }

    onBack() {
        const { edited } = this.state;

        if(edited){
            if (window.confirm('Any unsaved changes will be removed. Continue?')){
                this.props.history.goBack();
            }
        } else {
            this.props.history.goBack();
        }
    }

    generateAndSendUpdateRequests(groupViewState){
        const { dataset_id, group: groupData, groupModified: groupDataModified } = this.state;
        const { group, headerEdited = false, groupModified = false, removed } = groupViewState;
        const { id:group_id, rows, layers, header } = group;
        const updatedRows = [];
        const promises = [];

        const saveAction =  async (rows) =>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            return axios.post(`/api/group`,
                {
                    data:{
                        removed,
                        dataset_id,
                        group_id,
                        rows,
                        header: headerEdited ? header : undefined,
                        group: groupDataModified ? groupData : undefined,
                    }
                }
            ).then(
                (res) => {
                    const {errors=[], result} = res.data;
                    if(result == 'Success'){
                        this.loadGroupData(dataset_id, group_id);
                        alert('Saved!');
                    } else if (result == 'Request Error'){
                        alert('Error: There was an issue while processing this request. Kindly refresh and try again.');
                    } else {
                        alert(errors.join('\n'));
                    }
                    return res.data;
                }
            ).catch((e)=>{
                return {result: "Request Error", errors:[e]};
            });
        }

        // Save all modified data records
        rows.forEach((row)=>{
            if(row.modified){
                updatedRows.push(row);
            }
        });
        saveAction(rows);
    }

    loadGroupData(datasetID, groupID){
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.get(`/api/group?dataset_id=${datasetID}&group_id=${groupID}`).then(
            (res) => {
                if(res.data && res.data.group){
                    const { group } = res.data;
                    this.setState({group});
                } else {
                    alert("Could not find Group");
                }
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false, edited: false, addData: false, deleteData: false});
        });
    }

    handleTextChange(e){
        const { group } = this.state;
        const target = e.target;
        const name = target.name;
        let value = target.value;

        group[name] = value;

        this.setState({group, edited: true, groupModified: true});
    }

    renderGroups(){
        const groupElements = [];
        const { group, errors, viewOnly } = this.state;
        const {name, description, notes, layers, xDataType, yDataType, measurementClass, measurement, currency, custom_keys} = group;
        groupElements.push(
            <div key={`group-container`} className="col s12" style={{padding: '0px'}}>
                <div className="col s12 valign-wrapper">
                    <h5 className="left" style={{paddingRight:'1rem'}}>{name}</h5>
                </div>
                <Input
                    className={'col l4 s12'}
                    type = 'text'
                    name= 'name'
                    placeholder= 'Group Name'
                    inputClass={'col l12 s12'}
                    errors={errors}
                    disabled={true}
                    value={name}
                    handleChange={this.handleTextChange}
                />
                <Input
                    className={'col l6 s12'}
                    type = 'text'
                    name= 'description'
                    placeholder= 'Group Description'
                    inputClass={'col l12 s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={description}
                    handleChange={this.handleTextChange}
                />
                <Input
                    className={'col s12'}
                    type = 'text'
                    name= 'notes'
                    placeholder= 'Notes'
                    inputClass={'col l12 s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={notes}
                    handleChange={this.handleTextChange}
                />
                <Input
                    key= 'group-keys'
                    className={'col s8'}
                    type = 'text'
                    name= 'custom_keys'
                    placeholder= 'Custom Keys (ex. energy, solar)'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={custom_keys}
                    handleChange={this.handleTextChange}
                />
                <div className = 'col s12'>
                    <Select
                        className= 'col l2'
                        name= 'xDataType'
                        placeholder= 'X Data Type'
                        errors={errors}
                        value={xDataType}
                        disabled={true}
                        options={xDataTypes}
                        //onChange={(e)=>this.handleSelectChange(e, i)}
                    />
                    <Select
                        key= {`layer-ytype-${group}`}
                        className= 'col l2'
                        name= 'yDataType'
                        placeholder= 'Y Data Type'
                        errors={errors}
                        value={yDataType}
                        disabled={true}
                        options={baseTypes}
                    />

                    {yDataType == 'measurement' && <Measures
                        measurementClass ={measurementClass}
                        measurement = {measurement}
                        disabled={true}
                    />}
                    {yDataType == 'currency' && <Select
                        className= 'col l2'
                        name= 'currency'
                        placeholder= 'Currency Type'
                        errors={errors}
                        value={currency}
                        options={currencies}
                        disabled={true}
                    />}
                </div>
            </div>
        );
        return(groupElements);
    }

    render(){
        const { group, loading, viewOnly, edited } = this.state;
        const { id, rows = [] } = group;
        if(loading){
            return(
                <div className="row">
                    <div className="container" style={{marginTop: '5%'}}>
                        <h4>Loading...</h4>
                    </div>
                </div>
            )
        }
        return(
            <div className="row">
                <div className="container" style={{marginTop: '5%'}}>
                    <div className="col s12" style={{paddingBottom: '2rem'}}>
                        <button className="ppf-nav-button col s1" onClick={this.onBack}>Back</button>
                    </div>
                    <h2>Modify Group Data</h2>
                    {this.renderGroups()}
                </div>
                <DataView
                    loading={loading}
                    group = {group}
                    viewOnly={viewOnly}
                    onSave={this.generateAndSendUpdateRequests}
                />
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(Group);