import React, { Component } from "react";
import { connect } from 'react-redux';
import { Switch } from 'react-materialize';
import CustomInput from "../../Generics/Input";
import Select from "../../Generics/Select";
import Checkbox from "../../Generics/Checkbox";
import UserFileAccess from './UserFileAccess'
import axios from "axios";
import { hasPermission } from '../../../utils/PermissionUtil';
import { data } from "currency-codes";

const solid = "#3F51B5";
const blurred = "#9FA8DA";

class UserPermissions extends Component {
	constructor(props) {
		// TODO: Future - Create predefined roles for this view
		// TODO: Future - User Permissions Report / CSV
		// TODO: Future - Add Client Level restrictions to per user
		super(props);

		this.onBack = this.onBack.bind(this);
		this.saveUser = this.saveUser.bind(this);
		this.deleteUser = this.deleteUser.bind(this);
		this.resetUser = this.resetUser.bind(this);
		this.handleTextChange = this.handleTextChange.bind(this);
		this.onFileAccessChange = this.onFileAccessChange.bind(this);

		const { userId } = this.props.match.params;

		this.state = {
			user: {},
			create: true,
			key: userId == 'new' ? undefined : userId
		};
	}

	componentDidMount(){
		const { user, key } = this.state;

		if(key && Object.keys(user).length == 0){
			axios.defaults.headers.common['Authorization'] = this.props.auth.token;
			axios.get('/api/user/'+key)
				.then(
				(res) => {
					this.setState({user:res.data, create: false, key});
				})
			.catch((e)=>console.log(e));
		}
	}

	onBack(){
		if(window.confirm('All unsaved changes will be discarded. Continue?')){
			this.props.history.goBack();
		}
	}

	onFileAccessChange(files, storageFiles, writableFiles){
		const { user } = this.state;
		user.files = files;
		user.storageFiles = storageFiles;
		user.writableFiles = writableFiles;
		this.setState({user});
	}

	onPermissionSelect(role, feature, part, permission, selected){
		const { user } = this.state;
		let { permissions = {} } = user;

		if(selected){
			permissions[role][feature][part][permission] = false;
		} else {
			permissions[role][feature][part] = permissions[role][feature][part] || {};
			permissions[role][feature][part][permission] = true;
		}
		user.permissions = permissions;
		this.setState({user});
	}

	getClients() {
		const { fetching } = this.state;
		if(fetching){
			return;
		}
		this.setState({fetching: true}, ()=>{
			axios.defaults.headers.common['Authorization'] = this.props.auth.token;
			axios.get('/api/clients')
				.then(
					(res) => {
						this.state.clients = res.data.clients;
						this.state.fetching = false;
						this.setState(this.state);
					})
				.catch((e) => {
					console.log(e);
					this.setState({fetching: false, error: true});
				})
			});
	}

	getCompanies() {
		const { fetching } = this.state;
		if(fetching){
			return;
		}
		this.setState({fetching: true}, ()=>{
			axios.defaults.headers.common['Authorization'] = this.props.auth.token;
			const data = { key: '-MKQK8hqSPK2WQadhUdv' };
			axios.post('/api/getDictionary', {
				data
			}).then(
				(res) => {
					if (res.data.error) {
						return alert(res.data.error);
					}
					this.state.companies = res.data.options.map(x => {
						return {id: x.value, name: x.name};
					});
					this.state.fetching = false;
					this.setState(this.state);
				})
				.catch((e) => {
					this.setState({ data: [], loading: false });
					console.log(e);
				});
		});
	}

	handleDropdown(e){
		this.handleTextChange(e);
	}

	handleTextChange(e){
		const { user } = this.state;;
		const name = e.target.name;
		const value = e.target.value;

		user[name] = value;

		this.setState({user});
	}

	saveUser(){
		const { user, create, key } = this.state;
		user.username = user.username.trim();

		const form = { user };
		form.mode = create ? 'create' : 'modify';
		form.key = key;

		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.post('/api/user',{
				form
			}
		).then(
			(res) => {
				const {error, result, key } = res.data;
				if(result != 'Success'){
					alert('Unable to save data. '+error);
				} else {
					this.setState({key, create: false}, ()=>{
						alert('Success!');
					});
				}
			})
		.catch((e)=>console.log(e));
	}

	resetUser(){
		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		this.state.user.key = this.state.key;
		axios.post('/api/resetUser',{
				user: this.state.user
			}
		).then(
			(res) => {
				if(res.data.result === "Success"){
					alert('User password has been reset! The user has been notified.');
				} else {
					alert('Error: Could not reset user password.')
				}
			})
			.catch((e)=>console.log(e));
	}

	deleteUser(){
		if(!window.confirm('This will completely remove the user and all access to PPF Capital. Continue?')){
			return;
		}

		axios.defaults.headers.common['Authorization'] = this.props.auth.token;
		axios.delete('/api/user',{
			params: {
				userId: this.state.key,
				uid: this.state.user.uid,
			}
		}).then(
			(res) => {
				if(res.data.result === "Success"){
					alert('User deleted!')
					this.props.history.goBack();
				} else {
					alert('Error: Could not delete user.')
				}
			})
		.catch((e)=>{
			this.setState({create:false});
		});
	}


	setRole(role, selected){
		const { user } = this.state;
		const { permissions = {} } = user;
		role = role.toLowerCase();

		if(role == 'employee'){
			user.two_factor_auth = true;
		}

		if(selected){
			delete permissions[role];
		} else {
			permissions[role] = {};
		}
		user.permissions = permissions;
		this.setState({user});
	}

	setFeatureAccess(role, feature, parts = [], selected){
		const { user } = this.state;
		const { permissions = {} } = user;

		if(role == 'employee'){
			user.two_factor_auth = true;
		}

		if(selected){
			delete permissions[role][feature];
			if(feature === 'dataroom'){
				user.files = [];
			}
		} else {
			if(parts.length == 0){
				permissions[role][feature] = {read: true};
			} else if(parts.length == 1){
				permissions[role][feature] = {};
				permissions[role][feature][parts[0].type] = {read: true};
			} else {
				permissions[role][feature] = {};
			}
		}

		user.permissions = permissions;
		this.setState({user});
	}

	renderFileAccessModal(){
		if(!this.state.fileAccessModal){
		  return(
			<div></div>
		  )
		}
		return(
		  <div id="myModal" className="modal row">
			<div className="modal-content form col l6 s10 offset-s1 offset-l3">
			  <span className="close" onClick={()=>this.setState({fileAccessModal: false})}>&times;</span>
			  <h3>Data Room Access</h3>
			  <UserFileAccess form={this.state} auth={this.props.auth} onChange={this.onFileAccessChange}/>
			</div>
		  </div>
		)
	  }

	renderClientAccess(){
		if(!this.state.user.permissions.client){
			return;
		}

		const { dataroom } = this.state.user.permissions.client;
		const sections = [
			{
				name: 'Data Room',
				access: dataroom != null,
				type: 'dataroom',
				title: 'Data Room Access',
				parts: [],
				render: ()=>{
					return(
						<div className='row center'>
							<br/>
							<button
								className = 'ppf-primary-button'
								onClick = {()=>this.setState({fileAccessModal: true})}
							>View File Access</button>
						</div>
					);
				}
			},
		];
		const readOnly = !hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
		&& !hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write');
		return (this.renderAccessBlock('Client Access', sections, 'client', readOnly));
	}

	renderAdminAccess(){
		const initialPermission = {read: false, write: false, delete: false};

		if(!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'read')){
			return;
		}

		if(!this.state.user.permissions.admin){
			return;
		}

		const { userManagement, analytics, publications, alerts, dictionary, riskRating  } = this.state.user.permissions.admin;
		const { userManagement: userManagementAccess = initialPermission, dataRoomManagement = initialPermission } = userManagement || {};
		const { analytics: analyticsAccess = [{read: false}] } = analytics || {};
		const { publications: publicationsAccess = initialPermission } = publications || {};
		const { alerts: alertsAccess = initialPermission } = alerts || {};
		const { dictionary: dictionaryAccess = initialPermission } = dictionary || {};
		const { riskRating: riskRatingAccess = initialPermission } = riskRating || {};

		const sections = [
			{
				name: 'User Management',
				access: userManagement != null,
				type: 'userManagement',
				title: 'User Management Access',
				parts: [
					{title: 'User Management', permissions: ['Read', 'Write', 'Delete'], access: userManagementAccess, type: 'userManagement'},
					{title: 'Data Room Management', permissions: ['Read', 'Write', 'Delete'], access: dataRoomManagement, type: 'dataRoomManagement'},
				]
			},
			{
				name: 'Analytics',
				access: analytics != null,
				type: 'analytics',
				title: 'Analytics',
				parts: [
					{title: 'Analytics', permissions: ['Read'], access: analyticsAccess, type: 'analytics'}
				]
			},
			{
				name: 'Publications',
				access: publications != null,
				type: 'publications',
				title: 'Publications',
				parts: [
					{title: 'Publications', permissions: ['Read', 'Write', 'Delete'], access: publicationsAccess, type: 'publications'}
				]
			},
			{
				name: 'Alerts',
				access: alerts != null,
				type: 'alerts',
				title: 'Alerts',
				parts: [
					{title: 'Alerts', permissions: ['Read', 'Write'], access: alertsAccess, type: 'alerts'}
				]
			},
			{
				name: 'Dictionary',
				access: dictionary != null,
				type: 'dictionary',
				title: 'Dictionary',
				parts: [
					{title: 'Dictionary', permissions: ['Read', 'Write', 'Delete'], access: dictionaryAccess, type: 'dictionary'}
				]
			},
			{
				name: 'Risk Rating',
				access: riskRating != null,
				type: 'riskRating',
				title: 'Risk Rating',
				parts: [
					{title: 'Risk Rating', permissions: ['Read', 'Write', 'Delete'], access: riskRatingAccess, type: 'riskRating'}
				]
			},
		];

		const readOnly = !hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write');
		return (this.renderAccessBlock('Admin Access', sections, 'admin', readOnly));
	}

	renderEmployeeAccess(){
		const initialPermission = {read: false, write: false, delete: false};

		if(!this.state.user.permissions.employee){
			return;
		}

		if(!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'read')){
			return;
		}

		const { database, amlManagement, clientManagement, dealsManagement, vendorsManagement, dataroom  } = this.state.user.permissions.employee;
		const { schemas = initialPermission, datasets = initialPermission, queryTool = initialPermission } = database || {};
		const { general, clientInfo, documents, reports, deals, contacts, users, questions, sanctions, entities, risk } = clientManagement || {};
		const { amlRiskRatings, amlScreenings, amlSearch } = amlManagement || {};
		const { dataRoomRoot = initialPermission } = dataroom || {};
		const { general: dealsGeneral, documents: dealsDocuments, charges: dealsCharges, estimates, milestones: dealsMilestones, contacts: dealsContacts } = dealsManagement || {};
		const { general: vendorsGeneral, documents: vendorsDocuments, charges: vendorsCharges, milestones: vendorsMilestones, contacts: vendorsContacts } = vendorsManagement || {};

		const sections = [
			
			{
				name: 'Data Room',
				type: 'dataroom',
				access: dataroom != null,
				title: 'Data Room Access',
				parts: [
					{title: 'Data Room Root', permissions: ['Write'], access: dataRoomRoot, type: 'dataRoomRoot'},
				],
				render: ()=>{
					return(
						<div className='row center'>
							<br/>
							<button
								className = 'ppf-primary-button'
								onClick = {()=>this.setState({fileAccessModal: true})}
							>View File Access</button>
						</div>
					);
				}
			},	
			{
				name: 'Deals Management',
				type: 'dealsManagement',
				access: dealsManagement != null,
				title: 'Deals Management',
				parts: [
					{title: 'General Information', permissions: ['Read', 'Write', 'Delete'], access: dealsGeneral, type: 'general'},
					{title: 'Documents', permissions: ['Read', 'Write', 'Delete'], access: dealsDocuments, type: 'documents'},
					{title: 'Charges', permissions: ['Read', 'Write', 'Delete'], access: dealsCharges, type: 'charges'},
					{title: 'Estimates', permissions: ['Read', 'Write', 'Delete'], access: estimates, type: 'estimates'},
					{title: 'Milestones', permissions: ['Read', 'Write', 'Delete'], access: dealsMilestones, type: 'milestones'},
					{title: 'Contacts', permissions: ['Read', 'Write', 'Delete'], access: dealsContacts, type: 'contacts'},
				]
			},
			{
				name: 'Vendors Management',
				type: 'vendorsManagement',
				access: vendorsManagement != null,
				title: 'Vendors Management',
				parts: [
					{title: 'General Information', permissions: ['Read', 'Write', 'Delete'], access: vendorsGeneral, type: 'general'},
					{title: 'Documents', permissions: ['Read', 'Write', 'Delete'], access: vendorsDocuments, type: 'documents'},
					{title: 'Charges', permissions: ['Read', 'Write', 'Delete'], access: vendorsCharges, type: 'charges'},
					{title: 'Contacts', permissions: ['Read', 'Write', 'Delete'], access: vendorsContacts, type: 'contacts'},
				]
			},
			{
				name: 'Client Management',
				type: 'clientManagement',
				access: clientManagement != null,
				title: 'Client Management',
				parts: [
					{title: 'General Information', permissions: ['Read', 'Write', 'Delete'], access: general, type: 'general'},
					{title: 'Client Information', permissions: ['Read', 'Write'], access: clientInfo, type: 'clientInfo'},
					{title: 'Documents', permissions: ['Read', 'Write', 'Delete'], access: documents, type: 'documents'},
					{title: 'Reports', permissions: ['Read', 'Write', 'Delete'], access: reports, type: 'reports'},
					{title: 'Risk', permissions: ['Read', 'Write'], access: risk, type: 'risk'},
					{title: 'Entities', permissions: ['Read', 'Write', 'Delete'], access: entities, type: 'entities'},
					{title: 'Contacts', permissions: ['Read', 'Write', 'Delete'], access: contacts, type: 'contacts'},
					{title: 'Users', permissions: ['Read'], access: users, type: 'users'},
					{title: 'Questions', permissions: ['Read', 'Write'], access: questions, type: 'questions'},
					{title: 'Sanctions', permissions: ['Read'], access: sanctions, type: 'sanctions'}
				]
			},
			{
				name: 'AML Management',
				type: 'amlManagement',
				access: amlManagement != null,
				title: 'AML Management',
				parts: [
					{title: 'AML Screenings', permissions: ['Read', 'Write'], access: amlScreenings, type: 'amlScreenings'},
					{title: 'AML Risk Ratings', permissions: ['Read'], access: amlRiskRatings, type: 'amlRiskRatings'},
					{title: 'AML Search', permissions: ['Read'], access: amlSearch, type: 'amlSearch'}
				]
			},
			{
				name: 'Database',
				type: 'database',
				access: database != null,
				title: 'Database Access',
				parts: [
					{title: 'Schemas', permissions: ['Read', 'Write', 'Delete'], access: schemas, type: 'schemas'},
					{title: 'Datasets', permissions: ['Read', 'Write', 'Delete'], access: datasets, type: 'datasets'},
					{title: 'QueryTool', permissions: ['Read'], access: queryTool, type: 'queryTool'},
				]
			}
		];

		const readOnly = !hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write');
		return (this.renderAccessBlock('Employee Access', sections, 'employee', readOnly));
	}

	renderCRUDPermissions(title, displays, values, role, feature, partType, readOnly){
		const permissionElements = [];

		displays.forEach(val=>{
			const permissionType = val.toLowerCase();
			const value = values[permissionType] || false;
			const key = `${feature}-${partType}-${permissionType}`;

			permissionElements.push(
				<div className = 'col l4 s4'>
					<Checkbox
						key={key}
						placeholder= {val}
						className = 'col s12'
						name={key}
						value={value}
						disabled = {readOnly}
						onChange={(e, id)=> this.onPermissionSelect(role, feature, partType, permissionType, value) }
					/>
				</div>
			);
		});

		return (
			<div className = 'col s12'>
				<span style={{fontSize: '18px', fontWeight: '200'}}>{title}</span>
				<br/>
				{permissionElements}
			</div>
		);
	}

	renderAccessBlock(blockTitle, sections, type, readOnly){
		const height = '30px';
		const textSize = '16px';
		let featureElements = [];
		let sectionElements = [];
		let action = (feature, parts, selected) => () => this.setFeatureAccess(type, feature, parts, selected);

		if(readOnly){
			action = () => {};
		}

		// Render Features Available
		sections.forEach(({name = 'Unknown', access = false, type: featureType, parts = []})=>{
			featureElements.push(
				<div className="col l4 s12 left">
					{this.renderBlock(height, access, name, textSize, action(featureType, parts, access))}
				</div>
			);
		});

		// Render Access Section
		sections.forEach((sec)=>{
			const {access, title, parts, render, type: featureType} = sec;
			const sectionPartElements = [];

			if(!access){
				return;
			}

			// Render Permissions Options
			parts.forEach((part)=>{
				const {title, permissions, access = {}, type: partType} = part;

				sectionPartElements.push(
					this.renderCRUDPermissions(title, permissions, access, type, featureType, partType, readOnly)
				);
			});

			if(render){
				sectionPartElements.push(render());
			}

			sectionElements.push(
				<div className = 'col l4 s12' style={{borderRight: '1px #f1f1f1 solid'}}>
					<h5 className = 'center'>{title}</h5>
					{sectionPartElements}
				</div>
			)
		});

		// Lets spit into rows to look better
		if(sectionElements.length > 3){
			const rows = [];
			const pre = sectionElements;
			let lastI;
			sectionElements.forEach((el, i)=>{
				if(i && i % 3 == 0){
					rows.push(
						<div className = 'col s12 row'>
							{
								pre.slice(i-3, i)
							}
						</div>
					);
					lastI = i;
				}
			});

			rows.push(
				<div className = 'col s12 row'>
					{pre.slice(lastI, sectionElements.length + 1)}
				</div>
			);

			sectionElements = rows;
		}

		return(
			<div className="col s12" style={{ padding: "5px" }}>
				<div className="col s12 card">
					<div className="card-content">
						<span className="card-title">{blockTitle}</span>
						<div className="col l12 left" style={{paddingTop: '10px'}}>
							{featureElements}
						</div>
						<br/><br/><br/>
						<hr className = 'ppf-hr'/>
						<div className = 'col s12 row'>
							{sectionElements}
						</div>
					</div>
				</div>
			</div>
		)
	}

	renderBlock(h = "auto", selected = false, text = "", textSize = "18px", onClick) {
		const color = selected ? solid : blurred;
		return (
			<div className="valign-wrapper" style={{ backgroundColor: color, height: h, borderRadius: "4px", marginTop: '10px'}} onClick={onClick}>
				<span className="col s12 center white-text" style={{ fontWeight: "300", fontSize: textSize }}>
					{text}
				</span>
			</div>
		);
	}

	renderUserCompanyAccess(){
		const { errors, user, companies } = this.state;
		const height = '30px';
		const textSize = '16px';

		if(!user){
			return;
		}

		if(!companies){
			this.getCompanies();
			return
		}

		const companyTypesElements = [];
		const companyTypes = companies;

		if(!user.permissions || !user.permissions.employee){
			return;
		}

		const action = (id, value) => {
			this.state.user.companies = this.state.user.companies || {};
			this.state.user.companies[id] = value;
			this.setState({user: this.state.user});
		}

		companyTypes.forEach(({ name, id})=>{
			const selected = !!this.state.user.companies && !!this.state.user.companies[id];
			companyTypesElements.push(
				<div className="col l4 s12 left">
					{
						this.renderBlock(height, selected, name, textSize, ()=>{action(id, !selected)})
					}
				</div>
			);
		});

		return (
			<div className="col s12" style={{ padding: "5px" }}>
				<div className="col s12 card">
					<div className="card-content">
						<span className="card-title">Employee Company Access</span>
						<div className="col l12 left" style={{paddingTop: '10px'}}>
							{companyTypesElements}
							<br/>
							<br/>
							<br/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderRoles() {
		const { errors, user } = this.state;
		const { permissions = {} } = user;
		const height = "80px";
		const textSize = "20px";
		const hasUserManagementRead = hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'read');

		let action = (val, selected) => ()=>{
			switch(val){
				case 'Client':
					if(
						!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
						&& !hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
					){
						return;
					}
					break;
				default:
					if(!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')){
						return;
					}
			}
			this.setRole(val, selected)
		};

		return (
			<div className="col l6 s12" style={{ padding: "5px" }}>
				<div className="col s12 card" style={{ height: "250px" }}>
					<div className="card-content">
						<span className="card-title">Roles</span>
						<div className="row valign-wrapper" style={{ height: "150px" }}>
							{
								hasUserManagementRead &&
								<div className="col l4 center">
									{this.renderBlock(height, permissions.employee != null, "Employee", textSize, action('Employee', permissions.employee != null))}
								</div>
							}
							<div className="col l4 center">
								{this.renderBlock(height, permissions.client != null, "Client", textSize, action('Client', permissions.client != null))}
							</div>
							{
								hasUserManagementRead &&
								<div className="col l4 center">
									{this.renderBlock(height, permissions.admin != null, "Admin", textSize, action('Admin', permissions.admin != null))}
								</div>
							}

						</div>
					</div>
				</div>
			</div>
		);
	}

	renderGeneral() {
		const { errors,  user, clients, fetching, error } = this.state;
		const { clientname, username, phonenumber, client_id } = user;

		if(!clients && !fetching && !error){
			this.getClients();
			return;
		}

		return (
			<div className="col l6 s12" style={{ padding: "5px" }}>
				<div className="col s12 card" style={{ minHeight: "250px" }}>
					<div className="card-content">
						<span className="card-title">General</span>

						<CustomInput
							key={"general-clientname"}
							type={"text"}
							className="col l5"
							name={"clientname"}
							placeholder={"Full Name"}
							errors={errors}
							value={clientname || ""}
							handleChange={this.handleTextChange}
							disabled={
								!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
								&& !hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
							}
						/>
						<CustomInput
							key={"general-username"}
							type={"text"}
							className="col l6 offset-l1"
							name={"username"}
							placeholder={"Username/Email"}
							errors={errors}
							value={username || ""}
							handleChange={this.handleTextChange}
							disabled={
								!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
								&& !hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
							}
						/>
						<CustomInput
							key={"general-phonenumber"}
							type={"text"}
							className="col l5"
							name={"phonenumber"}
							placeholder={"Phone Number"}
							errors={errors}
							value={phonenumber || ""}
							handleChange={this.handleTextChange}
							disabled={
								!hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
								&& !hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
							}
						/>
						<Select
							key="general-client"
							className="col l6 offset-l1"
							placeholder="Associated Client"
							name ="client_id"
							value={client_id}
							errors={[]}
							options={clients}
							onChange={(e)=>this.handleDropdown(e)}
						/>
					</div>
				</div>
			</div>
		);
	}

	renderRolePermissions(){
		const { user } = this.state;
		const { permissions = {} } = user;

		return(
			<div>
				{permissions.employee && <div className="col s12">
					{this.renderEmployeeAccess()}
				</div>}
				{permissions.admin && <div className="col s12">
					{this.renderAdminAccess()}
				</div>}
				{permissions.client && <div className="col s12">
					{this.renderClientAccess()}
				</div>}
			</div>
		);
	}

	render() {
		const { user = {} } = this.state;
		const { two_factor_auth = false } = user;
		return (
			<div className="app row">
				<div className="col l12">
					<button
						className="ppf-nav-button left"
						onClick={this.onBack}
						style={{ marginLeft: "5%" }}
					>
						Back
         			 </button>
				</div>
				<div className="container" style={{ marginTop: "7%" }}>
					<div className="col s12 valign-wrapper">
						<h2 className="col s12 left">Permission Management</h2>
						<div className="col s12 right">
							{
								(
									hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
									|| hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
								)
								&& <button className="ppf-danger-button right" onClick={this.resetUser}>Reset Password</button>
							}
							{
								(
									hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'delete')
									|| hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'delete')
								)
								&& <button className="ppf-danger-button right padded-right" onClick={this.deleteUser}>Delete</button>
							}
							{
								(
									hasPermission(this.props.auth.user, 'userManagement', 'userManagement', 'write')
									|| hasPermission(this.props.auth.user, 'userManagement', 'dataRoomManagement', 'write')
								)
								&& <button className="ppf-primary-button right padded-right" onClick={this.saveUser}>Save</button>
							}
						</div>
					</div>
					<div className = 'col s12 valign-wrapper'>
						<div className='col l2 offset-l7 right'>
							<span className = 'col s12 ppf-control-title'>2 Factor Auth:</span>
						</div>
						<div className='col l2 left'>
							<Switch
								id="two_factor_auth"
								checked={two_factor_auth}
								name="two_factor_auth"
								offLabel="Off"
								onChange={(e)=>{
									let value = e.target.checked;
									this.state.user.two_factor_auth = value;
									this.setState(this.state);
								}}
								onLabel="On"
							/>
						</div>

						<button className="ppf-danger-button col l2" onClick={()=>{
							this.state.user.secret = null;
							this.setState(this.state, ()=>{
								this.saveUser();
							});
						}}>Rest 2FA</button>
					</div>
					<div className="col l12">
						{this.renderGeneral()}
						{this.renderRoles()}
					</div>
					<div className="col l12">
						{this.renderUserCompanyAccess()}
					</div>
					{this.renderRolePermissions()}
				</div>
				{this.renderFileAccessModal()}
			</div>
		);
	}
}

function mapStateToProps(state){
	return {
	  auth: state.auth
	};
  }

export default connect(mapStateToProps)(UserPermissions);