import React, { Component } from 'react';
import {connect} from 'react-redux'
import _ from 'lodash';
import axios from 'axios';
import { uploadFiles } from '../../../../../utils/FileHandler';
import { hasPermission } from '../../../../../utils/PermissionUtil';
import Input from '../../../../Generics/Input';
import Select from '../../../../Generics/Select';
import Measures from '../../../../Generics/Measures';
import Locations from '../../../../Generics/Locations';
import FileUpload from '../../../../Generics/FileUpload';
import { datasetTypes, getIndustries } from '../../../../Dictionaries/datasetTypes';
import { baseTypes, currencies } from '../../../../Dictionaries/yDataTypes.js';
import yLabelTypes from '../../../../Dictionaries/yLabelTypes';
import xDataTypes from '../../../../Dictionaries/xDataTypes';
import Rules from '../../../../Dictionaries/rules';

class Dataset extends Component {
    constructor(props){
        super(props);

        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
        this.onBack = this.onBack.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onRefresh = this.onRefresh.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteFile = this.onDeleteFile.bind(this);
        this.onRemoveFile = this.onRemoveFile.bind(this);

        // default state
        this.state = {
            dataset:{
                name: '',
                source: '',
                type: '',
                groups: [
                ],
            },
            errors: [],
            viewOnly: false,
            loading: false,
            modal: false
        };
        const { id, viewOnly } = this.props.match.params;
        if(viewOnly){
            this.state.viewOnly = viewOnly == 'true';
        }

        // Write Permission Check
        if(!hasPermission(this.props.auth.user, 'database', 'datasets', 'write')){
            this.state.viewOnly = true;
        }
        if(id != 'new'){
            this.state.loading = true;
            this.loadDataset(id);
        }
    }


    onBack() {
        const { edited } = this.state;

        if(edited){
            if (window.confirm('Any unsaved changes will be removed. Continue?')){
                this.props.history.goBack();
            }
        } else {
            this.props.history.goBack();
        }
    }

    onRefresh() {
        const {dataset} = this.state;

        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post(`/api/dataset/refresh`,{
            data: {
                dataset_id: dataset.id
            }
        }).then(
            (res) => {
                if(res.data.result == 'Success'){
                    this.loadDataset(dataset.id);
                    alert("Refreshed dataset");
                }
            }
        ).catch((e)=>console.log(e));
    }

    onSave(showAlert = true) {
        const {dataset} = this.state;

        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post(`/api/dataset`,{
            data: dataset
        }).then(
            (res) => {
                if(showAlert){
                    alert("Saved!");
                }
            }
        ).catch((e)=>console.log(e));
    }

    onViewModifyGroup(datasetKey, key, viewOnly = true) {
        const { history } = this.props;
        history.push(`/database/dataset/${datasetKey}/group/${key}/${viewOnly}`);
    }

    onDelete(){
        const { history } = this.props;
        const { id } = this.state.dataset;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        if(window.confirm("This will completely delete this dataset and it's associated data. Do you want to continue?")){
            axios.delete(`/api/dataset?id=${id}`).then(
                (res) => {
                    alert("Dataset has been deleted.")
                    history.goBack();
                }
            ).catch((e)=>{
                console.log(e);
                alert("Could not delete Record")
            });
        }
    }

    onRemoveFile(group, file){
        const grp = this.state.dataset.groups[group.key]
        grp.files.forEach((f, i)=>{
            if(f.file === file){
                grp.files.splice(i, 1);
            }
        });
        this.setState(this.state);
    }

    onDeleteGroup(group_id){
        const { dataset } = this.state;

        if(window.confirm("This will completely delete this group and it's associated data. Do you want to continue?")){
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.delete(`/api/dataset/group`,{
                data:{
                    group_id,
                    dataset_id: dataset.id
                }
            }).then(
                (res) => {
                    const {errors=[], result} = res.data;
                    if(result == 'Success'){
                        this.loadDataset(dataset.id);
                    }
                }
            ).catch((e)=>console.log(e))
            .finally(()=>{
                this.setState({loading: false});
            });
        }
    }
    onDeleteData(group, fileData, callback){
        const { id: dataset_id } = this.state.dataset;
        const {file, processed, path} = fileData;
        const { key:group_id } = group;
        const deleteAction = ()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.delete(`/api/dataset/batch`,
                {
                    data:{
                        dataset_id,
                        group_id,
                        fileName: file.name
                    }
                }
            ).then(
                (res) => {
                    const {errors=[], result} = res.data;
                    if(result == 'Success'){
                        this.loadDataset(dataset_id);
                    }
                }
            ).catch((e)=>{
                alert("Error: Could not delete records.")
            });
        }

        let message = "This will completely delete records that were created from this file. Do you want to continue?";
        if(window.confirm(message)){
            deleteAction();
        }
    }

    onDeleteFile(group, fileData){
        const { history } = this.props;
        const { id } = this.state.dataset;
        const {file, processed, path} = fileData;
        const deleteAction = ()=>{
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.delete(`/api/dataset/file`,
                {
                    data:{
                        id,
                        path,
                        processed
                    }
                }
            ).then(
                (res) => {
                    if(res.data.result === 'Success'){
                        this.onRemoveFile(group, file);
                        this.onSave(false);
                    } else {
                        alert("Error: Could not delete file.")
                    }
                }
            ).catch((e)=>{
                alert("Error: Could not delete file.")
            });
        }

        let message = "This will completely delete this file. Do you want to continue?";
        if(processed){
            message = "This will completely delete this file and it's associated records. Do you want to continue?";
        }
        if(window.confirm(message)){
            deleteAction();
        }
    }

    onUploadFileData(group, file){
        const { firebase } = this.props;
        const { id, groups } = this.state.dataset;
        const periodIndex = file.name.indexOf('.');
        const name = file.name.substr(0, periodIndex);
        const extension = file.name.substr(periodIndex, file.name.length);
        const fileName = `${name}_${Date.now()}${extension}`;

        file.fileName = fileName;

        uploadFiles(firebase, [{files: [file]}], `datasets/${id}/documents/`, (response)=>{
            let fileUrl;
            let storagePath;
            _.forEach(response, (data)=>{
                const {field, url, error, path} = data;
                if(error){
                    return;
                }
                fileUrl = url;
                storagePath = path;
            });
            let grp = groups[group.key];
            grp.files.map((f)=>{
                if(f.file === file){
                    const { fileName, size } = f.file;
                    f.url = fileUrl;
                    delete f.file;
                    f.file = { name: fileName, size };
                    f.path = storagePath;
                }
                return f;
            });
            this.setState(this.state, ()=>{
                alert("Successfully uploaded data!");
                this.onSave(false);
            });
        });
    }

    handleProcessFile(file, group){
        const { dataset } = this.state;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.post(`/api/dataset/batch`,{
            data:{
                file,
                group,
                dataset_id: dataset.id
            }
        }).then(
            (res) => {
                const {errors=[], result} = res.data;
                if(errors.length > 0){
                    alert(errors.join('\n'));
                }
                if(result == 'Success'){
                    this.loadDataset(dataset.id);
                }
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false});
        });
    }

    loadDataset(id){
        let dataset;
        axios.defaults.headers.common['Authorization'] = this.props.auth.token;
        axios.get(`/api/dataset?id=${id}`).then(
            (res) => {
                if(res.data && res.data.dataset){
                    dataset = res.data.dataset;
                    this.setState({dataset});
                } else {
                    alert("Could not find Record");
                }
            }
        ).catch((e)=>console.log(e))
        .finally(()=>{
            this.setState({loading: false, edited: false});
        });
    }

    handleTextChange(e, group, layer){
        const target = e.target;
        const name = target.name;
        let value = target.value;
        if(name === 'phonenumber'){
            value = target.value.replace(/\D/,'')
        }

        const dataset = this.state.dataset;
        if(group != undefined && layer != undefined){
            dataset.groups[group].layers[layer-1][name] = value;
        } else if(group != undefined){
            dataset.groups[group][name] = value;
        } else {
            dataset[name] = value;
        }
        this.setState({dataset});
        if(_.size(this.state.errors) > 0){
            this.setState({errors:{}});
        }
    }

    handleLocationChange(locationState){
        let { dataset } = this.state;
        dataset = Object.assign(dataset, locationState);
        this.setState({dataset});
    }

    handleSelectChange(e, group, layer){
        const target = e.target;
        const name = target.name;
        let value = target.value;

        const dataset = this.state.dataset;
        if(group != undefined && layer != undefined){
            dataset.groups[group].layers[layer-1][name] = value;
        } else if(group != undefined){
            dataset.groups[group][name] = value;
        } else {
            dataset[name] = value;
        }
        this.setState({dataset});
        if(_.size(this.state.errors) > 0){
            this.setState({errors:{}});
        }
    }

    handleUpload(event, key, group) {
        const { groups } = this.state.dataset;
        const uploadedFiles = event.target.files;
        let value = null;
        if(uploadedFiles.length > 0){
            const files = [];
            const grp = groups[group.key];
            let duplicate = false;

            for(let i = 0; i < uploadedFiles.length; i++){
                files.push({file: uploadedFiles.item(i), processed: false});
            }
            // Check for dupes
            if(grp.files){
                grp.files.forEach((a)=>{
                    files.forEach((b)=>{
                        if (a.file.name === b.file.name){
                            duplicate = true;
                        }
                    })
                });
            };
            if(duplicate){
                alert("Duplicate file found. You cannot upload the same file twice.");
                return grp;
            } else {
                // Merge new upload and previos upload
                grp.files = grp.files ? grp.files.concat(files) : files;
            }
            this.state.dataset.groups[group.key] = grp;
            this.setState(this.state);
        } else {
            this.state[key] = value;
            this.setState(this.state);
        }
    }

    renderDatasetDetails(){
        const { dataset, errors, viewOnly } = this.state;
        const { name, source, industry, type, locationType, region, country, custom_keys} = dataset;
        return(
            <div>
                <Input
                    key= 'dataset-name'
                    className={'col l3'}
                    type = 'text'
                    name= 'name'
                    placeholder= 'Dataset Name'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={name}
                    handleChange={this.handleTextChange}
                />
                <Input
                    key= 'dataset-source'
                    className={'col l3'}
                    type = 'text'
                    name= 'source'
                    placeholder= 'Source'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={source}
                    handleChange={this.handleTextChange}
                />
                <Input
                    key= 'dataset-keys'
                    className={'col s8'}
                    type = 'text'
                    name= 'custom_keys'
                    placeholder= 'Custom Keys (ex. energy, solar)'
                    inputClass={'col s12'}
                    errors={errors}
                    disabled={viewOnly}
                    value={custom_keys}
                    handleChange={this.handleTextChange}
                />
                <div className="col l12">
                    <Locations
                        disabled={viewOnly}
                        locationType ={locationType}
                        region = {region}
                        country = {country}
                        onChange = {(state)=>this.handleLocationChange(state)}
                    />
                    <Select
                        key= 'dataset-type'
                        className= 'col l3'
                        name= 'type'
                        placeholder= 'Data Type'
                        errors={errors}
                        value={type}
                        disabled={viewOnly}
                        options={datasetTypes}
                        onChange={this.handleSelectChange}
                    />
                    <Select
                        key= 'dataset-industry'
                        className= 'col l3'
                        name= 'industry'
                        placeholder= 'Industry'
                        errors={errors}
                        value={industry}
                        disabled={viewOnly}
                        options={getIndustries(type)}
                        onChange={this.handleSelectChange}
                    />
                </div>
            </div>
        );
    }

    renderGroupPreview(group){
        const {yDataType, xDataType, layers, measurementClass, measurement, currency} = group;
        const layerElements = [];
        layers.forEach((layer, i)=>{
            const {yLabelType, description, rule} = layer;
            i += 1;
            layerElements.push(
                <div className='col s12' key={`layer-container-${i}`} style={{paddingLeft: `${(i-1)*2}rem`}}>
                    <div key={`layer-${i}`}>
                        <Select
                            className= 'col l2'
                            name= 'yLabelType'
                            placeholder= 'Y Label Type'
                            value={yLabelType}
                            disabled={true}
                            options={yLabelTypes}
                        />
                        <Select
                            className= 'col l2'
                            name= 'rule'
                            placeholder= 'Data Rule'
                            value={rule ? rule : 'none'}
                            disabled={true}
                            options={Rules}
                        />
                        <Input
                            className={'col l4 s12'}
                            type = 'text'
                            name= 'description'
                            placeholder= {`Layer ${i} Description`}
                            inputClass={'col s12'}
                            disabled={true}
                            value={description}
                        />
                    </div>
                </div>
            );
        });
        return(
            <div className='row'>
                <h4>Layers Preview</h4>
                <div className = 'col s12' style={{paddingLeft: '0px', marginBottom: '1rem'}}>
                    <Select
                        className= 'col l3'
                        name= 'xDataType'
                        placeholder= 'X Data Type'
                        value={xDataType}
                        disabled={true}
                        options={xDataTypes}
                    />
                    <Select
                        className= 'col l3'
                        name= 'yDataType'
                        placeholder= 'Y Data Type'
                        value={yDataType}
                        disabled={true}
                        options={baseTypes}
                    />

                    {yDataType == 'measurement' && <Measures
                        className= 'col l3'
                        measurementClass ={measurementClass}
                        measurement = {measurement}
                        disabled={true}
                    />}
                     {yDataType == 'currency' && <Select
                        className= 'col l2'
                        name= 'currency'
                        placeholder= 'Currency Type'
                        value={currency}
                        options={currencies}
                        disabled={true}
                    />}
                </div>
                <br/>
                {layerElements}
            </div>
        );
    }

    renderModal(){
        const { modal, group } = this.state;
        let elements;
        if (!modal){
            return;
        }

        if(group){
            elements = this.renderGroupPreview(group);
        }

        return(
             <div id="myModal" className="modal row">
                <div className="modal-content form col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={()=>this.setState({modal:false})}>&times;</span>
                    {elements}
                </div>
            </div>
        );
    }

    renderFiles(group, files){
        const { viewOnly } = this.state;
        const fileElements = [];
        files.forEach((fileData)=>{
            let actions = [];
            const {file, processed, url, path} = fileData;
            const status = !url && !processed ?
                'Pending Upload' :
                url && !processed ? 'Pending Processing'
                : 'Completed';
            if (!url){
                actions = [
                    <button className="ppf-primary-button padded-right" onClick={()=>this.onUploadFileData(group, file)}>Upload</button>,
                    <button className="ppf-danger-button padded-right" onClick={()=>this.onRemoveFile(group, file)}>Remove File</button>,
                ];
            }
            if (!processed && url){
                actions .push(<button className="ppf-primary-button padded-right" onClick={()=>this.handleProcessFile(fileData, group)}>Process</button>);
            }

            if(url){
                actions .push(<button className="ppf-primary-button padded-right" onClick={()=>window.open(url)}>Download</button>);
                if(!viewOnly && hasPermission(this.props.auth.user, 'database', 'datasets', 'delete')){
                        if(!processed){
                        actions .push(<button className="ppf-danger-button padded-right" onClick={()=>this.onDeleteFile(group, fileData)}>Delete File</button>);
                    } else {
                        actions .push(<button className="ppf-danger-button padded-right" onClick={()=>this.onDeleteData(group, fileData)}>Delete Data</button>);
                    }
                }
            }

            fileElements.push(
                <tr>
                    <td>{file.name}</td>
                    <td>{`${(file.size/1000)}kb`}</td>
                    <td>{status}</td>
                    <td>
                        <div className='right'>{actions}</div>
                    </td>
                </tr>
            );
        });

        return(
            <div className="col s12" style={{padding:"15px"}}>
                {  <table className="col s12">
                        <thead>
                            <tr>
                                <th className="center ">File Name</th>
                                <th className="center">Size</th>
                                <th className="center">Status</th>
                                <th className="center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fileElements}
                        </tbody>
                    </table>
                }
            </div>
        );
    }

    renderGroups(){
        const { viewOnly } = this.state;
        const { groups, id: datasetKey } = this.state.dataset;
        const groupElements = [];

        let i = 0;
        for(const key in groups){
            const group = groups[key];
            group.key = key;
            const {name, description, files = []} = group;
            const fileName = 'group'+i;
            let fileElements = [<div></div>];
            if(files.length > 0){
                fileElements = this.renderFiles(group, files);
            }
            groupElements.push(
                <div>
                    <div className = "col l12" style={{paddingBottom: '1rem'}}>
                        <hr className="ppf-hr"/>
                    </div>
                    <div className = "col l12">
                        <h5 className = "left">{name}</h5>
                        {
                            !viewOnly
                            && !hasPermission(this.props.auth.user, 'database', 'datasets', 'write')
                            &&
                            <button className="ppf-danger-button right padded-right" onClick={()=>this.onDeleteGroup(key)}>Delete</button>
                        }
                        <button className="ppf-primary-button right padded-right" onClick={()=>this.onViewModifyGroup(datasetKey, key, false)}>Modify Data</button>
                        <button className="ppf-primary-button right padded-right" onClick={()=>this.onViewModifyGroup(datasetKey, key)}>View Data</button>
                        <button className="ppf-primary-button right padded-right" onClick={()=>this.setState({modal: true, group})}>View Layers</button>
                    </div>
                    <div className="col l12">
                        {description && <span className="left" style={{fontSize: '1.2rem', fontWeight: '200'}}>
                            {description}
                        </span>}
                    </div>
                    {!viewOnly && <div className = "col l12 valign-wrapper">
                        <FileUpload
                            hideFileName={true}
                            text='Add Files'
                            value={this.state[fileName]}
                            name={fileName}
                            className={'right padded-right'}
                            inputClassName={"ppf-primary-button left"}
                            onChange={(e)=>this.handleUpload(e, fileName, group)}
                        />
                    </div>}
                    {fileElements}
                </div>
            )
        };
        return(
            <div className = "col l12" style={{paddingTop: "1.5rem"}}>
                {groupElements}
            </div>
        );
    }

    render(){
        const { dataset, loading, viewOnly } = this.state;
        const { id } = dataset;
        if(loading){
            return(
                <div className="row">
                    <div className="container" style={{marginTop: '5%'}}>
                        <h4>Loading...</h4>
                    </div>
                </div>
            )
        }
        return(
            <div className="row">
                <div className="container" style={{marginTop: '5%'}}>
                    <div className="col s12" style={{paddingBottom: '2rem'}}>
                        <button className="ppf-nav-button col s1" onClick={this.onBack}>Back</button>
                        {!viewOnly && <button className="ppf-primary-button col s1 offset-s7 padded-right" onClick={this.onRefresh}>Refresh</button>}
                        {!viewOnly && <button className="ppf-primary-button col padded-right" onClick={this.onSave}>Save</button>}
                        {
                            !viewOnly
                            && id
                            && hasPermission(this.props.auth.user, 'database', 'datasets', 'delete')
                            && <button className="ppf-danger-button col s1" onClick={this.onDelete}>Delete</button>
                        }
                    </div>
                    <h2>{id ? 'Modify Dataset' : 'Create Dataset'}</h2>
                    {this.renderDatasetDetails()}
                    {
                        this.renderGroups()
                    }
                </div>
                {this.renderModal()}
            </div>
        );
    }
}

function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        firebase: state.firebase,
        history
    };
}

export default connect(mapStateToProps)(Dataset);