import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { authChange } from '../../../actions';
import { hasPermission } from '../../../utils/PermissionUtil';
import Cookies from 'universal-cookie';
import PasswordReset from '../Home/PasswordReset';
import Login from './Login';
import Setup2FA from './2FA/Setup2FA';
import VerifyWithSecret from './2FA/VerifyWithSecret';

const Loading = ()=>{
  return (
    <div className = 'container center' style = {{marginTop: '7%'}}>
      <h4>Loading.....</h4>
    </div>
  );
}

class PrivateRoute extends Component{
  componentDidMount(){
    const {dispatch, history} = this.props

    const fbUser = firebase ? firebase.auth().currentUser : null;
    if(fbUser){
      const auth = { fbUser };
      // authChange(auth, history, dispatch, true);
    }
  }

  checkViewPermissions(user, permissions = [], orPermissions = false){
    for(let i = 0; i < permissions.length; i++){
      const perm = permissions[i];
      const { feature, part, permission } = perm;
      const hasAuth = hasPermission(user, feature, part, permission);

      // Check if Only 1 permissions is needed
      if(orPermissions && hasAuth){
        return true;
      }

      // Checks that all permissions are met
      if(!hasAuth && !orPermissions){
        return false;
      }
    }

    return true;
  }

  render(){
    // default to Login View
    const { user, verified_2FA = false } = this.props.auth || {};
    let renderingComp = Loading;

    if(user){
      const { reset, two_factor_auth = false, secret } = user;
      // Check if password reset is needed
      if(reset){
          renderingComp = PasswordReset;
          return <Route path={this.props.path} component={renderingComp} />;
      }
      // Check for 2FA Incomplete
      else if(two_factor_auth && !secret){
        renderingComp = Setup2FA;
      }
      // Check for 2FA verification
      else if(two_factor_auth && !verified_2FA){
        renderingComp = VerifyWithSecret;
      }

      // Make sure the user has access to Nthe view
      else if(this.checkViewPermissions(this.props.user, this.props.permissions, this.props.orPermissions)){
          renderingComp = this.props.component;
      }
    } else {
      renderingComp = Login;
    }

    return <Route path={this.props.path} component={renderingComp} />
  }
}

function mapStateToProps(state){
  return {
    auth:state.auth
  };
}

export default connect(mapStateToProps)(PrivateRoute);