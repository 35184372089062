import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import Input from '../../Generics/Input';
import FileUpload from '../../Generics/FileUpload';

class UploadFiles extends Component{
    constructor(props) {
        super(props);
        this.state = {
            files: []
        };
    }

    onFileUpload() {
        if(this.state.files.length == 0){
            alert("No files attached!");
            return;
        }

        const { files } = this.state;
        const formData = new FormData();
        let path = this.props.path;
        if(path.charAt(path.length - 1) == "/"){
            path = path.slice(0, -1);
        }
        formData.append('path', path)
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        axios.post(
            '/api/dataRoom/upload',
            formData,
            {
                headers: {"Content-Type": "multipart/form-data"}
            }
        ).then(res => {
            alert("Success!");
            this.props.onBack();
        }).catch(err => {
            alert("Error: Could not upload files. Please try again.");
            console.log(err);
        })
    }

    /**
     * Handles the action of dropping a file on the uploader
     * or selecting a file through the uploader. All files
     * start in a pending state until onUpload is called.
     *
     * @param {Event} event
     */
    handleUpload(event) {
        const uploadedFiles = event.target.files;
        this.state.files = uploadedFiles;
        this.setState(this.state);
    }

    render(){
        const { value } = this.state;
        return (
            <div id="myModal" className="modal row">
                <div className="modal-content col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={() => this.props.onBack()}>&times;</span>
                    <h3>Create Folder</h3>
                    <div className="col s12 l12 left">
                    <FileUpload
                        className = 'col s12'
                        value = {value}
                        placeholder = 'Folder Name'
                        name = 'name'
                        onChange={(e)=>this.handleUpload(e)}
                    />
                    <button onClick={() => this.onFileUpload()} className='right ppf-primary-button col s4'>Submit</button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(UploadFiles);