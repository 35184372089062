
 const template = [
    {
        permission: [
            {feature: 'clientManagement', part: 'clientInfo'}
        ],
        title:"General Information",
        inputs:[
            { name: "businessName", type: "text", placeholder: "Business Name", className: "col s6", value: "", required: true },
            { name: "nature", type: "select", placeholder: "Nature of Business", className: "col s6", value:"", required: true, dictionaryId: '-MMceB1mgqtim5iJYb0r' },
            { name: "incorporation_type", type: "select", placeholder: "Incorporation Type", className: "col s6", required: true, dictionaryId: '-MMceHRdkvWMPUzx60gC'  },
            { name: "business_purpose", type: "select", placeholder: "Business Purpose", className: "col s6", required: true },
            { name: "financially_stable", type: "select",  placeholder: "Financial Stability", className: "col s6", yesNo:true, required: true },
            { name: "email", type: "text", placeholder: "Email Address", className: "col s6", value:"", required: true, sensitive: true },
            { name: "telephone", type: "text", placeholder: "Phone Number", className: "col s6", value:"", required: true, sensitive: true },
            { name: 'profile_image', placeholder: 'Profile Image', type: 'uploader', className: "col s7", autoUpload: true},
        ],
        pageButtons:[
            { name: 'save' }
        ]
    },
    {
        title: 'Address',
        inputs:[
            { name: "address", type: "address", className: "col s6", value: "", required: true }
        ]
    }
];

export default template;