import React, { useState } from 'react'
import axios from 'axios';
import Select from '../../../Generics/Select';
import { countries } from '../../../Dictionaries/locations';
import { SanctionMatchIndCard } from './SanctionMatchIndCard';
import { SanctionMatchEntityCard } from './SanctionsMatchEntityCard';
import { SanctionRecordDetailsPopUp } from './SanctionRecordDetails';
import indTestData from './data/indTestData';
import entityTestData from './data/entityTestData';

export default function SanctionsSearch() {
	const [results, setResults] = useState();
	const [firstName, setFName] = useState();
	const [middleName, setMName] = useState();
	const [lastName, setLName] = useState();
	const [companyName, setCName] = useState();
	const [dob, setDOB] = useState();
	const [country, setCountry] = useState();
	const [gender, setGender] = useState();
	const [type, setType] = useState();
	const [peps, setPep] = useState();
	const [interpol, setInterpol] = useState();
	const [loading, setLoading] = useState();
	const [selectedRecord, setSelectedRecord] = useState();

	const search = () => {
		if (!type) {
			return alert("You must select a record type.");
		}
		if (!firstName && type == 'individual') {
			return alert("You must enter a first name.");
		}
		if (!companyName && type == 'entity') {
			return alert("You must enter a company name.");
		}
		setLoading(true);
		setResults({});
		axios.post('/api/aml/search', {
			data:
			{
				first_name: firstName,
				last_name: lastName,
				middle_name: middleName,
				company_name: companyName,
				dob,
				country,
				type,
				gender,
				type,
				interpol,
				peps
			}
		}).then((res) => {
			setResults(res.data);
		}).catch((err) => {
			console.log(err);
			alert("Error fetching records")
		})
			.finally(() => {
				setLoading(false);
			});
	}
	let elements = [];
	const inputs = [
		{ name: 'first_name', func: setFName, value: firstName },
		{ name: 'middle_name', func: setMName, value: middleName },
		{ name: 'last_name', func: setLName, value: lastName },
		{ name: 'company_name', func: setCName, companyName },
		{ name: 'dob', func: setDOB, dob }
	].map(({ name, value, func }) => {
		return <input name={name} onChange={(e) => func(e.target.value)} value={value} placeholder={name} className="col l3 padded-right"></input>
	});
	const text = <div className="col s12">{inputs}</div>
	const generalDD = <div className="col s12">
		<Select
			className="col l3"
			placeholder="Record Type"
			options={[
				{ name: "Individual", value: "individual" },
				{ name: "Entity", value: "entity" }
			]}
			onChange={(e) => {
				setType(e.target.value);
			}}
		/>
		<Select
			className="col l3"
			placeholder="Interpol Search"
			options={[
				{ value: '1', name: 'Yes' },
				{ value: '0', name: 'No' },
			]}
			onChange={(e) => {
				setInterpol(e.target.value);
			}}
		/>
		<Select
			className="col l3"
			placeholder="Pep Search"
			options={[
				{ value: '1', name: 'Yes' },
				{ value: '0', name: 'No' },
			]}
			onChange={(e) => {
				setPep(e.target.value);
			}}
		/>
	</div>;
	const recordDD = <div className="col s12">
		<Select
			className="col l3"
			placeholder="Country"
			options={countries}
			onChange={(e) => {
				setCountry(e.target.value);
			}}
		/>
		<Select
			className="col l3"
			placeholder="Gender"
			options={[
				{ value: 'M', name: 'Male' },
				{ value: 'F', name: 'Female' },
				{ value: 'U', name: 'Other' },
			]}
			onChange={(e) => {
				setGender(e.target.value);
			}}
		/>
	</div>

	elements = [text, generalDD, recordDD];

	let matches = results && results.data && results.data.sanctions ? results.data.sanctions : [];
	let pepsMatches = results && results.data && results.data.peps ? results.data.peps : [];
	pepsMatches.map(x=>{
		x.peps = true;
		return x;
	});

	matches.push(...pepsMatches)
	matches = matches.filter((a, index, array) =>
		index === array.findIndex(b => a.id === b.id)
	);

	matches = matches.map((data) => {
		const formatted = {
			...data
		};
		return type == 'entity' ?
			<SanctionMatchEntityCard {...formatted} viewOnly={true} onView={() => setSelectedRecord(data)} /> :
			<SanctionMatchIndCard {...formatted} viewOnly={true} onView={() => setSelectedRecord(data)} />
	});

	// const resultFormatted = JSON.stringify(results.data, undefined, 2);
	return (
		<div className="app row">
			{selectedRecord && 
				<SanctionRecordDetailsPopUp data={selectedRecord} onClose={()=>setSelectedRecord()}/>
			}
			<div className="row no-print">
				<h2 className="center">AML Search</h2>
				<div className='col s12'>
				</div>
				<div className='container'>
					<div className="row">
						{elements}
					</div>
					<button onClick={() => search()} className='ppf-primary-button right'>Search</button>
					<div className='col s12'>{
						matches.length > 0 ?
							matches :
							<div className="center-align">
								<h4>{!results ? '' : loading ? 'Searching...' : 'No Matches Found'}</h4>
							</div>
					}</div>
					<div className="col s12 left">
						<p>
							Sources: OFAC Sanctions, UN Sanctions, EU Sanctions, UK Sanctions 
							{peps == '1' ? ', Every Politician, US CIA World Leaders, PPF Capital PEPS List' : ''}
							{interpol == '1' ? ', Interpol Red Notice' : ''}
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
