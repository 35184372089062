import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import Input from '../../Generics/Input';
import { fetchUserAndLoad } from '../../../actions';

class CreateFolder extends Component{
    constructor(props) {
        super(props);
        this.state = {
            value: ""
        };
    }

    onCreateFolder() {
        const { value } = this.state;
        let { path } = this.props;
        if(path.charAt(path.length - 1) == "/"){
            path = path.slice(0, -1);
        }
        axios.get(
            '/api/createFolder',
            {
                params: {
                    path: `${path}/${value}`
                }
            }
        ).then(res => {
            alert("Success");
            const { dispatch, history, auth } = this.props;
            fetchUserAndLoad(dispatch, history, auth, '/')
            this.props.onBack();
        }).catch(err => {
            alert("Error: Could not create folder. Please try again.");
        });
    }

    render(){
        const { value } = this.state;
        return (
            <div id="myModal" className="modal row">
                <div className="modal-content col l6 s10 offset-s1 offset-l3">
                    <span className="close" onClick={() => this.props.onBack()}>&times;</span>
                    <h3>Create Folder</h3>
                    <div className="col s12 l12 left">
                    <Input
                        className = 'col s12'
                        value = {value}
                        placeholder = 'Folder Name'
                        name = 'name'
                        handleChange = {(e)=>{
                            this.setState({value: e.target.value})
                        }}
                    />
                    <button onClick={() => this.onCreateFolder()} className='right ppf-primary-button col s4'>Save</button>
                    </div>
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(CreateFolder);