import React, {Component} from 'react';
import {connect} from 'react-redux'
import axios from 'axios';
import FileDownload from 'js-file-download';

class FileDetails extends Component{
    constructor(props) {
        super(props);
        this.state = {download:false, thumbnail: undefined};
        axios.post('/api/viewFileAudit',{
            file: this.props.file
        });
    }

    downloadFile() {
        this.setState({download:true});
            axios.defaults.headers.common['Authorization'] = this.props.auth.token;
            axios.defaults.headers.common['Accept'] = this.props.file.mimeType;
            axios.get('/api/download', {
                responseType: 'blob',
                params: {
                    file: this.props.file,
                    filePath: this.props.pathHistory
                }
            }).then(
                (res) => {
                FileDownload(res.data, this.props.file.name);
                this.setState({download:false});
                })
                .catch(
                (e) => {
                    this.setState({download:false});
                });
    }

    renderDescription(description) {
        if (typeof description != 'undefined') {
            return (
                <div className="row" style={{margin: '0px'}}>
                    <div className="col l2 label">Description:</div>
                    <div className="col l10">
                        <p>{description}</p>
                    </div>
                </div>
            )
        }
    }

    render(){
        const { modifiedTime, createdTime, description, name, thumbnailLink, createdBy } = this.props.file;
        const modified = new Date(modifiedTime);
        const created = new Date(createdTime);
        const { thumbnail } = this.state;
        if (thumbnail == undefined) {
            axios.get(
                '/api/getThumbLink',
                {
                    responseType: 'application/json',
                    params: {file: this.props.file}
                }
            ).then(res => {
                this.setState(st => ({...st, thumbnail: res.data.url ?? ""}));
            }).catch(err => {
                this.setState(st => ({...st, thumbnail: ""}));
            });
        }
        return (
            <div id="myModal" className="modal row">
                <div className="modal-content col l8 s10 offset-s1 offset-l2">
                    <span className="close" onClick={() => this.props.onBack()}>&times;</span>
                    <h3>{name}</h3>
                    {this.renderDescription(description)}
                    <div className="col s12 l4 left">
                        <div className="row" style={{margin: '0px'}}>
                            <div className="col s12 label">Created Date: {created.toLocaleDateString()}</div>
                        </div>
                        <div className="row" style={{margin: '0px'}}>
                            <div className="col s12 label">Modified Date: {modified.toLocaleDateString()}</div>
                        </div>
                        <div className="row" style={{margin: '0px'}}>
                            <div className="col s12 label">Created By: {createdBy}</div>
                        </div>
                        <div className="center col s12">
                            {!this.state.download && <button className="ppf-primary-button right col s12" onClick={() => this.downloadFile()}>Download</button>}
                            {this.state.download && <p>Downloading...</p>}
                        </div>
                    </div>
                    {thumbnailLink && <div className="col s12 l8 center-align">
                        <img className="responsive-img" src={thumbnailLink.replace('s220', 's550')}/>
                    </div>}
                    {thumbnail && thumbnail != "" && <div className="col s12 l8 center-align">
                        <img className="responsive-img" src={thumbnail}/>
                    </div>}
                </div>
            </div>
        )
    }
}
function mapStateToProps(state, {history}) {
    return {
        auth: state.auth,
        files: state.files,
        history
    };
}

export default connect(mapStateToProps)(FileDetails);